import React, {Component} from "react"
import {withRouter} from "react-router"
import {Link} from 'react-router-dom';
import api from "../../services/api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'
import ReactLoading from 'react-loading';
import axios from "axios";
import AdSense from 'react-adsense';
import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import StarComponent from "../../components/proUserStar"
import { Redirect } from "react-router-dom";


var spSizeOfBucket = 19

function isLoggedUser(userNameString){
    if (userNameString===null||userNameString===undefined||localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined){
      return false;
    }
    if (userNameString.trim()===localStorage.getItem('mdtUser').trim()){
      return true;
    }
    return false
}

class SearchPage extends Component{

    state = {
        loadingDisplay:'fixed',
        query:"",
        problemOnLoad:false,
        deckFolders:[],
        decksLoaded:[],
        cardsLoaded:[],
        decksLoadedOk:false,
        copyLinkModalIsOpen:false,
        selectedFilter:"views",
        selectedOrder:"asc",
        decksHash:{}, //key:deckName values:[] array of deck object
        folderHash:{}, //key:folder values:[] array of deckfolder objects || name, player, deckname and presentationorder
        folderHashButtons:{}, //key:folder values:array=0 = display inline array[1] = display block
        folderNamesTrack:{}, //key:old folder value:newFolderName
        decksBuckets:{},//key:'Commander match','deckname match',... value:a bucket = [] array of deck objects
        textsEn:{
            "seeMore":"See more",
            "default":"Default folder",
            "addDeck":"Create Deck",
            "addFolder":"Create folder",
            "nameNewFolder":"Name your new folder",
            "blockTitle":"Create your first deck",
            "blockMessage":"Welcome to Mydecktool decks page, create a new deck by clicking in the 'create deck' button, you can create your own custom categories for the cards in your decks, discover card recomendations, playtest, find out stores where you can get you list using less money and much more.",
            "formatsTitle":"Deck formats",
            "whyCategorizeTitle":"Why categorize the cards?",
            "whyCategorizeMessage":"It is a lot easier to understand what a deck is supposed to do if you label your cards in the right categories, it is quite common that MTG decks contains distinct cards that share functions, such as ramp, removal, bombs, card draw, wincons and so on.",
            "viewDeck":"View Deck",
            "howToSaveMoneyTitle":"How to save money?",
            "howToSaveMoneyMessage":"There is a LOT of MTG stores to purchase on the Internet, so it is quite hard to answer the question 'where do i buy the cards for my decks?', we can tell you what's the single store where your purchase have the minimun value for free, and we can even tell you what is the best combination of stores to make purchases with some restrictions for free.",
            "editDeck":"Edit deck",
            "playtest":"Test deck",
            "copyDeck":"Export deck"
        },
        textsPt:{
            "seeMore":"Ver mais",
            "default":"Pasta inicial",
            "addDeck":"Criar deck",
            "addFolder":"Criar pasta",
            "blockTitle":"Crie o seu primeiro deck",
            "nameNewFolder":"Coloque aqui o nome da sua pasta",
            "blockMessage":"Bem vindo a página de criação de decks, clique em criar deck para adicionar um novo baralho, categorize seus decks do seu jeito, faça playtests, receba recomendações de cards, descubra onde comprar gastando menos dinheiro e muito mais.",
            "whyCategorizeTitle":"Porquê categorizar as cartas?",
            "whyCategorizeMessage":"É muito mais fácil de entender o quê um deck deve fazer se você disser quais as categorias das cartas, é comum que baralhos de Magic tenham cartas que possuem funções similares, como ramp, remoções, bombas, compra de cartas, condições de vitória, etc.",
            "rampExample":"",
            "viewDeck":"Ver Deck",
            "howToSaveMoneyTitle":"Como economizar na compra de cartas?",
            "howToSaveMoneyMessage":"Existem muitas lojas de Magic na Internet, então é difícil de decidir onde comprar suas cartas gastando menos, nós podemos te dizer qual é a loja em que o seu deck sai mais barato de graça, podemos até te contar qual a melhor combinação de lojas para comprar com algumas restrições.",
            "editDeck":"Editar deck",
            "playtest":"Testar deck",
            "copyDeck":"Exportar deck"
        }
    }

    makeDefaultMessage = () => {
        return (
            <div className="presentationDecksPage">
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockTitle:this.state.textsEn.blockTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockMessage:this.state.textsEn.blockMessage}</p>
                <div className="mtg1">
                </div>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeTitle:this.state.textsEn.whyCategorizeTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeMessage:this.state.textsEn.whyCategorizeMessage}</p>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyTitle:this.state.textsEn.howToSaveMoneyTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyMessage:this.state.textsEn.howToSaveMoneyMessage}</p>
            </div>
        )
    }

    getDecksFaceCards = () => {
        let {decksLoaded} = this.state
        let deckFaceCards = []
        let deckFaceHash = {}
        for (let deck of decksLoaded){
            if (deck.maincard && deck.maincard!==""  && (deck.maincard in deckFaceHash===false)){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }

        return deckFaceCards
    }


    

    goToCreateDeckPage = () => {
        this.props.history.push("/createDeck")
    }


    processQueryParams = () => {
      let query = this.props.location.search
      let split = query.split('=')
      let queryNew = split[1].replace(/%20/g,'--')
      queryNew = queryNew.replace(/' '/g,'--')      
      return queryNew
    }

    loadDecksFromQuery = async () => {        
        let query = this.processQueryParams()
        
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        if (!query || query.trim()===""){
          this.setState({decksLoadedOk:false})
          return
        }
        
        let data = {
            "query": query,
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/deck/listFromQuery',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                
                let decksHash = {}
                for (let deck of res.data) {
                    if (!decksHash.hasOwnProperty(deck.deckname)){
                        decksHash[deck.deckname+'|||'+deck.player]=[]
                    }
                    decksHash[deck.deckname+'|||'+deck.player].push(deck)
                }
                
                let decksBuckets = this.prepareFolders(res.data,query,this.state.decksBuckets)
                
                this.setState({decksLoaded:res.data,decksHash:decksHash, decksLoadedOk:true, query,decksBuckets})
            }
        })
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')
    }

    reduceDeckName = (deckName) => {
        if (deckName.length<=29){
            return deckName;
        }
        return deckName.substring(0,26)+'...';
    }

    adjustDateFormat = (date) => {
        let parts = date.split('/')
        if(localStorage.getItem('countryCode')==="+55"){            
            return parts[2]+'/'+parts[1]+'/'+parts[0]
        }else if(localStorage.getItem('countryCode')==="+1"){
            return parts[1]+'/'+parts[0]+'/'+parts[2]
        }
        return date
    }

    LinkCopyedModal = () => {
        let message=undefined
        if(localStorage.getItem('countryCode')==='+55'){
            message='Link do deck copiado para a área de transferência'
        }else{
            message='Deck link saved in the transfer area.'
            
        }
        return (
            <div>
                <Modal
                isOpen={this.state.copyLinkModalIsOpen}
                onRequestClose={() => {this.setState({copyLinkModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%",textAlign:'center',padding:'5%'}}>
                    <div><h3>{message}</h3></div>
                    <button style={{width:'100%'}} className="deckPageButton" onClick={() => {this.setState({copyLinkModalIsOpen:false})}}>OK</button>
                </div>
                </Modal>
            </div>
        )
    }

    renderShareButton = (player,decklink) => {
        return (
            <CopyToClipboard style={{margin:'0px'}} text={'https://commanderdecks.com/users/'+player+'/decks/'+decklink}
                onCopy={() => this.setState({copied: true,copyLinkModalIsOpen:true})}>
                <nav className="darkBlueBt" ><FontAwesomeIcon style={{marginRight:'2px'}} icon={"share"}></FontAwesomeIcon>{localStorage.getItem('countryCode')==="+55"?"Compartilhar":"Share"}</nav>
            </CopyToClipboard>
        )
    }

    registerToString = (decklisttuple) => {
        let displayNameArray = decklisttuple.cardname.split(';')
        if (decklisttuple.layout==='modal_dfc'||decklisttuple.layout==='transform'){
            return {"name":displayNameArray[0].trim(),"qtdeName":decklisttuple.card_qtde+" "+displayNameArray[0].trim()+"\r\n"}
        }else{
          let qtde=decklisttuple.card_qtde+' '
          let name=''
          for(let i = 0; i<displayNameArray.length;i++){
              if (i===displayNameArray.length-1){
                name+=displayNameArray[i].trim()
              }else{
                name+=displayNameArray[i].trim()+" / "
              }
          }
          return {"name":name,"qtdeName":qtde+name+"\r\n"}
        }
    }

    getDeckString = async (player,decklink) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "player":player,
            "link":decklink
        }
        return await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/absoluteListLink',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            console.log(res.data)
            if (res.status === 200 && res.data.length>0) {                
                let mainboardStr = ""
                
                let commander = ""
                let companion = ""                
                let totalOfCards = 0
                let totalOfLands = 0                
                for (let decklisttuple of res.data){       
                    const displayCardValues = this.registerToString(decklisttuple)                     
                    if (decklisttuple.listtype==='mainboard'){                        
                        
                        totalOfCards+=decklisttuple.card_qtde
                        if (decklisttuple.type_en.toLowerCase().includes('land') && decklisttuple.cmc===0){
                            totalOfLands+=decklisttuple.card_qtde
                        }                        
                        mainboardStr+=displayCardValues.qtdeName                    
                    }else if(decklisttuple.listtype==='commander'){                        
                        commander+=displayCardValues.qtdeName
                    }else if(decklisttuple.listtype==='companion'){                        
                        companion+=displayCardValues.qtdeName
                    }
                }
                const deckString = commander+companion+mainboardStr;                            
                console.log(deckString)
                return deckString;
            }
        })
    }

    downloadTxtFile = async (player,decklink) => {
        const element = document.createElement("a");
        let deckString = await this.getDeckString(player,decklink);
        console.log(deckString)
        const file = new Blob([deckString],{type: 'text/plain;charSet=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = decklink+".txt";
        document.body.appendChild(element);
        element.click();
    }

    makeDecks = (folderDecks) => {
        let {decksHash} = this.state
        let foldersHtml = []
        
        if (this.state.selectedFilter==="date"&&this.state.selectedOrder==="asc")      
            folderDecks = folderDecks.sort(this.sortByDate);
        else if(this.state.selectedFilter==="views"&&this.state.selectedOrder==="asc")
            folderDecks = folderDecks.sort(this.sortByViews);
        else if(this.state.selectedFilter==="price"&&this.state.selectedOrder==="asc")
            folderDecks = folderDecks.sort(this.sortByPrice);
        else if (this.state.selectedFilter==="date"&&this.state.selectedOrder==="desc")      
            folderDecks = folderDecks.reverse(this.sortByDate);
        else if(this.state.selectedFilter==="views"&&this.state.selectedOrder==="desc")
            folderDecks = folderDecks.reverse(this.sortByViews);
        else if(this.state.selectedFilter==="price"&&this.state.selectedOrder==="desc")
            folderDecks = folderDecks.reverse(this.sortByPrice);
            
        for (let deck of folderDecks){              
              foldersHtml.push(
                <div className="deckPFolder" key={deck.deckname+deck.player+Math.random(1000)} style={{marginBottom:'5px'}}>
                    <div className="flip-box-deck">
                        <div className="flip-box-inner-deck">
                            <div className="flip-box-front-deck">
                            <Link to={"/users/"+decksHash[deck.deckname+'|||'+deck.player][0].player+'/decks/'+this.changeDeckName(deck.link)}>
                                <img className="deckfolderimage" key={decksHash[deck.deckname+'|||'+deck.player][0].name_en} alt={decksHash[deck.deckname+'|||'+deck.player][0].name_en} src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+decksHash[deck.deckname+'|||'+deck.player][0].last_edition+'/en/'+decksHash[deck.deckname+'|||'+deck.player][0].name_en+'.jpg'}></img>                                
                            </Link>
                            </div>
                            <div className="flip-box-back-deck">
                                <div style={{fontWeight:'600'}}>{deck.deckname}</div>
                                <Link to={"/users/"+decksHash[deck.deckname+'|||'+deck.player][0].player+'/decks/'+this.changeDeckName(deck.link)} className="youtube"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"eye"}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Ver deck':'View Deck'}</Link>
                                {isLoggedUser(deck.player)?<Link to={"/editDeck/"+decksHash[deck.deckname+'|||'+deck.player][0].player+'/'+this.changeDeckName(deck.link)} className="greenInsideButton"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"edit"}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Editar deck':'Edit Deck'}</Link>:<></>}
                                <Link to={"/users/"+decksHash[deck.deckname+'|||'+deck.player][0].player+'/decks/'+this.changeDeckName(deck.link)+'/playtest'} className="twitch"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"gamepad"}></FontAwesomeIcon>Playtest</Link>
                                {this.renderShareButton(deck.player,this.changeDeckName(deck.link))}
                                <nav className="pink" onClick={()=>this.downloadTxtFile(deck.player,this.changeDeckName(deck.link))}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"download"}/> {localStorage.getItem('countryCode')==="+55"?"Exportar":"Export"}</nav>
                            </div>
                        </div>
                    </div>  
                    <Link className="searchPageAHover" to={"/users/"+decksHash[deck.deckname+'|||'+deck.player][0].player+'/decks/'+this.changeDeckName(deck.link)}>
                          <div style={{fontWeight:'600'}}>{this.reduceDeckName(deck.deckname)}</div>  
                    </Link>
                    <div>
                    <StarComponent decklistobject={deck}/>
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                        {this.changeDisplayName(decksHash[deck.deckname+'|||'+deck.player][0].player)}</Link>
                    </div>
                    <div>
                        {this.adjustDateFormat(decksHash[deck.deckname+'|||'+deck.player][0].last_update)}
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                        <div className="iconButton textTip" style={{textAlign:'center',width:"121px"}}><FontAwesomeIcon className="spin60" style={{width:'25%',height:'22px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?"Qt. Visualizações":"Qt. Views"}</span> <p style={{display:'inline-block',fontSize:'22px'}}> {decksHash[deck.deckname+'|||'+deck.player][0].qtviews} </p>  </div>
                        <div className="iconButton textTip" style={{textAlign:'center',width:"121px"}}><FontAwesomeIcon className="spin60" style={{width:'25%',height:'22px'}} icon="dollar-sign"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?"Ultimo preço carregado":"Last loaded price"}</span>  <p style={{display:'inline-block',fontSize:'22px'}}> {parseInt(decksHash[deck.deckname+'|||'+deck.player][0].last_price,10)} </p> </div>
                    </div>
                </div>
              )
            

        }
        return foldersHtml
    }

    increaseBucket = (folder) => () =>{
      let {decksBuckets} = this.state

      if (decksBuckets[folder].length>1){
        let removed = decksBuckets[folder].splice(1,1)
        for (let deck of removed[0]){
            decksBuckets[folder][0].push(deck)
        }
      }

      this.setState({decksBuckets:decksBuckets})
    }

    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    makeDeckFolders2 = () => {
        let keys = Object.keys(this.state.decksBuckets)
        if(keys.length===0){
            if (this.state.decksLoadedOk===true){
                return (
                    <div style={{marginTop:'20px',textAlign:'center'}}>
                        <h2>{localStorage.getItem('countryCode')==='+55'?'Nenhum deck encontrado na busca':"No decks found"}</h2>
                        <FontAwesomeIcon icon="sad-tear" size="8x"></FontAwesomeIcon>
                    </div>
                );
            }else{
                return <></>
            }            
        }else if(keys.length===1 && this.state.decksBuckets.hasOwnProperty('Player match')){
            let player = this.state.decksBuckets['Player match'][0][0].player;
            return <Redirect to={"/users/"+player+"/decks"}></Redirect>
        }
        let out=[]
        for (let folder in this.state.decksBuckets){
            out.push(
                <div key={folder}>
                    <div className="decksPageFolderTitle"> <div className="tooltip"></div> {folder}   </div>

                    <div className="DeckFolder">
                        <div className="deckspage-grid-container">
                            {this.makeDecks(this.state.decksBuckets[folder][0])}
                        </div>
                        {this.state.decksBuckets[folder].length>1?<button className="defaultButton float" onClick={this.increaseBucket(folder)} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
                    </div>
                </div>
            )
        }
        return out
    }

    sortByDate = (deck1, deck2) => {
      if (deck1.last_update<deck2.last_update){
        return 1;
      }else{
        return -1;
      }
     
    }

    sortByViews = (deck1,deck2) =>{
        if (deck1.qtviews<deck2.qtviews){
            return 1;
        }else if (deck1.qtviews>deck2.qtviews){
            return -1
        }
        return 0;
    }

    sortByPrice = (deck1,deck2) =>{
        if (deck1.last_price<deck2.last_price){
            return 1;
        }else if (deck1.last_price>deck2.last_price){
            return -1
        }
        return 0;
    }

    prepareFolders = (decksLoaded,query, decksBuckets) => {
      
      let transformedQuery = query.replace(/--/g,' ');     
      decksLoaded = decksLoaded.sort(this.sortByViews);      
      for (let deck of decksLoaded){
        if (deck.colors.toUpperCase().includes(query.toUpperCase())){
            if (decksBuckets.hasOwnProperty('Colors match')===false){
              decksBuckets['Colors match'] = [[]]
            }
            let lastBucketPosition = decksBuckets['Colors match'].length-1
            if (decksBuckets['Colors match'][lastBucketPosition].length<spSizeOfBucket) {
              decksBuckets['Colors match'][lastBucketPosition].push(deck)
            } else {
              decksBuckets['Colors match'].push([])
              decksBuckets['Colors match'][lastBucketPosition].push(deck)
            }
            continue;
        }else if (deck.maincard.toUpperCase().includes(query.toUpperCase()) || deck.maincard.toUpperCase().includes(transformedQuery.toUpperCase()) ){
            if (decksBuckets.hasOwnProperty('Commander match')===false){
              decksBuckets['Commander match'] = [[]]
            }
            let lastBucketPosition = decksBuckets['Commander match'].length-1
            if (decksBuckets['Commander match'][lastBucketPosition].length<spSizeOfBucket) {
              decksBuckets['Commander match'][lastBucketPosition].push(deck)
            } else {
              decksBuckets['Commander match'].push([])
              decksBuckets['Commander match'][lastBucketPosition].push(deck)
            }
            continue;
        }else if (deck.f0rmat.toUpperCase().includes(query.toUpperCase())){
            if (decksBuckets.hasOwnProperty('Format match')===false){
              decksBuckets['Format match'] = [[]]
            }
            let lastBucketPosition = decksBuckets['Format match'].length-1
            if (decksBuckets['Format match'][lastBucketPosition].length<spSizeOfBucket) {
              decksBuckets['Format match'][lastBucketPosition].push(deck)
            } else {
              decksBuckets['Format match'].push([])
              decksBuckets['Format match'][lastBucketPosition].push(deck)
            }
            continue;
        }else if (deck.deckname.toUpperCase().includes(query.toUpperCase()) || deck.deckname.toUpperCase().includes(transformedQuery.toUpperCase())  ){
            if (decksBuckets.hasOwnProperty('Deck name match')===false){
              decksBuckets['Deck name match'] = [[]]
            }
            let lastBucketPosition = decksBuckets['Deck name match'].length-1
            if (decksBuckets['Deck name match'][lastBucketPosition].length<spSizeOfBucket) {
              decksBuckets['Deck name match'][lastBucketPosition].push(deck)
            } else {
              decksBuckets['Deck name match'].push([])
              decksBuckets['Deck name match'][lastBucketPosition].push(deck)
            }
            continue;
        }else if (deck.player.toUpperCase().includes(query.toUpperCase())){
            if (decksBuckets.hasOwnProperty('Player match')===false){
              decksBuckets['Player match'] = [[]]
            }
            let lastBucketPosition = decksBuckets['Player match'].length-1
            if (decksBuckets['Player match'][lastBucketPosition].length<spSizeOfBucket) {
              decksBuckets['Player match'][lastBucketPosition].push(deck)
            } else {
              decksBuckets['Player match'].push([])
              decksBuckets['Player match'][lastBucketPosition].push(deck)
            }
            continue;
        }else{
            if (decksBuckets.hasOwnProperty('Result')===false){
                decksBuckets['Result'] = [[]]
              }
              let lastBucketPosition = decksBuckets['Result'].length-1
              if (decksBuckets['Result'][lastBucketPosition].length<spSizeOfBucket) {
                decksBuckets['Result'][lastBucketPosition].push(deck)
              } else {
                decksBuckets['Result'].push([])
                decksBuckets['Result'][lastBucketPosition].push(deck)
              }
              continue;
        }
      }     
      return decksBuckets
    }

    componentDidMount = async () => {
        try{            
            await this.loadDecksFromQuery()
           
            this.setState({loadingDisplay:'none'})
        }catch(e){
            this.setState({problemOnLoad:true})
        }        
    }

    getFilterSelectionBox = () => {
        return(
            <>
                <label>
                    { window.innerWidth>=600? localStorage.getItem("countryCode")==="+55"?"Ordenar por ":"Order by ": <></>}
                </label>
                <select className="deckPageSelect" value={this.state.selectedFilter} onChange={(event)=>{this.setState({selectedFilter:event.target.value})}}>
                    <option value="views">{localStorage.getItem("countryCode")==="+55"?"Qt. Visualizações":"Qt. Views"}</option>
                    <option value="price">{localStorage.getItem("countryCode")==="+55"?"Preço":"Price"}</option>
                    <option value="date">{localStorage.getItem("countryCode")==="+55"?"Data":"Date"}</option>
                </select>
                <nav style={{display:'inline',marginLeft:'1px',marginRight:'1px'}}>:</nav>
                <select className="deckPageSelect" value={this.state.selectedOrder} onChange={(event)=>{this.setState({selectedOrder:event.target.value})}}>
                    <option value="asc">{localStorage.getItem("countryCode")==="+55"?"Do maior para menor":"ASC"}</option>
                    <option value="desc">{localStorage.getItem("countryCode")==="+55"?"Do menor para maior":"DESC"}</option>                    
                </select>
            </>
        )
    }

    render(){
        try{
            return (
                <>
                    <GlobalNavBar/>
                    
                    <div className='loading' style={{paddingTop:window.innerWidth>450?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight,display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                        <div style={{marginLeft:'35%'}}>
                          <ReactLoading type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                        </div>
                        <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                    </div>
                    <div style={{minHeight:"90vh"}}>
                        <div className="DeckBody">
                            {this.getFilterSelectionBox()}
                            {this.makeDeckFolders2()}
                            {this.LinkCopyedModal()}
                        </div>
                    </div>
                    
                    <BottomBar/>
                </>
            );
        }catch(e){
            return <Redirect to="/"></Redirect>
        }
        
    }
}
export default withRouter(SearchPage)
