import React, {Component} from "react"
import {withRouter} from "react-router"
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'



class PrivacyPage extends Component{

    state = {
        problemOnLoad:false,
    }

    render(){
        if (localStorage.getItem('countryCode')==='+55'){
            return (
                <>
                <GlobalNavBar/>
                <div style = {{marginLeft: window.innerWidth * 0.1, marginRight: window.innerWidth * 0.1, marginTop: "5px", marginBottom: "5px"}}>
                    <h1> Política de privacidade para Commander Decks </h1>

                    <p> No Commander Decks, acessível em https://commanderdecks.com, uma das nossas principais prioridades é a privacidade dos nossos visitantes. Este documento de Política de Privacidade contém tipos de informações que são coletadas e registradas pelo Commander Decks e como as usamos. </p>

                    <p> Se você tiver perguntas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em nos contatar. </p>

                    <p> Esta Política de Privacidade se aplica apenas às nossas atividades online e é válida para os visitantes do nosso site no que diz respeito às informações que eles compartilham e / ou coletam nos Commander Decks. Esta política não se aplica a nenhuma informação coletada offline ou por meio de outros canais que não este site. Nossa Política de Privacidade foi criada com a ajuda do <a href="https://www.privacypolicygenerator.info"> Gerador de Política de Privacidade Gratuito </a>. </p>

                    <h2> Consentimento </h2>

                    <p> Ao usar nosso site, você concorda com nossa Política de Privacidade e concorda com seus termos. </p>

                    <h2> Informações que coletamos </h2>

                    <p> As informações pessoais que você deve fornecer e os motivos pelos quais você deve fornecê-las serão esclarecidos no momento em que solicitarmos que você forneça suas informações pessoais. </p>
                    <p> Se você entrar em contato conosco diretamente, poderemos receber informações adicionais sobre você, como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e / ou anexos que você pode nos enviar e qualquer outra informação que você decida fornecer . </p>
                    <p> Quando você se registra para uma conta, podemos pedir suas informações de contato, incluindo itens como nome, nome da empresa, endereço, endereço de e-mail e número de telefone. </p>

                    <h2> Como usamos suas informações </h2>

                    <p> Usamos as informações que coletamos de várias maneiras, incluindo para: </p>

                    <ul>
                    <li> Fornecer, operar e manter nosso site </li>
                    <li> Melhorar, personalizar e expandir nosso site </li>
                    <li> Compreenda e analise como você usa nosso site </li>
                    <li> Desenvolva novos produtos, serviços, recursos e funcionalidades </li>
                    <li> Comunique-se com você, diretamente ou por meio de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao site e para fins de marketing e promocionais </li>
                    <li> Enviar e-mails para você </li>
                    <li> Encontre e evite fraudes </li>
                    </ul>

                    <h2> Arquivos de registro </h2>

                    <p> O Commander Decks segue um procedimento padrão de uso de arquivos de registro. Esses arquivos registram os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e fazem parte das análises dos serviços de hospedagem. As informações coletadas por arquivos de log incluem endereços de protocolo de internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência / saída e possivelmente o número de cliques. Eles não estão vinculados a nenhuma informação de identificação pessoal. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas. </p>

                    <h2> Cookies e Web Beacons </h2>

                    <p> Como qualquer outro site, o Commander Decks usa 'cookies'. Esses cookies são usados ​​para armazenar informações, incluindo as preferências dos visitantes e as páginas do site que o visitante acessou ou visitou. As informações são usadas para otimizar a experiência dos usuários, personalizando o conteúdo da nossa página da web com base no tipo de navegador dos visitantes e / ou outras informações. </p>

                    <p> Para obter mais informações gerais sobre cookies, leia <a href="https://www.cookieconsent.com/"> mais no site Cookie Consent </a>. </p>

                    <h2> Cookie Google DoubleClick DART </h2>

                    <p> O Google é um fornecedor terceirizado de nosso site. Ele também usa cookies, conhecidos como cookies DART, para veicular anúncios aos visitantes de nosso site com base em sua visita a www.website.com e outros sites na Internet. No entanto, os visitantes podem optar por recusar o uso de cookies DART visitando o anúncio do Google e a Política de Privacidade da rede de conteúdo no seguinte URL - <a href="https://policies.google.com/technologies/ads"> https: / /policies.google.com/technologies/ads </a> </p>

                    <h2> Nossos parceiros de publicidade </h2>

                    <p> Alguns anunciantes em nosso site podem usar cookies e web beacons. Nossos parceiros de publicidade estão listados abaixo. Cada um de nossos parceiros de publicidade tem sua própria Política de Privacidade para suas políticas de dados do usuário. Para facilitar o acesso, criamos um hiperlink para suas Políticas de Privacidade abaixo. </p>
                </div>
                </>
            );
        }

        return (
            <>                    
                <GlobalNavBar/>
                <div style={{marginLeft:window.innerWidth*0.1,marginRight:window.innerWidth*0.1,marginTop:"5px",marginBottom:"5px"}}>
                <h1>Privacy Policy for Commander Decks</h1>

                    <p>At Commander Decks, accessible from https://commanderdecks.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Commander Decks and how we use it.</p>

                    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Commander Decks. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the <a href="https://www.privacypolicygenerator.info">Free Privacy Policy Generator</a>.</p>

                    <h2>Consent</h2>

                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                    <h2>Information we collect</h2>

                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

                    <h2>How we use your information</h2>

                    <p>We use the information we collect in various ways, including to:</p>

                    <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                    </ul>

                    <h2>Log Files</h2>

                    <p>Commander Decks follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                    <h2>Cookies and Web Beacons</h2>

                    <p>Like any other website, Commander Decks uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

                    <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/">more on the Cookie Consent website</a>.</p>

                    <h2>Google DoubleClick DART Cookie</h2>

                    <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>

                    <h2>Our Advertising Partners</h2>

                    <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

                    <ul>
                        <li>
                            <p>Google</p>
                            <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
                        </li>
                    </ul>

                    <h2>Advertising Partners Privacy Policies</h2>

                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Commander Decks.</p>

                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Commander Decks, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

                    <p>Note that Commander Decks has no access to or control over these cookies that are used by third-party advertisers.</p>

                    <h2>Third Party Privacy Policies</h2>

                    <p>Commander Decks's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                    <p>Under the CCPA, among other rights, consumers have the right to:</p>
                    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                    <h2>GDPR Data Protection Rights</h2>

                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                    <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                    <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                    <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                    <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                    <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                    <h2>Children's Information</h2>

                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

                    <p>Commander Decks does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                </div>
                <BottomBar/>
            </>
        );
    }
}
export default withRouter(PrivacyPage);