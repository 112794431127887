import React, { Component } from "react";
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../services/api';
import './style.css'
import {Link} from 'react-router-dom';
import FacebookButton from '../../components/facebookButton'
const axios = require('axios');

class RegisterBox extends Component {

  
    state = {
        login: "",
        password: "",
        hiddenPassword: true,
        confirmPassword:"",
        hiddenConfirmPassword:true,
        token: "",
        nickname:'',
        writedNickname:false,
        modalBox:false,
        modalBoxMessage:"",
        modalBoxTitle:"",
        responseGet:null,
        chkbox:true,
        displayMessage:false,
        message:"",
        textsEn:{"notMatch":"Oops...the passwords do not match","authError":"Oops... it seems that this email or nickname is already in our system","show":"Show/Hide","email":"Email","password":"Password","confirmPassword":"Confirm Password","login":"Join us","register":"Register"},
        textsPt:{"notMatch":"Vish... as senhas não batem","authError":"Vish... parece que esse email ou nickname já fazem parte do sistema","show":"Ver/Ocultar","email":"Email","password":"Senha","confirmPassword":"Confirmar senha","login":"Registrar","register":"Registrar"}
    }
    

    buttonSend = async () => {        
        const { login, nickname, confirmPassword, password, chkbox} = this.state;
        const axiosConfig = api.axiosConfig;
        if(chkbox===false){
            this.setState({displayMessage:true,message:localStorage.getItem('countryCode')==="+55"?"Para criar uma conta, é preciso estar de acordo com os termos de privacidade":"To create an account, you must agree to the privacy terms"})
            return
        }
        
        if(confirmPassword!==password){
            this.setState({displayMessage:true,message:localStorage.getItem('countryCode')==="+55"?this.state.textsPt.notMatch:this.state.textsEn.notMatch})
            return
        }

        if (nickname.trim()===""){
            this.setState({displayMessage:true,message:localStorage.getItem('countryCode')==="+55"?this.state.textsPt.notMatch:this.state.textsEn.notMatch})
            return
        }

        if(login.includes('@')===false){
            this.setState({displayMessage:true,message:localStorage.getItem('countryCode')==="+55"?"Email inválido":"Invalid email"})
            return
        }

        let data = {
            "email": login,
            "passwd": password,
            "nickname":nickname
        }  
        
        
        await axios({
            method: 'post',
            url: api.authUrl + '/register',
            data: data, headers:axiosConfig.headers
        }).then(async res => {                         
            if (res.status === 200) {
                this.setState({ login, password, token: res.data.token })                    
                localStorage.setItem('mdtUser', nickname)
                localStorage.setItem('mdtToken', res.data.token)   
                localStorage.setItem('mdtConf', false)                                     
                await this.props.history.push('/')
                document.location.reload()
            }else if(res.status===400 && res.error==="Player not found"){
                this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})    
            }
        }).catch(err => {            
            this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})               
        });
    }

    
    buttonShowPassword = () => {        
        let { hiddenPassword } = this.state
        if (hiddenPassword === true) {
            this.setState({ hiddenPassword: false })
        } else {
            this.setState({ hiddenPassword: true })
        }
    }

    buttonShowConfirmPassword = () => {        
        let { hiddenConfirmPassword } = this.state
        if (hiddenConfirmPassword === true) {
            this.setState({ hiddenConfirmPassword: false })
        } else {
            this.setState({ hiddenConfirmPassword: true })
        }
    }

    fieldStateSetEmail = (event) => {
        if (this.state.writedNickname===false){
            let split = event.target.value.split('@')
            this.setState({ "login": event.target.value,"nickname":split[0] })
        }else{
            this.setState({ "login": event.target.value })
        }
        
    }

    handleChangeChk = (event) => {
        
        this.setState({ "chkbox": !this.state.chkbox })
    }

    fieldStateSetNickname = (event) => {
        this.setState({ "nickname": event.target.value,writedNickname:true })
    }

    fieldStateSetPassword = (event) => {
        let { password } = this.state
        password = event.target.value
        this.setState({ password })
    }

    fieldStateSetConfirmPassword = (event) => {
        let { confirmPassword } = this.state
        confirmPassword = event.target.value
        this.setState({ confirmPassword })
    }

    stringHide = (string) => {
        let out = "";
        let parts = string.split('')
        for (let p of parts) {
            out += '*';
        }
        return out;
    }

    
    divEmail = () => {
        return (
            <div>
                <input value={this.state.login} className="loginInput" type="email" onChange={this.fieldStateSetEmail} placeholder="Email" ></input> 
            </div>
        )
    }

    divNickname = () => {
        return (
            <div>
                <input value={this.state.nickname} className="loginInput" type="text" onChange={this.fieldStateSetNickname} placeholder="Nickname" ></input> 
            </div>
        )
    }
    divPassword = () => {
        return (
            <div className="passwordGrid">
                <div>
                    <input className="passwordInput" type={this.state.hiddenPassword ? "password" : "text"} placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.password:this.state.textsEn.password  } value={this.state.password} onChange={this.fieldStateSetPassword}/>               
                </div>
                 
                <div className="tooltip" style={{width:"16px"}}>
                    <FontAwesomeIcon id="iconEye" icon="eye" onClick={this.buttonShowPassword}/>
                    <span className="tooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.show:this.state.textsEn.show}</span>
                </div> 
                               
            </div>
        )
    }

    divPasswordConfirm = () => {
        return (
            <div className="passwordGrid">
                <div>
                    <input className="passwordInput" type={this.state.hiddenConfirmPassword ? "password" : "text"} placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.confirmPassword:this.state.textsEn.confirmPassword  } value={this.state.confirmPassword} onChange={this.fieldStateSetConfirmPassword}/>               
                </div>
                 
                <div className="tooltip" style={{width:"16px"}}>
                    <FontAwesomeIcon id="iconEye" icon="eye" onClick={this.buttonShowConfirmPassword}/>
                    <span className="tooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.show:this.state.textsEn.show}</span>
                </div> 
                               
            </div>
        )
    }

    formLogin = () => {
        return (
            <>
                {this.displayMessage()}
                {this.divEmail()}
                {this.divNickname()}
                {this.divPassword()}  
                {this.divPasswordConfirm()}      
                <div>
                    <input type="checkbox" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk}></input> {localStorage.getItem("countryCode")==='+55'?'Concordo com os temos de privacidade em ':"I agree with the privacy terms in "} <Link target="popup" style={{color:"cornflowerblue",fontSize:'16px'}} to={"/privacy"}>commanderdecks.com/privacy</Link>
                </div>    
            </> 
        )
    }

    displayMessage = () => {
        if (this.state.displayMessage){
            return (
                <div id="displayMessage">
                    {this.state.message}
                </div>
            )
        }
        return <div id="hiddenMessage"></div>
    }

    centralBox = () => {
        return(
            <div id="central">
                {this.formLogin()}
                <div className="buttons">
                    <button style={{display:"inline"}} id="sign" onClick={this.buttonSend}> <FontAwesomeIcon id="iconCrow" icon="dragon" onClick={this.buttonShowPassword}/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.login:this.state.textsEn.login  }</button>
                    {window.innerWidth>900&&this.state.chkbox===true?<FacebookButton parentObjectMdt={this}/>:<></>}
                    
                </div>               
            </div> 
        )  
    }    
    
    render() {        
        return (
            this.centralBox()            
        )
    }
};

export default withRouter(RegisterBox);