import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faGalacticSenate } from '@fortawesome/free-brands-svg-icons'
import { faGhost,faStar,faShoppingCart,faUserShield,faShare,faCameraRetro,faHamburger,faGlobeEurope ,faSync ,faDollarSign, faKhanda, faMeh, faLaugh, faSadTear, faGlasses, faBookOpen, faSearchDollar,faWindowClose, faHeart,faDownload,faSignInAlt, faPen,faJedi, faBars, faEye, faHatWizard, faCrow, faDragon, faCrown, faSearch, faFolder, faArrowRight,faArrowDown,faPlus, faEdit,faCopy,faGamepad, faSave, faUser} from '@fortawesome/free-solid-svg-icons'
import { faAmazon,faTwitter,faInstagram,faTwitch,faTiktok,faYoutube,faPaypal,faLinkedin,faFacebookF,faFacebookSquare, faWizardsOfTheCoast, faDigitalOcean, faShopify } from '@fortawesome/free-brands-svg-icons'


library.add(fab, faShoppingCart, faGhost, faStar, faUserShield,faShare,faAmazon,faCameraRetro, faInstagram,faTwitter,faTwitch,faTiktok,faHamburger,faSadTear, faShopify, faSync,faKhanda, faLinkedin,faDollarSign, faYoutube, faMeh, faLaugh, faDigitalOcean, faGalacticSenate,faGlobeEurope, faGlasses, faBookOpen, faSearchDollar, faPaypal, faHeart,faSignInAlt,faDownload, faWindowClose,faJedi, faBars, faEye, faHatWizard,faFacebookF,faDragon,faPen,faFacebookSquare, faCrow, faCrown,faCopy,faGamepad,faSearch,faArrowDown, faWizardsOfTheCoast, faFolder, faSave, faPlus, faArrowRight, faEdit, faUser)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
