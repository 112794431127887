import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import Modal from 'react-modal';
import { Redirect } from "react-router-dom";
import GlobalNavBar from "../../components/globalNavBar"
import AutoCompleteBox from "../../components/autoCompleteBox"
import BottomBar from "../../components/bottomBar"
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import ReactLoading from 'react-loading';
import AdSense from 'react-adsense';
import './style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function capitalizeFirstLetter([ first, ...rest ], locale = navigator.language) {
    return [ first.toLocaleUpperCase(locale), ...rest ].join('');
}
const axios = require('axios')

const Item = ({ entity: { name} }) => <div>{name.trim()}</div>;


const deckAreaStyle = {
    width: "100%",
    height: "400px",

    fontSize:"20px",
    resize:"none"
}
class EditDeckPage extends Component{

    state = {
        distinctFolders:[],
        settedFolder:false,
        upperCardNamesHash:{},
        problemOnLoad:false,
        prevDeckName:this.props.match.params.deckName,
        qtdeDeck:0,
        iscreator:0,
        qtdeSideboard:0,
        qtdeMaybeboard:0,
        text: '',
        categoriesHash:{},
        halfNames:{},
        newDeckName:"",
        batchGroup:[],
        suggestions:[],
        isPrivateDeck:"no",
        descriptionArea:"",
        loadingDisplay:'fixed',
        loadedFolder:"",
        deckArea:"",
        updateEdition:"",
        userTyped:false,
        deckName:"",
        youtubeArea:"",
        twitchArea:"",
        sideboardArea:"",
        maybeboardArea:"",
        commanderArea:"",
        deckNameRetrieved:"",
        partnerArea:"",
        displayMessage:false,
        modalErrorIsOpen:false,
        selectedPrivacy:"no",
        loadedPrivacy:"no",
        deckInsertOk:false,
        loadedPrices:false,
        loadedDecklists:{},//key:listvesion value: { deckCards[], sideboardCards[], maybeboardCards[] }
        loadedDeckHashs:{},//key:listvesion value: { deckCards{}, sideboardCards{}, maybeboardCards{} }
        deckCards:[],
        sideboardCards:[],
        defaultCatHash:{}, //key:cardname value:{cardname,type_en,defaultcat}
        maybeboardCards:[],
        companionArea:"",
        cards: [],
        allCards:[],
        allCardsHash:{},
        allEnglishCardsHash:{},
        lastListVersion:-1,
        allCommanders:[],
        allCommandersHash:{},
        allPartners:[],
        ptEnCardHash:{},
        allPartnersHash:{},
        allCompanions:[],
        allCompanionsHash:{},
        allTags:[],
        decklists:[],
        selectedFormat:"Commander",
        textAreaKey:"textAreaKey",
        tmpReplacement:"",
        commanderDisplay:"block",
        commanderAnimation:"fadeIn",
        abaDeckDisplay:"block",
        abaSideboardDisplay:"none",
        abaMaybeboardDisplay:"none",
        buttonDeckClass:"tablinks active",
        buttonSideboardClass:"tablinks",
        buttonMaybeboardClass:"tablinks",
        animationDuration:"1s",
        folder:"",
        textsEn:{
            "close":"Close",
            "name":"Deck name",
            "errorHeader":"Ooops... we have a problem",
            "errorMessage":"",
            "exampleName":"My epic monoblack deck",
            "exampleDescription":"This deck is amazing!",
            "private":"It's a private deck?",
            "no":"No, it's not a private deck",
            "yes":"Yes, it is a private deck",
            "description":"Deck description",
            "format":"Format",
            "commander":"Commander",
            "folder":"Deck Folder*",
            "partner":"Partner",
            "edit":"Save deck",
            "companion":"Companion"
        },
        textsPt:{
            "close":"Fechar",
            "name":"Nome do deck",
            "errorHeader":"Ooops... tivemos um probleminha",
            "errorMessage":"",
            "folder":"Pasta do deck*",
            "exampleName":"Meu deck monoblack épico",
            "exampleDescription":"Esse deck é muito apelão!",
            "no":"Não é um deck privado",
            "yes":"É um deck privado",
            "private":"É um deck privado?",
            "description":"Descrição do deck",
            "format":"Formato",
            "commander":"Comandante",
            "edit":"Salvar deck",
            "partner":"Comandante Parceiro",
            "companion":"Companheiro"
        }

    }

    showDeckTab = (e) => {
        this.setState({
            abaDeckDisplay:"block",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks active",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks",
        })
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){                 
                    this.setState({iscreator:res.data[0].iscreator})
                }else{
                    this.setState({iscreator:0})
                }
            }
        }).catch( (err) => {
            console.log(err)
            this.setState({ispremium:false})
        })
    }

    showSideboardTab = (e) => {
        this.setState({
            abaDeckDisplay:"none",
            abaSideboardDisplay:"block",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks active",
            buttonMaybeboardClass:"tablinks",
        })
    }

    showMaybeboardTab = (e) => {
        this.setState({
            abaDeckDisplay:"none",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"block",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks active",
        })
    }


    loadTags = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let language = localStorage.getItem('countryCode')==="+55"?'pt':'en'
        await axios({
            method: 'post',
            url: api.freeUrl + '/decktags/'+language,
            data: {}, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {

                this.setState({ allTags: res.data })
            }
        })
    }

    replaceIn = (cardName) => {
        if(cardName){
            return cardName.replace(/;/g,'/')
        }
        return
    }

    transformNamePt = (nameIn) => {
        let namePt = nameIn.replace(/á/g,'a')

        namePt = namePt.replace(/à/g,'a')
        namePt = namePt.replace(/ã/g,'a')
        namePt = namePt.replace(/â/g,'a')

        namePt = namePt.replace(/Á/g,'A')
        namePt = namePt.replace(/À/g,'A')
        namePt = namePt.replace(/Ã/g,'A')
        namePt = namePt.replace(/Â/g,'A')

        namePt = namePt.replace(/é/g,'e')
        namePt = namePt.replace(/è/g,'e')
        namePt = namePt.replace(/ẽ/g,'e')
        namePt = namePt.replace(/ê/g,'e')

        namePt = namePt.replace(/É/g,'E')
        namePt = namePt.replace(/È/g,'E')
        namePt = namePt.replace(/Ẽ/g,'E')
        namePt = namePt.replace(/Ê/g,'E')


        namePt = namePt.replace(/í/g,'i')
        namePt = namePt.replace(/ì/g,'i')
        namePt = namePt.replace(/ĩ/g,'i')
        namePt = namePt.replace(/î/g,'i')


        namePt = namePt.replace(/Í/g,'I')
        namePt = namePt.replace(/Ì/g,'I')
        namePt = namePt.replace(/Ĩ/g,'I')
        namePt = namePt.replace(/Î/g,'I')

        namePt = namePt.replace(/ó/g,'o')
        namePt = namePt.replace(/ò/g,'o')
        namePt = namePt.replace(/õ/g,'o')
        namePt = namePt.replace(/ô/g,'o')

        namePt = namePt.replace(/Ó/g,'O')
        namePt = namePt.replace(/Ò/g,'O')
        namePt = namePt.replace(/Õ/g,'O')
        namePt = namePt.replace(/Ô/g,'O')

        namePt = namePt.replace(/ú/g,'u')
        namePt = namePt.replace(/ù/g,'u')
        namePt = namePt.replace(/ũ/g,'u')
        namePt = namePt.replace(/û/g,'u')
        namePt = namePt.replace(/ü/g,'u')

        namePt = namePt.replace(/Ú/g,'U')
        namePt = namePt.replace(/Ù/g,'U')
        namePt = namePt.replace(/Ũ/g,'U')
        namePt = namePt.replace(/Û/g,'U')
        namePt = namePt.replace(/Ü/g,'U')
        return namePt
    }

    loadCardsFromLocalStorage = () => {
        let allCardsLS = JSON.parse(localStorage.getItem('allCards'))
        let names = []
        let namesLoaded = {}
        let commanders = {}
        let halfNames = {}
        let commandersNames = []
        let companions = {}
        let companionsNames = []
        let partners = {}
        let partnersNames = []
        let ptEnCardHash = {}
        let upperCardNamesHash = {}      
        let englishNamesLoaded = {}  
        for (let cards of allCardsLS){            
            let splitName = cards.e.split(';')
            let nameEn = cards.e? this.replaceIn(cards.e.trim()):""
            let namePt = cards.t?this.replaceIn(cards.t.trim()):""
            nameEn = nameEn.trim()
            namePt = namePt.trim()
            if (splitName.length>1){
                halfNames[splitName[0].trim()]=nameEn
            }
            if (nameEn===namePt && nameEn!=="" && namesLoaded.hasOwnProperty(nameEn)===false){
                names.push({"name":nameEn})
                namesLoaded[nameEn]= cards
                englishNamesLoaded[nameEn.trim()] = cards
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})                        
            }else if(nameEn!==""){                
                names.push({"name":nameEn})
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                namesLoaded[nameEn.trim()]= cards
                englishNamesLoaded[nameEn.trim()] = cards
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})                        
                if (namePt!=="" && namesLoaded.hasOwnProperty(namePt)===false){                    
                    names.push({"name":namePt})
                    namesLoaded[namePt]=cards
                    ptEnCardHash[namePt]=nameEn
                    let filteredName = this.transformNamePt(namePt)
                    if(filteredName!==namePt && namesLoaded.hasOwnProperty(filteredName)===false){
                        names.push({"name":filteredName})
                        namesLoaded[filteredName]=cards
                        ptEnCardHash[filteredName]=nameEn
                        upperCardNamesHash[filteredName.toUpperCase()]=nameEn
                    }
                    upperCardNamesHash[namePt.toUpperCase()]=nameEn                   
                    if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                        commanders[namePt.trim()]=cards
                        commandersNames.push({"name":namePt.trim()})
                        partners[nameEn.trim()]=cards
                        partnersNames.push({"name":namePt})
                    }
                    companions[nameEn.trim()]=cards
                    companionsNames.push({"name":namePt})                           
                }
            }
        }        
        return { allCards: names, halfNames:halfNames,ptEnCardHash, allCardsHash:namesLoaded, allEnglishCardsHash: englishNamesLoaded, allCommandersHash:commanders, allCommanders:commandersNames, allPartners:partnersNames,allPartnersHash:partners,allCompanions:companionsNames,allCompanionsHash:companions,loadedPrices:true, upperCardNamesHash }
    }


    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    await this.setState({updateEdition:res.data[0].edition})
                }
            }
        })
    }

    loadCards = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                await localStorage.setItem('allCards',JSON.stringify(res.data))
                let t = this.loadCardsFromLocalStorage()
                await this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
                await localStorage.setItem('lastEdition',this.state.updateEdition)
            }
        })
    }

    loadCardsOffline = async() => {

        let t = this.loadCardsFromLocalStorage()
        await this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allEnglishCardsHash:t.allEnglishCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
    }

    changeSelectedPrivacy = (event) => {
        this.setState({selectedPrivacy:event.target.value})

    }

    loadDistinctFolders = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/deckfolder/getDistinctFolders',
            data: {"player":localStorage.getItem('mdtUser')}, headers:axiosConfig.headers
        }).then(res => {
            this.setState({distinctFolders:res.data})

        })
    }

    loadDeckList = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/deckFromPlayer',
            data: {"player":localStorage.getItem('mdtUser'),"deckname":this.props.match.params.deckName}, headers:axiosConfig.headers
        }).then(res => {
            let lastListVersion = -1
            let loadedDecklists = {}
            let loadedDeckHashs = {}
            let qtdeHash = {}
            let isPrivate = false
            let youtubeLink=""
            let twitchLink=""
            if (res.status === 200) {
                

                 for (let card of res.data){
                    if (card.listversion>lastListVersion){
                        lastListVersion=card.listversion
                    }
                    youtubeLink=card.youtubelink;
                    twitchLink=card.twitchlink;
                    isPrivate = card.isprivate;
                    if (!loadedDecklists.hasOwnProperty(card.listversion)){
                        loadedDecklists[card.listversion]={ 'mainboard':[],'sideboard':[],'maybeboard':[],'commander':[],'companion':[] }
                        loadedDeckHashs[card.listversion]={ 'mainboard':{},'sideboard':{},'maybeboard':{},'commander':{},'companion':{} }
                    }
                    if (card.iscommander===1){

                        loadedDecklists[card.listversion]['commander'].push(card)
                        loadedDeckHashs[card.listversion]['commander'][card.cardname]=card
                        continue;
                    }
                    if (card.iscompanion===1){
                        loadedDecklists[card.listversion]['companion'].push(card)
                        loadedDeckHashs[card.listversion]['companion'][card.cardname]=card
                        continue;
                    }
                    loadedDecklists[card.listversion][card.listtype].push(card)
                    loadedDeckHashs[card.listversion][card.listtype][card.cardname]=card
                }

                for (let type in loadedDecklists[lastListVersion]){
                  let sum = 0
                  for (let cardObj of loadedDecklists[lastListVersion][type]){
                    sum+=cardObj.card_qtde
                  }
                  qtdeHash[type]=sum
                }
                let dc = this.loadDisplayComponents(loadedDecklists,lastListVersion,res.data[0])
                let event = {target:{value:res.data[0].f0rmat}}
                this.changeSelectedFormat(event)
                let privateString='no'

                if (isPrivate===true){
                    privateString='yes'
                }
                this.setState({ loadedPrivacy:privateString,selectedPrivacy:privateString,loadedDecklists:loadedDecklists, lastListVersion:lastListVersion,loadedDeckHashs,qtdeDeck:qtdeHash['mainboard'],qtdeSideboard:qtdeHash['sideboard'],qtdeMaybeboard:qtdeHash['maybeboard'], decklists:res.data, folder:res.data[0].name, settedFolder:true, loadedFolder:res.data[0].name, description:dc.description,companionArea:dc.companionArea,deckName:dc.deckName, selectedFormat:dc.selectedFormat ,deckArea:dc.deckArea, sideboardArea:dc.sideboardArea, maybeboardArea:dc.maybeboardArea, youtubeArea:youtubeLink, twitchArea:twitchLink })
            }
        })
    }

    getIsPrivateBox = () => {
        return(
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"É um deck privado?":"It's a private deck?"}*
                </label>
                <select value={this.state.selectedPrivacy} onChange={this.changeSelectedPrivacy}>
                    <option value="no">{localStorage.getItem('countryCode')==='+55'?'Não':'No'}</option>
                    <option value="yes">{localStorage.getItem('countryCode')==='+55'?'Sim':'Yes'}</option>

                </select>
            </>
        )
    }

    makeStringAreaFromList = (cardList) => {
        let out = ""
        let customCatsHash = {}
        for (let card of cardList){

            if(card.customcategorie===""){
                if (!customCatsHash.hasOwnProperty("none7fcewqds")){
                    customCatsHash["none7fcewqds"]=[]
                }
                customCatsHash["none7fcewqds"].push(card)
            }else{
                if (!customCatsHash.hasOwnProperty(card.customcategorie)){
                    customCatsHash[card.customcategorie]=[]
                }
                customCatsHash[card.customcategorie].push(card)
            }
        }
        let keys = Object.keys(customCatsHash)

        for(let cat of keys){
            let cardsList = customCatsHash[cat]
            for (let card of cardsList){
                out+=card.card_qtde+" "+card.cardname.replace(/;/g, '/')
                out+=cat==="none7fcewqds"||cat==="#other"?"\n":" "+cat+"\n"
            }
        }
        return out.replace(/;/g, '/');
    }

    loadDisplayComponents = (loadedDecklists,lastListVersion,deckHeader) => {
        let main = this.makeStringAreaFromList(loadedDecklists[lastListVersion]['mainboard'])
        let sideboard = this.makeStringAreaFromList(loadedDecklists[lastListVersion]['sideboard'])
        let maybeboard = this.makeStringAreaFromList(loadedDecklists[lastListVersion]['maybeboard'])

        if (deckHeader.f0rmat==='Commander' ||deckHeader.f0rmat==='Brawl' ){
            if (loadedDecklists[lastListVersion]['commander'].length===1){
                this.setState({commanderArea:loadedDecklists[lastListVersion]['commander'][0].cardname.replace(/;/g,'/')})
            }else if(loadedDecklists[lastListVersion]['commander'].length===2){
                this.setState({commanderArea:loadedDecklists[lastListVersion]['commander'][0].cardname.replace(/;/g,'/'),partnerArea:loadedDecklists[lastListVersion]['commander'][1].cardname.replace(/;/g,'/')})
            }
        }

        let companion = loadedDecklists[lastListVersion]['companion'].length>0?loadedDecklists[lastListVersion]['companion'][0].cardname:""

        return {  description:deckHeader.description,companionArea:companion,deckName:deckHeader.deckname, selectedFormat:deckHeader.f0rmat ,deckArea:main, sideboardArea:sideboard, maybeboardArea:maybeboard }
    }


    componentDidMount = async () => {
        try{
            this.loadUser()
            this.loadDeckList()
            this.loadDistinctFolders()
            await this.loadLastEdition()
            if (this.state.updateEdition!==''){
                this.loadCards()
            }else{
                this.loadCardsOffline()
            }
            this.setState({loadingDisplay:'none'})
        }catch(e){
            console.log(e)
            this.setState({problemOnLoad:true})
        }
    }

    loadDisplayCommander = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCommanders){
            if ( card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    loadDisplayCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCards){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    loadCompanionCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCompanions){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    loadPartnerCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allPartners){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }


    changeSelectedFormat = (event) => {

        if (event.target.value!=="Commander"&&event.target.value!=="Brawl"){
            this.setState({selectedFormat:event.target.value,commanderAnimation:"fadeout",animationDuration:"0.5s"})
            const timer = setTimeout(() => {
                this.setState({commanderDisplay:"none"})
            }, 500);
        }else{
            if (this.state.buttonSideboardClass==="tablinks active"){
                this.setState({
                    abaDeckDisplay:"block",
                    abaSideboardDisplay:"none",
                    abaMaybeboardDisplay:"none",
                    buttonDeckClass:"tablinks active",
                    buttonSideboardClass:"tablinks",
                    buttonMaybeboardClass:"tablinks",
                    selectedFormat:event.target.value,
                    commanderAnimation:"fadein",
                    commanderDisplay:"block",
                    animationDuration:"1s"
                })
            }else{
                this.setState({selectedFormat:event.target.value,commanderAnimation:"fadein",commanderDisplay:"block",animationDuration:"1s"})
            }
        }
    }

    commanderOnChange = (e) => {
        if (this.state.userTyped===false){
            if(e.target.value.includes('/')){
                let split = e.target.value.split('/');
                this.setState({"commanderArea":e.target.value, "deckName":split[0]+' EDH'})
            }else{
                this.setState({"commanderArea":e.target.value, "deckName":e.target.value+' EDH'})
            }
        }else{
            this.setState({"commanderArea":e.target.value})
        }
    }

    getCommanderBox = () => {

        return (
            <div style={{"display":this.state.commanderDisplay,"animationName":this.state.commanderAnimation,"animationDuration":this.state.animationDuration}}>
                <div className="CommanderSelection" >

                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.commander:this.state.textsEn.commander}*
                    </label>

                    <ReactTextareaAutocomplete
                        className="CardInput"
                        placeholder= {"Krav, the Unredeemed"}
                        onChange={this.commanderOnChange}
                        loadingComponent={() => <span>Loading</span>}
                        renderToBody={false}
                        style={{margin:"0px"}}
                        value={this.state.commanderArea}
                        dropdownStyle={{all:"initial"}}
                        containerStyle={{all:"initial"}}
                        trigger={{
                            "@": {
                                dataProvider: token => {
                                    let res = this.loadDisplayCommander(token)
                                    return res;
                                },
                                component: Item,
                                allowWhitespace:true,
                                output: (item, trigger) => item.name
                            }
                        }}
                    />
                </div>
                <div className="CommanderSelection">
                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.partner:this.state.textsEn.partner}
                    </label>
                    <ReactTextareaAutocomplete
                        className="CardInput"
                        placeholder= {"Regna, the Redeemer"}
                        onChange={e => this.setState({"partnerArea":e.target.value})}
                        loadingComponent={() => <span>Loading</span>}
                        style={{margin:"0px"}}
                        value={this.state.partnerArea}
                        dropdownStyle={{all:"initial"}}
                        containerStyle={{all:"initial"}}
                        trigger={{
                            "@": {
                                dataProvider: token => {
                                    let res = this.loadPartnerCards(token)
                                    return res;
                                },
                                component: Item,
                                allowWhitespace:true,
                                output: (item, trigger) => item.name
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    getCompanionBox = () => {

        return (
            <div className="CommanderSelection">
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.companion:this.state.textsEn.companion}
                </label>
                <ReactTextareaAutocomplete
                    className="CardInput"
                    placeholder= {"Obosh, the Preypiercer"}
                    onChange={e => this.setState({"companionArea":e.target.value})}
                    loadingComponent={() => <span>Loading</span>}
                    value={this.state.companionArea}
                    style={{margin:"0px"}}
                    dropdownStyle={{all:"initial"}}
                    containerStyle={{all:"initial"}}
                    trigger={{
                        "@": {
                            dataProvider: token => {
                                let res = this.loadCompanionCards(token)
                                return res;
                            },
                            component: Item,
                            allowWhitespace:true,
                            output: (item, trigger) => item.name
                        }
                     }}
                />
            </div>
        )
    }

    getDeckBox = () => {
        return (

            <div  style={{display:this.state.abaDeckDisplay}}>
                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}
                value={this.state.deckArea}
                placeholder= '1 Sol Ring'
                loadingComponent={() => <span>Loading</span>}
                onChange={e => this.setState({"deckArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                        return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) => item.name
                    }
                }}
                />
            </div>
        )
    }

    getMaybeboardBox = () => {
        return (
            <div className="CommanderSelection tabcontent" style={{display:this.state.abaMaybeboardDisplay}}>
                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}
                placeholder= '1 Murder'
                value={this.state.maybeboardArea}
                loadingComponent={() => <span>Loading</span>}
                onChange={e => this.setState({"maybeboardArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                            return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) => item.name
                    }
                }}
                />
            </div>
        )
    }

    getSideboardBox = () => {
        if (this.state.selectedFormat==="Commander"||this.state.selectedFormat==="Brawl"){
            return <div className="CommanderSelection"></div>
        }
        return (

            <div className="CommanderSelection tabcontent" style={{display:this.state.abaSideboardDisplay}}>

                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}

                placeholder= '1 Path to exile #Removal'
                loadingComponent={() => <span>Loading</span>}
                value={this.state.sideboardArea}
                onChange={e => this.setState({"sideboardArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                        return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) => item.name
                    }
                }}
                />
            </div>
        )
    }

    getDescriptionBox = () => {

        return (
            <div className="CommanderSelection" style={{marginBottom:"10px"}}>
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.description:this.state.textsEn.description}
                </label>
                <textarea  placeholder={ localStorage.getItem("countryCode")==="+55"?this.state.textsPt.exampleDescription:this.state.textsEn.exampleDescription } className="deckTextArea" onChange={e => this.setState({"descriptionArea":e.target.value})}/>
            </div>
        )
    }

    changeFolderHandler = (folderName, completed) => {
        this.setState({"folder":folderName})
    }


    mountFolders = (distinctFolders) => {
        if (window.innerWidth>=700){
            return distinctFolders.join()
        }
        if (distinctFolders.length<=4){
            return distinctFolders.join()
        }
        let part1 = distinctFolders.slice(0,4);
        let part2 = distinctFolders.slice(4);
        return (
            <div>
                <div>{part1.join()}</div>
                <div>{part2.join()}</div>
            </div>
        );
    }

    getFolderBox = () => {
        if (this.state.distinctFolders.length===0||this.state.settedFolder===false){
            return (
                <>
                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.folder:this.state.textsEn.folder}
                    </label>
                    <input type="text" placeholder="Decks" value={this.state.folder} onChange={ (e)=>{this.setState({"folder":e.target.value})} }/>

                </>
            )
        }

        return (
            <>
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.folder:this.state.textsEn.folder}
                </label>
                <AutoCompleteBox handler={this.changeFolderHandler} value={this.state.folder} stringList={this.state.distinctFolders}></AutoCompleteBox>
                {localStorage.getItem('countryCode')==='+55'? "Suas pastas: ":"Your folders:" } {this.mountFolders(this.state.distinctFolders)}
            </>
        )
    }

    getDeckNameBox = () => {
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.name:this.state.textsEn.name}*
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.exampleName:this.state.textsEn.exampleName} value={this.state.deckName} onChange={ (e)=>{this.setState({"deckName":e.target.value, "userTyped":true})} }/>
            </>
        )
    }

    getFormatSelectionBox = () => {
        return(
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.format:this.state.textsEn.format}*
                </label>
                <select value={this.state.selectedFormat} onChange={this.changeSelectedFormat}>
                    <option value="Commander">Commander</option>
                    <option value="Modern">Modern</option>
                    <option value="Pioneer">Pioneer</option>
                    <option value="Standard">Standard</option>
                    <option value="Pauper">Pauper</option>
                    <option value="Brawl">Brawl</option>
                    <option value="Legacy">Legacy</option>
                    <option value="Vintage">Vintage</option>
                    <option value="Casual">Casual</option>
                    <option value="Canadian Highlander">Canadian Highlander</option>
                    <option value="Limited">Limited</option>
                </select>
            </>
        )
    }

    getTags = (cardLine) => {
        let tags = []
        let tagsSplit = cardLine.split("#")
        for (let k = 1; k<tagsSplit.length;k++){
            let spacesSplit = tagsSplit[k].trim();
            tags.push('#'+spacesSplit)
        }
        return tags
    }


    validateCardArea = (areaText) => {
        let {allCardsHash,halfNames, upperCardNamesHash} = this.state
        let uniqueCardArea = {}
        let problematicCards = []
        let goodCards = []
        areaText = areaText.replace(/\r/g,'\n')
        areaText = areaText.replace(/\n\n/g,'\n')
        let lines = areaText.split('\n')

        for (let line of lines){
            if (line.trim()===""){
                continue;
            }
            let quantity = 1
            let tags = this.getTags(line)
            let lineCopy = line
            for(let tag of tags){
                lineCopy = lineCopy.replace(tag,'').replace(tag,'')
            }
            let cardName=lineCopy
            let spacesSplit = lineCopy.split(' ')
            if (!isNaN(spacesSplit[0])){
                cardName=""
                quantity = parseInt(spacesSplit[0],10)
                for(let k = 1;k<spacesSplit.length;k++){
                    cardName+=spacesSplit[k]+" "
                }
            }
            cardName = cardName.trim()
            let isFoil = false
            if (cardName.includes('*F*')){
                cardName = cardName.replace('*F*','').replace('*F*','').trim()
                isFoil = true
            }
            if (cardName in allCardsHash){
                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(cardName))){
                    uniqueCardArea[this.getCardNameOut(cardName)] = {"card":cardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[cardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(cardName)].quantity+=quantity
                }
            } else if (halfNames.hasOwnProperty(cardName.trim())){
                let compositeName = halfNames[cardName.trim()]

                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(compositeName))){
                    uniqueCardArea[this.getCardNameOut(compositeName)] = {"card":compositeName,"quantity":quantity, "tags":tags, "colors":allCardsHash[compositeName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(compositeName)].quantity+=quantity
                }

            } else if(cardName.toUpperCase() in upperCardNamesHash){
                let correctCardName = upperCardNamesHash[cardName.toUpperCase()]
                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(correctCardName))){
                    uniqueCardArea[this.getCardNameOut(correctCardName)] = {"card":correctCardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[correctCardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(correctCardName)].quantity+=quantity
                }
            } else {
                problematicCards.push( {"card":cardName, "quantity":quantity, "tags":tags} )
            }
        }
        let keys = Object.keys(uniqueCardArea)
        let goodCardNames = []
        let totalOfCards = 0
        for (let card of keys){
            if (this.state.selectedFormat==='Commander'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else if(this.state.selectedFormat==='Brawl'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else{
                if (card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }
        }

        return {"goodCards":goodCards,"problematicCards":problematicCards,"totalOfGoodCards":totalOfCards,"goodCardNames":goodCardNames}
    }


    validateDeck = () => {
        let errorMessageEn = []
        let errorMessagePt = []
        let sideboardAreaVars = {goodCards:[],problematicCards:[]}
        let {deckName,deckArea, sideboardArea, maybeboardArea, commanderArea, partnerArea, companionArea, allCardsHash,textsPt,textsEn} = this.state
        companionArea = companionArea.trim()
        if(deckName!==undefined && deckName!==null){
            let decknameProblemChars=""
            if(deckName.includes('#')){
                decknameProblemChars+='# '
            }
            if(deckName.includes('?')){
                decknameProblemChars+='? '
            }
            if(deckName.includes('/')){
                decknameProblemChars+='/ '
            }
            if(deckName.includes('\\')){
                decknameProblemChars+='\\ '
            }
            if(deckName.includes('%')){
                decknameProblemChars+='% '
            }
            if(deckName.includes('@')){
                decknameProblemChars+='@ '
            }
            if(deckName.includes('!')){
                decknameProblemChars+='! '
            }
            if(deckName.includes('$')){
                decknameProblemChars+='$ '
            }
            if(deckName.includes('¨')){
                decknameProblemChars+='¨ '
            }
            if(deckName.includes('&')){
                decknameProblemChars+='& '
            }
            if (decknameProblemChars!==""){
                textsEn.errorMessage="The deckname has characters that cannot be used, they are:\n"+decknameProblemChars
                textsPt.errorMessage="O deck tem caracteres que não podem ser usados, eles são:\n"+decknameProblemChars
                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }
        }
        if (this.state.loadedPrices===false){
            textsEn.errorMessage="Please wait until the cards load... just a little bit"
            textsPt.errorMessage="Por favor espere os cards carregarem, só um pouquinho..."
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }
        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            commanderArea = commanderArea.replace(/\n/g,"")
            commanderArea = commanderArea.replace(/\r/g,"")
            commanderArea = commanderArea.trim()
            partnerArea = partnerArea.replace(/\n/g,"")
            partnerArea = partnerArea.replace(/\r/g,"")
            partnerArea = partnerArea.trim()
            if (commanderArea==="" || commanderArea in allCardsHash===false){
                errorMessageEn.push( <label key="commanderErrorEn">We could not identify your Commander <nav>{commanderArea}</nav> </label> )
                errorMessagePt.push( <label key="commanderErrorPt">Não conseguimos identificar o seu comandante <nav>{commanderArea}</nav> </label> )
            }
            if (partnerArea!==""&& partnerArea in allCardsHash===false){
                errorMessageEn.push( <label key="partnerErrorEn">We could not identify your partner Commander <nav>{partnerArea}</nav> </label> )
                errorMessagePt.push( <label key="partnerErrorPt">Não conseguimos identificar o seu comandante parceiro <nav>{partnerArea}</nav> </label> )
            }
            if ( commanderArea!=="" && partnerArea!=="" && commanderArea===partnerArea){
                errorMessageEn.push( <label key="partnerErrorEn">You cannot use the same Commander and Partner Commander <nav>{partnerArea}</nav> </label> )
                errorMessagePt.push( <label key="partnerErrorPt">Você não deve usar o mesmo comandante e comandante parceiro <nav>{partnerArea}</nav> </label> )
            }
        }else{
            sideboardAreaVars = this.validateCardArea(sideboardArea)
            if (sideboardAreaVars.problematicCards.length>0){
                errorMessageEn.push( <label key="sideboardErrorEn">Sideboard has cards that does not exists, they are:</label> )
                errorMessagePt.push(  <label key="sideboardErrorPt"> Parece que o seu sideboard tem cartas que não existem, elas são: </label> )
                for(let problematic of sideboardAreaVars.problematicCards){
                    errorMessageEn.push(<label key={"sideboardErrorEn2"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                    errorMessagePt.push(<label key={"sideboardErrorPt2"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                }
            }
        }
        if (companionArea!==""&& companionArea in allCardsHash===false){
            errorMessageEn.push( <label key="companionErrorEn">We could not identify your companion <nav>{companionArea}</nav> </label> )
            errorMessagePt.push( <label key="companionErrorPt">Não conseguimos identificar o seu companheiro <nav>{companionArea}</nav> </label> )
        }

        if (deckArea.trim()===""){
            errorMessageEn.push( <label key="deckAreaEmptyErrorEn">Your deck must have cards in it</label> )
            errorMessagePt.push(  <label key="deckAreaEmptyErrorPt"> Seu deck deve ter cartas</label> )
        }

        let deckAreaVars = this.validateCardArea(deckArea)
        if (deckAreaVars.problematicCards.length>0){
            errorMessageEn.push( <label key="labelCardsNotExistsEn">Your deck has cards that does not exists, they are:</label> )
            errorMessagePt.push(  <label key="labelCardsNotExistsPt"> Parece que o seu deck tem cartas que não existem, elas são: </label> )
            for(let problematic of deckAreaVars.problematicCards){
                errorMessageEn.push(<label key={"labelEn"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                errorMessagePt.push(<label key={"labelPt"+problematic.card}> <nav>{problematic.card}</nav> </label>)
            }
        }
        let maybeboardAreaVars = this.validateCardArea(maybeboardArea)
        if (maybeboardAreaVars.problematicCards.length>0){
            errorMessageEn.push( <label key="labelCardsNotExistsEnMaybeboard">Your maybeboard has cards that does not exists, they are:</label> )
            errorMessagePt.push(  <label key="labelCardsNotExistsPtMaybeboard"> Parece que o seu maybeboard tem cartas que não existem, elas são: </label> )
            for(let problematic of maybeboardAreaVars.problematicCards){
                errorMessageEn.push(<label key={"labelEnMaybeboard"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                errorMessagePt.push(<label key={"labelPtMaybeboard"+problematic.card}> <nav>{problematic.card}</nav> </label>)
            }
        }

        if (errorMessageEn.length>0||errorMessagePt.length>0){
            textsEn.errorMessage=errorMessageEn
            textsPt.errorMessage=errorMessagePt
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }else{
            if (deckAreaVars.totalOfGoodCards>=30000){
                textsEn.errorMessage="Too many cards on the deck =("
                textsPt.errorMessage="Mas pra que um deck tão grande cara? Commander são só 100 cartas, diminui ai e tenta denovo"
                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else if(maybeboardAreaVars.totalOfGoodCards>=30000){
                textsEn.errorMessage="Too many cards on maybeboard, reduce and try again"
                textsPt.errorMessage="Mas pra que um maybeboard tão grande rapaz? diminui ai e tenta denovo"

                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else if(sideboardAreaVars.totalOfGoodCards>=500){
                textsEn.errorMessage="Too many cards on sideboard, reduce and try again"
                textsPt.errorMessage="Mas pra que um sideboard tão grande rapaz? diminui ai e tenta denovo"

                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else{
                return {"isValid":true,"deckCards":deckAreaVars.goodCards,"maybeboardCards":maybeboardAreaVars.goodCards,"sideboardCards":sideboardAreaVars.goodCards,"mainboardCardNames":deckAreaVars.goodCardNames}
            }

        }
    }

    closeModalError = () => {
        this.setState({modalErrorIsOpen:false})
    }

    errorModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.modalErrorIsOpen}
                onRequestClose={this.closeModalError}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}} className="errorMessageArea">
                    <div className="displayMessage">
                        {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorHeader:this.state.textsEn.errorHeader}
                        <FontAwesomeIcon className="closeButton" icon="window-close" onClick={this.closeModalError}/>
                    </div>
                    {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorMessage:this.state.textsEn.errorMessage}

                </div>
                </Modal>
            </div>
        )
    }

    mountColorMap = (deckCards,sideboardCards) =>{
        let colorMap = {}
        for (let card of deckCards){
            let colors = card.colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=card.quantity
                }else{
                    colorMap[cor] = colorMap[cor]+card.quantity
                }
            }
        }
        for (let card of sideboardCards){
            let colors = card.colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=1
                }else{
                    colorMap[cor] = colorMap[cor]+1
                }
            }
        }
        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            let {commanderArea,allCardsHash} = this.state
            let colors = allCardsHash[commanderArea.trim()].c

            colors = colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=1
                }else{
                    colorMap[cor] = colorMap[cor]+1
                }
            }
        }
        return colorMap
    }

    getCustomCategorie = (deckCardObject) => {
        let {defaultCatHash} = this.state
        let types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt','land']
        let tags = deckCardObject.tags.join()
        if (tags.trim()===""){
            let cardname = this.getCardNameOut(deckCardObject.card)
            if(defaultCatHash[cardname].defaultcat===undefined||defaultCatHash[cardname].defaultcat===null||defaultCatHash[cardname].defaultcat===""||defaultCatHash[cardname].defaultcat==="NOTSET"){
                let type = this.state.allCardsHash[deckCardObject.card].d
                let lowerCaseType=type.toLowerCase();
                for (let tp of types){
                    if (lowerCaseType.includes(tp)){
                        return '#'+tp.toLocaleLowerCase()
                    }
                }
            }else{
                return '#'+defaultCatHash[cardname].defaultcat.toLocaleLowerCase()
            }
        }
        return tags.toLowerCase()
    }

    manageCustomCategories = (deckCards) => {
        let catHash={}//key:categorie name value:array of cardObjects
        let totalQuantity=0
        for (let deckCardObject of deckCards){
            let categorie = this.getCustomCategorie(deckCardObject);
            if(catHash.hasOwnProperty(categorie)===false){
                catHash[categorie]=[]
            }
            catHash[categorie].push(deckCardObject)
            totalQuantity+=deckCardObject.quantity;
        }
        let categoriesHash = {}//key:cardname value:categorie        
        for (let cat in catHash){
            let categorie=cat;
            if (catHash[cat].length===1&&catHash[cat][0].quantity===1&&totalQuantity>=95)
                categorie='#other';
            for(let cardObj of catHash[cat]){
                categoriesHash[cardObj.card]=categorie;
            }         
        }
        this.setState({categoriesHash:categoriesHash})
    }

    connectionProblemError = () => {
        let {textsEn, textsPt} = this.state
        textsEn.errorMessage=[<label key={"connectionErrorEn"+Math.floor(Math.random() * 1000) + 1}>We got a connection problem, please refresh and try again</label>]
        textsPt.errorMessage=[<label key={"connectionErrorPt"+Math.floor(Math.random() * 1000) + 1}>Tivemos um pequeno problema de conexão, por favor atualize e tente denovamente</label>]
        this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
    }

    loadDefaultCatHash = async (goodCardNames) => {
        let axiosConfig = api.axiosConfig;
        await axios({
            method:'post',
            url:api.freeUrl+'/card/listDefaultCat',
            data:{"cardnames":goodCardNames},
            headers:axiosConfig.headers,
        }).then( async res => {
            if (res.status === 200){
                await this.setState({defaultCatHash:res.data})
            }
        })
        .catch( async err => {
            console.log(err)
            await this.setState({'deckInsertOk':false})
            await this.connectionProblemError()
        })
    }


    validateDeckList = (deckName,currentDate,deckCards, sideboardCards, maybeboardCards) => {
        let {allCardsHash, loadedDecklists, loadedDeckHashs, lastListVersion,categoriesHash} = this.state;
        let batchGroup = []
        let lastVersion = loadedDeckHashs[lastListVersion]
        let mustSave = false
        let newCommanders = {}
        let newCompanions = {}
        let newMainboard = {}
        let newSideboard = {}
        let newMaybeboard = {}

        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            let commander=this.state.commanderArea.trim();
            let partner=this.state.partnerArea.trim();
            if (commander!==""){
                if (this.getCardNameOut(commander) in lastVersion['commander']===false){
                  mustSave = true
                }
                newCommanders[this.getCardNameOut(commander)]=1
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut( commander) ,
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":this.state.lastListVersion+1,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":allCardsHash[commander].l,
                    "deckname":deckName
                })
            }
            if (partner!==""){
                if (this.getCardNameOut(partner) in lastVersion['commander']===false){
                  mustSave = true
                }
                newCommanders[this.getCardNameOut(partner)]=1
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut(partner),
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":this.state.lastListVersion+1,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":allCardsHash[partner].l,
                    "deckname":deckName
                })
            }
        }

        let companion=this.state.companionArea.trim();
        if (companion!==""){
            if (this.getCardNameOut(companion) in lastVersion['companion']===false){
              mustSave = true
            }
            newCompanions[this.getCardNameOut(companion)]=1
            batchGroup.push({
                "customcategorie":"Companion",
                "cardname": this.getCardNameOut(companion),
                "isfoil":0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":1,
                "version":"*",
                "listversion":this.state.lastListVersion+1,
                "listtype":"companion",
                "card_qtde":1,
                "edition":allCardsHash[companion].l,
                "deckname":deckName
            })
        }

        for (let card of deckCards){
            if (this.getCardNameOut(card.card) in lastVersion['mainboard']===false){
              mustSave = true
            }else{
              mustSave = lastVersion['mainboard'][this.getCardNameOut(card.card)].card_qtde!==card.quantity?true:mustSave
              if (mustSave===false && lastVersion['mainboard'][this.getCardNameOut(card.card)].customcategorie!==this.getCustomCategorie(card)){
                mustSave = true
              }
            }
            newMainboard[this.getCardNameOut(card.card)]=card.quantity
            batchGroup.push({
                "customcategorie":categoriesHash[card.card],
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":this.state.lastListVersion+1,
                "listtype":"mainboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }
        for (let card of sideboardCards){
            if (this.getCardNameOut(card.card) in lastVersion['sideboard']===false){
              mustSave = true
            }else{
              mustSave = lastVersion['sideboard'][this.getCardNameOut(card.card)].card_qtde!==card.quantity?true:mustSave

            }

            newSideboard[this.getCardNameOut(card.card)]=card.quantity
            batchGroup.push({
                "customcategorie":"#sideboard",
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":this.state.lastListVersion+1,
                "listtype":"sideboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }
        for (let card of maybeboardCards){
            if (this.getCardNameOut(card.card) in lastVersion['maybeboard']===false){
              mustSave = true
            }else{
              mustSave = lastVersion['maybeboard'][this.getCardNameOut(card.card)].card_qtde!==card.quantity?true:mustSave

            }

            newMaybeboard[this.getCardNameOut(card.card)]=card.quantity
            batchGroup.push({
                "customcategorie":"#maybeboard",
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":this.state.lastListVersion+1,
                "listtype":"maybeboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }

        if (mustSave===false){
          lastVersion = loadedDecklists[lastListVersion]
          for (let card of lastVersion['mainboard']){
              mustSave = card.cardname in newMainboard?mustSave:true
          }
          for (let card of lastVersion['maybeboard']){
              mustSave = card.cardname in newMaybeboard?mustSave:true
          }
          for (let card of lastVersion['sideboard']){
              mustSave = card.cardname in newSideboard?mustSave:true
          }
          for (let card of lastVersion['companion']){
              mustSave = card.cardname in newCompanions?mustSave:true
          }
          for (let card of lastVersion['commander']){
              mustSave = card.cardname in newCommanders?mustSave:true
          }
        }

        if (mustSave===true){
            return batchGroup
        }else{
            return []
        }
    }

    insertDeckList = async (player, deckname, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, lastListVersion,batchGroup,oldFolder,newFolder,oldDeckname,isprivate) => {
        let data = {
            "player": player,
            "colors": colors,
            "f0rmat": format,
            "description": description,
            "upvotes": upvotes,
            "last_update": lastUpdate,
            "maincard2": this.getCardNameOut(maincard2),
            "qtviews": qtViews,
            "deckname": deckname,
            "maincard": this.getCardNameOut(maincard),
            "lastlistversion":lastListVersion,
            "cardsArray":batchGroup,
            "oldfolder":oldFolder,
            "newfolder":newFolder,
            "prevdeckname":oldDeckname,
            "isprivate":isprivate,
            "youtubeLink":this.state.youtubeArea,
            "twitchLink":this.state.twitchArea
        }
        let axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method: 'post',
            url: api.baseUrl + '/decklist/multipleUpdate',
            data: data, headers:axiosConfig.headers,
        }).then (async res => {
            if (res.status === 200) {
                await this.setState({'deckInsertOk':true,'newDeckName':res.data.Message})
            }
        }).catch(async err => {
            console.log(err)
            await this.setState({'deckInsertOk':false})
            await this.connectionProblemError()
        });
    }


    deleteDeckFromFolder = async (name,deckname) => {
        let data = {
            "name": name,
            "player": localStorage.getItem('mdtUser').trim(),
            "deckname": deckname
        }

        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.baseUrl+'/deckfolder/drop',
            data:data,
            headers:axiosConfig.headers,
        })
    }

    insertDeckFolder = async (name, player, deckname, presentationorder) => {
        let data = {
            "name": name,
            "player": player,
            "deckname": deckname,
            "presentationorder": presentationorder
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.baseUrl+'/deckfolder/',
            data:data,
            headers:axiosConfig.headers,
        }).catch(async err => {
            console.log(err)
            console.log(err.response)
            await this.setState({'deckInsertOk':false})
            await this.connectionProblemError()
        });
    }

    getCardNameOut = (cardName) => {
        let {ptEnCardHash, allEnglishCardsHash} = this.state
        if (cardName in allEnglishCardsHash) return cardName.replace( /\//g,";" )
        let out = cardName
        if (ptEnCardHash.hasOwnProperty(cardName)){
            out = ptEnCardHash[cardName]
        }
        return out.replace( /\//g,";" )
    }

    getNextDeckName = (deckName) => {
        return deckName+'*'
    }

    getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy+'-'+mm+'-'+dd
    }

    removeTrashDecks = () => {
        let axiosConfig = api.axiosConfig;
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/dropIrrelevantOnes',
            data: {}, headers:axiosConfig.headers
        }).catch((e)=>{
            console.log(e)
        })
    }

    saveDeck = async () => {
        let results = this.validateDeck()
        if (results.isValid===false){
            return
        }
        let goodCardNames = results.mainboardCardNames
        await this.loadDefaultCatHash(goodCardNames)
        let colorMap = this.mountColorMap(results.deckCards,results.sideboardCards)
        let colors = Object.keys(colorMap).join('')


        let {deckName,folder} = this.state
        let splitDecknameBarra = deckName.split('/')
        deckName = splitDecknameBarra[0]
        folder = folder.trim()
        if (deckName===""){
            deckName=colors.replace(/,/g,'').replace(/ /g,'')+' '+this.state.selectedFormat
        }
        deckName = deckName.replace(/-/g,' ')
        deckName = deckName.trim()
        let maincard = ""
        let maincard2 = ""

        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            maincard = this.state.commanderArea.trim()
            if (this.state.partnerArea.trim()!==""){
                maincard2 = this.state.partnerArea.trim()
            }
        }else{
            maincard = results.deckCards[0].card
        }

        let newPrivacy = false;
        if (this.state.selectedPrivacy==="yes"){
            newPrivacy=true;
        }

        await this.manageCustomCategories(results.deckCards)
        let batchGroup = this.validateDeckList(deckName,this.getCurrentDate(), results.deckCards, results.sideboardCards, results.maybeboardCards)
        
        await this.insertDeckList(localStorage.getItem('mdtUser'),deckName,maincard, maincard2,colors,this.state.selectedFormat,this.state.descriptionArea.trim(),0,this.getCurrentDate(),0, this.state.lastListVersion+1,batchGroup, this.state.loadedFolder,folder,this.state.decklists[0].deckname,newPrivacy)
        if (this.state.deckInsertOk===false){
            this.connectionProblemError()
            this.removeTrashDecks()
            return;
        }       
        this.removeTrashDecks();
        this.props.history.push('/users/'+localStorage.getItem('mdtUser')+'/decks/'+this.props.match.params.deckName);

    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')       
    }

    
    isPrivateBox = () => {
        return (
            <div>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.private:this.state.textsEn.private}
                </label>
                <select value={this.state.isPrivateDeck} onChange={(event) => this.setState({isPrivateDeck:event.target.value})}>
                    <option value="no">{localStorage.getItem("countryCode")==="+55"?this.state.textsPt.no:this.state.textsEn.no}</option>
                    <option value="yes">{localStorage.getItem("countryCode")==="+55"?this.state.textsPt.yes:this.state.textsEn.yes}</option>
                </select>
            </div>
        )
    }

    loadingCardsText = () => {
        if (this.state.loadedPrices===false){
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Carregando cartas...':'Loading cards...'}</div>
        }else{
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Cartas carregadas, agora você pode usar @ para buscar cartas':'Loaded the cards, now you can use @ to fetch for cards'}</div>
        }
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    getYouTubeBox = () => {
        if (this.state.iscreator===0){
            return <></>
        }
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"Link do Youtube":"Youtube Link"}
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?"Cole aqui o link do Youtube desse deck":"Paste here the Youtube Link for this deck"} value={this.state.youtubeArea} onChange={ (e)=>{this.setState({"youtubeArea":e.target.value})} }/>
            </>
        )
    }

    getTwitchBox = () => {
        if (this.state.iscreator===0){
            return <></>
        }
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"Link da Twitch":"Twitch Link"}
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?"Cole aqui o link da Twitch desse deck":"Paste here the Twitch link for this deck"} value={this.state.twitchArea} onChange={ (e)=>{this.setState({"twitchArea":e.target.value})} }/>
            </>
        )
    }

    render(){
        try{
            if (this.props.match.params.user.trim()!==localStorage.getItem('mdtUser').trim()){
                this.props.history.push('/createDeck')
                document.location.reload()
                return null
            }
            return (
                  <>

                      <GlobalNavBar/>

                      <div className='loading' style={{paddingTop:window.innerWidth>950?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight+'px',display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                          <div style={{marginLeft:'35%'}}>
                            <ReactLoading  type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                          </div>
                          <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                      </div>
                      <div className="deckArea">
                          {this.loadingCardsText()}
                          {this.getFormatSelectionBox()}
                          <div>
                            {localStorage.getItem('countryCode')==='+55'?'Utilize o @ para buscar cartas existentes, por exemplo: ':'Use @ to fetch for existing cards, for example: '}
                            <nav style={{color:'red',display:'inline'}}>@krav</nav>
                        </div>
                          {this.getCommanderBox()}
                          {this.getCompanionBox()}
                          <div>
                            {localStorage.getItem('countryCode')==='+55'?'Você pode customizar as categorias das cartas do seu deck usando o caracter #, por exemplo: ':'You can customize the card categories from your deck by using the character #, for example: '}
                            <nav style={{color:'red',display:'inline'}}>1 Sol Ring #ramp</nav>
                          </div>
                          <div className="tab">
                              <button className={this.state.buttonDeckClass} onClick={this.showDeckTab}>Deck ({this.state.qtdeDeck})</button>
                              {(this.state.selectedFormat==="Commander"||this.state.selectedFormat==="Brawl")?<></>:<button className={this.state.buttonSideboardClass} onClick={this.showSideboardTab}>Sideboard ({this.state.qtdeSideboard})</button>}

                              <button className={this.state.buttonMaybeboardClass} onClick={this.showMaybeboardTab}>Maybeboard ({this.state.qtdeMaybeboard})</button>
                          </div>
                          <div>
                              {this.getDeckBox()}
                              {this.getSideboardBox()}
                              {this.getMaybeboardBox()}
                          </div>
                          {this.getDeckNameBox()}
                          {this.getYouTubeBox()}
                          {this.getTwitchBox()}
                          {this.getIsPrivateBox()}
                          {this.getFolderBox()}
                          <button className="deckCreateSaveButton" onClick={this.saveDeck}>
                              {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.edit:this.state.textsEn.edit}
                          </button>
                          {this.errorModal()}
                      </div>
                      {this.footerPropaganda()}
                      <BottomBar/>
                  </>
            );
        }catch(e){
            return <Redirect to="/"></Redirect>
        }

    }
}
export default withRouter(EditDeckPage)
