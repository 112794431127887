import React, { Component } from 'react';
import {withRouter} from "react-router"
import FacebookLogin from 'react-facebook-login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import api from '../../services/api';
import './style.css'

class ReactFaceLogin extends Component {

  state = {
    textsEn:{"button":"Sign in with Facebook","error":"User exists..."},
    textsPt:{"button":"Entrar com o Facebook","error":"O usuario existe..."}
  }

  reverseString = (str) => {
    return str.split("").reverse().join("");
  }

  encryptPassword = (email,userID) => {
    let struserID = (userID+"").split("")
    let part1 = struserID[0]+struserID[1]
    let part2 = struserID[2]+struserID[3]
    let part3 = struserID[4]+struserID[5]
    let newPassword = this.reverseString(email)+this.reverseString(part1)+part2+this.reverseString(part3)+this.reverseString(userID);
    return newPassword
  }

  registerUser = async (api,data,axiosConfig) => {   
    await axios({
      method: 'post',
      url: api.authUrl + '/register',
      data: data, axiosConfig
    }).then(res => {
        if (res.status===200){
          localStorage.setItem("mdtUser",res.data.results[0].nickname)
          localStorage.setItem("mdtToken",res.data.token)
          this.props.history.push("/users/"+res.data.results[0].nickname+'/decks')
        }
    }).catch(err => {
      this.props.parentObjectMdt.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.error:this.state.textsEn.error})
    });
  } 

  getNick = (email,userID) =>{
    let split = email.split('@')
    return split[0]+'-'+userID+'fb'+Math.floor(Math.random() * 10001)
  }

  responseFacebook = async (response) => {
    this.props.parentObjectMdt.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.error:this.state.textsEn.error})
    if (response.status && response.status==="unknown"){
      return
    }
    const axiosConfig = api.axiosConfig;
    let data = {
      "email": response.email,
      "nickname": this.getNick(response.email,response.userID),
      "passwd": this.encryptPassword(response.email,response.userID)
    }

    await axios({
      method: 'post',
      url: api.authUrl + '/authenticate',
      data: data, axiosConfig
    }).then(res => {
        if (res.status===200){
          localStorage.setItem("mdtUser",res.data.results[0].nickname)
          localStorage.setItem("mdtToken",res.data.token)
          this.props.history.push("/users/"+res.data.results[0].nickname+'/decks');
        }else if(res.status===400 && res.error==="Player not found"){
          this.registerUser(api,data,axiosConfig)
        }
    }).catch(err => {
      if (err.response && err.response.status===400 && err.response.data.error==="Player not found"){
        this.registerUser(api,data,axiosConfig)
      }else{
        this.props.parentObjectMdt.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.error:this.state.textsEn.error})
        return
      }
    });
  }

  render() {

    return (
      <>
        <FacebookLogin
          appId="2163581770569528"
          fields="name,email,picture"
          callback={this.responseFacebook}
          icon={<FontAwesomeIcon id="iconFacebook" icon={['fab', 'facebook-f']}/>}
          cssClass="facebookButton"
          textButton={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.button:this.state.textsEn.button}
        />
      </>
    );
  }
}

export default withRouter(ReactFaceLogin);
