import React from "react";
import "./style.css"
import HomePage from './pages/HomePage';
import BatchPurchasePage from './pages/BatchPurchase';
import LoggedHome from './pages/LoggedHome';
import DecksPage from "./pages/DecksPage"
import BecomeProPage from "./pages/BecomePro"
import CreateDeckPage from "./pages/CreateDeck"
import CreateDeckImageMode from "./pages/CreateDeckImageMode"
import EditDeckPage from "./pages/EditDeck"
import DeckPageOp from "./pages/DeckPageOp"
import DeckPageOpTests from "./pages/DeckPageOpTests"
import DeckPageCreator from "./pages/DeckPageCreator"
import HomePageTest from "./pages/tests"
import DecksOnSalePage from "./pages/DecksOnSalePage"
import PlaytestPage from "./pages/PlaytestPage"

import PlaytestPageMobile from "./pages/PlaytestPageMobile"
import SearchPage from './pages/SearchPage'
import RecoverPassword from './pages/RecoverPassword'
import ReactFaceLogin from './components/facebookButton'
import RecoverMail from './pages/RecoverMail'
import SuccessPage from './pages/SuccessPage'
import FailPage from './pages/FailPage'
import StorePage from './pages/Stores'
import CreatorsPage from './pages/Creators'
import PrivacyPage from './pages/PrivacyPage'
import {Route, Switch,Redirect} from "react-router-dom";


class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/purchase" component={BatchPurchasePage}/>
                <Route exact path="/creators" component={CreatorsPage}/>
                <Route exact path="/home" component={LoggedHome}/>
                <Route exact path="/tests/oAuth2" component={ReactFaceLogin}/>
                <Route exact path="/testNew" component={HomePageTest}/>
                <Route exact path="/users/:user/decks" component={DecksPage}/>
                <Route exact path="/users/:user/decks/:deckName" component={DeckPageOp}/>
                <Route exact path="/users/:user/decksTst/:deckName" component={DeckPageOpTests}/>
                <Route exact path="/users/:user/decksCt/:deckName" component={DeckPageCreator}/>
                <Route exact path="/users/:user/decks/:deckName/playtest" component={PlaytestPage}/>
                
                <Route exact path="/users/:user/decks/:deckName/playtestM" component={PlaytestPageMobile}/>
                <Route exact path="/createDeck" component={CreateDeckPage}/>
                <Route exact path="/createDeck2" component={CreateDeckImageMode}/>
                <Route exact path="/editDeck/:user/:deckName" component={EditDeckPage}/>
                <Route exact path="/search/" component={SearchPage}/>
                <Route exact path="/recoverPassword/" component={RecoverPassword}/>
                <Route exact path="/recoverPassword/:user/:hash" component={RecoverMail}/>
                <Route exact path="/payed" component={SuccessPage}/>
                <Route exact path="/onSale" component={DecksOnSalePage}/>
                <Route exact path="/cancel" component={FailPage}/>
                <Route exact path="/trustedStores" component={StorePage}/>
                <Route exact path="/becomePro" component={BecomeProPage}/>
                <Route exact path="/privacy" component={PrivacyPage}/>
                <Route path="*">
                    <Redirect to="/"/>
                </Route>
            </Switch>
        )
    }
}

export default Routes;
