import BottomBar from "../../components/bottomBar"
import ErrorPage from "../ErrorPage"
import React, {Component} from 'react'
import {withRouter} from "react-router"
import api from "../../services/api"
import './style.css'
import GlobalNavBar from "../../components/globalNavBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import obrigado from'../../assets/obrigado.jpg';
import AdSense from 'react-adsense';

const axios = require('axios')

function isLoggedUser(userNameString){
    if (userNameString===null||userNameString===undefined||localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined){
        return false;
      }
    if (userNameString.trim()===localStorage.getItem('mdtUser').trim()){
      return true;
    }
    return false
  }

function userOnLocalStorage(){
    if (localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined||localStorage.getItem('mdtToken')===null||localStorage.getItem('mdtToken')===undefined){
        return false
    }
    return true
}
class BecomeProPage extends Component{

    state = {
        waitPaypalClicked:false,
        loadedUser:undefined,
        showPix:false,
        problemOnLoad:false,
        showPropaganda:false,
        showGoogleAdd:false,
        showPaypal:true,
        donationLinkPt:"https://apoia.se/commanderdecks",
        textsEn:{
            "apoiaseWarning":"If you help us through PIX, please send an email with your contribution and email to ",
            "alternative":"OR... click on Discover+Video, that will display the best buy combination and an show you a nice cards video",
            "payApoia":"Help using PIX",
            "paypalPay":"Help with paypal",
            "waitPaypal":"Please wait while paypal loads...",
        },
        textsPt:{
            "waitPaypal":"Por favor espere enquanto o paypal carrega...",
            "payApoia":"Ajude por PIX",
            "paypalPay":"Ajude via Paypal",
            "apoiaseWarning":"Para contribuições feitas via PIX, envie um email informando o seu usuario e a sua contribuição em ",
            "alternative":"OU... clique em Descobrir+Video, que mostrará a melhor combinação de compra e te mostrará um vídeo com cartas boas e baratas",            
        },
    }

    constructor(props){
        super(props)
        

    }

    paypalMessage = () => {
        if (this.state.waitPaypalClicked===true){
            return (
                <div style={{padding:'3px',textAlign:'center',backgroundColor:'lightgray',fontWeight:'800'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.waitPaypal:this.state.textsEn.waitPaypal}</div>
            )
        }
    }

    getPaypalLink = async() => {
        if (localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')==undefined){
            return <></>
        }
        const axiosConfig = api.axiosConfig
        let headers = axiosConfig.headers
        headers['Access-Control-Allow-Origin']='*'
        
        let data = {
            "value":20.0,
            "currencycode":localStorage.getItem('countryCode')==='+55'?'BRL':'USD',
            "player":localStorage.getItem('mdtUser'),
            "deckname":"noDeckYet"
        }
        
        await axios({
            method: 'post',
            url: api.freeUrl + '/pay',
            data: data, headers:headers
        }).then( (res) => {
            if (res.data.Status===true){
                this.setState({paypalResponseLink:res.data.Token})
            }else{
                this.setState({paypalResponseLink:'failed to generate paypal link =('})
            }
            
        } ).catch((e) => {
            console.log('erro no /pay')
            console.log(e)
            
        })
    }

    paypalButton = () => {
        if (localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')==undefined||this.state.showPaypal===false){
            return <></>
        }
        return <a target="popup" className={'deckPageButton float'} style={{fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}} href={this.state.paypalResponseLink}><FontAwesomeIcon icon={["fab","paypal"]} style={{marginRight:'3px'}}/>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.paypalPay:this.state.textsEn.paypalPay}</a> 
    }

    paypalBox = () => {
        
        return (
            <div style={{textAlign:'center'}}>               
                {this.paypalButton()}
                <button className={'deckPageButton float'} onClick={()=>{this.setState({showPix:true})}} style={{fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}}><FontAwesomeIcon icon={"heart"} style={{marginRight:'3px'}}/>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.payApoia:this.state.textsEn.payApoia}</button> 
                <button className={'deckPageButton float'} onClick={()=>{this.setState({showPropaganda:true})}} style={{fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}}><FontAwesomeIcon icon={"laugh"} style={{marginRight:'3px'}}/>{localStorage.getItem('countryCode')==='+55'?"Sem grana, mas quero ajudar":"No money but I want to help"}</button>                        
                
                {this.paypalMessage()}

            </div>
        )
    }

    premiumWindow = () => {
        return(
            <div style={{margin:'25px',minHeight:"70vh"}}>
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> <h3>{localStorage.getItem("countryCode")==="+55"?"Nos ajude a manter evoluindo o Commander decks":"Help us to improve Commander decks" }</h3> </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px'}}> {localStorage.getItem("countryCode")==="+55"?"Por favor nos ajude a continuar evoluindo o Commander decks, além de ficarmos muito gratos pela seu apoio, vamos te permitir:":"Please help us to always evolve the site, we will be very grateful for your contribution, while allowing you to:" } </div>
                    

                    <div style={{marginRight:'15%',marginLeft:'15%',display:'grid',gridTemplateColumns:'auto auto'}}>                        
                        <div style={{textAlign:'center',marginBottom:'2px'}}> <div><FontAwesomeIcon icon={['fas','glasses']} size={window.innerWidth>700?"4x":"2x"}/> </div> <div><h3>{localStorage.getItem("countryCode")==="+55"?"Ser imune a propagandas":"Be imune to ads" }</h3> {window.innerWidth>700?localStorage.getItem("countryCode")==="+55"?"Propagandas não irão aparecer para você!":"No more ads baby" :""} </div> </div>
                        <div style={{textAlign:'center',marginBottom:'2px'}}> <div><FontAwesomeIcon icon={['fas','book-open']} size={window.innerWidth>700?"4x":"2x"}/></div><div><h3>{localStorage.getItem("countryCode")==="+55"?"Promover seus decks":"Promote your decks" }</h3> {window.innerWidth>700?localStorage.getItem("countryCode")==="+55"?"Decks promovidos aparecem na homepage de todos os usuários!":"Decks on sale will appear on the homepage of every user!":"" }</div> </div>
                        
                    </div>
                    <div style={{marginTop:12,textAlign:'center',marginBottom:'10px',marginLeft:'2px'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.apoiaseWarning:this.state.textsEn.apoiaseWarning } </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:800}}> commanderdecksmail@gmail.com </div>
                    <div>                    
                        {this.paypalBox()}                   
                    </div>
                    {this.pixArea()}
                    {this.partners()}
                    
                
            </div>
        )
    }

    partners = () => {
        if(this.state.showPropaganda===false){
            return <></>
        }
        return (
            <div>
                <div style={{marginTop:'5px'}}>
                    <div style={{textAlign:'center',marginLeft:'2px', fontWeight:800}}> {localStorage.getItem("countryCode")==="+55"?"Você nos ajuda se baixar um dos apps usando nossos links:":"Or you can help us by downloading one of the following apps using our links:"} </div>
                </div>     
                <div>
                    <a className="tiktok appsButton" target='popup' style={{color:'#fff',textDecoration:'none', fontSize:'20px'}} href='https://vm.tiktok.com/ZMRMbpwub/'><FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","tiktok"]} size="1x"></FontAwesomeIcon>{localStorage.getItem('countryCode')==='+55'?"Tik Tok":'Tik Tok'}
                    </a>
                    <a className="ifood appsButton" target='popup' style={{color:'#fff',textDecoration:'none', fontSize:'20px'}} href='https://ifoodbr.onelink.me/F4X4/mgm?mgm_code=PT438PYUXC'><FontAwesomeIcon style={{marginRight:'2px'}} icon={["fa","hamburger"]} size="1x"></FontAwesomeIcon>{localStorage.getItem('countryCode')==='+55'?"IFood":'IFood'}
                    </a>
                    <a className="digitalocean appsButton" target='popup' style={{color:'#fff',textDecoration:'none', fontSize:'20px'}} href='https://m.do.co/c/fa3fe7921217'><FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","digital-ocean"]} size="1x"></FontAwesomeIcon>{localStorage.getItem('countryCode')==='+55'?"Digital Ocean":'Digital Ocean'}
                    </a>
                </div>
            </div>
        )
    }

    footerPropaganda = () => {
        if(this.state.showPropaganda===false||this.state.showGoogleAdd===false){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            
            <div>
               <div style={{marginTop:'3px'}}>
                    <div style={{textAlign:'center',marginLeft:'2px', fontWeight:800}}> {localStorage.getItem("countryCode")==="+55"?"Veja se a propaganda abaixo te interessa. Clicando nela você visita um site legal e ainda ajuda o Commander decks com 1 R$.":"Check the ad bellow, if it seems interesting to you visit it, by doing so you help the site with 0.2$"} </div>
               </div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>              
            </div>
        )  
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;        
        let data = {
            "nickname": localStorage.getItem('mdtUser'),            
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                
                if (res.data.length>0){     
                    if (res.data[0].ispremium===true){
                        this.props.history.push('/')
                        document.location.reload()    
                    }             
                    this.setState({ispremium:res.data[0].ispremium,loadedUser:res.data[0]})
                
                }else{
                    this.setState({ispremium:false})
                    this.props.history.push('/')
                    document.location.reload()
                }            
            }
        }).catch( (err) => {
            this.setState({ispremium:false})
            this.props.history.push('/')
            document.location.reload()
        })
    }

    componentDidMount(){
        if (localStorage.getItem('mdtUser')!==null&&localStorage.getItem('mdtUser')!==undefined){
            this.getPaypalLink()
            this.loadUser()
        }else{
            this.setState({showPaypal:false});  
        }            
    }

    pixArea(){
        let {showPix} = this.state;
        if (showPix===undefined||showPix===null||showPix===false){
            return <></>
        }
        return (
            <div style={{marginTop:'3px'}}>
                <div style={{textAlign:'center',marginLeft:'2px', fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?"Muito obrigado por sua generosidade, é por conta de pessoas como você que o Commander decks pode seguir evoluindo, obrigado, obrigado, obrigado, obrigado e obrigado mais mil vezes pessoa generosa. Envie um email com o seu usuário e contribuição para commanderdecksmail@gmail.com após contribuir.":"Thank you so much for your generosity! It's because of people like you that Commander decks can continue to improve! Thank you a thousand times." } </div>
                <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px', fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?"Envie um email com o seu usuário e contribuição para commanderdecksmail@gmail.com após contribuir para ser imune a propagandas.":"Please send an email to commanderdecksmail@gmail.com to be immune to ads on the site" } </div>
                <p style={{textAlign:'center',color:'blue',fontWeight:800}}>Chave PIX</p>
                <p style={{textAlign:'center',color:'blue',fontWeight:800}}>commanderdecksmail@gmail.com</p>
                
                <img src={obrigado} style={{display:'block',marginLeft:'15%',width:window.innerWidth*0.7,height:window.innerWidth*0.7*0.7}} alt="Thank you!"/>
            </div>
        );
    }
    
    render(){
        if (this.state.problemOnLoad===true){
            return <ErrorPage/>
        }        
        try{
            return (
                <div>
                    <GlobalNavBar/>
                    {this.premiumWindow()}
                    <BottomBar/>
                </div>
            )
        }catch(e){
            
            if (this.state.problemOnLoad===true){
                return <ErrorPage></ErrorPage>
            }else{
                return <GlobalNavBar/>
            }
            
        }
        
    }
}
export default withRouter(BecomeProPage);
