import React, {Component} from "react";
import {withRouter} from "react-router";
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import BottomBar from "../../components/bottomBar"
import ScrollableAnchor from 'react-scrollable-anchor'
import api from '../../services/api';
import DecksPage from "../DecksPage"


import GlobalNavBar from "../../components/globalNavBar"
import ErrorPage from "../ErrorPage"
import AdSense from 'react-adsense';
Modal.setAppElement('#root');
const axios = require('axios')
var spSizeOfBucket = 9


class HomePageTest extends Component{

    state = {
        problemOnLoad:false,
        modalLoginIsOpen:false,
        modalRegisterIsOpen:false,
        decksLoadedOk:false,
        decksLoaded:[],
        topCardsFolder:[],
        ispremium:false,
        cardsLoaded:[],
        decksBuckets:{},
        cardHash:{},
        isLoggedUser:false,
        loadedPromoted:[],
        loadedPromotedOnSale:[],
        promoted:[[]],
        promotedOnSale:[[]],
        textsEn:{
            "popular":"Most popular Commanders",
            "fetchDecks":"Fetch decks",
            "latest":"Latest decks",
            "capabilities":"FEATURES",
            "helpOthersTitle":"Remember from where you came",
            "helpOthersMessage":"Analyze the older versions of your decks and check what cards changed",
            "moneyTitle":"Less money, more cards",
            "moneyMessage":"Find out the combination of stores that minimize the cost of your deck." ,
            "otherListsTitle":"Find decks",
            "otherListsMessage":"Search for decks made by players around the world.",
            "spellTitle":"Find great spells",
            "spellMessage":"Discover spells used by another players with similar decks as yours.",
            "testDecksTitle":"Test your decks",
            "testDecksMessage":"Playtest your decks in our platform.",
            "createDeckTitle":"Create decks",
            "createDeckMessage":"Create,label and categorize decks in your way.",
            "deckTitle":"Unlock your deckbuilder potential.",
            "mainText":"Welcome to Commanderdecks",
            "promoted":"Promoted decks",
            "promotedOnSale":"Decks on sale",
            "mainText1":"The ideal webapp to construct, compare and purchase MTG decks.",
            "mainText2":"What are you waiting for? Join today!",
            "seeMore":"See more",
            "default":"Default folder",
            "addDeck":"Create Deck",
            "addFolder":"Create folder",
            "nameNewFolder":"Name your new folder",
            "blockTitle":"Create your first deck",
            "blockMessage":"Welcome to Commanderdecks decks page, create a new deck by clicking in the 'create deck' button, you can create your own custom categories for the cards in your decks, discover card recomendations, playtest, find out stores where you can get you list using less money and much more.",
            "formatsTitle":"Deck formats",
            "whyCategorizeTitle":"Why categorize the cards?",
            "whyCategorizeMessage":"It is a lot easier to understand what a deck is supposed to do if you label your cards in the right categories, it is quite common that MTG decks contains distinct cards that share functions, such as ramp, removal, bombs, card draw, wincons and so on.",
            "viewDeck":"View Deck",
            "howToSaveMoneyTitle":"How to save money?",
            "howToSaveMoneyMessage":"There is a LOT of MTG stores to purchase on the Internet, so it is quite hard to answer the question 'where do i buy the cards for my decks?', we can tell you what's the single store where your purchase have the minimun value for free, and we can even tell you what is the best combination of stores to make purchases with some restrictions for free.",
            "editDeck":"Edit deck",
            "playtest":"Test deck",
            "copyDeck":"Export deck"
        },
        textsPt:{
            "promoted":"Decks promovidos",
            "promotedOnSale":"Decks a venda",
            "popular":"Comandantes mais populares",
            "fetchDecks":"Buscar decks",
            "mainText":"Bem vindo ao Commanderdecks",
            "latest":"Decks recentes",
            "capabilities":"FUNCIONALIDADES",
            "helpOthersTitle":"Veja sua evolução",
            "helpOthersMessage":"Analise as versões anteriores dos seus decks e veja quais cartas mudaram",
            "moneyTitle":"Menos dinheiro, mais cartas",
            "moneyMessage":"Descubra as combinações de lojas em quê o preço do seu deck é mínimo.",
            "otherListsTitle":"Encontre decks",
            "otherListsMessage":"Pesquise por decks feitos por jogadores em todo o globo.",
            "spellTitle":"Encontre mágicas poderosas",
            "spellMessage":"Descubra magias usadas por outros jogadores com decks semelhantes ao seu." ,
            "testDecksTitle":"Teste seus decks",
            "testDecksMessage":"Teste seus decks criados em nossa plataforma.",
            "createDeckTitle":"Construa decks",
            "createDeckMessage":"Crie, organize e categorize decks do seu jeito.",
            "deckTitle":"Potêncialize-se como deckbuilder.",

            "mainText1":"O webapp ideal para construir, comparar e comprar decks de MTG.",
            "mainText2":"O que você está esperando?  Entre hoje mesmo!",
            "seeMore":"Ver mais",
            "default":"Pasta inicial",
            "addDeck":"Criar deck",
            "addFolder":"Criar pasta",
            "blockTitle":"Crie o seu primeiro deck",
            "nameNewFolder":"Coloque aqui o nome da sua pasta",
            "blockMessage":"Bem vindo a página de criação de decks, clique em criar deck para adicionar um novo baralho, categorize seus decks do seu jeito, faça playtests, receba recomendações de cards, descubra onde comprar gastando menos dinheiro e muito mais.",
            "whyCategorizeTitle":"Porquê categorizar as cartas?",
            "whyCategorizeMessage":"É muito mais fácil de entender o quê um deck deve fazer se você disser quais as categorias das cartas, é comum que baralhos de Magic tenham cartas que possuem funções similares, como ramp, remoções, bombas, compra de cartas, condições de vitória, etc.",
            "rampExample":"",
            "viewDeck":"Ver Deck",
            "howToSaveMoneyTitle":"Como economizar na compra de cartas?",
            "howToSaveMoneyMessage":"Existem muitas lojas de Magic na Internet, então é difícil de decidir onde comprar suas cartas gastando menos, nós podemos te dizer qual é a loja em que o seu deck sai mais barato de graça, podemos até te contar qual a melhor combinação de lojas para comprar com algumas restrições.",
            "editDeck":"Editar deck",
            "playtest":"Testar deck",
            "copyDeck":"Exportar deck"
        }
    }

    loadPromotedDecksSale = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromotedOnSale',
            data:{"currencycode":localStorage.getItem('countryCode')==='+55'?'BRL':'USD'}
        }).then( res =>{

            if (res.status===200){
                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }
                this.setState({promotedOnSale:promotedBuckets,loadedPromotedOnSale:res.data})
            }
        })
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    loadPromotedDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromoted'
        }).then( res =>{
            if (res.status===200){
                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }
                this.setState({promoted:promotedBuckets,loadedPromoted:res.data})
            }
        })
    }


    
    makeShowCard = (divid, title,description) => {
        return(
            <div className="subCard">

                <div id={divid}></div>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        )
    }

    mainComponent2 = () => {
        return(
            <ScrollableAnchor id={'capabilities'}>
                <div className="bg2">
                    <div className="grid-container">
                        <div className="grid-item">{this.makeShowCard("money",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.moneyTitle:this.state.textsEn.moneyTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.moneyMessage:this.state.textsEn.moneyMessage)}</div>
                        <div className="grid-item">{this.makeShowCard("helpOthers",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.helpOthersTitle:this.state.textsEn.helpOthersTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.helpOthersMessage:this.state.textsEn.helpOthersMessage)}</div>
                        <div className="grid-item">{this.makeShowCard("backDeck",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.createDeckTitle:this.state.textsEn.createDeckTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.createDeckMessage:this.state.textsEn.createDeckMessage)}</div>
                        <div className="grid-item">{this.makeShowCard("testDecks",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.testDecksTitle:this.state.textsEn.testDecksTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.testDecksMessage:this.state.textsEn.testDecksMessage)}</div>
                        <div className="grid-item">{this.makeShowCard("spell",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.spellTitle:this.state.textsEn.spellTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.spellMessage:this.state.textsEn.spellMessage)}</div>
                        <div className="grid-item">{this.makeShowCard("otherLists",localStorage.getItem("countryCode")==="+55"?this.state.textsPt.otherListsTitle:this.state.textsEn.otherListsTitle,localStorage.getItem("countryCode")==="+55"?this.state.textsPt.otherListsMessage:this.state.textsEn.otherListsMessage)}</div>
                    </div>
                </div>
            </ScrollableAnchor>
        )
    }

    getDecksFaceCards = () => {
        let {decksLoaded,topCardsFolder,loadedPromoted,loadedPromotedOnSale} = this.state
        let deckFaceCards = []
        let deckFaceHash = {}
        for (let deck of decksLoaded){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false) ){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        for (let deck of topCardsFolder){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false) ){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        for (let deck of loadedPromoted){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false)){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        for (let deck of loadedPromotedOnSale){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false)){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }

        return deckFaceCards
    }


    loadCardObjects = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "cardArray":this.getDecksFaceCards(),
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/getCardsGrouped',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                let cardHash = {}
                for(let card of res.data){
                    if (!cardHash.hasOwnProperty(card.name_en)){
                        cardHash[card.name_en]=[]
                    }
                    cardHash[card.name_en].push(card)
                }
                this.setState({cardsLoaded:res.data,cardHash:cardHash})
            }
        })
    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')
        
    }


    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    increaseBucket = (folder) => () =>{
      let {decksBuckets} = this.state

      if (decksBuckets[folder].length>1){
        let removed = decksBuckets[folder].splice(1,1)
        for (let deck of removed[0]){
            decksBuckets[folder][0].push(deck)
        }
      }

      this.setState({decksBuckets:decksBuckets})
    }

    sortByDate = (deck1, deck2) => {
      if (deck1.last_update<deck2.last_update){
        return 1;
      }else{
        return -1;
      }

    }

    getLatestKey = () => {
        return localStorage.getItem('countryCode')==='+55'?this.state.textsPt.latest:this.state.textsEn.latest
    }

    prepareFolders = () => {
      let {decksLoaded, decksBuckets} = this.state
      decksLoaded = decksLoaded.sort(this.sortByDate)
      for (let deck of decksLoaded){
        if (decksBuckets.hasOwnProperty(this.getLatestKey())===false){
          decksBuckets[this.getLatestKey()] = [[]]
        }
        let lastBucketPosition = decksBuckets[this.getLatestKey()].length-1
        if (decksBuckets[this.getLatestKey()][lastBucketPosition].length<spSizeOfBucket) {
          decksBuckets[this.getLatestKey()][lastBucketPosition].push(deck)
        } else {
          decksBuckets[this.getLatestKey()].push([])
          decksBuckets[this.getLatestKey()][lastBucketPosition].push(deck)
        }
        continue
      }
      this.setState({decksBuckets})
    }

    loadDecks = async () => {
        const axiosConfig = api.axiosConfig;
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listHome',
            data:{},
            headers:axiosConfig.headers
        }).then(res => {
            if(res.status===200){
                this.setState({decksLoadedOk:true,decksLoaded:res.data})
            }else{
                this.setState({decksLoadedOk:false})
            }
        }).catch(err => {
            this.setState({decksLoadedOk:false})
        })
    }

    loadPopularDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPopular'
        }).then( res =>{
            if (res.status===200){
                this.setState({topCardsFolder:res.data})
            }
        })
    }

    checkLoggedIn = () => {
        if (localStorage.getItem('mdtUser')&&localStorage.getItem('mdtToken')){
            this.setState({isLoggedUser:true})

        }
    }

    loadCards = (lastEdition) => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then( res => {
            if (res.status === 200) {
                localStorage.setItem('allCards',JSON.stringify(res.data))
                localStorage.setItem('lastEdition',lastEdition)
            }
        })
    }

    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                console.log(res.data)
                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    this.loadCards(res.data[0].edition)
                }
            }
        })
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){
                    localStorage.setItem('mdtConf',res.data[0].ispremium)
                    this.setState({ispremium:res.data[0].ispremium})
                }else{
                    this.setState({ispremium:false})
                }
            }
        }).catch( (err) => {
            this.setState({ispremium:false})
        })
    }

    async componentDidMount(){
        try{
            this.loadUser()
            this.loadLastEdition()
            await this.checkLoggedIn()
            if (this.state.isLoggedUser===false){
                await this.loadDecks()
                await this.loadPopularDecks()

                await this.loadPromotedDecks()
                await this.loadCardObjects()
                if (this.state.decksLoadedOk && this.state.decksLoaded.length>0){
                    await this.prepareFolders()
                }

            }
        } catch(e){
            this.setState({problemOnLoad:true})
        }

    }

    makeTopCardsFolder =() => {
        return (
            <div key='top commanders'>
                <div className="homePageFolderTitle">  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.popular:this.state.textsEn.popular}   </div>
                <div className="DeckFolderHomePage">
                    <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                        {this.showTopDecks()}
                    </div>
                </div>            
            </div>
        )
    }

    showTopDecks = () => {
        let {topCardsFolder,cardHash} = this.state
        let foldersHtml = []
        let keys = Object.keys(cardHash)
        if (keys.length===0){
            return
        }
        for (let deck of topCardsFolder){
            let card = cardHash[deck.maincard][0]
            let queryName= deck.maincard.trim().replace(' ','--')
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+Math.random(10000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/search?query="+queryName}>                        
                            <img alt={card.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'}></img>
                    </Link>                    
                    <div style={{fontWeight:'600',textAlign:'center'}}>
                        {deck.maincard}
                    </div>
                    <div style={{fontWeight:'1200',textAlign:'center'}}>
                        Decks:{deck.qtde}
                    </div>
                </div>
            )
        }

        return foldersHtml
    }

    render(){
        try{
            if (this.state.isLoggedUser===true){
                return <DecksPage user={localStorage.getItem('mdtUser')}/>
            }
            return (
                <>
                    <div>
                            <GlobalNavBar/>
                            <div>
                                <div style={{textAlign:'center',paddingTop:'20px'}}>
                                    <h2> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.mainText:this.state.textsEn.mainText}</h2>
                                    <h4>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.mainText1:this.state.textsEn.mainText1}</h4>
                                </div>
                                {this.mainComponent2()}
                                <div style={{minHeight:"90vh"}}>
                                    <div>                                        
                                        {this.makeTopCardsFolder()}
                                    </div>
                                </div>
                            </div>
                    </div>
                    {this.footerPropaganda()}
                    <BottomBar/>
                </>
            )
        }catch(e){
            return (<ErrorPage/>)
        }
    }

}
export default withRouter(HomePageTest);
