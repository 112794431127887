import React, {Component} from "react";
import { withRouter } from 'react-router';

class ProgressBar extends Component {

    render(){
        const { bgcolor, completed } = this.props;
  
        const containerStyles = {
        height: 20,
        display:'block',
        backgroundColor: "#e0e0de",
        borderRadius: 50,        
        }
    
        const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right'
        }
    
        const labelStyles = {
        padding: 20,
        color: 'white',
        fontWeight: 'bold'
        }
    
        return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
            <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
        );
    }
    
  };
  
  export default withRouter(ProgressBar);
  