import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import Modal from 'react-modal';
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"

import './style.css'
import "@webscopeio/react-textarea-autocomplete/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var baseWidth = -10+window.innerWidth/5;
var baseHeight = (-10+window.innerWidth/5)*1.34482
class CreateDeckImageMode extends Component{
        
    state = {    
        cardName:"",
        cardText1:"",
        cardText2:"",
        cardText3:"",
        selectedType:"",
        selectedFunction:"",
        selectedColor:"",
        subType1:"",
        subType2:"",        
        powerLessEqualThen:"",
        powerHighEquanThen:"",
        toughLessEqualThen:"",
        toughHighEquanThen:"",
        manaValueLessEqualThen:"",
        manaValueHighEqualThen:"",
        mainDeckGrind:[[]],
        mainGridSize:10,
        filterAreaSize:5,
        imageWidth:baseWidth,
        imageHeight:baseHeight,
        cardSectionWidth: baseWidth/4,
        cardSectionHeight: baseHeight/4
    }

    componentDidMount(){

    }

    makeCardDisplayArea = () => {
        return (
            <div style={{width:this.state.imageWidth,height:this.state.imageHeight, display:'inline-block'}} className="cardSectionCreateDeck">
                
            </div>
        )
    }

    makeDeckArea = () =>{
        let cardAreas = []
        for(let i = 0;i<this.state.mainGridSize*this.state.mainGridSize;i++){
            cardAreas.push(<div style={{width:this.state.cardSectionWidth,height:this.state.cardSectionHeight, display:'inline'}} className="cardSectionCreateDeck"></div>);
        }
        return (<div style={{display:'grid',gridTemplateColumns:'repeat(auto-fit, '+this.state.cardSectionWidth+'px)'}}>{cardAreas}</div>)
    }

    makeTypeSelect = () => {
        return ( <select value={this.state.selectedType} onChange={(e) => {this.setState({selectedType:e.target.value})}}>
        <option value="creature">{localStorage.getItem('countryCode')==='+55'?'Criatura':'Creature'}</option>
        <option value="artifact">{localStorage.getItem('countryCode')==='+55'?'Artefato':'Artifact'}</option>
        <option value="enchantment">{localStorage.getItem('countryCode')==='+55'?'Encantamento':'Enchantment'}</option>
        <option value="instant">{localStorage.getItem('countryCode')==='+55'?'Instantânea':'Instant'}</option>
        <option value="sorcery">{localStorage.getItem('countryCode')==='+55'?'Feitiço':'Sorcery'}</option>
        <option value="planeswalker">Planeswalker</option>                    
        </select>)
    }

    makeColorSelect = () => {
        return ( <select value={this.state.selectedColor} onChange={(e) => {this.setState({selectedColor:e.target.value})}}>
        <option value="white">{localStorage.getItem('countryCode')==='+55'?'branco':'white'}</option>
        <option value="red">{localStorage.getItem('countryCode')==='+55'?'vermelho':'red'}</option>
        <option value="blue">{localStorage.getItem('countryCode')==='+55'?'azul':'blue'}</option>
        <option value="black">{localStorage.getItem('countryCode')==='+55'?'preto':'black'}</option>
        <option value="green">{localStorage.getItem('countryCode')==='+55'?'verde':'green'}</option>
        <option value="azorius">azorius</option>
        <option value="dimir">dimir</option>
        <option value="rakdos">rakdos</option>
        <option value="gruul">gruul</option>
        <option value="selesnya">selesnya</option>
        <option value="orzhov">orzhov</option>
        <option value="izzet">izzet</option>
        <option value="golgari">golgari</option>
        <option value="boros">boros</option>
        <option value="simic">simic</option>
        <option value="esper">esper</option>
        <option value="grixis">grixis</option>
        <option value="jund">jund</option>
        <option value="naya">naya</option>
        <option value="bant">bant</option>
        <option value="abzan">abzan</option>
        <option value="jeskai">jeskai</option>
        <option value="sultai">sultai</option>
        <option value="mardu">mardu</option>
        <option value="temur">temur</option>
        <option value="nonGreen">{localStorage.getItem('countryCode')==='+55'?'Todas menos verde':'All but green'}</option>
        <option value="nonWhite">{localStorage.getItem('countryCode')==='+55'?'Todas menos branco':'All but white'}</option>
        <option value="nonBlue">{localStorage.getItem('countryCode')==='+55'?'Todas menos azul':'All but blue'}</option>
        <option value="nonBlack">{localStorage.getItem('countryCode')==='+55'?'Todas menos preto':'All but black'}</option>
        <option value="nonRed">{localStorage.getItem('countryCode')==='+55'?'Todas menos vermelho':'All but red'}</option>
        <option value="fiveColors">{localStorage.getItem('countryCode')==='+55'?'Cinco cores':'Five colors'}</option>
         </select>)
    }

    makeRaritySelect = () => {
        return ( <select value={this.state.selectedRarity} onChange={(e) => {this.setState({selectedType:e.target.selectedRarity})}}>
        <option value="mythic">{localStorage.getItem('countryCode')==='+55'?'Mítica':'Mythical'}</option>
        <option value="rare">{localStorage.getItem('countryCode')==='+55'?'Rara':'Rare'}</option>
        <option value="uncommon">{localStorage.getItem('countryCode')==='+55'?'Incomum':'Uncommon'}</option>
        <option value="common">{localStorage.getItem('countryCode')==='+55'?'Comum':'Common'}</option>                
        </select>)
    }

    makeFunctionSelect = () => {
        return ( <select value={this.state.selectedFunction} onChange={(e) => {this.setState({selectedFunction:e.target.value})}}>
        <option value="cardSelection">{localStorage.getItem('countryCode')==='+55'?'seleção de cartas':'cardSelection'}</option>
        <option value="donate">{localStorage.getItem('countryCode')==='+55'?'doação':'donate'}</option>
        <option value="blink">{localStorage.getItem('countryCode')==='+55'?'blink':'blink'}</option>
        <option value="buff">{localStorage.getItem('countryCode')==='+55'?'buff':'buff'}</option>
        <option value="directLoss">{localStorage.getItem('countryCode')==='+55'?'perca direta':'directLoss'}</option>
        <option value="denier">{localStorage.getItem('countryCode')==='+55'?'denier':'denier'}</option>
        <option value="counter">{localStorage.getItem('countryCode')==='+55'?'counter':'counter'}</option>
        <option value="tutor">{localStorage.getItem('countryCode')==='+55'?'tutor':'tutor'}</option>
        <option value="cardAdvantage">{localStorage.getItem('countryCode')==='+55'?'cardAdvantage':'cardAdvantage'}</option>
        <option value="cheatCost">{localStorage.getItem('countryCode')==='+55'?'cheatCost':'cheatCost'}</option>
        <option value="removal">{localStorage.getItem('countryCode')==='+55'?'remoção':'removal'}</option>
        <option value="sacOutlet">{localStorage.getItem('countryCode')==='+55'?'fonte de sacrifício':'sacOutlet'}</option>
        <option value="land">{localStorage.getItem('countryCode')==='+55'?'terreno':'land'}</option>
        <option value="doubler">{localStorage.getItem('countryCode')==='+55'?'dobrador':'doubler'}</option>
        <option value="dungeon">{localStorage.getItem('countryCode')==='+55'?'dungeon':'dungeon'}</option>
        <option value="heal">{localStorage.getItem('countryCode')==='+55'?'cura':'heal'}</option>
        <option value="extraTurn">{localStorage.getItem('countryCode')==='+55'?'turno extra':'extraTurn'}</option>
        <option value="ramp">{localStorage.getItem('countryCode')==='+55'?'ramp':'ramp'}</option>
        <option value="graveHate">{localStorage.getItem('countryCode')==='+55'?'exilar cemiterio':'graveHate'}</option>
        <option value="protection">{localStorage.getItem('countryCode')==='+55'?'proteção':'protection'}</option>
        <option value="disruption">{localStorage.getItem('countryCode')==='+55'?'descarte':'disruption'}</option>
        <option value="other">{localStorage.getItem('countryCode')==='+55'?'outros':'other'}</option>
        <option value="mill">{localStorage.getItem('countryCode')==='+55'?'triturar (tombar)':'mill'}</option>
        <option value="threat">{localStorage.getItem('countryCode')==='+55'?'ameaça':'threat'}</option>
        <option value="recursion">{localStorage.getItem('countryCode')==='+55'?'recursão':'recursion'}</option>                  
        </select>)
    }

    makePowerArea = () => {
        return (
            <div style={{display:'grid',gridTemplateColumns:"auto auto auto"}}>
                <div>
                    <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"2"} value={this.state.powerLessEqualThen} onChange={ (e)=>{this.setState({"powerLessEqualThen":e.target.value})}}/>
                    ≤ Power ≤
                    <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"5"} value={this.state.powerHighEquanThen} onChange={ (e)=>{this.setState({"powerHighEquanThen":e.target.value})}}/>
                </div>
                <div>
                    <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"2"} value={this.state.toughLessEqualThen} onChange={ (e)=>{this.setState({"toughLessEqualThen":e.target.value})}}/>
                    ≤ Tough ≤
                    <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"5"} value={this.state.toughHighEquanThen} onChange={ (e)=>{this.setState({"toughHighEquanThen":e.target.value})}}/>
                </div>                
            </div>            
        )
    }

    makeManaValueArea = () => {
        return (
            <div>
                <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"2"} value={this.state.manaValueLessEqualThen} onChange={ (e)=>{this.setState({"manaValueLessEqualThen":e.target.value})}}/>
                ≤ Mana Value ≤
                <input style={{width:'20%',margin:'3px'}} type="text" placeholder={"5"} value={this.state.manaValueHighEquanThen} onChange={ (e)=>{this.setState({"manaValueHighEquanThen":e.target.value})}}/>
            </div>
        )
    }

    makeFilterArea = () =>{
        let cardAreas = []
        for(let i = 0;i<this.state.filterAreaSize*this.state.filterAreaSize;i++){
            cardAreas.push(<div style={{width:this.state.cardSectionWidth,height:this.state.cardSectionHeight, display:'inline'}} className="cardSectionCreateDeck"></div>);
        }
        return (
            <div>
                <div>
                    <div>{localStorage.getItem('countryCode')==='+55'?'BUSCAR CARTAS':'SEARCH CARDS'}</div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Nome':'Name'}: <input type="text" placeholder={"Sol Ring"} value={this.state.cardName} onChange={ (e)=>{this.setState({"cardName":e.target.value})} }/> </div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Texto 1':'Text 1'}: <input type="text" placeholder={"destroy target"} value={this.state.cardText1} onChange={ (e)=>{this.setState({"cardText1":e.target.value})} }/> </div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Texto 2':'Text 2'}: <input type="text" placeholder={"when enter"} value={this.state.cardText2} onChange={ (e)=>{this.setState({"cardText2":e.target.value})} }/> </div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Texto 3':'Text 3'}: <input type="text" placeholder={"draw"} value={this.state.cardText3} onChange={ (e)=>{this.setState({"cardText3":e.target.value})} }/> </div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Tipo':'Type'}: {this.makeTypeSelect()}  <input style={{width:'25%'}} type="text" placeholder={"angel"} value={this.state.subType1} onChange={ (e)=>{this.setState({"subType1":e.target.value})} }/> <input style={{width:'25%'}} type="text" placeholder={"equipment"} value={this.state.subType2} onChange={ (e)=>{this.setState({"subType2":e.target.value})} }/> </div>
                    <div> {localStorage.getItem('countryCode')==='+55'?'Função':'Function'}: {this.makeFunctionSelect()} {localStorage.getItem('countryCode')==='+55'?'Raridade':'Rarity'}: {this.makeRaritySelect()} </div> 
                    <div> {localStorage.getItem('countryCode')==='+55'?'Cores':'Colors'}: {this.makeColorSelect()} </div>
                    <div> {this.makePowerArea()} </div>
                    <div>{this.makeManaValueArea()}</div>
                    <button style={{width:'100%',textAlign:'center'}}>{localStorage.getItem('countryCode')==='+55'?'Pesquisar':'Search'}</button>
                </div>               
                <div style={{display:'grid',gridTemplateColumns:'repeat(auto-fit, '+this.state.cardSectionWidth+'px)'}}>{cardAreas}</div>
            </div>
        )
    }



    render(){
        return (
            <div style={{display:'grid',gridTemplateColumns:this.state.imageWidth+'px auto '+this.state.imageWidth+'px'}}>                
                    {this.makeCardDisplayArea()}                
                    {this.makeDeckArea()}
                    {this.makeFilterArea()}                
            </div>
        )
    }
    
}
export default withRouter(CreateDeckImageMode)
