import React, {Component} from "react"
import {withRouter} from "react-router"
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"

class ErrorPage extends Component{

    state = {
        textsEn:{"error":"Ooops... something went wrong, please contact us at nakaosensei@gmail.com =("},
        textsPt:{"error":"Ooops... algo deu errado, por favor nos contate pelo e-mail nakaosensei@gmail.com =("}
    }

    render(){
        return (
            <div>
                <meta charSet="utf-8"></meta>
    
                <GlobalNavBar/>
                <div style={{minHeight:window.innerHeight*0.8,width:window.innerWidth,textAlign:'center'}}>
                    <div style={{marginTop:'20%'}}>
                        <h1>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.error:this.state.textsEn.error}
                        </h1>
                    </div>
                </div>
                <BottomBar/>
            </div>
        )
    }

}
export default withRouter(ErrorPage)