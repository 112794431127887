import React, {Component} from "react"
import {withRouter} from "react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'
import {Link} from 'react-router-dom';
import brazilFlag from '../../assets/icons/brazil-flag-icon-32.png';
import usaFlag from '../../assets/icons/usa-flag-icon-32.png';
import commanderdecks from '../../assets/logo.png';

class BottomBar extends Component{


    state={

        textsEn:{"en":"English","br":"Portuguese","nakaoMessage":"Created and mantained by Thiago Nakao (nakaosensei@gmail.com)"},
        textsPt:{"en":"Inglês","br":"Português" ,"nakaoMessage":"Criado e mantido pelo Thiago Nakao (nakaosensei@gmail.com)"}
    }

    footerText = () =>{
        return (
            <div className="footText">
                {localStorage.getItem('countryCode') === "+55" ? this.state.textsPt.nakaoMessage : this.state.textsEn.nakaoMessage}
                <a href="https://www.linkedin.com/in/thiago-alexandre-nakao-fran%C3%A7a-427970144/" size="lg" style={{color:'#0a66c2'}}><FontAwesomeIcon style={{marginLeft:'3px'}} id="icon" icon={["fab","linkedin"]}></FontAwesomeIcon></a>
                <Link to={"/privacy"}> <FontAwesomeIcon icon="user-shield"></FontAwesomeIcon></Link>
            </div>
        )
    }

    changeToPt = () => {
        localStorage.setItem('countryCode',"+55")
        window.location.reload()
    }

    changeToEn = () => {
        localStorage.setItem('countryCode',"+1")
        window.location.reload()
    }

    footerFlags = () => {
        return(
            <div className="footFlags">
                <div className="bottombartooltip">
                    <img alt="Brazil Flag" src={brazilFlag} onClick={this.changeToPt}/>
                    <span className="bottombartooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.br:this.state.textsEn.br}</span>
                </div>
                <div className="bottombartooltip">
                    <img alt="USA Flag" src={usaFlag} onClick={this.changeToEn}/>
                    <span className="bottombartooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.en:this.state.textsEn.en}</span>
                    
                
                </div>
                
                
            </div>
        )
    }

    footerPropaganda = () => {

        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        return (
            <div>
                <Link className="commanderdecks" target='popup' style={{color:'#fff',textDecoration:'none', fontSize:'20px'}} to='/becomePro'><img src={commanderdecks} style={{paddingTop:'3px',marginRight:'5px',width:25,height:30}} />{localStorage.getItem('countryCode')==='+55'?"Ajude o Commander decks a progredir":'Help Commander decks to evolve'}
                </Link>
            </div>
        )

    }


    render(){
        return (
            <div>
                
                <div id="main" style={{minHeight:"10vh"}}>
                    {this.footerText()}
                    {this.footerFlags()}
                </div>
            </div>
        )
    }

}
export default withRouter(BottomBar)
