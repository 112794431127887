import React, {Component} from "react"
import {withRouter} from "react-router"
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"

import './style.css'


class SuccessPage extends Component{

    state = {
        problemOnLoad:false,
        textsEn:{
            "tittle":"Success! Thank you!",
            "paymentApproved":"Now you can find out where your decks are cheaper, promote decks and be immune to ads!"
        },
        textsPt:{
            "tittle":"Tudo deu certo! Muito obrigado!",
            "paymentApproved":"Agora fique a vontade para fazer compras sempre no menor valor possível, promova seus decks e saiba que ajudou a comunidade!"
        }

    }




    mainText = () => {
        if (localStorage.getItem('countryCode')==='+55'){
            return <div>  <div><h1>{this.state.textsPt.tittle}</h1></div> {this.state.textsPt.paymentApproved} </div>
        }else{
            return <div>  <div><h1>{this.state.textsEn.tittle}</h1></div> {this.state.textsEn.paymentApproved} </div>
        }
    }

    render(){

        return (
            <>
                <meta charSet="utf-8"></meta>
                <GlobalNavBar/>
                <div className="bgSuccess" style={{height:window.innerHeight,textAlign:'center'}} >
                    <div style={{color:'snow',paddingTop:'15%'}}>{this.mainText()}</div>
                </div>
                
                <BottomBar/>
            </>
        );
    }
}
export default withRouter(SuccessPage)
