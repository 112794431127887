import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'
import StarComponent from "../../components/proUserStar"
import ReactLoading from 'react-loading';
import axios from "axios"
import ErrorPage from "../ErrorPage"
import AdSense from 'react-adsense';
let userSizeOfBucket = 12

let spSizeOfBucket = 12
function isLoggedUser(userNameString){
  if (userNameString===null||userNameString===undefined||localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined){
    return false;
  }
  if (userNameString.trim()===localStorage.getItem('mdtUser').trim()){
    return true;
  }
  return false
}

class DecksOnSalePage extends Component{

    state = {
        problemOnLoad:false,
        loadingDisplay:'fixed',
        deckFolders:[],
        
        cardsLoaded:[],
        loadedPromoted:[],
        loadedPromotedOnSale:[],
        promoted:[[]],
        promotedOnSale:[[]],
        loadedData:false,
       
        decksHash:{}, //key:deckName values:[] array of deck object
        folderHash:{}, //key:folder values:[] array of deckfolder objects || name, player, deckname and presentationorder
        folderHashButtons:{}, //key:folder values:array=0 = display inline array[1] = display block
        folderBuckets:{},//key:folder values:[[]] array of folder buckets
        folderNamesTrack:{}, //key:old folder value:newFolderName
        textsEn:{
            "popular":"Most popular Commanders",
            "fetchDecks":"Fetch decks",
            "seeMore":"See more",
            "promoted":"Promoted decks",
            "promotedOnSale":"Decks on sale",
            "default":"Default folder",
            "addDeck":"Create Deck",
            "addFolder":"Create folder",
            "nameNewFolder":"Name your new folder",
            "blockTitle":"Create your first deck",
            "blockMessage":"Welcome to Mydecktool decks page, create a new deck by clicking in the 'create deck' button, you can create your own custom categories for the cards in your decks, discover card recomendations, playtest, find out stores where you can get you list using less money and much more.",
            "formatsTitle":"Deck formats",
            "whyCategorizeTitle":"Why categorize the cards?",
            "whyCategorizeMessage":"It is a lot easier to understand what a deck is supposed to do if you label your cards in the right categories, it is quite common that MTG decks contains distinct cards that share functions, such as ramp, removal, bombs, card draw, wincons and so on.",
            "viewDeck":"View Deck",
            "howToSaveMoneyTitle":"How to save money?",
            "howToSaveMoneyMessage":"There is a LOT of MTG stores to purchase on the Internet, so it is quite hard to answer the question 'where do i buy the cards for my decks?', we can tell you what's the single store where your purchase have the minimun value for free, and we can even tell you what is the best combination of stores to make purchases with some restrictions for free.",
            "editDeck":"Edit deck",
            "playtest":"Playtest",
            "copyDeck":"Download deck"
        },
        textsPt:{
            "promoted":"Decks promovidos",
            "promotedOnSale":"Decks a venda",
            "popular":"Comandantes mais populares",
            "fetchDecks":"Buscar decks",
            "seeMore":"Ver mais",
            "default":"Pasta inicial",
            "addDeck":"Criar deck",
            "addFolder":"Criar pasta",
            "blockTitle":"Crie o seu primeiro deck",
            "nameNewFolder":"Coloque aqui o nome da sua pasta",
            "blockMessage":"Bem vindo a página de criação de decks, clique em criar deck para adicionar um novo baralho, categorize seus decks do seu jeito, faça playtests, receba recomendações de cards, descubra onde comprar gastando menos dinheiro e muito mais.",
            "whyCategorizeTitle":"Porquê categorizar as cartas?",
            "whyCategorizeMessage":"É muito mais fácil de entender o quê um deck deve fazer se você disser quais as categorias das cartas, é comum que baralhos de Magic tenham cartas que possuem funções similares, como ramp, remoções, bombas, compra de cartas, condições de vitória, etc.",
            "rampExample":"",
            "viewDeck":"Ver Deck",
            "howToSaveMoneyTitle":"Como economizar na compra de cartas?",
            "howToSaveMoneyMessage":"Existem muitas lojas de Magic na Internet, então é difícil de decidir onde comprar suas cartas gastando menos, nós podemos te dizer qual é a loja em que o seu deck sai mais barato de graça, podemos até te contar qual a melhor combinação de lojas para comprar com algumas restrições.",
            "editDeck":"Editar deck",
            "playtest":"Testar deck",
            "copyDeck":"Exportar deck"
        }

    }

    getDecksFaceCards = () => {        
        let {loadedPromoted,loadedPromotedOnSale} = this.state
        let deckFaceCards = []
        let deckFaceHash = {}
        
        for (let deck of loadedPromoted){            
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false)){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        for (let deck of loadedPromotedOnSale){            
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false)){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }        
        return deckFaceCards
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }


    sortByDate = (folderDeck1, folderDeck2) => {
      let {decksHash} = this.state
      let obj1 = decksHash[folderDeck1.deckname][0]
      let obj2 = decksHash[folderDeck2.deckname][0]
      if (obj1.last_update<obj2.last_update){
        return 1;
      }else{
        return -1;
      }
      
    
    }

    showPromotedDecksOnSale = () => {
        let {promotedOnSale} = this.state
        let foldersHtml = []
        
        for (let deck of promotedOnSale[0]){          
            
            
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'promotedSale'+deck.player+Math.floor(Math.random() * 110000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                            <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard +Math.floor(Math.random() * 110000)}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                            <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                            </div>
                        </div>
                        <div style={{fontWeight:'600',textAlign:'center'}}>{deck.deckname}</div>
                    </Link>
                    
                    <div style={{textAlign:'center'}}>
                        <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                            {this.changeDisplayName(deck.player)}
                        </Link>         
                        <div>
                             {deck.saleprice}  {deck.salecurrencycode==='BRL'?'R$ ':'$$ '}
                        </div>             
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }
        
        return foldersHtml
    }

    showPromotedDecks = () => {
        let {promoted} = this.state
        let foldersHtml = []
        
        for (let deck of promoted[0]){          
            
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'promotedSale'+deck.player+Math.floor(Math.random() * 110000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                            <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                            <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                            </div>
                        </div>
                        <div style={{fontWeight:'600',textAlign:'center'}}>{deck.deckname}</div>
                    </Link>
                    
                    <div style={{textAlign:'center'}}>
                        <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                            {this.changeDisplayName(deck.player)}
                        </Link>                      
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }
        
        return foldersHtml
    }   

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')    
    }



    increaseBucketPromotedDecks = () => {
        let {promoted} = this.state

        if (promoted.length>1){
            let removed =promoted.splice(1,1)
            for (let deck of removed[0]){
                promoted[0].push(deck)
            }
        }

        this.setState({promoted:promoted})
    }

    increaseSaleBucket = () => {
        let {promotedOnSale} = this.state

        if (promotedOnSale.length>1){
            let removed =promotedOnSale.splice(1,1)
            for (let deck of removed[0]){
                promotedOnSale[0].push(deck)
            }
        }

        this.setState({promotedOnSale:promotedOnSale})
    }

    makePromotedFolder =() => {
        let {promoted} = this.state
        if (promoted.length<=0 || promoted[0].length===0 ){
            return <></>
        }        
        return (
            <div key='promoted decks'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.promoted:this.state.textsEn.promoted}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showPromotedDecks()}
                        </div>                        
                    </div>
                    {this.state.promoted.length>1?<button className="defaultButton float" onClick={this.increaseBucketPromotedDecks} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
            </div>
        )
    }

    makePromotedOnSaleFolder =() => {
        let {promotedOnSale} = this.state
        if (promotedOnSale.length<=0 || promotedOnSale[0].length===0){
            return <></>
        }        
        return (
            <div key='promoted decks sale'>
                <div className="decksPageFolderTitle">  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.promotedOnSale:this.state.textsEn.promotedOnSale}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showPromotedDecksOnSale()}
                        </div>                        
                    </div>
                    {this.state.promotedOnSale.length>1?<button className="defaultButton float" onClick={this.increaseSaleBucket} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
            </div>
        )
    }

  

    goToCreateDeckPage = () => {
        this.props.history.push("/createDeck")
    }
    

    loadPromotedDecksSale = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromotedOnSale',
            data:{"currencycode":localStorage.getItem('countryCode')==='+55'?'BRL':'USD'}
        }).then( res =>{
            if (res.status===200){
                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }
                
                this.setState({promotedOnSale:promotedBuckets,loadedPromotedOnSale:res.data,loadedData:true})
            }
        })
    }

    loadPromotedDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromoted'
        }).then( res =>{
            if (res.status===200){
                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }
                
                this.setState({promoted:promotedBuckets,loadedPromoted:res.data})
            }
        })
    }


    prepareFolders = (deckFolders) => {
        let {folderBuckets} = this.state
        let folderHash = {}
        let folderHashButtons = {}
        let folderNamesTrack={}
        deckFolders = deckFolders.sort(this.sortByDate)
        for (let folder in deckFolders){
            if ( !folderHash.hasOwnProperty( deckFolders[folder].name) ){
                folderHash[deckFolders[folder].name]=[]
                folderBuckets[deckFolders[folder].name]=[[]]
                folderHashButtons[deckFolders[folder].name]=['inline','none']
                folderNamesTrack[deckFolders[folder].name]=deckFolders[folder].name
            }
            let lastBucketPosition = folderBuckets[deckFolders[folder].name].length-1
            if (folderBuckets[deckFolders[folder].name][lastBucketPosition].length<userSizeOfBucket){
                folderBuckets[deckFolders[folder].name][lastBucketPosition].push(deckFolders[folder])
            }else{
                folderBuckets[deckFolders[folder].name].push([])
                folderBuckets[deckFolders[folder].name][lastBucketPosition].push(deckFolders[folder])
            }
            folderHash[deckFolders[folder].name].push(deckFolders[folder])
        }
        for (let folder in folderHash){
          folderHash[folder] = folderHash[folder].sort(this.sortByDate)
        }
        this.setState({folderBuckets:folderBuckets,folderHash:folderHash,folderHashButtons:folderHashButtons,folderNamesTrack:folderNamesTrack})
    }

    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    getMainPage = () => {
        if (this.state.loadedPromotedOnSale.length===0 && this.state.loadedData===true){
            return (
            <div style={{marginTop:'20px',textAlign:'center'}}>
                <h2>{localStorage.getItem('countryCode')==='+55'?'Nenhum deck à venda ainda':"There's no decks on sale now"}</h2>
                <FontAwesomeIcon icon="sad-tear" size="8x"></FontAwesomeIcon>
            </div>)
        }
        return (
          <div className="DeckBody">              
              {this.makePromotedOnSaleFolder()}
              {this.makePromotedFolder()}       
          </div>
        )
      
    }

    

    componentDidMount = async () => {
        try{     
            this.loadPromotedDecks()       
            this.loadPromotedDecksSale()            
                        
            this.setState({loadingDisplay:'none'})
        }catch(error){
            console.log(error)
            this.setState({problemOnLoad:true})
        }
        
    }

    render(){
        try{
            return (
                <>
                    <meta charSet="utf-8"></meta>
    
                    <GlobalNavBar/>
                    <div className='loading' style={{paddingTop:window.innerWidth>950?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight+'px',display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                        <div style={{marginLeft:'35%'}}>
                          <ReactLoading type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                        </div>
                        <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                    </div>
                    <div style={{minHeight:"90vh"}}>
                      {this.getMainPage()}
                    </div>
                    {this.footerPropaganda()}
                    <BottomBar/>
                </>
            );
        }catch(e){
            if (this.state.problemOnLoad===true){
                return <ErrorPage></ErrorPage>
            }else{
                console.log(e)
                return <GlobalNavBar/>
            }
            
        }
        
    }
}
export default withRouter(DecksOnSalePage)
