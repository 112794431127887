import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalNavBar from "../../components/globalNavBar"
import HelpUsBox from "../../components/helpUs"
import BottomBar from "../../components/bottomBar"
import dragonShield from '../../assets/dragonShield.png';
import centralShield from '../../assets/centralShield.png';
import './style.css'
import ReactLoading from 'react-loading';
import StarComponent from "../../components/proUserStar"

import axios from "axios"
import ErrorPage from "../ErrorPage"
import Modal from 'react-modal';

import AdSense from 'react-adsense';
import {CopyToClipboard} from 'react-copy-to-clipboard';
let userSizeOfBucket = 13
let spSizeOfBucket = 13
function isLoggedUser(userNameString){
  if (userNameString===null||userNameString===undefined||localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined){
    return false;
  }
  if (userNameString.trim()===localStorage.getItem('mdtUser').trim()){
    return true;
  }
  return false
}

class DecksPage extends Component{

    state = {
        problemOnLoad:false,
        loadingDisplay:'fixed',
        deckFolders:[],
        decksLoaded:[],
        copyLinkModalIsOpen:false,
        recentLoadedOk:false,
        recentCardsFolder:[],
        ispremium:false,
        loadedPromoted:[],
        loadedPromotedOnSale:[],
        promoted:[[]],
        selectedFilter:"date",
        selectedOrder:"asc",
        promotedOnSale:[[]],
        topCardsFolder:[],
        promotedDecksHash:{},
        mostPopularCommandersHash:{},
        showGoogleAdd:false,
        folderHash:{}, //key:folder values:[] array of deckfolder objects || name, player, deckname and presentationorder
        folderHashButtons:{}, //key:folder values:array=0 = display inline array[1] = display block
        folderBuckets:{},//key:folder values:[[]] array of folder buckets
        folderNamesTrack:{}, //key:old folder value:newFolderName        
        textsEn:{
            "popular":"Most popular Commanders",
            "fetchDecks":"Fetch decks",
            "seeMore":"See more",
            "promoted":"Promoted decks",
            "promotedOnSale":"Decks on sale",
            "default":"Default folder",
            "addDeck":"Create Deck",
            "addFolder":"Create folder",
            "nameNewFolder":"Name your new folder",
            "blockTitle":"Create your first deck",
            "blockMessage":"Welcome to Mydecktool decks page, create a new deck by clicking in the 'create deck' button, you can create your own custom categories for the cards in your decks, discover card recomendations, playtest, find out stores where you can get you list using less money and much more.",
            "formatsTitle":"Deck formats",
            "whyCategorizeTitle":"Why categorize the cards?",
            "whyCategorizeMessage":"It is a lot easier to understand what a deck is supposed to do if you label your cards in the right categories, it is quite common that MTG decks contains distinct cards that share functions, such as ramp, removal, bombs, card draw, wincons and so on.",
            "viewDeck":"View Deck",
            "howToSaveMoneyTitle":"How to save money?",
            "howToSaveMoneyMessage":"There is a LOT of MTG stores to purchase on the Internet, so it is quite hard to answer the question 'where do i buy the cards for my decks?', we can tell you what's the single store where your purchase have the minimun value for free, and we can even tell you what is the best combination of stores to make purchases with some restrictions for free.",
            "editDeck":"Edit deck",
            "playtest":"Playtest",
            "copyDeck":"Download deck"
        },
        textsPt:{
            "promoted":"Decks promovidos",
            "promotedOnSale":"Decks a venda",
            "popular":"Comandantes mais populares",
            "fetchDecks":"Buscar decks",
            "seeMore":"Ver mais",
            "default":"Pasta inicial",
            "addDeck":"Criar deck",
            "addFolder":"Criar pasta",
            "blockTitle":"Crie o seu primeiro deck",
            "nameNewFolder":"Coloque aqui o nome da sua pasta",
            "blockMessage":"Bem vindo a página de criação de decks, clique em criar deck para adicionar um novo baralho, categorize seus decks do seu jeito, faça playtests, receba recomendações de cards, descubra onde comprar gastando menos dinheiro e muito mais.",
            "whyCategorizeTitle":"Porquê categorizar as cartas?",
            "whyCategorizeMessage":"É muito mais fácil de entender o quê um deck deve fazer se você disser quais as categorias das cartas, é comum que baralhos de Magic tenham cartas que possuem funções similares, como ramp, remoções, bombas, compra de cartas, condições de vitória, etc.",
            "rampExample":"",
            "viewDeck":"Ver Deck",
            "howToSaveMoneyTitle":"Como economizar na compra de cartas?",
            "howToSaveMoneyMessage":"Existem muitas lojas de Magic na Internet, então é difícil de decidir onde comprar suas cartas gastando menos, nós podemos te dizer qual é a loja em que o seu deck sai mais barato de graça, podemos até te contar qual a melhor combinação de lojas para comprar com algumas restrições.",
            "editDeck":"Editar deck",
            "playtest":"Testar deck",
            "copyDeck":"Exportar deck"
        }

    }

    getFilterSelectionBox = () => {
        return(
            <div style={{marginLeft:'20px'}}>
                <label >
                    { window.innerWidth>=600? localStorage.getItem("countryCode")==="+55"?"Ordenar por ":"Order by ": <></>}
                </label>
                <select className="deckPageSelect" value={this.state.selectedFilter} onChange={(event)=>{this.setState({selectedFilter:event.target.value})}}>
                    <option value="views">{localStorage.getItem("countryCode")==="+55"?"Qt. Visualizações":"Qt. Views"}</option>
                    <option value="price">{localStorage.getItem("countryCode")==="+55"?"Preço":"Price"}</option>
                    <option value="date">{localStorage.getItem("countryCode")==="+55"?"Data":"Date"}</option>
                </select>
                <nav style={{display:'inline',marginLeft:'1px',marginRight:'1px'}}>:</nav>
                <select className="deckPageSelect" value={this.state.selectedOrder} onChange={(event)=>{this.setState({selectedOrder:event.target.value})}}>
                    <option value="asc">{localStorage.getItem("countryCode")==="+55"?"Do maior para menor":"ASC"}</option>
                    <option value="desc">{localStorage.getItem("countryCode")==="+55"?"Do menor para maior":"DESC"}</option>                    
                </select>
            </div>
        )
    }

    adjustDateFormat = (date) => {
        let parts = date.split('/')
        if(localStorage.getItem('countryCode')==="+55"){            
            return parts[2]+'/'+parts[1]+'/'+parts[0]
        }else if(localStorage.getItem('countryCode')==="+1"){
            return parts[1]+'/'+parts[0]+'/'+parts[2]
        }
        return date
    }

    registerToString = (decklisttuple) => {
        let displayNameArray = decklisttuple.cardname.split(';')
        if (decklisttuple.layout==='modal_dfc'||decklisttuple.layout==='transform'){
            return {"name":displayNameArray[0].trim(),"qtdeName":decklisttuple.card_qtde+" "+displayNameArray[0].trim()+"\r\n"}
        }else{
          let qtde=decklisttuple.card_qtde+' '
          let name=''
          for(let i = 0; i<displayNameArray.length;i++){
              if (i===displayNameArray.length-1){
                name+=displayNameArray[i].trim()
              }else{
                name+=displayNameArray[i].trim()+" / "
              }
          }
          return {"name":name,"qtdeName":qtde+name+"\r\n"}
        }
    }

    getDeckString = async (player,decklink) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "player":player,
            "link":decklink
        }
        return await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/absoluteListLink',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            
            if (res.status === 200 && res.data.length>0) {                
                let mainboardStr = ""                
                let commander = ""
                let companion = ""                
                let totalOfCards = 0
                let totalOfLands = 0                
                for (let decklisttuple of res.data){       
                    const displayCardValues = this.registerToString(decklisttuple)                     
                    if (decklisttuple.listtype==='mainboard'){                        
                        
                        totalOfCards+=decklisttuple.card_qtde
                        if (decklisttuple.type_en.toLowerCase().includes('land') && decklisttuple.cmc===0){
                            totalOfLands+=decklisttuple.card_qtde
                        }                        
                        mainboardStr+=displayCardValues.qtdeName                    
                    }else if(decklisttuple.listtype==='commander'){                        
                        commander+=displayCardValues.qtdeName
                    }else if(decklisttuple.listtype==='companion'){                        
                        companion+=displayCardValues.qtdeName
                    }
                }
                const deckString = commander+companion+mainboardStr;                            
                return deckString;
            }
        })
    }

    downloadTxtFile = async (player,decklink) => {
        const element = document.createElement("a");
        let deckString = await this.getDeckString(player,decklink);
        
        const file = new Blob([deckString],{type: 'text/plain;charSet=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = decklink+".txt";
        document.body.appendChild(element);
        element.click();
    }

    makeDefaultMessage = () =>{
        return (
            <div className="presentationDecksPage">
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockTitle:this.state.textsEn.blockTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockMessage:this.state.textsEn.blockMessage}</p>
                <div className="mtg1">
                </div>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeTitle:this.state.textsEn.whyCategorizeTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeMessage:this.state.textsEn.whyCategorizeMessage}</p>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyTitle:this.state.textsEn.howToSaveMoneyTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyMessage:this.state.textsEn.howToSaveMoneyMessage}</p>
            </div>
        )
    }

    getDecksFaceCards = (folderDecks) => {
        let deckFaceCards = []
        let deckFaceHash = {}
        for (let deck of folderDecks){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false) ){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        return deckFaceCards
    }



    sortByDate = (folderDeck1, folderDeck2) => {
      if (folderDeck1.last_update<folderDeck2.last_update){
        return 1;
      }else{
        return -1;
      }
    }

    sortByPrice = (deck1,deck2) =>{
        if (deck1.last_price<deck2.last_price){
            return 1;
        }else if (deck1.last_price>deck2.last_price){
            return -1
        }
        return 0;
    }

    sortByViews = (deck1,deck2) =>{
        if (deck1.qtviews<deck2.qtviews){
            return 1;
        }else if (deck1.qtviews>deck2.qtviews){
            return -1
        }
        return 0;
    }

    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    showPromotedDecks = () => {
        let {promoted} = this.state
        let foldersHtml = []
        if (promoted.length===0){
            return
        }
        for (let deck of promoted[0]){
            
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'promotedSale'+deck.player+Math.floor(Math.random() * 110000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                            <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                            <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                            </div>
                        </div>
                        <div style={{fontWeight:'600',textAlign:'center'}}>{this.reduceDeckName(deck.deckname)}</div>
                    </Link>

                    <div style={{textAlign:'center'}}>
                        <StarComponent decklistobject={deck}/>
                        <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                             {this.changeDisplayName(deck.player)}
                        </Link>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }

        return foldersHtml
    }

    showRecentDecks = () => {
        let {recentCardsFolder} = this.state
        let foldersHtml = []
        for (let deck of recentCardsFolder){

            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'recentdecks'+deck.player+Math.random(10000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                        <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                                <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                                </div>
                            </div>
                        <div style={{fontWeight:'600'}}>{this.reduceDeckName(deck.deckname)}</div>
                    </Link>
                    <div>
                      <StarComponent decklistobject={deck}/>
                      <Link className="searchPageAHover" target="_blank" to={"/users/"+deck.player+'/decks'}>
                        {this.changeDisplayName(deck.player)}</Link>
                    </div>
                    <div>
                      {deck.last_update}
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" target="_blank"><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }
        return foldersHtml
    }

    showTopDecks = () => {
        let {topCardsFolder} = this.state
        let foldersHtml = []
        for (let deck of topCardsFolder){
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/search?query="+this.changeDeckName(deck.maincard)}>
                        <div className="decksPageContainer">
                        <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                                <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.fetchDecks:this.state.textsEn.fetchDecks}</div>
                                </div>
                            </div>

                    </Link>
                    <div style={{fontWeight:'600',textAlign:'center'}}>
                        {deck.maincard}
                    </div>
                    <div style={{fontWeight:'1200',textAlign:'center'}}>
                        Decks:{deck.qtde}
                    </div>
                </div>
            )
        }
        return foldersHtml
    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')          
    }

    

    

    renderShareButton = (player,decklink) => {
        return (
            <CopyToClipboard style={{margin:'0px'}} text={'https://commanderdecks.com/users/'+player+'/decks/'+decklink}
                onCopy={() => this.setState({copied: true,copyLinkModalIsOpen:true})}>
                <nav className="darkBlueBt" ><FontAwesomeIcon style={{marginRight:'2px'}} icon={"share"}></FontAwesomeIcon>{localStorage.getItem('countryCode')==="+55"?"Compartilhar":"Share"}</nav>
            </CopyToClipboard>
        )
    }

    LinkCopyedModal = () => {
        let message=undefined
        if(localStorage.getItem('countryCode')==='+55'){
            message='Link do deck copiado para a área de transferência'
        }else{
            message='Deck link saved in the transfer area.'
            
        }
        return (
            <div>
                <Modal
                isOpen={this.state.copyLinkModalIsOpen}
                onRequestClose={() => {this.setState({copyLinkModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%",textAlign:'center',padding:'5%'}}>
                    <div><h3>{message}</h3></div>
                    <button style={{width:'100%'}} className="deckPageButton" onClick={() => {this.setState({copyLinkModalIsOpen:false})}}>OK</button>
                </div>
                </Modal>
            </div>
        )
    }

    reduceDeckName = (deckName) => {
        if (deckName.length<=29){
            return deckName;
        }
        return deckName.substring(0,26)+'...';
    }

    makeDecks = (folderDecks) => {        
        if (this.state.selectedFilter==="date"&&this.state.selectedOrder==="asc")      
            folderDecks = folderDecks.sort(this.sortByDate);
        else if(this.state.selectedFilter==="views"&&this.state.selectedOrder==="asc")
            folderDecks = folderDecks.sort(this.sortByViews);
        else if(this.state.selectedFilter==="price"&&this.state.selectedOrder==="asc")
            folderDecks = folderDecks.sort(this.sortByPrice);
        else if (this.state.selectedFilter==="date"&&this.state.selectedOrder==="desc")      
            folderDecks = folderDecks.reverse(this.sortByDate);
        else if(this.state.selectedFilter==="views"&&this.state.selectedOrder==="desc")
            folderDecks = folderDecks.reverse(this.sortByViews);
        else if(this.state.selectedFilter==="price"&&this.state.selectedOrder==="desc")
            folderDecks = folderDecks.reverse(this.sortByPrice);
        let foldersHtml = []
        for (let deck of folderDecks){
            if (deck.isprivate===true && deck.player!==localStorage.getItem('mdtUser')){
                continue;
            }
            
            foldersHtml.push(
                <div className="deckPFolder" key={deck.deckname} style={{marginBottom:'5px'}} >
                    <div>
                        <div className="flip-box-deck">
                            <div className="flip-box-inner-deck">
                                <div className="flip-box-front-deck">
                                <Link to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                                    <img className="deckfolderimage" key={deck.name_en} alt={deck.name_en} src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>                                
                                </Link>
                                </div>
                                <div className="flip-box-back-deck">
                                    
                                    <Link to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)} className="youtube"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"eye"}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Ver deck':'View Deck'}</Link>
                                    {isLoggedUser(deck.player)?<Link to={"/editDeck/"+deck.player+'/'+this.changeDeckName(deck.link)} className="greenInsideButton"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"edit"}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Editar deck':'Edit Deck'}</Link>:<></>}
                                    <Link to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)+'/playtest'} className="twitch"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"gamepad"}></FontAwesomeIcon>Playtest</Link>
                                    {this.renderShareButton(deck.player,this.changeDeckName(deck.link))}
                                    <nav className="pink" onClick={()=>this.downloadTxtFile(deck.player,this.changeDeckName(deck.link))}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={"download"}/> {localStorage.getItem('countryCode')==="+55"?"Exportar":"Export"}</nav>
                                </div>
                            </div>
                        </div> 
                        <div>
                        <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                            <div style={{fontWeight:'600'}}>{this.reduceDeckName(deck.deckname)}</div>                                
                        </Link>
                        <StarComponent decklistobject={deck}/>
                        {this.changeDisplayName(deck.player)} 
                        </div>
                        <div>
                        {this.adjustDateFormat(deck.last_update)}
                        </div>
                        <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                            <div className="iconButton textTip" style={{textAlign:'center',width:"121px"}}><FontAwesomeIcon className="spin60" style={{width:'25%',height:'22px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?"Qt. Visualizações":"Qt. Views"}</span> <p style={{display:'inline-block',fontSize:'22px'}}> {deck.qtviews} </p>  </div>
                            <div className="iconButton textTip" style={{textAlign:'center',width:"121px"}}><FontAwesomeIcon className="spin60" style={{width:'25%',height:'22px'}} icon="dollar-sign"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?"Ultimo preço carregado":"Last loaded price"}</span>  <p style={{display:'inline-block',fontSize:'22px'}}> {parseInt(deck.last_price,10)} </p> </div>
                        </div>
                    </div>

                </div>
            )
        }
        return foldersHtml
    }

    updateFolder = (folder) => () => {
        let {folderHashButtons} = this.state
        let tmp = folderHashButtons[folder][0]
        folderHashButtons[folder][0] = folderHashButtons[folder][1]
        folderHashButtons[folder][1] = tmp
        this.setState(folderHashButtons)
    }

    updateFolderNameTextField = (folder) => (e) => {
        let {folderNamesTrack} = this.state
        folderNamesTrack[folder]=e.target.value;
        this.setState({folderNamesTrack})
    }

    updateDeckFolderName = async (player,old_name,name) => {
        let data = {
            "player": player,
            "old_name":old_name,
            "name":name,
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'put',
            url:api.baseUrl+'/deckfolder/updateName',
            data:data,
            headers:axiosConfig.headers,
        })
    }

    saveFolder = (folder) => () => {
        this.updateDeckFolderName(this.props.match.params.user,folder,this.state.folderNamesTrack[folder])
        let {folderHashButtons} = this.state
        let tmp = folderHashButtons[folder][0]
        folderHashButtons[folder][0] = folderHashButtons[folder][1]
        folderHashButtons[folder][1] = tmp
        this.setState({folderHashButtons})
    }

    increaseBucket = (folder) => () =>{
      let {folderBuckets} = this.state
      if (folderBuckets[folder].length>1){
        let removed = folderBuckets[folder].splice(1,1)
        for (let deck of removed[0]){
            folderBuckets[folder][0].push(deck)
        }
      }
      this.setState({folderBuckets:folderBuckets})
    }


    increaseBucketPromotedDecks = () => {
        let {promoted} = this.state

        if (promoted.length>1){
            let removed =promoted.splice(1,1)
            for (let deck of removed[0]){
                promoted[0].push(deck)
            }
        }

        this.setState({promoted:promoted})
    }

    increaseSaleBucket = () => {
        let {promotedOnSale} = this.state

        if (promotedOnSale.length>1){
            let removed =promotedOnSale.splice(1,1)
            for (let deck of removed[0]){
                promotedOnSale[0].push(deck)
            }
        }

        this.setState({promotedOnSale:promotedOnSale})
    }

    makePromotedFolder =() => {
        let {promoted} = this.state
        if (promoted.length<=0 || promoted[0].length===0 ){
            return <></>
        }
        return (
            <div key='promoted decks'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.promoted:this.state.textsEn.promoted}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showPromotedDecks()}
                        </div>
                    </div>
                    {this.state.promoted.length>1?<button className="defaultButton float" onClick={this.increaseBucketPromotedDecks} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
            </div>
        )
    }

    makeRecentDecksFolder = () => {
        let {recentCardsFolder,promoted} = this.state
        
        if (recentCardsFolder.length===0) return <></>;
        if (promoted.length>0&&promoted[0].length!==0) return <></>;
        
        return (
            <div key='recent decks'>
                <div className="decksPageFolderTitle" style={{marginLeft:'20px',color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?"Decks recentes":"Recent Decks"}   </div>
                    <div className="DeckFolder"style={{marginLeft:'20px'}}>
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showRecentDecks()}
                        </div>
                    </div>
            </div>
        )
        
    }

    makePromotedOnSaleFolder =() => {
        let {promotedOnSale} = this.state
        if (promotedOnSale.length<=0 || promotedOnSale[0].length===0){
            return <></>
        }
        return (
            <div key='promoted decks sale'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.promoted:this.state.textsEn.promoted}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showPromotedDecks()}
                        </div>
                    </div>
                    {this.state.promotedOnSale.length>1?<button className="defaultButton float" onClick={this.increaseSaleBucket} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
            </div>
        )
    }


    allPrivateDecks = (folderDecks) => {
        for (let deck of folderDecks){
            if (deck.isprivate===false){
                return false
            }            
        }
        return true;
    }

    makeDeckFolders = () => {
        if(this.state.deckFolders.length===0){
            return <></>
        }
        let out=[]
        
        for (let folder in this.state.folderBuckets){
            if (this.allPrivateDecks(this.state.folderBuckets[folder][0])===true && this.props.match.params.user!==localStorage.getItem('mdtUser')){
                continue;
            }
            if (folder==='Commander' && this.props.match.params.user!=='wizards'){
                out.unshift(
                    <div key={folder}>
                        <div className="decksPageEditFolder" style={{display:this.state.folderHashButtons[folder][1],padding:"0px"}}>
                            <input type="text" style={{ marginTop:"15px" }} placeholder="Name of your new folder" value={this.state.folderNamesTrack[folder]} onChange={this.updateFolderNameTextField(folder)}/> <FontAwesomeIcon icon="save" className="decksPageSaveButton" onClick={this.saveFolder(folder)}></FontAwesomeIcon>
                        </div>
                        <div className="decksPageFolderTitle">  <div className="tooltip"> {isLoggedUser()?<FontAwesomeIcon icon="edit" onClick={this.updateFolder(folder)} className="decksPageEditIcon"></FontAwesomeIcon>:<div></div>}  </div> {this.state.folderNamesTrack[folder]}   </div>
                        <div className="DeckFolder">
                            <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                                {this.makeDecks(this.state.folderBuckets[folder][0])}
                            </div>
                            {this.state.folderBuckets[folder].length>1?<button className="defaultButton float" onClick={this.increaseBucket(folder)} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
                        </div>
                    </div>
                )
                
            }else{

                out.push(
                <div key={folder}>
                    <div className="decksPageEditFolder" style={{display:this.state.folderHashButtons[folder][1],padding:"0px"}}>
                        <input type="text" style={{ marginTop:"15px" }} placeholder="Name of your new folder" value={this.state.folderNamesTrack[folder]} onChange={this.updateFolderNameTextField(folder)}/> <FontAwesomeIcon icon="save" className="decksPageSaveButton" onClick={this.saveFolder(folder)}></FontAwesomeIcon>
                    </div>
                    <div className="decksPageFolderTitle">  <div className="tooltip"> {isLoggedUser()?<FontAwesomeIcon icon="edit" onClick={this.updateFolder(folder)} className="decksPageEditIcon"></FontAwesomeIcon>:<div></div>}  </div> {this.state.folderNamesTrack[folder]}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.makeDecks(this.state.folderBuckets[folder][0])}
                        </div>
                        {this.state.folderBuckets[folder].length>1?<button className="defaultButton float" onClick={this.increaseBucket(folder)} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
                    </div>
                </div>)
            }
        }
        return out
    }

    goToCreateDeckPage = () => {
        this.props.history.push("/createDeck")
    }

    loadDeckFoldersFromUser = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "user": this.props.match.params.user,
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/deckfolder/listFromUser',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                const r = this.prepareFolders(res.data);                
                this.setState({deckFolders:res.data,folderBuckets:r.folderBuckets,folderHash:r.folderHash,folderHashButtons:r.folderHashButtons,folderNamesTrack:r.folderNamesTrack})
            }
        })
    }

    loadPopularDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPopular'
        }).then( res =>{
            if (res.status===200){


                this.setState({topCardsFolder:res.data})
            }
        })
    }

    loadRecent = async () => {
        const axiosConfig = api.axiosConfig;
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listHome',
            data:{},
            headers:axiosConfig.headers
        }).then(res => {
            if(res.status===200){
                this.setState({recentLoadedOk:true,recentCardsFolder:res.data})
            }else{
                this.setState({recentLoadedOk:false})
            }
        }).catch(err => {
            this.setState({recentLoadedOk:false})
        })
    }



    loadPromotedDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromoted'
        }).then( res =>{
            if (res.status===200){


                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }

                this.setState({promoted:promotedBuckets,loadedPromoted:res.data})
            }
        })
    }

    prepareFolders = (deckFolders) => {
        let {folderBuckets} = this.state
        let folderHash = {}
        let folderHashButtons = {}
        let folderNamesTrack={}

        for (let folder in deckFolders){
            if ( !folderHash.hasOwnProperty( deckFolders[folder].name) ){
                folderHash[deckFolders[folder].name]=[]
                folderBuckets[deckFolders[folder].name]=[[]]
                folderHashButtons[deckFolders[folder].name]=['inline','none']
                folderNamesTrack[deckFolders[folder].name]=deckFolders[folder].name
            }
            let lastBucketPosition = folderBuckets[deckFolders[folder].name].length-1
            if (folderBuckets[deckFolders[folder].name][lastBucketPosition].length<userSizeOfBucket){
                folderBuckets[deckFolders[folder].name][lastBucketPosition].push(deckFolders[folder])
            }else{
                folderBuckets[deckFolders[folder].name].push([])
                folderBuckets[deckFolders[folder].name][lastBucketPosition].push(deckFolders[folder])
            }
            folderHash[deckFolders[folder].name].push(deckFolders[folder])
        }
        return {folderBuckets,folderHash,folderHashButtons,folderNamesTrack}
    }





    getMainPage = () => {
      if (this.state.deckFolders.length>0){
        return (
           <>
               <button className="addDeckButton float" onClick={this.goToCreateDeckPage}> <FontAwesomeIcon icon="arrow-right"/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.addDeck:this.state.textsEn.addDeck} </button>
               <HelpUsBox></HelpUsBox> 
               {this.getFilterSelectionBox()}
                             
               <div className="DeckBody">
                    {this.makeDeckFolders()}            
                    {this.makePromotedFolder()}
                    {this.makeRecentDecksFolder()}
                </div>
           </>
        )
      }else{
        return (
          <>
          <button className="addDeckButton float" onClick={this.goToCreateDeckPage}> <FontAwesomeIcon icon="arrow-right"/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.addDeck:this.state.textsEn.addDeck} </button>
           
          <div>
              {this.makePromotedFolder()}
              {this.makeRecentDecksFolder()}
          </div>
          </>

        )
      }
    }

    loadCards = (lastEdition) => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then( res => {
            if (res.status === 200) {
                localStorage.setItem('allCards',JSON.stringify(res.data))
                localStorage.setItem('lastEdition',lastEdition)
            }
        })
    }

    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {

                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    this.loadCards(res.data[0].edition)
                }
            }
        })
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){
                    localStorage.setItem('mdtConf',res.data[0].ispremium)
                    this.setState({ispremium:res.data[0].ispremium})
                }else{
                    this.setState({ispremium:false})
                }
            }
        }).catch( (err) => {
            this.setState({ispremium:false})
        })
    }

    componentDidMount = async () => {
        try{
            this.loadUser()
            this.loadLastEdition()
            if(this.props.user&&!this.props.match.params.user){
                this.props.match.params.user=this.props.user
            }
            this.loadDeckFoldersFromUser()
            this.loadPopularDecks()
            this.loadPromotedDecks()
            this.loadRecent()
            this.setState({loadingDisplay:'none'})
        }catch(error){
            this.setState({problemOnLoad:true})
        }

    }
    

    amazonPropaganda = () => {
        if (localStorage.getItem('countryCode')==='+55'){
            return (
                <a style={{marginLeft:window.innerWidth*0.15}} target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Central Shield"> <img style={{width:window.innerWidth*0.7+"px"}} src={centralShield}/> </a>
            );
        }
        return (
            <a style={{marginLeft:window.innerWidth*0.15}} target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Dragon Shield" > <img style={{width:window.innerWidth*0.7+"px"}} src={dragonShield}/> </a>
        );
        
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    render(){
        
       
            return (
                <>
                    <meta charSet="utf-8"></meta>
                    <GlobalNavBar/>
                    <div className='loading' style={{paddingTop:window.innerWidth>950?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight+'px',display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                        <div style={{marginLeft:'35%'}}>
                          <ReactLoading type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                        </div>
                        <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                    </div>
                    
                    <div style={{minHeight:"90vh"}}>
                      {this.getMainPage()}
                    </div>
                    {this.LinkCopyedModal()}
                    {this.footerPropaganda()}
                    <BottomBar/>
                </>
            );
        
    }
}
export default withRouter(DecksPage)
