import React, { Component } from "react";
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../services/api';
import './style.css'
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import ErrorPage from "../ErrorPage"
const axios = require('axios');

class RecoverMail extends Component {


    state = {
        problemOnLoad:false,
        login: "",
        password: "",
        hiddenPassword: true,
        confirmPassword:"",
        hiddenConfirmPassword:true,
        playerOk:false,
        token: "",
        modalBox:false,
        modalBoxMessage:"",
        modalBoxTitle:"",
        responseGet:null,
        displayMessage:false,
        message:"",
        textsEn:{"noMail":"Sorry, it seems that this email it's not on the system","passWordLb":"Please, choose a new password","notMatch":"Oops...the passwords do not match","authError":"Oops... something went wrong...","show":"Show/Hide","email":"Email","password":"Password","confirmPassword":"Confirm Password","login":"Change password","register":"Register"},
        textsPt:{"noMail":"Desculpe, parece que esse email não faz parte do sistema","passWordLb":"Escolha uma nova senha" ,"notMatch":"Vish... as senhas não batem","authError":"Vish... algo deu errado","show":"Ver/Ocultar","email":"Email","password":"Senha","confirmPassword":"Confirmar senha","login":"Mudar senha","register":"Registrar"}
    }

    authenticate = async (login,password) => {
        const axiosConfig = api.axiosConfig;
        let data = {
            "email": login,
            "passwd": password,
            "nickname":login
        }
        await axios({
            method: 'post',
            url: api.authUrl + '/authenticate',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                this.setState({ login, password, token: res.data.token })
                localStorage.setItem('mdtUser', res.data.results[0].nickname)
                localStorage.setItem('mdtToken', res.data.token)
                localStorage.setItem('mdtConf', res.data.results[0].ispremium)
                this.props.history.push('/users/'+localStorage.getItem('mdtUser')+'/decks/')
            }
        })
    }


    buttonSend = async () => {
        const { confirmPassword, password } = this.state;
        const axiosConfig = api.axiosConfig;
        if(confirmPassword!==password){
            this.setState({displayMessage:true,message:localStorage.getItem('countryCode')==="+55"?this.state.textsPt.notMatch:this.state.textsEn.notMatch})
            return
        }

        let data = {
            "email": this.props.match.params.user,
            "passwd": password,
            "hash":this.props.match.params.hash
        }

        await axios({
            method: 'post',
            url: api.freeUrl + '/player/updatePasswd',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
              this.authenticate(this.props.match.params.user,password)
            }
        }).catch(err => {
            console.log(err)
            console.log(err.response)
            this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
        });
    }


    buttonShowPassword = () => {
        let { hiddenPassword } = this.state
        if (hiddenPassword === true) {
            this.setState({ hiddenPassword: false })
        } else {
            this.setState({ hiddenPassword: true })
        }
    }

    buttonShowConfirmPassword = () => {
        let { hiddenConfirmPassword } = this.state
        if (hiddenConfirmPassword === true) {
            this.setState({ hiddenConfirmPassword: false })
        } else {
            this.setState({ hiddenConfirmPassword: true })
        }
    }

    fieldStateSetEmail = (event) => {
        this.setState({ "login": event.target.value })

    }

    fieldStateSetPassword = (event) => {
        let { password } = this.state
        password = event.target.value
        this.setState({ password })
    }

    fieldStateSetConfirmPassword = (event) => {
        let { confirmPassword } = this.state
        confirmPassword = event.target.value
        this.setState({ confirmPassword })
    }

    stringHide = (string) => {
        let out = "";
        let parts = string.split('')
        for (let p of parts) {
            out += '*';
        }
        return out;
    }


    divPassword = () => {
        return (
            <div className="passwordGrid">
                <div>
                    <input className="passwordInput" type={this.state.hiddenPassword ? "password" : "text"} placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.password:this.state.textsEn.password  } value={this.state.password} onChange={this.fieldStateSetPassword}/>
                </div>

                <div className="tooltip" style={{width:"16px",marginTop:'1px'}}>
                    <FontAwesomeIcon id="iconEye" icon="eye" onClick={this.buttonShowPassword}/>
                    <span className="tooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.show:this.state.textsEn.show}</span>
                </div>

            </div>
        )
    }

    divPasswordConfirm = () => {
        return (
            <div className="passwordGrid">
                <div>
                    <input className="passwordInput" type={this.state.hiddenConfirmPassword ? "password" : "text"} placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.confirmPassword:this.state.textsEn.confirmPassword  } value={this.state.confirmPassword} onChange={this.fieldStateSetConfirmPassword}/>
                </div>

                <div className="tooltip" style={{width:"16px", marginTop:'2px'}}>
                    <FontAwesomeIcon id="iconEye" icon="eye" onClick={this.buttonShowConfirmPassword}/>
                    <span className="tooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.show:this.state.textsEn.show}</span>
                </div>

            </div>
        )
    }

    formLogin = () => {
        return (
            <>
                {this.displayMessage()}
                <h2 style={{color:'snow'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.passWordLb:this.state.textsEn.passWordLb}</h2>
                {this.divPassword()}
                {this.divPasswordConfirm()}
            </>
        )
    }

    displayMessage = () => {
        if (this.state.displayMessage){
            return (
                <div id="displayMessage">
                    {this.state.message}
                </div>
            )
        }
        return <div id="hiddenMessage"></div>
    }

    centralBox = () => {
        return(
            <div style={{marginTop:window.innerHeight/3.5,width:window.innerWidth/2}} id="central">
                {this.formLogin()}
                <div className="buttons">
                    <button style={{display:"inline",marginTop:'2px'}} id="sign" onClick={this.buttonSend}> <FontAwesomeIcon id="iconCrow" icon="dragon" onClick={this.buttonShowPassword}/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.login:this.state.textsEn.login  }</button>

                </div>
            </div>
        )
    }

   
    checkUser = async () => {
        let login = this.props.match.params.user
        const axiosConfig = api.axiosConfig;
        let data = {
            "email": login
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/player/getByEmail',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length===0){
                  this.setState({playerOk:false,displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.noMail:this.state.textsEn.noMail})
                }else{
                  if (this.props.match.params.hash.trim()!==res.data[0].changepwtoken){
                    this.props.history.push('/')
                    document.location.reload()
                  }
                  this.setState({playerOk:true})
                }
            }
        }).catch(err => {
            console.log(err)
            this.setState({playerOk:false,displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
        });
    }

    async componentDidMount(){
      try{
        await this.checkUser()
      }catch(e){
        this.setState({problemOnLoad:true})
      }
    }

    render() {
        try{
            return (
                <>
                  <div className="bgRecover2" style={{height:window.innerHeight}}>
                    <meta charSet="utf-8"></meta>
                    <GlobalNavBar/>
                    <div >
                      {this.centralBox()}
                    </div>
    
                  </div>
                  <BottomBar/>
                </>
            )
        }catch(e){
            if (this.state.problemOnLoad===true){
                return <ErrorPage></ErrorPage>
            }else{
                return <GlobalNavBar/>
            }
        }        
    }
};

export default withRouter(RecoverMail);
