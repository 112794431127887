import React, {Component} from "react"
import {withRouter} from "react-router"
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'



class FailPage extends Component{

    state = {
        problemOnLoad:false,
        textsEn:{
            "tittle":"Oooops...something went wrong with the payment!",
            "paymentApproved":"Something went wrong, sorry for that... please try again."
        },
        textsPt:{
            "tittle":"Oooops...algo deu errado no pagamento",
            "paymentApproved":"Algo deu errado no pagamento, por favor tente novamente"
        }
    }


    
    componentDidMount = async () => {
        
    }

    mainText = () => {
        if (localStorage.getItem('countryCode')==='+55'){
            return <div>  <div><h1>{this.state.textsPt.tittle}</h1></div> {this.state.textsPt.paymentApproved} </div>
        }else{
            return <div>  <div><h1>{this.state.textsEn.tittle}</h1></div> {this.state.textsEn.paymentApproved} </div>
        }
    }
    
    render(){
        return (
            <>
                    
                <GlobalNavBar/>
                <div className="bgFail" style={{height:window.innerHeight,textAlign:'center'}} >
                    <div style={{color:'snow',paddingTop:'15%'}}>{this.mainText()}</div>
                </div>
                <BottomBar/>
            </>
        );
    }
}
export default withRouter(FailPage)
