import React, {Component} from "react"
import {withRouter} from "react-router"
import ProgressBar from "../../components/progressBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css'
class HelpUsBox extends Component{

    getProgressBar = () => {
        return (
            <div className="helpUsBoxFolder">
                <div>
                    {localStorage.getItem('countryCode')==='+55'?'Nos ajude a alcançar o nosso próximo objetivo (bancar custos básicos de servidor) com sua doação, ao doar você se torna imune a propagandas, pode promover seus decks e entra no grupo de WhattsApp dos apoiadores da aplicação. Muito obrigado pelo seu apoio!':'By donating you become a contributor. As a contributor, you can promote your decks for everyone to see for some time; additionally you no longer see any ads. Thanks for your help!'}
                    <ProgressBar bgcolor="#288745" completed={19}/>
                </div>                                                         
            </div>
              
        )
    }

    render(){
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        return (
            <div>
                <a href="https://apoia.se/commanderdecks" target="_blank">
                    <div className="helpUsBox"> {localStorage.getItem('countryCode')==='+55'?'Você pode nos ajudar?':'Can you help us?'} {window.innerWidth>=600?<FontAwesomeIcon style={{paddingTop:'2px',paddingBottom:'2px'}} icon="star" size="small"/>:<></>}   </div>
                    {window.innerWidth>=600?this.getProgressBar():<></>} 
                    
                </a>
            </div>            
        )  
    }

}
export default withRouter(HelpUsBox)
