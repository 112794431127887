import React, {Component} from "react"
import {withRouter} from "react-router"
import './style.css'

class AutoCompleteBox extends Component{

    state = {
        value:"",
        fetchList:[],
        fetchListStyle:{},
        selectedPosition:-1,
        backgroundColor:'#FFFFFF',
        placeholder:''
    }
    
    renderPossibilities = () => {
        let vals = []
        for(let index in this.state.fetchList){
            let value = this.state.fetchList[index]
            vals.push(
                <li  key={index} style={{color:this.state.fetchListStyle[value].color,backgroundColor:this.state.fetchListStyle[value].backgroundColor,padding:'4px'}} onMouseEnter={ this.onMouseEnter(value,index) } onMouseLeave={ this.onMouseLeave(value) } onClick={ (e) => this.setValueToSelected(value)}>{value}</li>
            )
        }
        return vals
    }

    onMouseEnter = (value,index) => () => {
        let {fetchListStyle} = this.state
        if (this.state.selectedPosition!==-1){
            fetchListStyle[this.state.fetchList[this.state.selectedPosition]].backgroundColor='#FFFFFF'
            fetchListStyle[this.state.fetchList[this.state.selectedPosition]].color='#000000'
        }

        fetchListStyle[value].backgroundColor='#0366d6'
        fetchListStyle[value].color='#FFFFFF'
        this.setState({fetchListStyle,selectedPosition:parseInt(index,10)})
    }

    onMouseLeave = (value) => () => {
        let {fetchListStyle} = this.state
        fetchListStyle[value].backgroundColor='#FFFFFF'
        fetchListStyle[value].color='#000000'
        this.setState({fetchListStyle})
    }

    setValueToSelected = async (value) => {        
        await this.props.handler(value,true)
        this.setState({value:value,fetchList:[],selectedPosition:-1})
    }

    changeValue = (e) => {
        
        let tmpFethList = []
        let tmpFetchListStyle ={}
        
        for(let stringIndex in this.props.stringList){
            let string = this.props.stringList[stringIndex]
            if (string.toLowerCase().includes(e.target.value.toLowerCase()) && e.target.value.length>=2 && tmpFethList.length<=10){
                tmpFethList.push(string)
                tmpFetchListStyle[string]={backgroundColor:'#FFFFFF',index:stringIndex, color:"#000000"}
                
            }
        }
        this.props.handler(e.target.value, false)
        this.setState({value:e.target.value,fetchList:tmpFethList,fetchListStyle:tmpFetchListStyle})
    }

    keyAction = (e) => {  
         
        let {selectedPosition,fetchList} = this.state


        if (e.key === 'Enter') {
            
            if(fetchList.length===1){
                this.props.handler(fetchList[0],true)
                this.setState({value:fetchList[0],fetchList:[],selectedPosition:-1})
            }else if (selectedPosition>=0 && selectedPosition<fetchList.length){
                this.props.handler(fetchList[selectedPosition],true)
                this.setState({value:fetchList[selectedPosition],fetchList:[],selectedPosition:-1})
            
            }else{
                if(fetchList.length===0 && this.state.value!=="" &&this.state.value!==null&&this.state.value!==undefined){
                    this.props.handler(this.state.value,true)
                    this.setState({fetchList:[],selectedPosition:-1})
                }else{
                    this.setState({fetchList:[],selectedPosition:-1})
                }                
            }    
        }else if(e.key==='ArrowUp'){
            let {fetchListStyle} = this.state
            if (selectedPosition>0 && selectedPosition<fetchList.length){
                let prev = selectedPosition-1
                fetchListStyle[fetchList[selectedPosition]].backgroundColor='#FFFFFF'
                fetchListStyle[fetchList[selectedPosition]].color='#000000'
                fetchListStyle[fetchList[prev]].backgroundColor='#0366d6'
                fetchListStyle[fetchList[prev]].color='#FFFFFF'
                
                this.setState({selectedPosition:prev,fetchListStyle})
            }
            
        }else if(e.key==='ArrowDown'){
            let {fetchListStyle} = this.state
            if (selectedPosition<fetchList.length-1){
                let next = selectedPosition+1
                if(selectedPosition>=0){
                    fetchListStyle[fetchList[selectedPosition]].backgroundColor='#FFFFFF' 
                    fetchListStyle[fetchList[selectedPosition]].color='#000000' 
                }
                if (selectedPosition>=-1){
                    fetchListStyle[fetchList[next]].backgroundColor='#0366d6'
                    fetchListStyle[fetchList[next]].color='#FFFFFF'
                    this.setState({selectedPosition:next,fetchListStyle})
                }                
            }
        }
        
    }

    componentDidMount(){
        if (this.props.value!==null&&this.props.value!==undefined&&this.props.value!==""){
            this.setState({"value":this.props.value})
        }
        if (this.props.placeholder!==null&&this.props.placeholder!==undefined&&this.props.placeholder!==""){
            this.setState({"placeholder":this.props.placeholder})
        }
        
    }

    onMouseLeaveUl = (e) => {
       
        this.setState({fetchList:[],fetchListStyle:{},selectedPosition:-1,backgroundColor:'#FFFFFF'})
    }

    render(){
        return (
           <>
            <input style={this.props.style} placeholder={this.state.placeholder} type="text" value={this.state.value} onKeyDown={this.keyAction} onChange={this.changeValue}></input>
            <ul className="AutoCompleteLi" style={{listStyle:"none", zIndex:'2000',position:"absolute"}} onMouseLeave={ this.onMouseLeaveUl }>
                {this.renderPossibilities()}
            </ul>
           </> 
        )    
    }

}
export default withRouter(AutoCompleteBox)
