import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import Modal from 'react-modal';
import GlobalNavBar from "../../components/globalNavBar"
import AutoCompleteBox from "../../components/autoCompleteBox"
import BottomBar from "../../components/bottomBar"
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import './style.css'
import AdSense from 'react-adsense';
import "@webscopeio/react-textarea-autocomplete/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function capitalizeFirstLetter([ first, ...rest ], locale = navigator.language) {
    return [ first.toLocaleUpperCase(locale), ...rest ].join('');
}
const axios = require('axios')

const Item = ({ entity: {name} }) => <div>{name.trim()}</div>;


const deckAreaStyle = {
    width: "100%",
    height: "400px",

    fontSize:"20px",
    resize:"none"
}
class CreateDeckPage extends Component{
        
    state = {
        upperCardNamesHash:{},
        settedFolder:false,
        clickedButton1:false,
        problemOnLoad:false,
        text: '',
        suggestions:[],
        isPrivateDeck:"no",
        halfNames:{},
        descriptionArea:"",
        deckArea:"",
        userTyped:false,
        deckName:"",
        sideboardArea:"",
        maybeboardArea:"",
        loadedPrices:false,
        commanderArea:"",
        deckNameRetrieved:"",
        partnerArea:"",
        displayMessage:false,
        modalErrorIsOpen:false,
        deckInsertOk:false,
        iscreator:0,
        deckCards:[],
        sideboardCards:[],
        maybeboardCards:[],
        companionArea:"",
        youtubeArea:"",
        twitchArea:"",
        cards: [],
        allCards:[],
        allCardsHash:{},
        allEnglishCardsHash:{},
        allCommanders:[],
        allCommandersHash:{},
        allPartners:[],
        allPartnersHash:{},
        allCompanions:[],
        allCompanionsHash:{},
        ptEnCardHash:{},
        defaultCatHash:{}, //key:cardname value:{cardname,type_en,defaultcat}
        allTags:[],
        distinctFolders:[],
        updateEdition:'',
        selectedFormat:"Commander",
        textAreaKey:"textAreaKey",
        tmpReplacement:"",
        commanderDisplay:"block",
        commanderAnimation:"fadeIn",
        abaDeckDisplay:"block",
        abaSideboardDisplay:"none",
        abaMaybeboardDisplay:"none",
        buttonDeckClass:"tablinks active",
        buttonSideboardClass:"tablinks",
        selectedPrivacy:"no",
        buttonMaybeboardClass:"tablinks",
        animationDuration:"1s",
        folder:"",
        categoriesHash:{},
        textsEn:{
            "close":"Close",
            "name":"Deck name",
            "errorHeader":"Ooops... we have a problem",
            "errorMessage":"",
            "exampleName":"My epic monoblack deck",
            "exampleDescription":"This deck is amazing!",
            "private":"It's a private deck?",
            "no":"No, it's not a private deck",
            "yes":"Yes, it is a private deck",
            "description":"Deck description",
            "format":"Format",
            "commander":"Commander",
            "folder":"Deck Folder*",
            "partner":"Partner",
            "create":"Create deck",
            "companion":"Companion"
        },
        textsPt:{
            "close":"Fechar",
            "name":"Nome do deck",
            "errorHeader":"Ooops... tivemos um probleminha",
            "errorMessage":"",
            "folder":"Pasta do deck*",
            "exampleName":"Meu deck monoblack épico",
            "exampleDescription":"Esse deck é muito apelão!",
            "no":"Não é um deck privado",
            "yes":"É um deck privado",
            "private":"É um deck privado?",
            "description":"Descrição do deck",
            "format":"Formato",
            "commander":"Comandante",
            "create":"Criar deck",
            "partner":"Comandante Parceiro",
            "companion":"Companheiro"
        }
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    showDeckTab = (e) => {
        this.setState({
            abaDeckDisplay:"block",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks active",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks",
        })
    }

    showSideboardTab = (e) => {
        this.setState({
            abaDeckDisplay:"none",
            abaSideboardDisplay:"block",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks active",
            buttonMaybeboardClass:"tablinks",
        })
    }



    showMaybeboardTab = (e) => {
        this.setState({
            abaDeckDisplay:"none",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"block",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks active",
        })
    }

    loadingCardsText = () => {
        if (this.state.loadedPrices===false){
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Carregando cartas...':'Loading cards...'}</div>
        }else{
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Cartas carregadas, agora você pode usar @ para buscar cartas':'Loaded the cards, now you can use @ to fetch for cards'}</div>
        }
    }

    loadTags = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let language = localStorage.getItem('countryCode')==="+55"?'pt':'en'
        await axios({
            method: 'post',
            url: api.freeUrl + '/decktags/'+language,
            data: {}, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {

                this.setState({ allTags: res.data })
            }
        })
    }

    replaceIn = (cardName) => {
        if(cardName){
            return cardName.replace(/;/g,'/')
        }
        return
    }

    loadDistinctFolders = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/deckfolder/getDistinctFolders',
            data: {"player":localStorage.getItem('mdtUser')}, headers:axiosConfig.headers
        }).then(res => {
            this.setState({distinctFolders:res.data})
            
        })
    }

    transformNamePt = (nameIn) => {
        let namePt = nameIn.replace(/á/g,'a')
        
        namePt = namePt.replace(/à/g,'a')
        namePt = namePt.replace(/ã/g,'a')
        namePt = namePt.replace(/â/g,'a')

        namePt = namePt.replace(/Á/g,'A')
        namePt = namePt.replace(/À/g,'A')
        namePt = namePt.replace(/Ã/g,'A')
        namePt = namePt.replace(/Â/g,'A')

        namePt = namePt.replace(/é/g,'e')
        namePt = namePt.replace(/è/g,'e')
        namePt = namePt.replace(/ẽ/g,'e')
        namePt = namePt.replace(/ê/g,'e')

        namePt = namePt.replace(/É/g,'E')
        namePt = namePt.replace(/È/g,'E')
        namePt = namePt.replace(/Ẽ/g,'E')
        namePt = namePt.replace(/Ê/g,'E')


        namePt = namePt.replace(/í/g,'i')
        namePt = namePt.replace(/ì/g,'i')
        namePt = namePt.replace(/ĩ/g,'i')
        namePt = namePt.replace(/î/g,'i')


        namePt = namePt.replace(/Í/g,'I')
        namePt = namePt.replace(/Ì/g,'I')
        namePt = namePt.replace(/Ĩ/g,'I')
        namePt = namePt.replace(/Î/g,'I')

        namePt = namePt.replace(/ó/g,'o')
        namePt = namePt.replace(/ò/g,'o')
        namePt = namePt.replace(/õ/g,'o')
        namePt = namePt.replace(/ô/g,'o')

        namePt = namePt.replace(/Ó/g,'O')
        namePt = namePt.replace(/Ò/g,'O')
        namePt = namePt.replace(/Õ/g,'O')
        namePt = namePt.replace(/Ô/g,'O')

        namePt = namePt.replace(/ú/g,'u')
        namePt = namePt.replace(/ù/g,'u')
        namePt = namePt.replace(/ũ/g,'u')
        namePt = namePt.replace(/û/g,'u')
        namePt = namePt.replace(/ü/g,'u')

        namePt = namePt.replace(/Ú/g,'U')
        namePt = namePt.replace(/Ù/g,'U')
        namePt = namePt.replace(/Ũ/g,'U')
        namePt = namePt.replace(/Û/g,'U')
        namePt = namePt.replace(/Ü/g,'U')
        return namePt
    }

    loadCardsFromLocalStorage = () => {
        let allCardsLS = JSON.parse(localStorage.getItem('allCards'))
        let names = []
        let namesLoaded = {}
        let commanders = {}
        let halfNames = {}
        let commandersNames = []
        let companions = {}
        let companionsNames = []
        let partners = {}
        let partnersNames = []
        let ptEnCardHash = {}
        let upperCardNamesHash = {}        
        let englishNamesLoaded = {}
        for (let cards of allCardsLS){            
            let splitName = cards.e.split(';')
            let nameEn = cards.e? this.replaceIn(cards.e.trim()):""
            let namePt = cards.t?this.replaceIn(cards.t.trim()):""
            nameEn = nameEn.trim()
            namePt = namePt.trim()
            if (splitName.length>1){
                halfNames[splitName[0].trim()]=nameEn
            }
            if (nameEn===namePt && nameEn!=="" && namesLoaded.hasOwnProperty(nameEn)===false){
                names.push({"name":nameEn})
                namesLoaded[nameEn]= cards
                englishNamesLoaded[nameEn] = cards
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})                        
            }else if(nameEn!==""){                
                names.push({"name":nameEn})
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                namesLoaded[nameEn.trim()]= cards
                englishNamesLoaded[nameEn.trim()] = cards
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})                        
                if (namePt!=="" && namesLoaded.hasOwnProperty(namePt)===false){                    
                    names.push({"name":namePt})
                    namesLoaded[namePt]=cards
                    ptEnCardHash[namePt]=nameEn
                    let filteredName = this.transformNamePt(namePt)
                    if(filteredName!==namePt && namesLoaded.hasOwnProperty(filteredName)===false){
                        names.push({"name":filteredName})
                        namesLoaded[filteredName]=cards
                        ptEnCardHash[filteredName]=nameEn
                        upperCardNamesHash[filteredName.toUpperCase()]=nameEn
                    }
                    upperCardNamesHash[namePt.toUpperCase()]=nameEn                   
                    if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                        commanders[namePt.trim()]=cards
                        commandersNames.push({"name":namePt.trim()})
                        partners[nameEn.trim()]=cards
                        partnersNames.push({"name":namePt})
                    }
                    companions[nameEn.trim()]=cards
                    companionsNames.push({"name":namePt})                           
                }
            }
        }        
        return { allCards: names, halfNames:halfNames,ptEnCardHash, allCardsHash:namesLoaded, allEnglishCardsHash: englishNamesLoaded, allCommandersHash:commanders, allCommanders:commandersNames, allPartners:partnersNames,allPartnersHash:partners,allCompanions:companionsNames,allCompanionsHash:companions,loadedPrices:true, upperCardNamesHash }
    }

    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                
                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    await this.setState({updateEdition:res.data[0].edition})
                }
            }
        })
    }

    loadCards = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                await localStorage.setItem('allCards',JSON.stringify(res.data))
                let t = this.loadCardsFromLocalStorage()                
                this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
                await localStorage.setItem('lastEdition',this.state.updateEdition)
            }
        })
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){                 
                    this.setState({iscreator:res.data[0].iscreator})
                }else{
                    this.setState({iscreator:0})
                }
            }
        }).catch( (err) => {
            console.log(err)
            this.setState({ispremium:false})
        })
    }

    loadCardsOffline = async() => {               
        let t = this.loadCardsFromLocalStorage()
        await this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allEnglishCardsHash:t.allEnglishCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
    }

    componentDidMount = async () => {
        try{
            await this.loadLastEdition()      
            this.loadUser()    
            if (this.state.updateEdition!==''){
                this.loadCards()
            }else{
                this.loadCardsOffline()
            }            
            this.loadDistinctFolders()
            console.log(this.state.allEnglishCardsHash)
            this.setState({"folder":this.state.selectedFormat,"settedFolder":true})
        }catch(e){            
            this.setState({problemOnLoad:true})
        }
    }

    loadDisplayCommander = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCommanders){

            if ( card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    loadDisplayCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCards){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){                
                cartas.push(card);
            }
        }
        return cartas
    }

    loadCompanionCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allCompanions){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    loadPartnerCards = (token) => {
        if ((token.length)<2){
            return []
        }
        let cartas = []
        for (let card of this.state.allPartners){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }

    changeSelectedPrivacy = (event) => {
        this.setState({selectedPrivacy:event.target.value})
        
    }

    changeSelectedFormat = (event) => {
        
        if (event.target.value!=="Commander"&&event.target.value!=="Brawl"){
            this.setState({selectedFormat:event.target.value,folder:event.target.value,commanderAnimation:"fadeout",animationDuration:"0.5s"})
            const timer = setTimeout(() => {
                this.setState({commanderDisplay:"none"})
            }, 500);
        }else{
            if (this.state.buttonSideboardClass==="tablinks active"){
                this.setState({
                    abaDeckDisplay:"block",
                    abaSideboardDisplay:"none",
                    abaMaybeboardDisplay:"none",
                    buttonDeckClass:"tablinks active",
                    buttonSideboardClass:"tablinks",
                    buttonMaybeboardClass:"tablinks",
                    selectedFormat:event.target.value,
                    commanderAnimation:"fadein",
                    commanderDisplay:"block",
                    animationDuration:"1s"
                })
            }else{
                this.setState({selectedFormat:event.target.value,folder:event.target.value,commanderAnimation:"fadein",commanderDisplay:"block",animationDuration:"1s"})
            }

        }

    }

    commanderOnChange = (e) => {
        if (this.state.userTyped===false){
            if(e.target.value.includes('/')){
                let split = e.target.value.split('/');
                this.setState({"commanderArea":e.target.value, "deckName":split[0]+' EDH'})
            }else{
                this.setState({"commanderArea":e.target.value, "deckName":e.target.value+' EDH'})
            }            
        }else{
            this.setState({"commanderArea":e.target.value})
        }
    }

    getCommanderBox = () => {

        return (
            <div style={{"display":this.state.commanderDisplay,"animationName":this.state.commanderAnimation,"animationDuration":this.state.animationDuration}}>
                <div className="CommanderSelection" >

                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.commander:this.state.textsEn.commander}*
                    </label>

                    <ReactTextareaAutocomplete
                        className="CardInput"
                        placeholder= {"Krav, the Unredeemed"}
                        onChange={this.commanderOnChange}
                        loadingComponent={() => <span>Loading</span>}
                        renderToBody={false}
                        style={{margin:"0px"}}
                        dropdownStyle={{all:"initial"}}
                        containerStyle={{all:"initial"}}
                        trigger={{
                            "@": {
                                dataProvider: token => {
                                    let res = this.loadDisplayCommander(token)
                                    return res;
                                },
                                component: Item,
                                allowWhitespace:true,
                                output: (item, trigger) => item.name
                            }
                        }}
                    />
                </div>
                <div className="CommanderSelection">
                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.partner:this.state.textsEn.partner}
                    </label>
                    <ReactTextareaAutocomplete
                        className="CardInput"
                        placeholder= {"Regna, the Redeemer"}
                        onChange={e => this.setState({"partnerArea":e.target.value})}
                        loadingComponent={() => <span>Loading</span>}
                        style={{margin:"0px"}}
                        dropdownStyle={{all:"initial"}}
                        containerStyle={{all:"initial"}}
                        trigger={{
                            "@": {
                                dataProvider: token => {
                                    let res = this.loadPartnerCards(token)
                                    return res;
                                },
                                component: Item,
                                allowWhitespace:true,
                                output: (item, trigger) => item.name
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    getCompanionBox = () => {

        return (
            <div className="CommanderSelection">
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.companion:this.state.textsEn.companion}
                </label>
                <ReactTextareaAutocomplete
                    className="CardInput"
                    placeholder= {"Obosh, the Preypiercer"}
                    onChange={e => this.setState({"companionArea":e.target.value})}
                    loadingComponent={() => <span>Loading</span>}
                    style={{margin:"0px"}}
                    dropdownStyle={{all:"initial"}}
                    containerStyle={{all:"initial"}}
                    trigger={{
                        "@": {
                            dataProvider: token => {
                                let res = this.loadCompanionCards(token)
                                return res;
                            },
                            component: Item,
                            allowWhitespace:true,
                            output: (item, trigger) => item.name
                        }
                     }}
                />
            </div>
        )
    }

    getDeckBox = () => {
        return (

            <div  style={{display:this.state.abaDeckDisplay}}>
                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}

                placeholder="1 Sol Ring" 
                loadingComponent={() => <span>Loading</span>}
                onChange={e => this.setState({"deckArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                        return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) =>  item.name
                    }
                }}
                />
            </div>
        )
    }

    getMaybeboardBox = () => {
        return (
            <div className="CommanderSelection tabcontent" style={{display:this.state.abaMaybeboardDisplay}}>
                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}
                placeholder= '1 Murder'
                loadingComponent={() => <span>Loading</span>}
                onChange={e => this.setState({"maybeboardArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                            return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) => item.name
                    }
                }}
                />
            </div>
        )
    }

    getSideboardBox = () => {
        if (this.state.selectedFormat==="Commander"||this.state.selectedFormat==="Brawl"){
            return <div className="CommanderSelection"></div>
        }
        return (

            <div className="CommanderSelection tabcontent" style={{display:this.state.abaSideboardDisplay}}>

                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}
                
                placeholder= '1 Path to exile #Removal'
                loadingComponent={() => <span>Loading</span>}
                onChange={e => this.setState({"sideboardArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                        return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) => item.name
                    }
                }}
                />
            </div>
        )
    }

    getDescriptionBox = () => {

        return (
            <div className="CommanderSelection" style={{marginBottom:"10px"}}>
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.description:this.state.textsEn.description}
                </label>
                <textarea  placeholder={ localStorage.getItem("countryCode")==="+55"?this.state.textsPt.exampleDescription:this.state.textsEn.exampleDescription } className="deckTextArea" onChange={e => this.setState({"descriptionArea":e.target.value})}/>
            </div>
        )
    }

    changeFolderHandler = (folderName, completed) => {
        this.setState({"folder":folderName})
    }

    getFolderBox = () => {
        
        if (this.state.distinctFolders.length===0||this.state.settedFolder===false){
            return (
                <>
                    <label>
                        {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.folder:this.state.textsEn.folder}
                    </label>
                    <input type="text" placeholder="Decks" value={this.state.folder} onChange={ (e)=>{this.setState({"folder":e.target.value})} }/>                    

                </>
            )    
        }
        return (
            <>
                <label>
                    {localStorage.getItem('countryCode')==="+55"?this.state.textsPt.folder:this.state.textsEn.folder}
                </label>
                <AutoCompleteBox handler={this.changeFolderHandler} value={this.state.folder} stringList={this.state.distinctFolders}></AutoCompleteBox>
                {localStorage.getItem('countryCode')==='+55'? "Suas pastas: ":"Your folders:" } {this.state.distinctFolders.join()} 
            </>
        )
    }

    

    getDeckNameBox = () => {
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.name:this.state.textsEn.name}*
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.exampleName:this.state.textsEn.exampleName} value={this.state.deckName} onChange={ (e)=>{this.setState({"deckName":e.target.value, "userTyped":true})} }/>
            </>
        )
    }

    getYouTubeBox = () => {
        if (this.state.iscreator===0){
            return <></>
        }
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"Link do Youtube":"Youtube Link"}
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?"Cole aqui o link do Youtube desse deck":"Paste here the Youtube Link for this deck"} value={this.state.youtubeArea} onChange={ (e)=>{this.setState({"youtubeArea":e.target.value})} }/>
            </>
        )
    }

    getTwitchBox = () => {
        if (this.state.iscreator===0){
            return <></>
        }
        return (
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"Link da Twitch":"Twitch Link"}
                </label>
                <input type="text" placeholder={localStorage.getItem("countryCode")==="+55"?"Cole aqui o link da Twitch desse deck":"Paste here the Twitch link for this deck"} value={this.state.twitchArea} onChange={ (e)=>{this.setState({"twitchArea":e.target.value})} }/>
            </>
        )
    }

    getIsPrivateBox = () => {
        return(
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"É um deck privado?":"It's a private deck?"}*
                </label>
                <select value={this.state.selectedPrivacy} onChange={this.changeSelectedPrivacy}>
                    <option value="no">{localStorage.getItem('countryCode')==='+55'?'Não':'No'}</option>
                    <option value="yes">{localStorage.getItem('countryCode')==='+55'?'Sim':'Yes'}</option>
                    
                </select>
            </>
        )
    }

    getFormatSelectionBox = () => {
        return(
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.format:this.state.textsEn.format}*
                </label>
                <select value={this.state.selectedFormat} onChange={this.changeSelectedFormat}>
                    <option value="Commander">Commander</option>
                    <option value="Modern">Modern</option>
                    <option value="Pioneer">Pioneer</option>
                    <option value="Standard">Standard</option>
                    <option value="Pauper">Pauper</option>
                    <option value="Brawl">Brawl</option>
                    <option value="Legacy">Legacy</option>
                    <option value="Vintage">Vintage</option>
                    <option value="Casual">Casual</option>
                    <option value="Canadian Highlander">Canadian Highlander</option>
                    <option value="Limited">Limited</option>
                </select>
            </>
        )
    }

    getTags = (cardLine) => {
        
        let tags = []
        let tagsSplit = cardLine.split("#")
        for (let k = 1; k<tagsSplit.length;k++){
            let spacesSplit = tagsSplit[k].trim();
            tags.push('#'+spacesSplit)
        }
        
        return tags
    }


    validateCardArea = (areaText) => {
        let {allCardsHash,halfNames,upperCardNamesHash} = this.state
        let uniqueCardArea = {}
        let problematicCards = []
        let goodCards = []
        areaText = areaText.replace(/\r/g,'\n')
        areaText = areaText.replace(/\n\n/g,'\n')
        let lines = areaText.split('\n')

        for (let line of lines){
            if (line.trim()===""){
                continue;
            }
            let quantity = 1
            let tags = this.getTags(line)
            let lineCopy = line
            for(let tag of tags){
                lineCopy = lineCopy.replace(tag,'').replace(tag,'')
            }
            let cardName=lineCopy
            let spacesSplit = lineCopy.split(' ')

            if (!isNaN(spacesSplit[0])){
                cardName=""
                quantity = parseInt(spacesSplit[0],10)
                for(let k = 1;k<spacesSplit.length;k++){
                    cardName+=spacesSplit[k]+" "
                }
            }
            cardName = cardName.trim()
            
            let isFoil = false
            if (cardName.includes('*F*')){
                cardName = cardName.replace('*F*','').replace('*F*','').trim()
                isFoil = true
            }

            if (cardName in allCardsHash){
                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(cardName))){
                    uniqueCardArea[this.getCardNameOut(cardName)] = {"card":cardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[cardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(cardName)].quantity+=quantity
                }
                
            } else if (halfNames.hasOwnProperty(cardName.trim())){
                let compositeName = halfNames[cardName.trim()]

                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(compositeName))){
                    uniqueCardArea[this.getCardNameOut(compositeName)] = {"card":compositeName,"quantity":quantity, "tags":tags, "colors":allCardsHash[compositeName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(compositeName)].quantity+=quantity
                }
            } else if(cardName.toUpperCase() in upperCardNamesHash){
                let correctCardName = upperCardNamesHash[cardName.toUpperCase()]
                if (!uniqueCardArea.hasOwnProperty(this.getCardNameOut(correctCardName))){
                    uniqueCardArea[this.getCardNameOut(correctCardName)] = {"card":correctCardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[correctCardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.getCardNameOut(correctCardName)].quantity+=quantity
                }
            } else {
                problematicCards.push( {"card":cardName, "quantity":quantity, "tags":tags} )
            }
        }
        let keys = Object.keys(uniqueCardArea)
        let goodCardNames = []
        let totalOfCards = 0
        for (let card of keys){
            if (this.state.selectedFormat==='Commander'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else if(this.state.selectedFormat==='Brawl'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else{
                if (card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    goodCardNames.push(this.getCardNameOut(uniqueCardArea[card]["card"]))
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }

        }
        return {"goodCards":goodCards,"problematicCards":problematicCards,"totalOfGoodCards":totalOfCards,"goodCardNames":goodCardNames}
    }


    validateDeck = () => {
        let errorMessageEn = []
        let errorMessagePt = []
        let sideboardAreaVars = {goodCards:[],problematicCards:[]}
        let {deckName,deckArea, sideboardArea, maybeboardArea, commanderArea, partnerArea, companionArea, allCardsHash,textsPt,textsEn} = this.state
        companionArea = companionArea.trim()
        if(deckName!==undefined && deckName!==null){
            let decknameProblemChars=""
            if(deckName.includes('#')){
                decknameProblemChars+='# '
            }
            if(deckName.includes('?')){
                decknameProblemChars+='? '
            }
            if(deckName.includes('/')){
                decknameProblemChars+='/ '
            }
            if(deckName.includes('\\')){
                decknameProblemChars+='\\ '
            }
            if(deckName.includes('%')){
                decknameProblemChars+='% '
            }
            if(deckName.includes('@')){
                decknameProblemChars+='@ '
            }
            if(deckName.includes('!')){
                decknameProblemChars+='! '
            }            
            if(deckName.includes('$')){
                decknameProblemChars+='$ '
            }
            if(deckName.includes('¨')){
                decknameProblemChars+='¨ '
            }
            if(deckName.includes('&')){
                decknameProblemChars+='& '
            }
            if (decknameProblemChars!==""){
                textsEn.errorMessage="The deckname has characters that cannot be used, they are:\n"+decknameProblemChars
                textsPt.errorMessage="O nome do deck tem caracteres proibidos, eles são:\n"+decknameProblemChars
                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }
        }
        
        if (this.state.loadedPrices===false){
            textsEn.errorMessage="Please wait until the cards load... just a little bit"
            textsPt.errorMessage="Por favor espere os cards carregarem, só um pouquinho..."
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }

        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            commanderArea = commanderArea.replace(/\n/g,"")
            commanderArea = commanderArea.replace(/\r/g,"")
            commanderArea = commanderArea.trim()
            partnerArea = partnerArea.replace(/\n/g,"")
            partnerArea = partnerArea.replace(/\r/g,"")
            partnerArea = partnerArea.trim()
            if (commanderArea==="" || commanderArea in allCardsHash===false){
                errorMessageEn.push( <label key="commanderErrorEn">We could not identify your Commander <nav>{commanderArea}</nav> </label> )
                errorMessagePt.push( <label key="commanderErrorPt">Não conseguimos identificar o seu comandante <nav>{commanderArea}</nav> </label> )
            }
            if (partnerArea!==""&& partnerArea in allCardsHash===false){
                errorMessageEn.push( <label key="partnerErrorEn">We could not identify your partner Commander <nav>{partnerArea}</nav> </label> )
                errorMessagePt.push( <label key="partnerErrorPt">Não conseguimos identificar o seu comandante parceiro <nav>{partnerArea}</nav> </label> )
            }
            if ( commanderArea!=="" && partnerArea!=="" && commanderArea===partnerArea){
                errorMessageEn.push( <label key="partnerErrorEn">You cannot use the same Commander and Partner Commander <nav>{partnerArea}</nav> </label> )
                errorMessagePt.push( <label key="partnerErrorPt">Você não deve usar o mesmo comandante e comandante parceiro <nav>{partnerArea}</nav> </label> )
            }
        }else{
            sideboardAreaVars = this.validateCardArea(sideboardArea)
            if (sideboardAreaVars.problematicCards.length>0){
                errorMessageEn.push( <label key="sideboardErrorEn">Sideboard has cards that does not exists, they are:</label> )
                errorMessagePt.push(  <label key="sideboardErrorPt"> Parece que o seu sideboard tem cartas que não existem, elas são: </label> )
                for(let problematic of sideboardAreaVars.problematicCards){
                    errorMessageEn.push(<label key={"sideboardErrorEn2"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                    errorMessagePt.push(<label key={"sideboardErrorPt2"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                }
            }
        }
        if (companionArea!==""&& companionArea in allCardsHash===false){
            errorMessageEn.push( <label key="companionErrorEn">We could not identify your companion <nav>{companionArea}</nav> </label> )
            errorMessagePt.push( <label key="companionErrorPt">Não conseguimos identificar o seu companheiro <nav>{companionArea}</nav> </label> )
        }

        if (deckArea.trim()===""){
            errorMessageEn.push( <label key="deckAreaEmptyErrorEn">Your deck must have cards in it</label> )
            errorMessagePt.push(  <label key="deckAreaEmptyErrorPt"> Seu deck deve ter cartas</label> )
        }

        let deckAreaVars = this.validateCardArea(deckArea)
        if (deckAreaVars.problematicCards.length>0){
            errorMessageEn.push( <label key="labelCardsNotExistsEn">Your deck has cards that does not exists, they are:</label> )
            errorMessagePt.push(  <label key="labelCardsNotExistsPt"> Parece que o seu deck tem cartas que não existem, elas são: </label> )
            for(let problematic of deckAreaVars.problematicCards){
                errorMessageEn.push(<label key={"labelEn"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                errorMessagePt.push(<label key={"labelPt"+problematic.card}> <nav>{problematic.card}</nav> </label>)
            }
        }
        let maybeboardAreaVars = this.validateCardArea(maybeboardArea)
        if (maybeboardAreaVars.problematicCards.length>0){
            errorMessageEn.push( <label key="labelCardsNotExistsEnMaybeboard">Your maybeboard has cards that does not exists, they are:</label> )
            errorMessagePt.push(  <label key="labelCardsNotExistsPtMaybeboard"> Parece que o seu maybeboard tem cartas que não existem, elas são: </label> )
            for(let problematic of maybeboardAreaVars.problematicCards){
                errorMessageEn.push(<label key={"labelEnMaybeboard"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                errorMessagePt.push(<label key={"labelPtMaybeboard"+problematic.card}> <nav>{problematic.card}</nav> </label>)
            }
        }

        if (errorMessageEn.length>0||errorMessagePt.length>0){
            textsEn.errorMessage=errorMessageEn
            textsPt.errorMessage=errorMessagePt
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }else{       
              
            if (deckAreaVars.totalOfGoodCards>=30000){
                textsEn.errorMessage="Too many cards on the deck =("
                textsPt.errorMessage="Mas pra que um deck tão grande cara? Commander são só 100 cartas, diminui ai e tenta denovo"
                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else if(maybeboardAreaVars.totalOfGoodCards>=30000){
                textsEn.errorMessage="Too many cards on maybeboard, reduce and try again"
                textsPt.errorMessage="Mas pra que um maybeboard tão grande rapaz? diminui ai e tenta denovo"

                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else if(sideboardAreaVars.totalOfGoodCards>=30000){
                textsEn.errorMessage="Too many cards on sideboard, reduce and try again"
                textsPt.errorMessage="Mas pra que um sideboard tão grande rapaz? diminui ai e tenta denovo"

                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else{
                return {"isValid":true,"deckCards":deckAreaVars.goodCards,"maybeboardCards":maybeboardAreaVars.goodCards,"sideboardCards":sideboardAreaVars.goodCards, "mainboardCardNames":deckAreaVars.goodCardNames}
            }
            
        }
    }

    closeModalError = () => {
        this.setState({modalErrorIsOpen:false})
    }

    errorModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.modalErrorIsOpen}
                onRequestClose={this.closeModalError}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}} className="errorMessageArea">
                    <div className="displayMessage">
                        {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorHeader:this.state.textsEn.errorHeader}
                        <FontAwesomeIcon className="closeButton" icon="window-close" onClick={this.closeModalError}/>
                    </div>
                    {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorMessage:this.state.textsEn.errorMessage}

                </div>
                </Modal>
            </div>
        )
    }

    mountColorMap = (deckCards,sideboardCards) =>{
        let colorMap = {}
        for (let card of deckCards){
            let colors = card.colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=card.quantity
                }else{
                    colorMap[cor] = colorMap[cor]+card.quantity
                }
            }
        }
        for (let card of sideboardCards){
            let colors = card.colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=1
                }else{
                    colorMap[cor] = colorMap[cor]+1
                }
            }
        }
        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            let {commanderArea,allCardsHash} = this.state
            let colors = allCardsHash[commanderArea.trim()].c

            colors = colors.split("")
            for(let cor of colors){
                if (cor in colorMap===false){
                    colorMap[cor]=1
                }else{
                    colorMap[cor] = colorMap[cor]+1
                }
            }
        }
        return colorMap
    }

    connectionProblemError = () => {
        let {textsEn, textsPt} = this.state
        textsEn.errorMessage=[<label key={"connectionErrorEn"+Math.floor(Math.random() * 1000) + 1}>We got a connection problem, please refresh and try again</label>]
        textsPt.errorMessage=[<label key={"connectionErrorPt"+Math.floor(Math.random() * 1000) + 1}>Tivemos um pequeno problema de conexão, por favor atualize e tente novamente</label>]
        this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
    }


    getCardNameOut = (cardName) => {
        let {ptEnCardHash, allEnglishCardsHash} = this.state
        if (cardName in allEnglishCardsHash) return cardName.replace( /\//g,";" )    
        let out = cardName
        if (ptEnCardHash.hasOwnProperty(cardName)){
            out = ptEnCardHash[cardName]
        }
        return out.replace( /\//g,";" )
    }

    removeSpecialChars = (cardName) => {        
        return cardName.replace( /\//g,";" )
    }

    getCustomCategorie = (deckCardObject) => {
        let {defaultCatHash} = this.state
        let types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt','land']
        let tags = deckCardObject.tags.join()
        if (tags.trim()===""){
            let cardname = this.getCardNameOut(deckCardObject.card)
            if (defaultCatHash.hasOwnProperty(cardname)===false) return '#notset'
            if(defaultCatHash[cardname].defaultcat===undefined||defaultCatHash[cardname].defaultcat===null||defaultCatHash[cardname].defaultcat===""||defaultCatHash[cardname].defaultcat==="NOTSET"){
                let type = this.state.allCardsHash[deckCardObject.card].d
                let lowerCaseType=type.toLowerCase();
                for (let tp of types){
                    if (lowerCaseType.includes(tp)){
                        return '#'+tp.toLocaleLowerCase()
                    }
                }
            }else{
                return '#'+defaultCatHash[cardname].defaultcat.toLocaleLowerCase()
            }            
        }
        return tags.toLowerCase()
    }

    manageCustomCategories = (deckCards) => {
        let catHash={}//key:categorie name value:array of cardObjects
        let totalQuantity=0
        for (let deckCardObject of deckCards){
            let categorie = this.getCustomCategorie(deckCardObject);
            if(catHash.hasOwnProperty(categorie)===false){
                catHash[categorie]=[]
            }
            catHash[categorie].push(deckCardObject)
            totalQuantity+=deckCardObject.quantity;
        }
        let categoriesHash = {}//key:cardname value:categorie        
        for (let cat in catHash){
            let categorie=cat;
            if (catHash[cat].length===1&&catHash[cat][0].quantity===1&&totalQuantity>=95)
                categorie='#other';
            for(let cardObj of catHash[cat]){
                categoriesHash[cardObj.card]=categorie;
            }         
        }
        this.setState({categoriesHash:categoriesHash})
    }

    
    insertDeckList = async ( player, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck, deckName,currentDate,deckCards, sideboardCards, maybeboardCards,folder,link) => {
        let {allCardsHash, categoriesHash} = this.state;
        
        let batchGroup = []
        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            let commander=this.state.commanderArea.trim();
            let partner=this.state.partnerArea.trim();

            if (commander!==""){
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut(commander),
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":0,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":allCardsHash[commander].l,
                    "deckname":deckName
                })
            }
            if (partner!==""){
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut(partner),
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":0,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":allCardsHash[partner].l,
                    "deckname":deckName
                })
            }
        }

        let companion=this.state.companionArea.trim();
        if (companion!==""){
            batchGroup.push({
                "customcategorie":"Companion",
                "cardname": this.getCardNameOut(companion),
                "isfoil":0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":1,
                "version":"*",
                "listversion":0,
                "listtype":"companion",
                "card_qtde":1,
                "edition":allCardsHash[companion].l,
                "deckname":deckName
            })
        }
        
        for (let card of deckCards){
            if (card.card.trim()===this.getCardNameOut(this.state.companionArea.trim()) || card.card.trim()===this.getCardNameOut(this.state.commanderArea.trim())||card.card.trim()===this.getCardNameOut(this.state.partnerArea.trim())){
                continue;
            }
            
            batchGroup.push({
                "customcategorie":categoriesHash[card.card],
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"mainboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }
        for (let card of sideboardCards){
            if (card.card.trim()===this.getCardNameOut(this.state.companionArea.trim()) || card.card.trim()===this.getCardNameOut(this.state.commanderArea.trim())||card.card.trim()===this.getCardNameOut(this.state.partnerArea.trim())){
                continue;
            }
            batchGroup.push({
                "customcategorie":"#sideboard",
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"sideboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }
        for (let card of maybeboardCards){
            batchGroup.push({
                "customcategorie":"#maybeboard",
                "cardname": this.getCardNameOut(card.card),
                "isfoil":card.isFoil?1:0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"maybeboard",
                "card_qtde":card.quantity,
                "edition":allCardsHash[card.card].l,
                "deckname":deckName
            })
        }
        let data = {
            "player": player,
            "colors": colors,
            "f0rmat": format,
            "description": description,
            "upvotes": upvotes,
            "last_update": lastUpdate,
            "maincard2": this.getCardNameOut(maincard2),
            "qtviews": qtViews,
            "deckname": deckName,
            "maincard": this.getCardNameOut(maincard),
            "isprivate":privateDeck,
            "cardsArray":batchGroup,
            "name":folder,
            "presentationorder":0,
            "link":link,
            "youtubeLink":this.state.youtubeArea,
            "twitchLink":this.state.twitchArea
        }
        let axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        
        await axios({
            method: 'post',
            url: api.baseUrl + '/decklist/multiple',
            data: data, headers:axiosConfig.headers,
        }).then (async res => {
            if (res.status === 200) {
                console.log(link)
                await this.setState({'deckInsertOk':true, deckName:deckName, link:link})
            }
        }).catch(async err => {
            if (err.response){
                if (err.response.data && err.response.data.Message.includes('duplicate key')){
                    await this.insertDeckList(player, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck, this.getNextDeckName(deckName),currentDate,deckCards, sideboardCards, maybeboardCards,folder, this.getNextDeckLink(link))
                }else{
                    console.log(err)
                    console.log(err.response)
                    await this.setState({'deckInsertOk':false})
                    this.connectionProblemError()
                }
            }else{
                console.log(err)
                await this.setState({'deckInsertOk':false})
                this.connectionProblemError()
            }           
        });
    }

    loadDefaultCatHash = async (goodCardNames) => {
        let axiosConfig = api.axiosConfig;
        await axios({
            method:'post',
            url:api.freeUrl+'/card/listDefaultCat',
            data:{"cardnames":goodCardNames},
            headers:axiosConfig.headers,
        }).then( async res => {
            if (res.status === 200){
                await this.setState({defaultCatHash:res.data})
            }
        })
        .catch( async err => {
            console.log(err)
            await this.setState({'deckInsertOk':false})
            await this.connectionProblemError()
        })
    }


    insertDeckFolder = async (name, player, deckname, presentationorder) => {
        let data = {
            "name": name,
            "player": player,
            "deckname": deckname,
            "presentationorder": presentationorder
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.baseUrl+'/deckfolder/',
            data:data,
            headers:axiosConfig.headers,
        }).catch( (e) => {
            console.log(e)
        } )
    }



    insertDeck = async (player, deckname, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck) => {
        let data = {
            "player": player,
            "colors": colors,
            "f0rmat": format,
            "description": description,
            "upvotes": upvotes,
            "last_update": lastUpdate,
            "maincard2": this.getCardNameOut(maincard2),
            "qtviews": qtViews,
            "deckname": deckname,
            "maincard": this.getCardNameOut(maincard),
            "isprivate":privateDeck
        }
        
        let axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/',
            data: data, headers:axiosConfig.headers,
        }).then (async res => {
            if (res.status === 200) {
                await this.setState({'deckInsertOk':true, deckName:deckname})
            }
        }).catch(async err => {
            if (err.response){
                if (err.response.data && err.response.data.Message.includes('duplicate key')){
                    await this.insertDeck(player, this.getNextDeckName(deckname), maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck)
                }else{
                    console.log(err)
                    console.log(err.response)
                    await this.setState({'deckInsertOk':false})
                    this.connectionProblemError()
                }
            }else{
                console.log(err)
                await this.setState({'deckInsertOk':false})
                this.connectionProblemError()
            }
        });
    }

    getNextDeckName = (deckName) => {
        return deckName.trim()+'*'
    }

    getNextDeckLink = (deckName) => {
        return deckName.trim()+'1'
    }

    getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy+'-'+mm+'-'+dd
    }

    removeTrashDecks = () => {  
        let axiosConfig = api.axiosConfig;      
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/dropIrrelevantOnes',
            data: {}, headers:axiosConfig.headers
        }).catch((e)=>{
            console.log(e)
        })
    }

    generateLink = (maincard) => {        
        let split = maincard.split(' ')
        let decklink = split[0].replace(/,/g,'')
        decklink = decklink.replace(/-/g,'')
        return decklink
    }

    saveDeck = async () => {
        let results = this.validateDeck()
        if (results.isValid===false){
            return
        }        
        this.setState({clickedButton1:true})
        let goodCardNames = results.mainboardCardNames
        await this.loadDefaultCatHash(goodCardNames)        
        let colorMap = this.mountColorMap(results.deckCards,results.sideboardCards)
        let colors = Object.keys(colorMap).join('')
        let {deckName,folder,distinctFolders} = this.state
        let maincard = ""
        let maincard2 = ""
        deckName = deckName.trim()
        let splitDecknameBarra = deckName.split('/')
        deckName = splitDecknameBarra[0]
        folder = folder.trim()
        if (deckName.trim()===""){
            deckName=colors.replace(/,/g,'').replace(/ /g,'')+' '+this.state.selectedFormat
        }
        deckName = deckName.replace(/-/g,' ')        
        deckName = deckName.trim()
        if (this.state.selectedFormat==='Commander' || this.state.selectedFormat==='Brawl'){
            maincard = this.state.commanderArea.trim()
            if (this.state.partnerArea.trim()!==""){
                maincard2 = this.state.partnerArea.trim()
            }
        }else{
            maincard = results.deckCards[0].card
        }
        
        let privateDeck = false;
        if (this.state.selectedPrivacy==='yes'){
            privateDeck = true
        }        
        await this.manageCustomCategories(results.deckCards)
        let link = this.generateLink(maincard);
        await this.insertDeckList(localStorage.getItem('mdtUser'), maincard, maincard2,colors,this.state.selectedFormat,this.state.descriptionArea.trim(),0,this.getCurrentDate(),0, privateDeck,  deckName.trim(),this.getCurrentDate(), results.deckCards, results.sideboardCards, results.maybeboardCards, folder, link)
        if (this.state.deckInsertOk===false){
            this.connectionProblemError()
            this.removeTrashDecks()
            return;
        }        
        deckName = this.state.deckName;
        link = this.state.link;
        
        this.removeTrashDecks()
       
        this.props.history.push('/users/'+localStorage.getItem('mdtUser')+'/decks/'+this.changeDeckName(link))
    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')
    }
    
    render(){      
            return (
                <>
                    <meta charSet="utf-8"></meta>    
                    <GlobalNavBar/>                    
                    <div className="deckArea">
                        {this.loadingCardsText()}
                        {this.getFormatSelectionBox()}
                        <div>
                            {localStorage.getItem('countryCode')==='+55'?'Utilize o @ para buscar cartas existentes, por exemplo: ':'Use @ to fetch for existing cards, for example: '}
                            <nav style={{color:'red',display:'inline'}}>@krav</nav>
                        </div>
                        {this.getCommanderBox()}
                        {this.getCompanionBox()}                       
                        
                        <div className="tab">
                            <button className={this.state.buttonDeckClass} onClick={this.showDeckTab}>Deck</button>
                            {(this.state.selectedFormat==="Commander"||this.state.selectedFormat==="Brawl")?<></>:<button className={this.state.buttonSideboardClass} onClick={this.showSideboardTab}>Sideboard</button>}
    
                            <button className={this.state.buttonMaybeboardClass} onClick={this.showMaybeboardTab}>Maybeboard</button>
                        </div>
                        
                        <div>
                            {this.getDeckBox()}
                            {this.getSideboardBox()}
                            {this.getMaybeboardBox()}
                        </div>
                        {this.getDeckNameBox()}
                        {this.getYouTubeBox()}
                        {this.getTwitchBox()}
                        {this.getIsPrivateBox()}                        
                        {this.getFolderBox()}
                        {this.state.clickedButton1?<button disabled className="deckCreateSaveButton">
                            {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.create:this.state.textsEn.create}
                        </button>:<button className="deckCreateSaveButton" onClick={this.saveDeck}>
                            {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.create:this.state.textsEn.create}
                        </button>}
                        
                        {this.errorModal()}
                    </div>
                    {this.footerPropaganda()}
                    <BottomBar/>
                </>
            );
        
        
    }
}
export default withRouter(CreateDeckPage)
