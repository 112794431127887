import React, { Component } from "react";
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../services/api';
import './style.css'
import ErrorPage from "../ErrorPage"
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
const axios = require('axios');

class ChangePassword extends Component {

    
    state = {
        problemOnLoad:false,
        login: "",
        password: "",
        hiddenPassword: true,
        confirmPassword:"",
        hiddenConfirmPassword:true,
        token: "",
        modalBox:false,
        modalBoxMessage:"",
        modalBoxTitle:"",
        responseGet:null,
        playerOk:false,
        displayMessage:false,
        message:"",
        textsEn:{"emailSent":"Restoration email sent, check your mailbox","noMail":"Sorry, it seems that this email it's not on the system","emailChange":"Type an email to restore your password","notMatch":"Oops...the passwords do not match","authError":"Oops... it seems that this email is already in our system","show":"Show/Hide","email":"Email","password":"Password","confirmPassword":"Confirm Password","login":"Send restoration mail"},
        textsPt:{"emailSent":"Email de restauração enviado, cheque a sua caixa de entrada","noMail":"Desculpe, parece que esse email não faz parte do sistema","emailChange":"Digite um email para restaurar sua senha","notMatch":"Vish... as senhas não batem","authError":"Vish... parece que esse email já faz parte do sistema","show":"Ver/Ocultar","email":"Email","password":"Senha","confirmPassword":"Confirmar senha","login":"Enviar email de recuperação"}
    }



    buttonSend = async() => {
      await this.checkUser()
      let {playerOk} = this.state
      if (playerOk===true){
        await this.sendMail()
      }
    }

    sendMail = async () => {
      let {login} = this.state;
      const axiosConfig = api.axiosConfig;
      let data = {
          "email": login
      }
      await axios({
          method: 'post',
          url: api.freeUrl + '/player/mailRecovery',
          data: data, headers:axiosConfig.headers
      }).then(res => {
          if (res.status === 200) {
            this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.emailSent:this.state.textsEn.emailSent})
          }
      }).catch(err => {
          this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
      });
    }

    checkUser = async () => {
        const { login } = this.state;
        const axiosConfig = api.axiosConfig;
        let data = {
            "email": login
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/player/getByEmail',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length===0){
                  this.setState({playerOk:false,displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.noMail:this.state.textsEn.noMail})
                }else{
                  this.setState({playerOk:true})
                }
            }
        }).catch(err => {
            this.setState({playerOk:false,displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
        });
    }

    fieldStateSetEmail = (event) => {
        this.setState({ "login": event.target.value })
    }

    stringHide = (string) => {
        let out = "";
        let parts = string.split('')
        for (let p of parts) {
            out += '*';
        }
        return out;
    }

    divEmail = () => {
        return (
            <div>
                <input className="loginInput" type="email" onChange={this.fieldStateSetEmail} placeholder="Email"></input>
            </div>
        )
    }

    formLogin = () => {
        return (
            <>
                {this.displayMessage()}
                <h2 style={{color:'snow'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.emailChange:this.state.textsEn.emailChange}</h2>
                {this.divEmail()}
            </>
        )
    }

    displayMessage = () => {
        if (this.state.displayMessage){
            return (
                <div id="displayMessage">
                    {this.state.message}
                </div>
            )
        }
        return <div id="hiddenMessage"></div>
    }

    centralBox = () => {
        return(
            <div style={{marginTop:window.innerHeight/3.5,width:window.innerWidth/2}} id="central">
                {this.formLogin()}
                <div className="buttons">
                    <button style={{display:"inline",marginTop:'2px'}} id="sign" onClick={this.buttonSend}> <FontAwesomeIcon id="iconCrow" icon="dragon" onClick={this.buttonShowPassword}/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.login:this.state.textsEn.login  }</button>
                </div>
            </div>
        )
    }

    render() {
        try{
            return (
                <>
                  <div className="bgRecover" style={{height:window.innerHeight}}>
                    <meta charSet="utf-8"></meta>
                    <GlobalNavBar/>
                    <div >
                      {this.centralBox()}
                    </div>
                  </div>
                  <BottomBar/>
                </>
            )
        }catch(e){
          return <ErrorPage></ErrorPage>
        }     
    }
};

export default withRouter(ChangePassword);
