import { BrowserRouter as Router } from 'react-router-dom';
import React, {Component} from 'react';
import axios from 'axios';
import Routes from './Routes'

function getGeoInfo () {
    if (!localStorage.getItem('countryCode')) {
    localStorage.setItem('countryCode', '+55');
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      localStorage.setItem('countryCode', data.country_calling_code);
    }).catch((error) => {
      localStorage.setItem('countryCode', '+1');
    });
  }
};


class App extends Component{ 
  componentDidMount(){
    getGeoInfo();    
    document.title = "CommanderDecks";
  } 

  render(){    
    return (      
      <Router>
        <Routes/>
      </Router>
      
    );
  }
  
}

export default App;
