import React, {Component} from "react"
import {Redirect, withRouter} from "react-router"
import {Link} from 'react-router-dom';
import api from "../../services/api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'
import ReactLoading from 'react-loading';
import axios from "axios";
import AdSense from 'react-adsense';
import ErrorPage from "../ErrorPage"

var spSizeOfBucket = 19

class StorePage extends Component{

    state = {
        loadingDisplay:'fixed',
        query:"",
        problemOnLoad:false,
        deckFolders:[],
        decksLoaded:[],
        cardsLoaded:[],
        decksLoadedOk:false,
        ligaStoresHash:{},
        decksHash:{}, //key:deckName values:[] array of deck object
        folderHash:{}, //key:folder values:[] array of deckfolder objects || name, player, deckname and presentationorder
        folderHashButtons:{}, //key:folder values:array=0 = display inline array[1] = display block
        folderNamesTrack:{}, //key:old folder value:newFolderName
        decksBuckets:{},//key:'Commander match','deckname match',... value:a bucket = [] array of deck objects
        textsEn:{           
        },
        textsPt:{            
        }
    }

       
    loadLigaStores = async () =>{
        const axiosConfig = api.axiosConfig;
         await axios({
            method: 'get',
            url: api.freeUrl + '/ligastore',
            headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {                
                await this.setState({ligaStoresHash:res.data});
            }
        })
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

   

    componentDidMount = async () => {
        try{            
            await this.loadLigaStores();      
            console.log(this.state.ligaStoresHash);     
            this.setState({loadingDisplay:'none'})
        }catch(e){
            this.setState({problemOnLoad:true})
        }        
    }

    makeStoreFolders = () =>{
        let {ligaStoresHash} = this.state;
        let out = [];
        let i = 0;
        for(let store in ligaStoresHash){
            i+=1;
            out.push(
                <div key={i}>                    
                    <div className="card" style={{backgroundColor:'black',width:window.innerWidth/3.2,marginRight:'4px',height:'100%',borderRadius:'4px',borderColor:'black',borderStyle:"groove"}}> 
                        <a href={ligaStoresHash[store]['storelink']} target="_blank"> 
                        <img style={{width:window.innerWidth/3.4,height:window.innerWidth/3.4/3}} src={process.env.REACT_APP_BACKENDURL+'/images/stores/'+store+".png"} alt={store}/>                      
                                                                
                        </a>
                        
                        <a className="bt" style={{display:'block'}} href={ligaStoresHash[store]['storelink']} target="_blank"> 
                            {ligaStoresHash[store]['storenickname']}                                        
                        </a>
                    </div>
                </div>
            );
        }
        return out;
    }

    render(){
        try{
            return (
                <>
                    <GlobalNavBar/>
                    
                    <div className='loading' style={{paddingTop:window.innerWidth>450?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight,display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                        <div style={{marginLeft:'35%'}}>
                          <ReactLoading type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                        </div>
                        <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                    </div>
                    <div style={{minHeight:"90vh"}}>
                        <h2 style={{textAlign:'center',marginTop:'7px'}}>{localStorage.getItem("countryCode")==="+55"?'Lojas indicadas':'Indicated stores'}</h2>
                        <div className="storeGrid" style={{marginTop:'5px'}}>
                            
                            {this.makeStoreFolders()}
                        </div>
                    </div>                    
                    <BottomBar/>
                </>
            );
        }catch(e){
            return <Redirect to={"/"}></Redirect>
        }
        
    }
}
export default withRouter(StorePage)
