let baseUrl = process.env.REACT_APP_BACKENDURL+'/api'
let baseImgUrl = process.env.REACT_APP_BACKENDURL+'/stonesPics'
let authUrl = process.env.REACT_APP_BACKENDURL+'/auth'
let freeUrl = process.env.REACT_APP_BACKENDURL+'/free'
let shopbotUrl = process.env.REACT_APP_MULTISHOP+'/apiMulti'
let shopbotUrlB = process.env.REACT_APP_MULTISHOP_B+'/apiMulti'
let axiosConfig = {
    headers: {
        'Content-Type': 'application/json', 'accept':'*/*'
    }
};
let pagSeguroConfig = {
  headers: {
      'Content-Type': 'application/json;charSet=ISO-8859-1', 
      'accept':'application/json;charSet=ISO-8859-1',
      'Access-Control-Allow-Origin':'*'
  }
};
export default {baseUrl, axiosConfig,pagSeguroConfig, freeUrl,authUrl, baseImgUrl,shopbotUrl,shopbotUrlB};
