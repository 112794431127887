import React, {Component} from "react"
import {withRouter} from "react-router"
import api from "../../services/api"
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalNavBar from "../../components/globalNavBar"
import BottomBar from "../../components/bottomBar"
import './style.css'
import ReactLoading from 'react-loading';
import axios from "axios"
import AdSense from 'react-adsense';
let spSizeOfBucket = 13


class CreatorsPage extends Component{

    state = {
        problemOnLoad:false,
        loadingDisplay:'fixed',
        deckFolders:[],
        decksLoaded:[],
        listCreators:{},
        recentLoadedOk:false,
        recentCardsFolder:[],
        ispremium:false,
        loadedPromoted:[],
        loadedPromotedOnSale:[],
        promoted:[[]],
        promotedOnSale:[[]],
        topCardsFolder:[],
        promotedDecksHash:{},
        mostPopularCommandersHash:{},
        showGoogleAdd:false,
        folderHash:{}, //key:folder values:[] array of deckfolder objects || name, player, deckname and presentationorder
        folderHashButtons:{}, //key:folder values:array=0 = display inline array[1] = display block
        folderBuckets:{},//key:folder values:[[]] array of folder buckets
        folderNamesTrack:{}, //key:old folder value:newFolderName
        
        textsEn:{
            "popular":"Most popular Commanders",
            "fetchDecks":"Fetch decks",
            "seeMore":"See more",
            "promoted":"Promoted decks",
            "promotedOnSale":"Decks on sale",
            "default":"Default folder",
            "addDeck":"Create Deck",
            "addFolder":"Create folder",
            "nameNewFolder":"Name your new folder",
            "blockTitle":"Create your first deck",
            "blockMessage":"Welcome to Mydecktool decks page, create a new deck by clicking in the 'create deck' button, you can create your own custom categories for the cards in your decks, discover card recomendations, playtest, find out stores where you can get you list using less money and much more.",
            "formatsTitle":"Deck formats",
            "whyCategorizeTitle":"Why categorize the cards?",
            "whyCategorizeMessage":"It is a lot easier to understand what a deck is supposed to do if you label your cards in the right categories, it is quite common that MTG decks contains distinct cards that share functions, such as ramp, removal, bombs, card draw, wincons and so on.",
            "viewDeck":"View Deck",
            "howToSaveMoneyTitle":"How to save money?",
            "howToSaveMoneyMessage":"There is a LOT of MTG stores to purchase on the Internet, so it is quite hard to answer the question 'where do i buy the cards for my decks?', we can tell you what's the single store where your purchase have the minimun value for free, and we can even tell you what is the best combination of stores to make purchases with some restrictions for free.",
            "editDeck":"Edit deck",
            "playtest":"Playtest",
            "copyDeck":"Download deck"
        },
        textsPt:{
            "promoted":"Decks promovidos",
            "promotedOnSale":"Decks a venda",
            "popular":"Comandantes mais populares",
            "fetchDecks":"Buscar decks",
            "seeMore":"Ver mais",
            "default":"Pasta inicial",
            "addDeck":"Criar deck",
            "addFolder":"Criar pasta",
            "blockTitle":"Crie o seu primeiro deck",
            "nameNewFolder":"Coloque aqui o nome da sua pasta",
            "blockMessage":"Bem vindo a página de criação de decks, clique em criar deck para adicionar um novo baralho, categorize seus decks do seu jeito, faça playtests, receba recomendações de cards, descubra onde comprar gastando menos dinheiro e muito mais.",
            "whyCategorizeTitle":"Porquê categorizar as cartas?",
            "whyCategorizeMessage":"É muito mais fácil de entender o quê um deck deve fazer se você disser quais as categorias das cartas, é comum que baralhos de Magic tenham cartas que possuem funções similares, como ramp, remoções, bombas, compra de cartas, condições de vitória, etc.",
            "rampExample":"",
            "viewDeck":"Ver Deck",
            "howToSaveMoneyTitle":"Como economizar na compra de cartas?",
            "howToSaveMoneyMessage":"Existem muitas lojas de Magic na Internet, então é difícil de decidir onde comprar suas cartas gastando menos, nós podemos te dizer qual é a loja em que o seu deck sai mais barato de graça, podemos até te contar qual a melhor combinação de lojas para comprar com algumas restrições.",
            "editDeck":"Editar deck",
            "playtest":"Testar deck",
            "copyDeck":"Exportar deck"
        }

    }

    makeDefaultMessage = () =>{
        return (
            <div className="presentationDecksPage">
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockTitle:this.state.textsEn.blockTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.blockMessage:this.state.textsEn.blockMessage}</p>
                <div className="mtg1">
                </div>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeTitle:this.state.textsEn.whyCategorizeTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.whyCategorizeMessage:this.state.textsEn.whyCategorizeMessage}</p>
                <h2>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyTitle:this.state.textsEn.howToSaveMoneyTitle}</h2>
                <p>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.howToSaveMoneyMessage:this.state.textsEn.howToSaveMoneyMessage}</p>
            </div>
        )
    }

    getDecksFaceCards = (folderDecks) => {
        let deckFaceCards = []
        let deckFaceHash = {}
        for (let deck of folderDecks){
            if (deck.maincard && deck.maincard!=="" && (deck.maincard in deckFaceHash===false) ){
                deckFaceCards.push(deck.maincard)
                deckFaceHash[deck.maincard]=1
            }
        }
        return deckFaceCards
    }



    sortByDate = (folderDeck1, folderDeck2) => {
      if (folderDeck1.last_update<folderDeck2.last_update){
        return 1;
      }else{
        return -1;
      }
    }

    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }

    showPromotedDecks = () => {
        let {promoted} = this.state
        let foldersHtml = []
        if (promoted.length===0){
            return
        }
        for (let deck of promoted[0]){
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'promotedSale'+deck.player+Math.floor(Math.random() * 110000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                            <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                            <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                            </div>
                        </div>
                        <div style={{fontWeight:'600',textAlign:'center'}}>{deck.deckname}</div>
                    </Link>

                    <div style={{textAlign:'center'}}>
                        <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                            {this.changeDisplayName(deck.player)}
                        </Link>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }

        return foldersHtml
    }

    showRecentDecks = () => {
        let {recentCardsFolder} = this.state
        let foldersHtml = []
        for (let deck of recentCardsFolder){

            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard+'recentdecks'+deck.player+Math.random(10000)} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks/'+this.changeDeckName(deck.link)}>
                        <div className="decksPageContainer">
                        <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                                <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</div>
                                </div>
                            </div>
                        <div style={{fontWeight:'600'}}>{deck.deckname}</div>
                    </Link>
                    <div>
                      <Link className="searchPageAHover" to={"/users/"+deck.player+'/decks'}>
                        {this.changeDisplayName(deck.player)}</Link>
                    </div>
                    <div>
                      {deck.last_update}
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'auto auto',marginRight:'10px'}}>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}}  icon="eye" /> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.viewDeck:this.state.textsEn.viewDeck}</span> </Link>
                          <Link to={"/users/"+deck.player+"/decks/"+this.changeDeckName(deck.link)+'/playtest'} className="iconButton textTip" ><FontAwesomeIcon className="spin60" style={{width:'100%',height:'27px'}} icon="gamepad"/><span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.playtest:this.state.textsEn.playtest}</span></Link>
                    </div>
                </div>
            )
        }
        return foldersHtml
    }

    showTopDecks = () => {
        let {topCardsFolder} = this.state
        let foldersHtml = []
        for (let deck of topCardsFolder){
            foldersHtml.push(
                <div className="deckPFolder" key={deck.maincard} style={{marginBottom:'5px'}} >
                    <Link className="searchPageAHover" to={"/search?query="+this.changeDeckName(deck.maincard)}>
                        <div className="decksPageContainer">
                        <img alt={deck.name_en} className="deckfolderimage" key={deck.maincard}  src={process.env.REACT_APP_BACKENDURL+'/images/art_crop/'+deck.last_edition+'/en/'+deck.name_en+'.jpg'}></img>
                                <div className="decksPageMiddle">
                                    <div className="decksPageImageCenterText" style={{backgroundColor:'#333'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.fetchDecks:this.state.textsEn.fetchDecks}</div>
                                </div>
                            </div>

                    </Link>
                    <div style={{fontWeight:'600',textAlign:'center'}}>
                        {deck.maincard}
                    </div>
                    <div style={{fontWeight:'1200',textAlign:'center'}}>
                        Decks:{deck.qtde}
                    </div>
                </div>
            )
        }
        return foldersHtml
    }

    changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')
    }

    updateFolder = (folder) => () => {
        let {folderHashButtons} = this.state
        let tmp = folderHashButtons[folder][0]
        folderHashButtons[folder][0] = folderHashButtons[folder][1]
        folderHashButtons[folder][1] = tmp
        this.setState(folderHashButtons)
    }

    updateFolderNameTextField = (folder) => (e) => {
        let {folderNamesTrack} = this.state
        folderNamesTrack[folder]=e.target.value;
        this.setState({folderNamesTrack})
    }

    updateDeckFolderName = async (player,old_name,name) => {
        let data = {
            "player": player,
            "old_name":old_name,
            "name":name,
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'put',
            url:api.baseUrl+'/deckfolder/updateName',
            data:data,
            headers:axiosConfig.headers,
        })
    }

    saveFolder = (folder) => () => {
        this.updateDeckFolderName(this.props.match.params.user,folder,this.state.folderNamesTrack[folder])
        let {folderHashButtons} = this.state
        let tmp = folderHashButtons[folder][0]
        folderHashButtons[folder][0] = folderHashButtons[folder][1]
        folderHashButtons[folder][1] = tmp
        this.setState({folderHashButtons})
    }

    increaseBucket = (folder) => () =>{
      let {folderBuckets} = this.state
      if (folderBuckets[folder].length>1){
        let removed = folderBuckets[folder].splice(1,1)
        for (let deck of removed[0]){
            folderBuckets[folder][0].push(deck)
        }
      }
      this.setState({folderBuckets:folderBuckets})
    }

    makeRecentCardsFolder =() => {

        return (
            <div key='recent commanders'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?"Decks recentes":"Recent decks"}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showRecentDecks()}
                        </div>
                    </div>
            </div>
        )
    }

    makeTopCardsFolder =() => {

        return (
            <div key='top commanders'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.popular:this.state.textsEn.popular}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showTopDecks()}
                        </div>
                    </div>
            </div>
        )
    }

    increaseBucketPromotedDecks = () => {
        let {promoted} = this.state

        if (promoted.length>1){
            let removed =promoted.splice(1,1)
            for (let deck of removed[0]){
                promoted[0].push(deck)
            }
        }

        this.setState({promoted:promoted})
    }

    increaseSaleBucket = () => {
        let {promotedOnSale} = this.state

        if (promotedOnSale.length>1){
            let removed =promotedOnSale.splice(1,1)
            for (let deck of removed[0]){
                promotedOnSale[0].push(deck)
            }
        }

        this.setState({promotedOnSale:promotedOnSale})
    }

    makePromotedFolder =() => {
        let {promoted} = this.state
        if (promoted.length<=0 || promoted[0].length===0 ){
            return <></>
        }
        return (
            <div key='promoted decks'>
                <div className="decksPageFolderTitle" style={{color:'snow',backgroundColor:'#333'}}>  {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.promoted:this.state.textsEn.promoted}   </div>
                    <div className="DeckFolder">
                        <div className="deckspage-grid-container" style={{gridTemplateColumns: "repeat(auto-fit, 260px)"}}>
                            {this.showPromotedDecks()}
                        </div>
                    </div>
                    {this.state.promoted.length>1?<button className="defaultButton float" onClick={this.increaseBucketPromotedDecks} style={{width:'100%',fontSize:'18px'}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.seeMore:this.state.textsEn.seeMore}</button>:<></>}
            </div>
        )
    }

    getYoutubeButton = (creatorNickname) => {
        
    }

    mountCreatorDiv(creatorNickname){
        let {listCreators} = this.state;
        return (
            <div key={creatorNickname} className="flip-box">
                <div className="flip-box-inner">
                    <div className="flip-box-front">
                        <Link to={"/users/"+creatorNickname+"/decks"}>
                        
                        <img className="chanelImage" alt={creatorNickname} style={{width:'200px',height:'200px'}} src={process.env.REACT_APP_BACKENDURL+'/images/creators/'+creatorNickname+'.png'}></img>
                        {listCreators[creatorNickname].displayname!==undefined&&listCreators[creatorNickname].displayname!==null&&listCreators[creatorNickname].displayname!==""?<p style={{textAlign:'center',fontWeight:'800', fontSize:'25px'}}>{listCreators[creatorNickname].displayname}</p>:<p style={{textAlign:'center',fontWeight:'700'}}>{creatorNickname}</p>}
                        
                        </Link>
                    </div>                
                    <div className="flip-box-back">
                        {listCreators[creatorNickname].displayname!==undefined&&listCreators[creatorNickname].displayname!==null&&listCreators[creatorNickname].displayname!==""?<p style={{textAlign:'center',fontWeight:'800', fontSize:'25px'}}>{listCreators[creatorNickname].displayname}</p>:<p style={{textAlign:'center',fontWeight:'700'}}>{creatorNickname}</p>}
                        <Link to={"/users/"+creatorNickname+"/decks"}>
                        
                        <img className="chanelImage" alt={creatorNickname} style={{width:'30px',height:'30px'}} src={process.env.REACT_APP_BACKENDURL+'/images/creators/'+creatorNickname+'.png'}></img>
                        <div className="goToDeck"><FontAwesomeIcon style={{marginRight:'2px'}} icon={"folder"}></FontAwesomeIcon>Decks</div>                        
                        </Link>
                        {listCreators[creatorNickname].youtubepage!==undefined&&listCreators[creatorNickname].youtubepage!==null&&listCreators[creatorNickname].youtubepage!==""?<a className="youtube" target="_blank" href={listCreators[creatorNickname].youtubepage}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","youtube"]}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Youtube':'Youtube'}</a>:<></>}
                        {listCreators[creatorNickname].twitchpage!==undefined&&listCreators[creatorNickname].twitchpage!==null&&listCreators[creatorNickname].twitchpage!==""?<a className="twitch" target="_blank" href={listCreators[creatorNickname].twitchpage}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","twitch"]}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Twitch':'Twitch'}</a>:<></>}
                        {listCreators[creatorNickname].twitterpage!==undefined&&listCreators[creatorNickname].twitterpage!==null&&listCreators[creatorNickname].twitterpage!==""?<a className="twitter" target="_blank" href={listCreators[creatorNickname].twitterpage}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","twitter"]}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Twitter':'Twitter'}</a>:<></>}
                        {listCreators[creatorNickname].instagrampage!==undefined&&listCreators[creatorNickname].instagrampage!==null&&listCreators[creatorNickname].instagrampage!==""?<a className="instagram" target="_blank" href={listCreators[creatorNickname].instagrampage}> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","instagram"]}></FontAwesomeIcon>{localStorage.getItem("countryCode")=='+55'?'Instagram':'Instagram'}</a>:<></>}
                    </div>
                </div>                
            </div>
        );
    }

    makeCreators = () => {        
        let nicknames = Object.keys(this.state.listCreators);                
        if(nicknames.length===0)
            return (<></>);        
        let out=[]
        let vipCreators={"canalestacaomagic":0}
        out.push(this.mountCreatorDiv("canalestacaomagic"));
        for (let creatorNick of nicknames){
            if (vipCreators.hasOwnProperty(creatorNick)===false){
                out.push(this.mountCreatorDiv(creatorNick));
            }
        }
        return out
    }

    goToCreateDeckPage = () => {
        this.props.history.push("/createDeck")
    }

    loadPopularDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPopular'
        }).then( res =>{
            if (res.status===200){
                this.setState({topCardsFolder:res.data})
            }
        })
    }

    listCreators = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/player/listCreators'
        }).then( res =>{
            if (res.status===200){
                
                this.setState({listCreators:res.data})
            }
        })
    }

    loadRecent = async () => {
        const axiosConfig = api.axiosConfig;
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listHome',
            data:{},
            headers:axiosConfig.headers
        }).then(res => {
            if(res.status===200){
                this.setState({recentLoadedOk:true,recentCardsFolder:res.data})
            }else{
                this.setState({recentLoadedOk:false})
            }
        }).catch(err => {
            this.setState({recentLoadedOk:false})
        })
    }



    loadPromotedDecks = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer'+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.freeUrl+'/deck/listPromoted'
        }).then( res =>{
            if (res.status===200){


                let promotedBuckets = [[]]
                for (let deck of res.data){
                    if (promotedBuckets[promotedBuckets.length-1].length<spSizeOfBucket){
                        promotedBuckets[promotedBuckets.length-1].push(deck)
                    } else {
                        promotedBuckets.push([])
                    }
                }

                this.setState({promoted:promotedBuckets,loadedPromoted:res.data})
            }
        })
    }


    getMainPage = () => {
        return (
           <>
               <button style={{marginBottom:'5px'}} className="addDeckButton float" onClick={this.goToCreateDeckPage}> <FontAwesomeIcon icon="arrow-right"/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.addDeck:this.state.textsEn.addDeck} </button>
               {this.topPropaganda()}
               <div className="DeckBody">
                    <div style={{marginBottom:'15px',display:'grid',justifyContent:"center",alignContent:'center',gridTemplateColumns:"repeat(auto-fit, 230px)"}}>
                        {this.makeCreators()}
                    </div>
                    {this.makePromotedFolder()}
                </div>
           </>
        )      
    }

    loadCards = (lastEdition) => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then( res => {
            if (res.status === 200) {
                localStorage.setItem('allCards',JSON.stringify(res.data))
                localStorage.setItem('lastEdition',lastEdition)
            }
        })
    }

    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    this.loadCards(res.data[0].edition)
                }
            }
        })
    }

    

    componentDidMount = async () => {
        try{            
            this.loadLastEdition()
            this.listCreators()            
            this.loadPopularDecks()
            this.loadPromotedDecks()
            this.loadRecent()
            this.setState({loadingDisplay:'none'})
        }catch(error){
            this.setState({problemOnLoad:true})
        }

    }
    topPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"||this.state.showGoogleAdd===false){
            return <></>
        }
        let width = window.innerWidth*0.6
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.23 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    render(){
        try{
            return (
                <>
                    <meta charSet="utf-8"></meta>
                    <GlobalNavBar/>
                    <div className='loading' style={{paddingTop:window.innerWidth>950?'2%':'50%',width:window.innerWidth+'px',height:window.innerHeight+'px',display:this.state.loadingDisplay,backgroundColor:"rgba(56, 56, 56, 0.4)"}}>
                        <div style={{marginLeft:'35%'}}>
                          <ReactLoading type={'spokes'} color={'#8884d8'} width={'50%'} height={'50%'} />
                        </div>
                        <div style={{ marginLeft:'35%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:'#8884d8',color:'snow',fontSize:'30px',padding:'5px'}}> Loading...  </div>
                    </div>
                    <div style={{minHeight:"90vh"}}>
                      {this.getMainPage()}
                    </div>
                    {this.footerPropaganda()}
                    <BottomBar/>
                </>
            );
        }catch(e){
            
            return <GlobalNavBar></GlobalNavBar>
        }

    }
}
export default withRouter(CreatorsPage);
