import React, {Component} from "react"
import ErrorPage from "../ErrorPage"
import {withRouter} from "react-router"
import api from "../../services/api"
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import GlobalNavBar from "../../components/globalNavBar"
import ReactLoading from 'react-loading';
import BottomBar from "../../components/bottomBar"
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import './style.css'
import AdSense from 'react-adsense';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const axios = require('axios')

const Item = ({ entity: { name} }) => <div>{name.trim()}</div>;
function userOnLocalStorage(){
    if (localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined||localStorage.getItem('mdtToken')===null||localStorage.getItem('mdtToken')===undefined){
        return false
    }
    return true
}

const deckAreaStyle = {
    width: "100%",
    height: "400px",

    fontSize:"20px",
    resize:"none"
}
class BatchPurchasePage extends Component{


    state = {
        cardMap:{},
        ligaStoresHash:{},
        upperCardNamesHash:{},
        problemOnLoad:false,
        text: '',
        halfNames:{},
        promoteModalIsOpen:false,
        priceHash:{},
        ispremium:false,
        deckArea:"",
        loadingDisplay:'none',
        deckString:"",
        loadedPrices:false,
        commanderArea:"",
        partnerArea:"",
        displayMessage:false,
        modalErrorIsOpen:false,
        responseBestConfiguration:undefined,
        deckCards:[],
        companionArea:"",
        cards: [],
        allCards:[],
        allCardsHash:{},
        displayStrsTabs:"none",
        allCommanders:[],
        allCommandersHash:{},
        missingCards:{},
        lastValue:-1,
        bestValue:-1,
        bestStores:[],
        stores:[],
        fontSize:'16px',
        allPartners:[],
        allPartnersHash:{},
        allCompanions:[],
        loadingBarColor:'#8884d8',
        allCompanionsHash:{},
        ptEnCardHash:{},
        clickedButton1:false,
        allTags:[],
        distinctFolders:{},
        updateEdition:'',
        selectedFormat:"Commander",
        textAreaKey:"textAreaKey",
        abaDeckDisplay:"block",
        folder:"",
        textsEn:{
            "loadingMessage":"",
            "close":"Close",
            "name":"Deck name",
            "alternative":"OR... click on Discover+Video, that will display the best buy combination and an show you a nice cards video, is that ok?",
            "errorHeader":"Ooops... we have a problem",
            "errorMessage":"",
            "exampleName":"My epic monoblack deck",
            "exampleDescription":"This deck is amazing!",
            "private":"It's a private deck?",
            "purchase":"Purchase deck",
            "no":"No, it's not a private deck",
            "yes":"Yes, it is a private deck",
            "description":"Deck description",
            "format":"Format",
            "commander":"Commander",
            "folder":"Deck Folder*",
            "partner":"Partner",
            "create":"Create deck",
            "companion":"Companion"
        },
        textsPt:{
            "loadingMessage":"",
            "close":"Fechar",
            "name":"Nome do deck",
            "errorHeader":"Ooops... tivemos um probleminha",
            "errorMessage":"",
            "folder":"Pasta do deck*",
            "purchase":"Comprar deck",
            "alternative":"OU... clique em Descobrir+Video para ver em quais lojas o seu deck sai mais em conta. (E ver um vídeo super bacana no Youtube)",
            "exampleName":"Meu deck monoblack épico",
            "exampleDescription":"Esse deck é muito apelão!",
            "no":"Não é um deck privado",
            "yes":"É um deck privado",
            "private":"É um deck privado?",
            "description":"Descrição do deck",
            "format":"Formato",
            "commander":"Comandante",
            "create":"Criar deck",
            "partner":"Comandante Parceiro",
            "companion":"Companheiro"
        }
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){
                    localStorage.setItem('mdtConf',res.data[0].ispremium)
                    this.setState({ispremium:res.data[0].ispremium})
                }else{
                    this.setState({ispremium:false})
                }
            }
        }).catch( (err) => {
            console.log(err)
            this.setState({ispremium:false})
        })
    }

    loadLigaStores = () =>{
        const axiosConfig = api.axiosConfig;
         axios({
            method: 'get',
            url: api.freeUrl + '/ligastore',
            headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                this.setState({ligaStoresHash:res.data});
            }
        })
    }

    promoteModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.promoteModalIsOpen}
                onRequestClose={() => {this.setState({promoteModalIsOpen:false})}}
                className="ModalSmart"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.premiumWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    paypalBox = () => {
        let buttonWidth = window.innerWidth<=800?'100%':'40%'
        return (
            <div style={{textAlign:'center'}}>
                {userOnLocalStorage()===true?<a target="popup" className={'deckPageButton float'} style={{fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}} href={'/becomePro'}><FontAwesomeIcon icon={"laugh"} style={{marginRight:'3px'}}/>{localStorage.getItem('countryCode')==='+55'?'Virar parceiro':'Become our pal'}</a>:<></>}
                <div style={{color:'red',textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:'800'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.alternative:this.state.textsEn.alternative } </div>
                <div>
                    <a target="popup" onClick={this.showBestStores} className='deckPageButtonRed float' style={{width:buttonWidth,fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}} href="https://youtu.be/LvUy-MziXtw"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","youtube"]}></FontAwesomeIcon> {localStorage.getItem('countryCode')==='+55'?'Descobrir+Vídeo':'Discover+Video'} </a>
                    <button onClick={() =>this.setState({promoteModalIsOpen:false})} className={'deckPageButton float'} style={{width:buttonWidth,marginTop:'4px',color:'snow',backgroundColor:'salmon'}}><FontAwesomeIcon icon="meh" style={{marginRight:'2px'}}></FontAwesomeIcon>{localStorage.getItem('countryCode')==='+55'?'Ignorar e comprar numa loja só':'Ignore and buy from one store'}</button>

                </div>


            </div>
        )
    }

    premiumWindow = () => {
        return(
            <div style={{margin:'25px'}}>
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> <h3>{localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteTitle:this.state.textsEn.promoteTitle }</h3> </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:800, color:'#00008b'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteMessage2:this.state.textsEn.promoteMessage2 } </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:800, color:'#00008b'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteMessage3:this.state.textsEn.promoteMessage3 } </div>
                    {userOnLocalStorage()===true?<div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px', fontWeight:800}}> {localStorage.getItem("countryCode")==="+55"?"Se torne nosso parceiro por 1 ano para sempre economizar! É só nos apoiar com 20 R$ no paypal ou apoia.se":"Become our partner for a year to always save money! All you have to do is to help is with 20 bucks on paypal or apoia.se" } </div>:<></>}
                    <div>
                        {this.paypalBox()}
                    </div>
            </div>
        )
    }

    loadingCardsText = () => {
        if (this.state.loadedPrices===false){
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Carregando cartas...':'Loading cards...'}</div>
        }else{
            return <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Cartas carregadas, agora você pode usar @ para buscar cartas':'Loaded the cards, now you can use @ to fetch for cards'}</div>
        }
    }

    limitationsText = () => {
        return (
            <div>
                <div style={{fontWeight:'800',textAlign:'center', marginBottom:'5px', color: 'red'}}>{localStorage.getItem('countryCode')==='+55'?'Atualizamos os preços das cartas a cada 8 horas, e assumimos um valor fixo de 12 R$ de frete por loja':'We update our card prices three times a day, and we assume a fixed value of 12 R$ of shipping.'}</div>

            </div>
        )

    }

    replaceIn = (cardName) => {
        if(cardName){
            return cardName.replace(';','/')
        }
        return
    }




    transformNamePt = (nameIn) => {
        let namePt = nameIn.replace(/á/g,'a')

        namePt = namePt.replace(/à/g,'a')
        namePt = namePt.replace(/ã/g,'a')
        namePt = namePt.replace(/â/g,'a')

        namePt = namePt.replace(/Á/g,'A')
        namePt = namePt.replace(/À/g,'A')
        namePt = namePt.replace(/Ã/g,'A')
        namePt = namePt.replace(/Â/g,'A')

        namePt = namePt.replace(/é/g,'e')
        namePt = namePt.replace(/è/g,'e')
        namePt = namePt.replace(/ẽ/g,'e')
        namePt = namePt.replace(/ê/g,'e')

        namePt = namePt.replace(/É/g,'E')
        namePt = namePt.replace(/È/g,'E')
        namePt = namePt.replace(/Ẽ/g,'E')
        namePt = namePt.replace(/Ê/g,'E')


        namePt = namePt.replace(/í/g,'i')
        namePt = namePt.replace(/ì/g,'i')
        namePt = namePt.replace(/ĩ/g,'i')
        namePt = namePt.replace(/î/g,'i')


        namePt = namePt.replace(/Í/g,'I')
        namePt = namePt.replace(/Ì/g,'I')
        namePt = namePt.replace(/Ĩ/g,'I')
        namePt = namePt.replace(/Î/g,'I')

        namePt = namePt.replace(/ó/g,'o')
        namePt = namePt.replace(/ò/g,'o')
        namePt = namePt.replace(/õ/g,'o')
        namePt = namePt.replace(/ô/g,'o')

        namePt = namePt.replace(/Ó/g,'O')
        namePt = namePt.replace(/Ò/g,'O')
        namePt = namePt.replace(/Õ/g,'O')
        namePt = namePt.replace(/Ô/g,'O')

        namePt = namePt.replace(/ú/g,'u')
        namePt = namePt.replace(/ù/g,'u')
        namePt = namePt.replace(/ũ/g,'u')
        namePt = namePt.replace(/û/g,'u')
        namePt = namePt.replace(/ü/g,'u')

        namePt = namePt.replace(/Ú/g,'U')
        namePt = namePt.replace(/Ù/g,'U')
        namePt = namePt.replace(/Ũ/g,'U')
        namePt = namePt.replace(/Û/g,'U')
        namePt = namePt.replace(/Ü/g,'U')
        return namePt
    }

    loadCardsFromLocalStorage = () => {
        let allCardsLS = JSON.parse(localStorage.getItem('allCards'))
        let names = []
        let namesLoaded = {}
        let commanders = {}
        let halfNames = {}
        let commandersNames = []
        let companions = {}
        let companionsNames = []
        let partners = {}
        let partnersNames = []
        let ptEnCardHash = {}
        let upperCardNamesHash = {}

        for (let cards of allCardsLS){

            let splitName = cards.e.split(';')
            let nameEn = cards.e? this.replaceIn(cards.e.trim()):""
            let namePt = cards.t?this.replaceIn(cards.t.trim()):""
            nameEn = nameEn.trim()
            namePt = namePt.trim()



            if (splitName.length>1){
                halfNames[splitName[0].trim()]=nameEn
            }

            if (nameEn===namePt && nameEn!=="" && !(nameEn in namesLoaded)){
                names.push({"name":nameEn})
                namesLoaded[nameEn]= cards
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})

            }else if(nameEn!=="" && !(nameEn in namesLoaded)){

                names.push({"name":nameEn})
                upperCardNamesHash[nameEn.toUpperCase()]=nameEn
                namesLoaded[nameEn.trim()]= cards
                if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                    commanders[nameEn.trim()]=cards
                    commandersNames.push({"name":nameEn})
                    partners[nameEn.trim()]=cards
                    partnersNames.push({"name":nameEn})
                }
                companions[nameEn.trim()]=cards
                companionsNames.push({"name":nameEn})

                if (namePt!=="" && !(namePt in namesLoaded)){

                    names.push({"name":namePt})
                    namesLoaded[namePt]=cards
                    ptEnCardHash[namePt]=nameEn

                    let filteredName = this.transformNamePt(namePt)
                    if(filteredName!==namePt){
                        names.push({"name":filteredName})
                        namesLoaded[filteredName]=cards
                        ptEnCardHash[filteredName]=nameEn
                        upperCardNamesHash[filteredName.toUpperCase()]=nameEn
                    }
                    upperCardNamesHash[namePt.toUpperCase()]=nameEn


                    if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                        commanders[namePt.trim()]=cards
                        commandersNames.push({"name":namePt.trim()})
                        partners[nameEn.trim()]=cards
                        partnersNames.push({"name":namePt})
                    }
                    companions[nameEn.trim()]=cards
                    companionsNames.push({"name":namePt})

                }
            }
        }

        return { allCards: names, halfNames:halfNames,ptEnCardHash, allCardsHash:namesLoaded, allCommandersHash:commanders, allCommanders:commandersNames, allPartners:partnersNames,allPartnersHash:partners,allCompanions:companionsNames,allCompanionsHash:companions,loadedPrices:true, upperCardNamesHash }
    }

    loadLastEdition = async () => {
        const axiosConfig = api.axiosConfig
        await axios({
            method: 'get',
            url: api.freeUrl + '/lastEdition',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {

                if (localStorage.getItem('lastEdition')!==res.data[0].edition){
                    await this.setState({updateEdition:res.data[0].edition})
                }
            }
        })
    }

    loadCards = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/distinctNames',
            data: {}, headers:axiosConfig.headers
        }).then(async res => {
            if (res.status === 200) {
                await localStorage.setItem('allCards',JSON.stringify(res.data))
                let t = this.loadCardsFromLocalStorage()
                this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
                await localStorage.setItem('lastEdition',this.state.updateEdition)
            }
        })
    }

    loadCardsExtraInto = async (goodCards) => {
        let {allCardsHash} = this.state
        let cardArray = []
        let cardMap = {}
        for (let card of goodCards){
            let cardName = allCardsHash[card.card].e
            cardMap[cardName]=card
            cardArray.push(cardName)
        }

        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/getCardsGrouped',
            data: {cardArray:cardArray}, headers:axiosConfig.headers
        }).then(async res => {

            let deckString = ""
            if (res.status === 200) {
                for (let card of res.data){
                    cardMap[card.name_en].layout=card.layout
                   
                    let nameObject = this.registerToString(cardMap[card.name_en],card.name_en)
                    deckString+=nameObject.qtdeName
                }
            }
            await this.setState({cardMap,deckString:deckString})
        })
    }

    loadCardsOffline = async() => {

        let t = this.loadCardsFromLocalStorage()
        await this.setState({ allCards: t.allCards, halfNames:t.halfNames,ptEnCardHash:t.ptEnCardHash, allCardsHash:t.allCardsHash, allCommandersHash:t.allCommandersHash, allCommanders:t.allCommanders, allPartners:t.allPartners,allPartnersHash:t.allPartnersHash,allCompanions:t.allCompanions,allCompanionsHash:t.allCommandersHash,loadedPrices:t.loadedPrices, upperCardNamesHash:t.upperCardNamesHash })
    }



    loadDisplayCards = (token) => {
        if ((token.length)<3){
            return []
        }
        let cartas = []
        for (let card of this.state.allCards){
            if (card.name.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push(card);
            }
        }
        return cartas
    }


    getDeckBox = () => {
        return (
            <div  style={{display:this.state.abaDeckDisplay}}>
                <ReactTextareaAutocomplete
                containerStyle={deckAreaStyle}
                placeholder= '1 @Sol Ring'
                loadingComponent={() => <span>Loading</span>}
                value={this.state.deckArea}

                onChange={e => this.setState({"deckArea":e.target.value})}
                trigger={{
                    "@": {
                        dataProvider: token => {
                        let res = this.loadDisplayCards(token)
                        return res;
                        },
                        component: Item,
                        allowWhitespace:true,
                        output: (item, trigger) =>  item.name
                    }
                }}
                />
            </div>
        )
    }


    getTags = (cardLine) => {
        let tags = []
        let tagsSplit = cardLine.split("#")
        for (let k = 1; k<tagsSplit.length;k++){
            let spacesSplit = tagsSplit[k].split(' ')
            tags.push('#'+spacesSplit[0])
        }
        return tags
    }


    validateCardArea = (areaText) => {
        let {allCardsHash,halfNames,upperCardNamesHash} = this.state
        let uniqueCardArea = {}
        let problematicCards = []
        let goodCards = []
        areaText = areaText.replace('\r','\n')
        areaText = areaText.replace('\n\n','\n')
        let lines = areaText.split('\n')

        for (let line of lines){
            if (line.trim()===""){
                continue;
            }
            let quantity = 1
            let tags = this.getTags(line)
            let lineCopy = line
            for(let tag of tags){
                lineCopy = lineCopy.replace(tag,'')
            }
            let cardName=lineCopy
            let spacesSplit = lineCopy.split(' ')

            if (!isNaN(spacesSplit[0])){
                cardName=""
                quantity = parseInt(spacesSplit[0],10)
                for(let k = 1;k<spacesSplit.length;k++){
                    cardName+=spacesSplit[k]+" "
                }
            }
            cardName = cardName.trim()

            let isFoil = false
            if (cardName.includes('*F*')){
                cardName = cardName.replace('*F*','').trim()
                isFoil = true
            }

            if (cardName in allCardsHash){
                if (!uniqueCardArea.hasOwnProperty(this.filterNameOut(cardName))){
                    uniqueCardArea[this.filterNameOut(cardName)] = {"card":cardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[cardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.filterNameOut(cardName)].quantity+=quantity
                }

            } else if (halfNames.hasOwnProperty(cardName.trim())){
                let compositeName = halfNames[cardName.trim()]

                if (!uniqueCardArea.hasOwnProperty(this.filterNameOut(compositeName))){
                    uniqueCardArea[this.filterNameOut(compositeName)] = {"card":compositeName,"quantity":quantity, "tags":tags, "colors":allCardsHash[compositeName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.filterNameOut(compositeName)].quantity+=quantity
                }
            } else if(cardName.toUpperCase() in upperCardNamesHash){
                let correctCardName = upperCardNamesHash[cardName.toUpperCase()]
                if (!uniqueCardArea.hasOwnProperty(this.filterNameOut(correctCardName))){
                    uniqueCardArea[this.filterNameOut(correctCardName)] = {"card":correctCardName,"quantity":quantity, "tags":tags, "colors":allCardsHash[correctCardName].c, "isFoil":isFoil }
                }else{
                    uniqueCardArea[this.filterNameOut(correctCardName)].quantity+=quantity
                }
            } else {
                problematicCards.push( {"card":cardName, "quantity":quantity, "tags":tags} )
            }
        }
        let keys = Object.keys(uniqueCardArea)
        let totalOfCards = 0
        for (let card of keys){
            if (this.state.selectedFormat==='Commander'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else if(this.state.selectedFormat==='Brawl'){
                if (card!==this.state.commanderArea && card!==this.state.partnerArea && card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }else{
                if (card!==this.state.companionArea){
                    goodCards.push( uniqueCardArea[card] )
                    totalOfCards+=uniqueCardArea[card]["quantity"]
                }
            }

        }
        return {"goodCards":goodCards,"problematicCards":problematicCards,"totalOfGoodCards":totalOfCards}
    }


    validateDeck = () => {
        let errorMessageEn = []
        let errorMessagePt = []

        let {deckArea,textsPt,textsEn} = this.state

        if (this.state.loadedPrices===false){
            textsEn.errorMessage="Please wait until the cards load... just a little bit"
            textsPt.errorMessage="Por favor espere os cards carregarem, só um pouquinho..."
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }

        if (deckArea.trim()===""){
            textsEn.errorMessage="Please write some cards in the text area"
            textsPt.errorMessage="Por favor escreva algumas cartas na area de texto"
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }

        let deckAreaVars = this.validateCardArea(deckArea)
        if (deckAreaVars.problematicCards.length>0){
            errorMessageEn.push( <label key="labelCardsNotExistsEn">Your deck has cards that does not exists, they are:</label> )
            errorMessagePt.push(  <label key="labelCardsNotExistsPt"> Parece que o seu deck tem cartas que não existem, elas são: </label> )
            for(let problematic of deckAreaVars.problematicCards){
                errorMessageEn.push(<label key={"labelEn"+problematic.card}> <nav>{problematic.card}</nav> </label>)
                errorMessagePt.push(<label key={"labelPt"+problematic.card}> <nav>{problematic.card}</nav> </label>)
            }
        }

        if (errorMessageEn.length>0||errorMessagePt.length>0){
            textsEn.errorMessage=errorMessageEn
            textsPt.errorMessage=errorMessagePt
            this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
            return {"isValid":false}
        }else{

            if (deckAreaVars.totalOfGoodCards>=150){
                textsEn.errorMessage="Too many cards on the deck =("
                textsPt.errorMessage="Mas pra que um deck tão grande cara? Commander são só 100 cartas, diminui ai e tenta denovo"
                this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
                return {"isValid":false}
            }else{
                return {"isValid":true,"deckCards":deckAreaVars.goodCards}
            }
        }
    }

    closeModalError = () => {
        this.setState({modalErrorIsOpen:false})
    }

    errorModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.modalErrorIsOpen}
                onRequestClose={this.closeModalError}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}} className="errorMessageArea">
                    <div className="displayMessage">
                        {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorHeader:this.state.textsEn.errorHeader}
                        <FontAwesomeIcon className="closeButton" icon="window-close" onClick={this.closeModalError}/>
                    </div>
                    {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.errorMessage:this.state.textsEn.errorMessage}

                </div>
                </Modal>
            </div>
        )
    }

    connectionProblemError = () => {
        let {textsEn, textsPt} = this.state
        textsEn.errorMessage=[<label key={"connectionErrorEn"+Math.floor(Math.random() * 1000) + 1}>We got a connection problem, please refresh and try again</label>]
        textsPt.errorMessage=[<label key={"connectionErrorPt"+Math.floor(Math.random() * 1000) + 1}>Tivemos um pequeno problema de conexão, por favor atualize e tente novamente</label>]
        this.setState({modalErrorIsOpen:true, textsEn:textsEn, textsPt:textsPt})
    }


    filterNameOut = (cardName) => {
        let {ptEnCardHash} = this.state
        let out = cardName
        if (ptEnCardHash.hasOwnProperty(cardName)){
            out = ptEnCardHash[cardName]
        }
        return out.replace( "/",";" )
    }

    saveDeck = async () => {
        let results = await this.validateDeck()
        if (results.isValid===false){
            return
        }
        let {textsEn,textsPt} = this.state

        textsPt.loadingMessage = "Buscando preços nas lojas... Isto pode demorar um pouco, vamos pegar um cafézinho?"
        textsEn.loadingMessage = "Fetching card prices on stores, this may take a while, how about a coffe break?"

        this.setState({loadingDisplay:'block',clickedButton1:true,textsEn,textsPt})

        let cards = results.deckCards
        await this.loadCardsExtraInto(cards)
        await this.findBestCombination()
    }

    componentDidMount = async () => {
        try{
            this.loadUser()
            this.loadLigaStores()
            await this.loadLastEdition()
            if (this.state.updateEdition!==''){
                this.loadCards()
            }else{
                this.loadCardsOffline()
            }
            
        }catch(e){
            this.setState({problemOnLoad:true})
        }
    }

    registerToString = (decklisttuple, nameen) => {
        let name2 = nameen.replace(';','/')
        let displayNameArray = name2.split('/')
        if (decklisttuple.layout==='modal_dfc'||decklisttuple.layout==='transform'){
            return {"name":displayNameArray[0].trim(),"qtdeName":decklisttuple.quantity+" "+displayNameArray[0].trim()+"\r\n"}
        }else{
          let qtde=decklisttuple.quantity+' '
          let name=''
          for(let i = 0; i<displayNameArray.length;i++){
              if (i===displayNameArray.length-1){
                name+=displayNameArray[i].trim()
              }else{
                name+=displayNameArray[i].trim()+" / "
              }
          }
          return {"name":name,"qtdeName":qtde+name+"\r\n"}
        }
    }

    loadCardPrices = (selectedServer) => {
        const axiosConfig = api.axiosConfig
        let {cardMap} = this.state

        axios({
            method: 'post',
            url: api.freeUrl + '/read',
            data: {
                "cardHash":cardMap,"selectedServer":selectedServer
            }, headers:axiosConfig.headers
        }).then(res => {
            let {priceHash} = this.state
            if (res.status === 200 ) {
                for (let card in cardMap){
                    priceHash[cardMap[card].card]=res.data[card.replace('/',';')]
                }
            }

            this.setState(priceHash)
        }).catch(err => {
            console.log(err)
            console.log(err.response)
        })
    }

    showBestStores = () => {
        this.setState({promoteModalIsOpen:false,lastValue:this.state.bestValue,stores:this.state.bestStores})
    }


    findBestCombination = async () => {
        let {textsEn,textsPt} = this.state
        const axiosConfig = api.axiosConfig
        
        let data = {
            data:{"cardList":this.state.deckString},
            selectedServer: Math.floor(Math.random()*2)!=0?api.shopbotUrl:api.shopbotUrlB          
        }
        
        await axios({
            method: 'post',
            url: api.freeUrl+'/update',
            data: data, headers:axiosConfig.headers
        }).then( res => {
            textsPt.loadingMessage = "Buscando a melhor combinação de lojas, pode demorar de 1 a 5 minutinhos"
            textsEn.loadingMessage = "Fetching the best combination of stores to buy, this can take about 5 minutes"
            this.setState({textsEn,textsPt,loadingBarColor:'#FF851B'})
        })
        this.loadCardPrices(data.selectedServer)
        await axios({
            method: 'post',
            url:api.freeUrl+'/solver',
            data: data, headers:axiosConfig.headers, timeout:900000
        }).then(res => {
            if (res.status === 200 ) {
                textsPt.loadingMessage = "Carregando..."
                textsEn.loadingMessage = "Loading..."
                if (res.data.status===1){
                    for (let storeKey in res.data.stores){
                        if (this.state.ligaStoresHash.hasOwnProperty(storeKey)===false)
                            continue;                        
                        this.updateStoresAppearances(this.state.ligaStoresHash[storeKey].id,this.state.ligaStoresHash[storeKey].appearances+1)
                    }
                    this.setState({textsEn,textsPt, loadingBarColor:'#8884d8',lastValue:res.data.finalValue,stores:res.data.stores,displayStrsTabs:"block",loadingDisplay:'none',responseBestConfiguration:res.data})
                }else{
                    this.setState({textsEn,textsPt, loadingBarColor:'#8884d8',lastValue:-1,stores:[],loadingDisplay:'none'})
                }
            }
        }).catch(err => {
            console.log(err)
            console.log(err.response)
            this.setState({loadingDisplay:'none'})
        })
    }

    updateStoresAppearances  = (id,appearances) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "id":id,
            "appearances":appearances
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/ligastore/appearances',
            data: data, headers:axiosConfig.headers
        })
    }

    updateClicks  = (id,clicks) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "id":id,
            "clicks":clicks
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/ligastore/clicks',
            data: data, headers:axiosConfig.headers
        })
    }

    getOtherStoresOptions = (cardName,cardsInStore,originalStoresStock) => {
        let posPrice=1
        let posQuantity=2
        let lesserPrice=1000000;
        let lesserStoreName="notExist";             
        let neededQuantity = cardsInStore[cardName][posQuantity]
        let otherStoresOptions = {}        
        while (neededQuantity>=0){            
            let lesserStoreQuantity=0; 
            for (let originalStoreName of Object.keys(originalStoresStock)){
                if (originalStoresStock[originalStoreName].hasOwnProperty(cardName)===false) continue;
                if (originalStoresStock[originalStoreName][cardName][posPrice] >= lesserPrice) continue;
                if (otherStoresOptions.hasOwnProperty(originalStoreName)) continue;               
                lesserPrice=originalStoresStock[originalStoreName][cardName][posPrice];
                lesserStoreQuantity=originalStoresStock[originalStoreName][cardName][posQuantity];
                lesserStoreName=originalStoreName;                                          
            }
            if (lesserStoreQuantity===0) break
            if (originalStoresStock[lesserStoreName][cardName][posPrice]>cardsInStore[cardName][posPrice]*10) continue
            otherStoresOptions[lesserStoreName]={}
            otherStoresOptions[lesserStoreName][cardName]=originalStoresStock[lesserStoreName][cardName]
            neededQuantity-=lesserStoreQuantity;            
            if (neededQuantity<0){
                otherStoresOptions[lesserStoreName][cardName][posQuantity] += neededQuantity;
            }
        }
        return otherStoresOptions
    }

    recalculateTotalPrice = (stores) => {
        let posPrice=1
        let posQuantity=2
        let totalPrice=0;
        for(let store of Object.keys(stores)){
            for(let card of Object.keys(stores[store])){
                let cardObject = stores[store][card]
                totalPrice+=cardObject[posPrice]*cardObject[posQuantity]
            }
        }
        this.setState({lastValue:totalPrice})
    }

    removeStoreAndRecalculate = async (storeName) => {
        let {responseBestConfiguration,stores,missingCards} = this.state
        let cardsInStore = responseBestConfiguration["stores"][storeName]
        let originalStoresStock = responseBestConfiguration["oldStores"]; 
        let posQuantity=2
        delete stores[storeName]
        delete originalStoresStock[storeName];               
        for (let cardName in cardsInStore){
            if (cardName==='frete_shipping' || cardName==='frete shipping') continue;            
            let otherStoresOptions = this.getOtherStoresOptions(cardName,cardsInStore,originalStoresStock)
            
            if (Object.keys(otherStoresOptions).length===0){                
                missingCards[cardName]=['unknown',0,cardsInStore[cardName][posQuantity]]
                continue
            }
            let totalQuantity=0;
            for(let storeOptionName of Object.keys(otherStoresOptions)){                
                if(stores[storeOptionName].hasOwnProperty(cardName)===false){
                    stores[storeOptionName][cardName]=otherStoresOptions[storeOptionName][cardName];
                }else{
                    stores[storeOptionName][cardName][posQuantity]+=otherStoresOptions[storeOptionName][cardName][posQuantity]
                }
                totalQuantity+=stores[storeOptionName][cardName][posQuantity]
            }
            if (cardsInStore[cardName][posQuantity]>totalQuantity){
                missingCards[cardName]=['unknown',0,cardsInStore[cardName][posQuantity]-totalQuantity]
            }
        }        
        this.recalculateTotalPrice(stores);
        this.setState({stores,missingCards})
    }

    getStoresWindows = () => {
        let {ligaStoresHash,missingCards} = this.state;
        let keys = Object.keys(this.state.stores);
        let storesSections = [];
        for (let store of keys){
            if (ligaStoresHash.hasOwnProperty(store.trim())){
                storesSections.push(
                    <div style={{margin:"1%"}} key={store+Math.floor(5000)}>
                        <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}> <a onClick={() => this.updateClicks(ligaStoresHash[store.trim()].id,ligaStoresHash[store.trim()].clicks+1)} className="ligaStoreLink" href={ligaStoresHash[store.trim()].storelink} target = "_blank" 
rel = "noopener noreferrer"> {ligaStoresHash[store.trim()].storename} {localStorage.getItem("countryCode")==="+55"?"(Clique para visitar a loja)":"(Go to site)"}</a> {keys.length>1?<div style={{display:"inline",cursor:'pointer'}}><FontAwesomeIcon icon="window-close" onClick={() =>{this.removeStoreAndRecalculate(store)}}></FontAwesomeIcon></div>:<></>} </div>
                        <div className="grid-container-deckPage deckPage" style={{fontSize:this.state.fontSize}}>
                            {this.getAllCardsFromStore(store)}
                        </div>
                    </div>
                )
            }else{
                storesSections.push(
                    <div style={{margin:"1%"}} key={store+Math.floor(5000)}>
                        <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}> {store} {keys.length>1?<div style={{display:"inline",cursor:'pointer'}}><FontAwesomeIcon icon="window-close" onClick={() =>{this.removeStoreAndRecalculate(store)}}></FontAwesomeIcon></div>:<></>}  </div>
                        <div className="grid-container-deckPage deckPage" style={{fontSize:this.state.fontSize}}>
                            {this.getAllCardsFromStore(store)}
                        </div>
                    </div>
                )
            }            
        }
        if (Object.keys(missingCards).length>0){
            let missingCardsLabel = localStorage.getItem('countryCode')==='+55'?'Cartas faltantes:':'Missing Cards';            
            storesSections.push(
                <div style={{margin:"1%"}} key={'missingCardsDiv'+Math.floor(5000)}>
                    <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"orange",color:"black",padding:'3px',marginBottom:"1%"}}> {missingCardsLabel}  </div>
                    <div className="grid-container-deckPage deckPage" style={{fontSize:this.state.fontSize}}>
                        {this.getAllCardsFromMissingCards(missingCards)}
                    </div>
                </div>
            )  
        }
        return storesSections
    }

    getPresentationName = (cardName) => {
        let split = cardName.split(';')
        return split[0]
    }

    getAllCardsFromStore = (store) => {
        let {stores} = this.state
        let keys = Object.keys(stores[store])
        let out = []
        for (let card of keys){
            if(card==='frete shipping'){
                out.push(<div key={'frete'+Math.random(4000)}>{localStorage.getItem('countryCode')==='+55'?'Frete - 12 R$':'Shipping - 12 $'}

                </div>)
                continue
            }
            let cardArray = stores[store][card]
            out.push( <div key={card}> <nav >  <nav > {cardArray[2]} {this.getPresentationName(card)} - R$ {cardArray[1]}  </nav> </nav>    </div>)
        }
        return out
    }

    getAllCardsFromMissingCards = (missingCards) => {        
        let keys = Object.keys(missingCards)
        let out = []
        for (let card of keys){            
            let cardArray = missingCards[card]
            out.push( <div className="deckPageStoreSectionText" style={{color:'black'}} key={card}> <nav>  <nav > {cardArray[2]} {this.getPresentationName(card)}  </nav> </nav>    </div>)
        }
        return out
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    render(){
        if (this.state.problemOnLoad===true){
            return <ErrorPage></ErrorPage>
        }
        
            return (
                <>
                    <GlobalNavBar/>
                    <div style={{minHeight:'90vh'}}>
                        <div className="deckArea">
                            {this.limitationsText()}
                            {this.loadingCardsText()}

                            <div style={{display:this.state.loadingDisplay}}>
                                <div style={{marginLeft:'48%'}}>
                                    <ReactLoading type={'spokes'} color={this.state.loadingBarColor} width={'15%'} height={'15%'} />
                                </div>
                                <div style={{ marginLeft:'35%', marginBottom:'1%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:this.state.loadingBarColor,color:'snow',fontSize:'20px',padding:'5px'}}> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.loadingMessage:this.state.textsEn.loadingMessage}  </div>
                            </div>

                            <label>
                                {localStorage.getItem('countryCode')==="+55"?"Lista de cartas a comprar":"Card list to purchase"}
                            </label>
                            <div style={{marginBottom:'3px'}}>
                                {this.getDeckBox()}
                            </div>

                            {this.state.clickedButton1?<button style={{color:'snow',textDecoration:'none',backgroundColor:"rgba(28,87,45,0.9)",marginBottom:'2px',display:'block',width:'100%'}} className="batchButton" disabled >{localStorage.getItem('countryCode')==="+55"?"Atualize a página para comprar novamente":"Refresh the page to purchase again"}</button>:<button style={{color:'snow',textDecoration:'none',display:'block', marginBottom:'2px', width:'100%'}}  className="batchButton" onClick={this.saveDeck} >{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.purchase:this.state.textsEn.purchase}</button>}

                            {this.promoteModal()}

                            {this.errorModal()}
                        </div>
                        {this.footerPropaganda()}
                        <div style={{ display:this.state.displayStrsTabs}}>
                                <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}>   {localStorage.getItem('countryCode')==='+55'?"Valor total (frete incluso)":'Total value (shipping included)'}:{this.state.lastValue.toFixed(2)} </div>
                                {this.getStoresWindows()}
                        </div>
                    </div>
                    <BottomBar></BottomBar>
                </>
            );


    }
}
export default withRouter(BatchPurchasePage)
