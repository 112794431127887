import React, { Component } from "react";
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import LoginBox from "../../components/LoginBox"
import RegisterBox from "../../components/RegisterBox"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoImg from '../../assets/commanderDecks.png'
import AutoCompleteBox from "../../components/autoCompleteBox"

import './style.css'

class GlobalNavBar extends Component {

    state = {
        loadedCommanders:false,
        navClass:"topnav",
        operation:"spin",
        donationLinkPt:"https://apoia.se/commanderdecks",
        donationLinkEn:"https://apoia.se/commanderdecks",
        navTextsEn:{
            "onsale":"On sale",
            "sign":"Sign in",
            "register":"Register",
            "login":"Login",
            "decks":"My decks",
            "home":"Home",
            "create":"Create deck",
            "wizards":"Wizards precons",
            "logout":"Logout"
        },
        navTextsPt:{
            "onsale":"A venda",
            "login":"Entrar",
            "decks":"Meus decks",
            "home":"Inicio",
            "sign":"Entrar",
            "register":"Registrar",
            "create":"Criar deck",
            "wizards":"Wizards precons",
            "logout":"Sair"

        }
    }

    closeLoginModal = () => {
      this.setState({modalLoginIsOpen:false})
  }

  openLoginModal = () => {
      this.setState({modalLoginIsOpen:true})
  }

  closeRegisterModal = () => {
      this.setState({modalRegisterIsOpen:false})
  }

  openRegisterModal = () => {
      this.setState({modalRegisterIsOpen:true})
  }

  getFirstPart = (cardName) => {
    if(cardName.includes(';')){
        let split = cardName.split(';')
        return split[0]
    }
    return cardName
}

  loadCardsFromLocalStorage = () => {
    if(typeof localStorage["allCards"] === "undefined"){
      this.setState({commanders:[],loadedCommanders:true})
      return
    }
    let allCardsLS = JSON.parse(localStorage.getItem('allCards'))
    let namesLoaded = {}
    let commanders = []

    for (let cards of allCardsLS){

        let nameEn = cards.e? this.getFirstPart(cards.e.trim()):""
        let namePt = cards.t?this.getFirstPart(cards.t.trim()):""

        if (nameEn===namePt && nameEn!=="" && !(nameEn in namesLoaded)){
            namesLoaded[nameEn]= cards
            if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
              commanders.push(nameEn.trim())
            }
        }else if(nameEn!=="" && !(nameEn in namesLoaded)){
            namesLoaded[nameEn.trim()]= cards
            if (cards.d.toUpperCase().includes('LEGEND') || cards.d.toUpperCase().includes('PLANESWALKER')){
                commanders.push(nameEn.trim())
            }
        }
    }

    this.setState({commanders,loadedCommanders:true})

}

  loginModal = () => {
      return (
          <div>
              <Modal
              isOpen={this.state.modalLoginIsOpen}
              onRequestClose={this.closeLoginModal}
              className="Modal"
              overlayClassName="Overlay"
              >
              <div style={{"width":"100%"}}>
                  <LoginBox/>
              </div>
              </Modal>
          </div>
      )
  }

  registerModal = () => {
      return (
          <div>
              <Modal
              isOpen={this.state.modalRegisterIsOpen}
              onRequestClose={this.closeRegisterModal}
              className="Modal"
              overlayClassName="Overlay"
              >
              <div style={{"width":"100%"}}>
                  <RegisterBox test="aaa"/>
              </div>
              </Modal>
          </div>
      )
  }

    changeClassName = () => {
        if (this.state.navClass==="topnav"){
            this.setState({navClass:"topnav responsive"})
        }else{
            this.setState({navClass:"topnav"})
        }
    }

    searchForCard = (event) => {
      let {inputText} = this.state
      this.props.history.push('/search?query='+this.changePtCardForEnglishCard(inputText))
      console.log(inputText);
      event.preventDefault();
      document.location.reload();
    }

    clearLocalStorage = async () => {
      localStorage.removeItem('mdtUser')
      localStorage.removeItem('mdtToken')
      localStorage.removeItem('mdtConf')
      await this.props.history.push('/')
      document.location.reload()
    }

    loginAndRegisterLinks = () =>{
      if (window.innerWidth>=650){
        return(
          <div>
          <nav className="float" style={{float:"right"}} onClick={this.openLoginModal}> <FontAwesomeIcon style={{marginRight:"3px"}} icon="user" size="lg"/> {localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.sign:this.state.navTextsEn.sign  }</nav>
          <nav className="float" style={{float:"right"}} onClick={this.openRegisterModal} > <FontAwesomeIcon style={{marginRight:"3px"}} icon="jedi" size="lg"/> {localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.register:this.state.navTextsEn.register  }</nav>
          </div>
        )
      }else{
        return (<div>
          <nav  onClick={this.openLoginModal}> <FontAwesomeIcon style={{marginRight:"3px"}} icon="user" size="lg"/> {localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.sign:this.state.navTextsEn.sign  }</nav>
          <nav onClick={this.openRegisterModal} > <FontAwesomeIcon style={{marginRight:"3px"}} icon="jedi" size="lg"/> {localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.register:this.state.navTextsEn.register  }</nav>

        </div>)
      }
    }

    getDonarionLink = (loggedStr) =>{
      let direction='right'
      if (window.innerWidth<=500){
        direction='left'
      }
      if (loggedStr==='logged'){
        direction='right'
      }

      
    }

    changeDisplayName = (player) => {
      if(player.includes('fb') === false || player.includes('-') === false){
          return player
      }
      let playerSplit = player.split('-')
      if(playerSplit[playerSplit.length-1].includes('fb')===false){
          return player
      }
      let displayName = ""
      for(let i = 0;i<playerSplit.length-1;i++){
          if (i!==playerSplit.length-2){
              displayName+=playerSplit[i]+'-'
          }else{
              displayName+=playerSplit[i]
          }
      }
      return displayName
  }

    getBecomeProLink = () =>{      
        return <a href="https://apoia.se/commanderdecks" target="_blank" className="float" style={{float:"right"}}><FontAwesomeIcon style={{marginRight:"3px"}} icon={'heart'} size="lg"/>{localStorage.getItem("countryCode")==="+55"?"Ajudar o site":"Help the site"}</a>     
    }

    onChangeInputText = (e) => {
        if (e.key === 'Enter'){
          return;
        }
        this.setState({inputText:e.target.value});
    }

    changePtCardForEnglishCard = (cardname) => {
      let allCards = localStorage.getItem('allCards');
      for (let card of allCards){
        if (card["t"]===cardname){
          return card.e
        }
      }
      return cardname
    }

    selectCommanderHandler = async (value,completed) => {
      await this.setState({inputText:value})
      if (completed===true){
        this.props.history.push('/search?query='+this.changePtCardForEnglishCard(value))
        document.location.reload();
      }


    }

    getCorrectNavBar = () => {
      
      if (localStorage.getItem('mdtUser')!==null && localStorage.getItem('mdtUser')!==undefined && localStorage.getItem('mdtUser')!==""){
        return (
          <div>
            <div className={this.state.navClass} id="myTopnav">
              <Link to="/home" style={{padding:"6px 14px"}}><img alt="CommanderDecks" src={logoImg} style={{width:"100px",height:"33px"}}></img></Link>

              <Link to={"/"} className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon="crown" size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.decks:this.state.navTextsEn.decks}</Link>
              <Link to="/createDeck" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon="plus" size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.create:this.state.navTextsEn.create}</Link>

              <Link target="_blank" to="/users/wizards/decks" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={['fab', 'wizards-of-the-coast']} size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.wizards:this.state.navTextsEn.wizards}</Link>
              <Link target="_blank" to="/creators" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={"camera-retro"} size="lg"/>{localStorage.getItem("countryCode")==="+55"?"Criadores de Conteúdo":"Content Creators"}</Link>


              {localStorage.getItem('countryCode')==='+55'?this.state.donationLinkPt:this.state.donationLinkEn}


              <nav className="icon" onClick={this.changeClassName}>
              <FontAwesomeIcon id="icon" icon="bars"/>
              </nav>
            </div>
            <hr></hr>
            <div className={this.state.navClass} id="myTopnav">
              <Link to={"/"} className="float"> {localStorage.getItem('mdtConf')==='true'?<FontAwesomeIcon style={{marginRight:"3px",color:'#FFDF00'}} icon={'star'} size="lg"/>:<></>}  {localStorage.getItem("countryCode")==="+55"?"Olá ":"Hello "} {this.changeDisplayName(localStorage.getItem('mdtUser'))}</Link>
              <Link to="/" onClick={this.clearLocalStorage} className="float" style={{float:"right"}}><FontAwesomeIcon style={{marginRight:"3px"}} icon={'user'} size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.logout:this.state.navTextsEn.logout}</Link>
              {this.getBecomeProLink()}
              {this.getDonarionLink('logged')}
              <div hidden><Link to="/purchase" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={["fab","shopify"]} size="lg"/>{localStorage.getItem("countryCode")==="+55"?'Comprar cartas':'Purchase cards'}</Link></div>
              <div className="part" style={{float:"right"}}>
                <form onSubmit={this.searchForCard}>
                  {this.state.loadedCommanders?<AutoCompleteBox style={{padding:'4px',borderRadius:'4px'}} placeholder={localStorage.getItem('countryCode')==='+55'?'Pesquisar comandante ou jogador':'Fetch for commander or player'} handler={this.selectCommanderHandler} stringList={this.state.commanders}></AutoCompleteBox>:<input style={{padding:'4px',borderRadius:'4px'}} type="text" value={this.state.inputText} onChange={this.onChangeInputText} placeholder={localStorage.getItem('countryCode')==='+55'?'Pesquisar comandante ou jogador':'Fetch for commander or player'}/>}

                </form>
              </div>


            </div>
          </div>
        )
      }else{
        if (window.innerWidth>=500){
          return (
            <div>

              <div className={this.state.navClass} id="myTopnav">

                <Link to="/" style={{padding:"6px 14px"}}><img alt="CommanderDecks" src={logoImg} style={{width:"100px",height:"33px"}}></img></Link>

                <nav className="float" onClick={this.openRegisterModal}><FontAwesomeIcon style={{marginRight:"3px"}} icon="plus" size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.create:this.state.navTextsEn.create}</nav>

                <Link target="_blank" to="/users/wizards/decks" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={['fab', 'wizards-of-the-coast']} size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.wizards:this.state.navTextsEn.wizards}</Link>
                <Link target="_blank" to="/creators" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={"camera-retro"} size="lg"/>{localStorage.getItem("countryCode")==="+55"?"Criadores de Conteúdo":"Content Creators"}</Link>


                <nav className="icon" onClick={this.changeClassName}>
                  <FontAwesomeIcon id="icon" icon="bars"/>
                </nav>
              </div>
              <hr></hr>
              <div className={this.state.navClass} id="myTopnav">
                <div className="part" style={{float:window.innerWidth>=500?"right":"left"}}>

                  <form onSubmit={this.searchForCard}>
                  <FontAwesomeIcon style={{marginRight:"3px"}} icon="search" size="lg"/>
                  <input style={{padding:'4px',borderRadius:'4px',display:'inline-block'}} type="text" value={this.state.inputText} onChange={(e) => this.setState({inputText:e.target.value})} placeholder={localStorage.getItem('countryCode')==='+55'?'Pesquisar comandante ou jogador':'Fetch for commander or player'}/>
                  </form>

                </div>

                {this.loginAndRegisterLinks()}
                {this.getDonarionLink('notLogged')}
                <div hidden><Link to="/purchase" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={["fab","shopify"]} size="lg"/>{localStorage.getItem("countryCode")==="+55"?'Comprar cartas':'Purchase cards'}</Link></div>
              </div>
            </div>

            )
        }else{
          return (
            <div>

              <div className={this.state.navClass} id="myTopnav">

                <Link to="/" style={{padding:"6px 14px"}}><img alt="CommanderDecks" src={logoImg} style={{width:"100px",height:"33px"}}></img></Link>

                <nav className="float" onClick={this.openRegisterModal}><FontAwesomeIcon style={{marginRight:"3px"}} icon="plus" size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.create:this.state.navTextsEn.create}</nav>

                <Link target="_blank" to="/users/wizards/decks" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={['fab', 'wizards-of-the-coast']} size="lg"/>{localStorage.getItem("countryCode")==="+55"?this.state.navTextsPt.wizards:this.state.navTextsEn.wizards}</Link>
                <Link target="_blank" to="/creators" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={"camera-retro"} size="lg"/>{localStorage.getItem("countryCode")==="+55"?"Criadores de Conteúdo":"Content Creators"}</Link>

                <nav className="icon" onClick={this.changeClassName}>
                <FontAwesomeIcon id="icon" icon="bars"/>
                </nav>
              </div>
              <hr></hr>
              <div className={this.state.navClass} id="myTopnav">
                <div className="part" style={{float:window.innerWidth>=500?"right":"left"}}>

                  <form onSubmit={this.searchForCard}>
                  <FontAwesomeIcon style={{marginRight:"3px"}} icon="search" size="lg"/>
                  <input style={{padding:'4px',borderRadius:'4px',display:'inline-block'}} type="text" value={this.state.inputText} onChange={(e) => this.setState({inputText:e.target.value})} placeholder={localStorage.getItem('countryCode')==='+55'?'Pesquisar comandante ou jogador':'Fetch for commander or player'}/>
                  </form>

                </div>

              </div>

              <div className={this.state.navClass} id="myTopNav">
                {this.loginAndRegisterLinks()}
                {this.getDonarionLink('notLogged')}
                <div hidden><Link to="/purchase" className="float"><FontAwesomeIcon style={{marginRight:"3px"}} icon={["fab","shopify"]} size="lg"/>{localStorage.getItem("countryCode")==="+55"?'Comprar cartas':'Purchase cards'}</Link></div>
              </div>

            </div>
            )
        }
      }
    }

    componentDidMount(){
      this.loadCardsFromLocalStorage()
    }

    

    render(){
        return (
          <div>
            {this.loginModal()}
            {this.registerModal()}
            {this.getCorrectNavBar()}
            
          </div>
        )
    }
}
export default withRouter(GlobalNavBar)
