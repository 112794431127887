import React, {Component} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router';
import "./style.css"

class StarComponent extends Component{
    state = {}    

    render() {
        const {decklistobject} = this.props;
        if (decklistobject.ispremium===true){
            return (
                <div className="textTip" style={{display:'inline'}}><a href="https://apoia.se/commanderdecks"><FontAwesomeIcon className="starProIcon spin60" icon={'star'}/> <span className="textTiptext">{localStorage.getItem('countryCode')==='+55'?'PRO User':'Usuario PRO'}</span> </a></div> 
            )
        }
        return null;
    }
}
export default withRouter(StarComponent);