import BottomBar from "../../components/bottomBar"
import React, {Component} from 'react'
import {withRouter} from "react-router"
import api from "../../services/api"
import './stylePlaytestPage.css'

import { Redirect } from "react-router-dom";
import GlobalNavBar from "../../components/globalNavBar"
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import AdSense from 'react-adsense';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ItemTextArea = ({ entity: { name} }) => <div style={{cursor:'pointer'}}>{name.trim()}</div>;
const axios = require('axios')

class PlaytestPage extends Component{

    constructor(props){
        super(props)

        this.state = {
            problemOnLoad:false,
            imageWidth:-10+window.innerWidth/11,
            imageHeight:(-10+window.innerWidth/11)*1.34482,
            currentTurn:1,
            deckString:"",
            showGoogleAdd:false,
            cardArea:"",
            selectedCmc:-1,
            shuffledDeck:[],
            deckname:null,
            deckHeader:[],
            deckHeaderOk:false,
            deckList:[],
            deckListOk:false,
            loadedCardObjects:[],
            loadedCardObjectsOk:false,
            deckCardsHash:{},
            stores:[],
            cardHash:{}, //key:cardname value:cardObject
            priceHash:{}, //key:cardname value:price
            typeCardHash:{}, //key:tipo[creature, artifact, enchantment, instant, sorcery, planeswalker] value:cardObject[]
            customTypeHash:{}, //key:tipo value:cardObject[]
            versionsCustomTypeHash:{}, //key:version value:customTypeHash da versao especifica
            commanders:[],//cardObject[]
            companions:[],
            companionsOk:false,
            allVersions:[],
            selectedVersion:"",
            greaterVersion:"",
            cardsInstances:[],
            handCards:[],
            creaturesCards:[],
            creaturesTapped:[],
            permanentsCards:[],
            graveCards:[],
            landsCards:[],
            landsTapped:[],
            selectedImage:"",
            selectedImageObject:null,
            permanentsTapped:[],
            commandersPlay:[],
            fontSize:'16px',
            bigTexts:{
              "toGrave":"Grave",
              "toTop":"Top"
            },
            textsEn:{
                "loadingMessage":"Loading...",
            },
            textsPt:{
                "loadingMessage":"Carregando...",
            },

        }
    }

    changeSelectedImage = (card) => ()  => {
      if(card===null || card===undefined){
          return
      }
      
      if (card && this.state.selectedImage!==process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'){
          
          this.setState({selectedImage:process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg',selectedImageObject:card})
      }
  }

    changeClassName = () => {
        if (this.state.navClass==="topnav"){
            this.setState({navClass:"topnav responsive"})
        }else{
            this.setState({navClass:"topnav"})
        }
    }

    searchForCard = (event) => {
      this.props.history.push('/search?query='+this.state.inputText)
      event.preventDefault();
    }


    loadDeckHeader = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player":this.props.match.params.user,
            "link":this.props.match.params.deckName
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/deck/getByLink',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {

                this.setState({deckHeader:res.data,deckHeaderOk:true})
            }
        }).catch(err => {
            this.setState({deckHeaderOk:false})
        })
    }

    loadCommanders = async() => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');

        let data = {
            "player":this.props.match.params.user,
            "link":this.props.match.params.deckName,
            "listtype":"commander"
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/getDeckListFromType',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                let versions = {}
                let greaterVersion = -1
                for (let card of res.data){
                    if(!versions.hasOwnProperty(card.listversion)){
                        versions[card.listversion]={}
                    }
                    if (card.listversion>greaterVersion){
                        greaterVersion=card.listversion
                    }
                    versions[card.listversion][card.cardname] = card
                }
                let {deckList, namesEn, deckCardsHash} = this.state
                for(let card in versions[greaterVersion]){
                    if (!deckCardsHash.hasOwnProperty(card)){
                        deckCardsHash[card]=versions[greaterVersion][card]
                        deckList.push(versions[greaterVersion][card])
                        namesEn.push(versions[greaterVersion][card]['cardname'])
                    }
                }
                this.setState({deckList,namesEn,deckCardsHash})
            }
        }).catch(err => {

            console.log(err.response)
            this.setState({deckListOk:false,namesEn:[]})
        })
    }

    loadCompanions = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');

        let data = {
            "player":this.props.match.params.user,
            "link":this.props.match.params.deckName,
            "listtype":"companion"
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/getDeckListFromType',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                let cards = []
                let {namesEn}=this.state
                let versions = {}
                let greaterVersion = -1
                for (let card of res.data){
                    if(!versions.hasOwnProperty(card.listversion)){
                        versions[card.listversion]={}
                    }
                    if (card.listversion>greaterVersion){
                        greaterVersion=card.listversion
                    }
                    versions[card.listversion][card.cardname] = card

                }

                for(let card in versions[greaterVersion]){
                    if (!this.isInArray(card,namesEn)){
                        namesEn.push(card.trim())
                    }
                    cards.push(versions[greaterVersion][card])
                }
                this.setState({companions:cards,companionsOk:true,namesEn:namesEn})

            }
        }).catch(err => {

            console.log(err.response)
            this.setState({deckListOk:false,namesEn:[]})
        })
    }

    loadDeckList = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');

        let data = {
            "player":this.props.match.params.user,
            "link":this.props.match.params.deckName,
            "listtype":"mainboard"
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/getDeckListFromType',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {

                let cards = []
                let namesEn=[]
                let cardsInstances = []
                let versions = {}
                let greaterVersion = -1
                for (let i = 0;i<res.data.length;i++){
                    let card = res.data[i]
                    if(!versions.hasOwnProperty(card.listversion)){
                        versions[card.listversion]={}
                    }
                    if (card.listversion>greaterVersion){
                        greaterVersion=card.listversion
                    }
                    versions[card.listversion][card.cardname] = card
                }

                for(let card in versions[greaterVersion]){
                    namesEn.push(versions[greaterVersion][card]['cardname'])
                    cards.push(versions[greaterVersion][card])
                    for(let i = 0; i<versions[greaterVersion][card]['card_qtde'];i++){
                      cardsInstances.push(card)
                    }
                }
                this.setState({selectedVersion:greaterVersion-1,deckList:cards,cardsInstances:cardsInstances, shuffledDeck:this.shuffleArray(cardsInstances) ,deckListOk:true,namesEn:namesEn,deckCardsHash:versions[greaterVersion],allVersions:versions,greaterVersion:greaterVersion})

            }
        }).catch(err => {

            console.log(err.response)
            this.setState({deckListOk:false,namesEn:[]})
        })
    }

    isInArray = (pesq,stringArray) => {
        for (let value of stringArray){
            if (value.trim()===pesq.trim()){
                return true;
            }
        }
        return false;
    }



    loadCardObjects = async () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let {namesEn} = this.state
        let data = {
            "cardArray":namesEn,
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/card/getCardsGrouped',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                let cardHash = {}
                let priceHash = {}
                let types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt','land']
                let typeCardHash = {'creature':[],'artifact':[],'enchantment':[],'land':[],'planeswalker':[],'instant':[],'sorcery':[],'interrupt':[]}
                for (let card of res.data){
                    cardHash[card.name_en]=card
                    priceHash[card.name_en]=0
                    if (!this.state.deckCardsHash.hasOwnProperty(  card.name_en) ){
                        continue
                    }
                    let lowerCaseType=card['type_en'].toLowerCase();

                    for (let tp of types){
                        if (lowerCaseType.includes(tp)){
                            typeCardHash[tp].push(card)
                            break;
                        }
                    }
                }
                this.setState({typeCardHash:typeCardHash,loadedCardObjects:res.data,loadedCardObjectsOk:true, cardHash:cardHash,priceHash})

            }
        }).catch(err => {
            this.setState({loadedCardObjectsOk:false})
        })
    }

    async loadCustomCategories(){
        let {deckList,customTypeHash,cardHash,commanders,commandersPlay,companion} = this.state
        for (let card of deckList){
            if (card.iscommander===1){
                commanders.push(cardHash[card.cardname])
                commandersPlay.push(card.cardname)
                continue;
            }
            if (card.iscompanion===1){
                companion.push(cardHash[card.cardname])
                continue;
            }
            if (card.customcategorie!=="" && card.customcategorie !== null && card.customcategorie !== undefined){
                if (!(card.customcategorie in customTypeHash)){
                    customTypeHash[card.customcategorie]=[]
                }
                customTypeHash[card.customcategorie].push(cardHash[card.cardname])
            }else{
                let types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt']

                let lowerCaseType= cardHash[card.cardname]['type_en'].toLowerCase();
                for (let tp of types){
                    if (lowerCaseType.includes(tp)){
                        if (!(tp in customTypeHash)){
                            customTypeHash[tp]=[]
                        }
                        customTypeHash[tp].push(cardHash[card.cardname])
                        break;
                    }
                }
                if (lowerCaseType.includes('land') && !lowerCaseType.includes('artifact') && !lowerCaseType.includes('enchantment') && !lowerCaseType.includes('creature') && !lowerCaseType.includes('planeswalker')){
                    if (!('land' in customTypeHash)){
                        customTypeHash['land']=[]
                    }
                    customTypeHash['land'].push(cardHash[card.cardname])
                }
            }
        }

        this.setState({customTypeHash,commanders,commandersPlay})
    }

    makeDeckString = () => {
        let str=""
        let {deckCardsHash,cardHash} = this.state

        for (let card of Object.keys(deckCardsHash)){

            if (cardHash[card]&& cardHash[card].isflipcard===true){
                let displayNameArray = deckCardsHash[card].cardname.split(';')
                str+=deckCardsHash[card].card_qtde+" "+displayNameArray[0].trim()+"\r\n"
                continue
            }
            str+=deckCardsHash[card].card_qtde+" "+deckCardsHash[card].cardname+"\r\n"
        }
        this.setState({deckString:str})
    }

    async componentDidMount (){
        try{
          await this.loadDeckHeader()
          if (this.state.deckHeaderOk===false){
              console.log('erro no deck header is ok')
          }

          await this.loadDeckList()
          if (this.state.deckListOk===false){
              console.log('erro no decklistok')
          }

          this.loadCompanions()

          if (this.isCommanderFormat()){
              await this.loadCommanders()
              if (this.state.deckListOk===false){
                  console.log('erro no decklistok')
              }
          }

          await this.loadCardObjects()
          if (this.state.deckListOk===false){
              console.log('erro no load card objectsok')
          }

          if (window.innerWidth<=400){
            this.setState({fontSize:'10px'})
          
          }
          this.makeDeckString()
          this.loadCustomCategories()

          this.drawCard()
          this.drawCard()
          this.drawCard()
          this.drawCard()
          this.drawCard()
          this.drawCard()
          this.drawCard()
        } catch(e) {
          this.setState({problemOnLoad:true})
        }
        


    }

    footerPropaganda = () => {
      if(localStorage.getItem('mdtConf')==="true"||this.state.showGoogleAdd===false){
          return <></>
      }
      let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
      return (
          <div>
             <div style={{marginTop:'3px',marginBottom:'3px'}}>
                  <AdSense.Google
                    client='ca-pub-9649956420997697'
                    slot='7996053677'
                    style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                    format=''
                  />
              </div>
          </div>
      )  
  }

    getPresentationName = (cardName) => {
        let split = cardName.split(';')
        return split[0]
    }



    orderBySize = (listMap) => {
        let tmp = null
        let keys = Object.keys(listMap)
        for(let i = 0;i<keys.length;i++){
            for(let j = i+1; j<keys.length;j++){
                if( listMap[keys[i]].length<listMap[keys[j]].length ){
                    tmp =keys[j]
                    keys[j] = keys[i]
                    keys[i] = tmp
                }
            }
        }
        return keys
    }

    sumOfQuantitys = (cardArray) => {
        let sum=0
        for (let card of cardArray){
            if (this.state.deckCardsHash.hasOwnProperty(card.name_en)){
                sum+=this.state.deckCardsHash[card.name_en].card_qtde
            }

        }
        return sum
    }

    totalOfCards = () => {
        let sum = 0
        for(let card of this.state.deckList){
            sum+=card.card_qtde
        }
        if (this.state.companions.length>0){
            sum+=1
        }
        return sum
    }

    averageCmc = () => {
        let sum = 0
        let qtde = 0
        for(let card of this.state.deckList){

            if (this.state.cardHash.hasOwnProperty(card.cardname)){
                if(this.state.cardHash[card.cardname].cmc!==0){
                    sum+=this.state.cardHash[card.cardname].cmc*card.card_qtde
                    qtde+=card.card_qtde
                }
            }

        }
        return sum/qtde
    }


    sumOfQuantitysVersions = (cardArray) => {
        let sum=0
        for (let card of cardArray){
            sum+=card.card_qtde
        }
        return sum
    }

    compareSelectedVersionToLastVersion = () => {
        let {allVersions,selectedVersion,greaterVersion} = this.state
        let selectedOne = allVersions[selectedVersion]
        let deckCardsHash = allVersions[greaterVersion]

        let addedObjects = {}
        let addedHash = {}
        let removedObjects = {}
        let removedHash = {}

        for (let updatedCard in deckCardsHash){
            if (selectedOne && (updatedCard in selectedOne===false)){
                if (deckCardsHash[updatedCard].iscommander===0 && deckCardsHash[updatedCard].iscompanion===0){

                    addedObjects[updatedCard]=deckCardsHash[updatedCard]
                    if(addedHash.hasOwnProperty(updatedCard)){
                        addedHash[updatedCard]+=deckCardsHash[updatedCard].card_qtde
                    }else{
                        addedHash[updatedCard]=deckCardsHash[updatedCard].card_qtde
                    }
                }
            }else{
                if(deckCardsHash[updatedCard].card_qtde>selectedOne[updatedCard].card_qtde){
                    addedObjects[updatedCard]=deckCardsHash[updatedCard]
                    addedHash[updatedCard]=deckCardsHash[updatedCard].card_qtde-selectedOne[updatedCard].card_qtde
                }
            }
        }
        for (let card in selectedOne){
            if ( deckCardsHash && (card in deckCardsHash===false)){
                removedObjects[card]=selectedOne[card]
                if(removedHash.hasOwnProperty(card)){
                    removedHash[card]+=selectedOne[card].card_qtde
                }else{
                    removedHash[card]=selectedOne[card].card_qtde
                }
            }else{
                if(deckCardsHash[card].card_qtde<selectedOne[card].card_qtde){
                    removedObjects[card]=deckCardsHash[card]
                    removedHash[card]=selectedOne[card].card_qtde-deckCardsHash[card].card_qtde
                }
            }
        }
        return {removedCards:Object.values(removedObjects),addedCards:Object.values(addedObjects), addedHash,removedHash}
    }

    isCommanderFormat = () => {
        if (this.state.deckHeaderOk){
            if (this.state.deckHeader[0].f0rmat==="Commander"||this.state.selectedFormat==="Brawl"){
                return true;
            }else{
                return false;
            }
        }else{
            return false
        }
    }

    shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    updateCreatureDisplay = (creatureIndex) => () =>{
      let {creaturesTapped} = this.state
      if (creaturesTapped[creatureIndex]==='default'){
        creaturesTapped[creatureIndex]='tapped'
      }else{
        creaturesTapped[creatureIndex]='default'
      }
      this.setState({creaturesTapped})
    }

    updateLandDisplay = (landIndex) => () =>{
      let {landsTapped} = this.state
      if (landsTapped[landIndex]==='default'){
        landsTapped[landIndex]='tapped'
      }else{
        landsTapped[landIndex]='default'
      }
      this.setState({landsTapped})
    }

    updatePermanentDisplay = (cardIndex) => () => {
      let {permanentsTapped} = this.state
      if (permanentsTapped[cardIndex]==='default'){
        permanentsTapped[cardIndex]='tapped'
      }else{
        permanentsTapped[cardIndex]='default'
      }
      this.setState({permanentsTapped})
    }

    getPlayedCreatures = () => {
      let {cardHash,creaturesCards,creaturesTapped} = this.state
      let cardImages = []
      for (let cardIndex in creaturesCards){
        let card = cardHash[creaturesCards[cardIndex]]
        if (creaturesTapped[cardIndex]==='default'){
          cardImages.push(
          <div key={'creatures'+cardIndex}>
            <div className="playtestPageContainer">
              <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'} onClick={this.updateCreatureDisplay(cardIndex)}/>
              <div className="playtestPageMiddle">
                  <div className="playtestPageImageCenterText" onClick={this.updateCreatureDisplay(cardIndex)}>Tap</div>
              </div>
            </div>
            <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'creaturesZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
            <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'creaturesZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
          </div>)
        }else{
          cardImages.push(
          <div key={'creatures'+cardIndex}>
            <div className="playtestPageContainer">
              <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" style={{ border:"5px", transform: "rotate(90deg)", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'} onClick={this.updateCreatureDisplay(cardIndex)}/>
              <div className="playtestPageMiddle">
                  <div className="playtestPageImageCenterText" onClick={this.updateCreatureDisplay(cardIndex)}>Untap</div>
              </div>
            </div>
            <button style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'creaturesZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
            <button style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'creaturesZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
            
          </div>)
        }
      }
      return cardImages
    }

    getPlayedPermanents = () => {
      let {cardHash,permanentsCards,permanentsTapped} = this.state
      let cardImages = []
      for (let cardIndex in permanentsCards){
        let card = cardHash[permanentsCards[cardIndex]]
        
        if (permanentsTapped[cardIndex]==='default'){
            cardImages.push(
            <div key={'permanents'+cardIndex}>
            <div className="playtestPageContainer">
              <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" onClick={this.updatePermanentDisplay(cardIndex)}  style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'}/>
              <div className="playtestPageMiddle">
                  <div className="playtestPageImageCenterText" onClick={this.updatePermanentDisplay(cardIndex)}>Tap</div>
              </div>
            </div>
            <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'permanentsZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
            <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'permanentsZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
            </div>)
        }else{
            cardImages.push(
              <div key={'permanents'+cardIndex}>
                <div className="playtestPageContainer">
                  <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" onClick={this.updatePermanentDisplay(cardIndex)}  style={{ border:"5px", borderRadius:"5px", transform:"rotate(90deg)",width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'}/>
                  <div className="playtestPageMiddle">
                      <div className="playtestPageImageCenterText" onClick={this.updatePermanentDisplay(cardIndex)}>Untap</div>
                  </div>
                </div>
                <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'permanentsZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
                <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'permanentsZone',cardIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
              </div>)
        }

      }
      return cardImages
    }

    getPlayedLands = () => {
      let {cardHash,landsCards,landsTapped} = this.state
      let cardImages = []

      for (let landIndex in landsCards){

        let card = cardHash[landsCards[landIndex]]
        
        if(landsTapped[landIndex]==='default'){
          cardImages.push(
            <div key={landIndex}>
              <div className="playtestPageContainer">
                <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} onClick={this.updateLandDisplay(landIndex)} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'}/>
                <div className="playtestPageMiddle">
                    <div className="playtestPageImageCenterText" onClick={this.updateLandDisplay(landIndex)}>Tap</div>
                </div>
              </div>
              <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'landsZone',landIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
              <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'landsZone',landIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
            </div>)
        }else{
          cardImages.push(
            <div key={landIndex}>
              <div className="playtestPageContainer">
                <img onMouseEnter = {this.changeSelectedImage(card)} alt="cardImage" className="deckfolderimage" style={{ border:"5px", transform: "rotate(90deg)", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} onClick={this.updateLandDisplay(landIndex)} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'}/>
                <div className="playtestPageMiddle">
                    <div className="playtestPageImageCenterText" onClick={this.updateLandDisplay(landIndex)}>Untap</div>
                </div>
              </div>
              <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToGrave(card,'landsZone',landIndex)}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
              <button className="toGraveButton" style={{width:this.state.imageWidth/2-2}} onClick={this.sendToHand(card,'landsZone',landIndex)}>{localStorage.getItem('countryCode')==='+55'?'Mão':'Hand'}</button>
            </div> )
        }

      }
      return cardImages
    }

    drawCard = () => {
      let {shuffledDeck,handCards} = this.state
      if (shuffledDeck.length===0){
        return
      }
      handCards.push(shuffledDeck.pop())
      this.setState({shuffledDeck,handCards})
    }

    tutorCard = (cardname) => {
      let {shuffledDeck,handCards} = this.state

      if (shuffledDeck.indexOf(cardname)===-1){
        return
      }
      shuffledDeck.splice(shuffledDeck.indexOf(cardname), 1)
      handCards.push(cardname)
      this.setState({shuffledDeck,handCards,cardArea:""})
    }

    playCommander = (card, type) => () => {
      let {commandersPlay,creaturesCards, creaturesTapped, permanentsCards,permanentsTapped} = this.state
      type = type.toUpperCase()
      if (type.includes('CREATURE')){
        creaturesCards.push(card.name_en)
        creaturesTapped.push('default')
        commandersPlay.splice(commandersPlay.indexOf(card.name_en), 1);
        this.setState({creaturesCards,commandersPlay,creaturesTapped})
        return
      }
      permanentsCards.push(card.name_en)
      permanentsTapped.push('default')
      commandersPlay.splice(commandersPlay.indexOf(card.name_en), 1);
      this.setState({permanentsCards,commandersPlay,permanentsTapped})
    }


    sendToTop = (card, source) => () => {
      let {shuffledDeck,handCards,permanentsCards,creaturesCards,landsCards} = this.state
      if (source==='creaturesZone'){
        shuffledDeck.push(card.name_en)
        creaturesCards.splice(creaturesCards.indexOf(card.name_en), 1);
        this.setState({creaturesCards,shuffledDeck})
        return
      }
      if (source==='handZone'){
        shuffledDeck.push(card.name_en)
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({shuffledDeck,handCards})
        return
      }
      if (source==='landsZone'){
        shuffledDeck.push(card.name_en)
        landsCards.splice(landsCards.indexOf(card.name_en), 1);
        this.setState({landsCards,shuffledDeck})
        return
      }
      if (source==='permanentsZone'){
        shuffledDeck.push(card.name_en)
        permanentsCards.splice(permanentsCards.indexOf(card.name_en), 1);
        this.setState({permanentsCards,shuffledDeck})
      }
    }

    sendToBottom = (card, source) => () => {
      let {shuffledDeck,handCards,permanentsCards,creaturesCards,landsCards} = this.state
      if (source==='creaturesZone'){
        shuffledDeck.unshift(card.name_en)
        creaturesCards.splice(creaturesCards.indexOf(card.name_en), 1);
        this.setState({creaturesCards,shuffledDeck})
        return
      }
      if (source==='handZone'){
        shuffledDeck.unshift(card.name_en)
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({shuffledDeck,handCards})
        return
      }
      if (source==='landsZone'){
        shuffledDeck.unshift(card.name_en)
        landsCards.splice(landsCards.indexOf(card.name_en), 1);
        this.setState({landsCards,shuffledDeck})
        return
      }
      if (source==='permanentsZone'){
        shuffledDeck.unshift(card.name_en)
        permanentsCards.splice(permanentsCards.indexOf(card.name_en), 1);
        this.setState({permanentsCards,shuffledDeck})
      }
    }


    sendToGrave = (card, source, index) => () => {
      let {handCards,permanentsCards,creaturesCards,landsCards, graveCards,landsTapped, creaturesTapped, permanentsTapped} = this.state
      if (source==='handZone'){
        graveCards.push(card.name_en)
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({graveCards,handCards})
        return
      }

      if (source==='landsZone'){
        graveCards.push(card.name_en)
        landsCards.splice(landsCards.indexOf(card.name_en), 1);        
        landsTapped.splice(index,1)        
        this.setState({landsCards,graveCards,landsTapped})
        return
      }
      
      if (source==='creaturesZone'){
        graveCards.push(card.name_en)
        creaturesCards.splice(creaturesCards.indexOf(card.name_en), 1);
        creaturesTapped.splice(index, 1)
        this.setState({creaturesCards,graveCards,creaturesTapped})
        return
      }
      
      
      if (source==='permanentsZone'){
        graveCards.push(card.name_en)
        permanentsCards.splice(permanentsCards.indexOf(card.name_en), 1);
        permanentsTapped.splice(index,1)
        this.setState({permanentsCards,graveCards,permanentsTapped})
      }
    }

    sendToHand = (card, source, index) => () => {
      let {handCards,permanentsCards,creaturesCards,landsCards, graveCards,landsTapped, creaturesTapped, permanentsTapped} = this.state
      if (source==='creaturesZone'){
        handCards.push(card.name_en)
        creaturesCards.splice(creaturesCards.indexOf(card.name_en), 1);
        creaturesTapped.splice(index, 1)
        this.setState({creaturesCards,handCards,creaturesTapped})
        return
      }
      if (source==='graveyardZone'){
        handCards.push(card.name_en)
        graveCards.splice(graveCards.indexOf(card.name_en), 1);
        this.setState({graveCards,handCards})
        return
      }
      if (source==='landsZone'){
        handCards.push(card.name_en)
        landsCards.splice(landsCards.indexOf(card.name_en), 1);
        landsTapped.splice(index, 1)
        this.setState({landsCards,handCards,landsTapped})
        return
      }
      if (source==='permanentsZone'){
        handCards.push(card.name_en)
        permanentsCards.splice(permanentsCards.indexOf(card.name_en), 1);
        permanentsTapped.splice(index, 1)
        this.setState({permanentsCards,handCards,permanentsTapped})
      }
    }

    graveToHand = (card) => () => {
      let {handCards,graveCards} = this.state
      handCards.push(card.name_en)
      graveCards.splice(graveCards.indexOf(card.name_en), 1)
      this.setState({graveCards,handCards})
    }

    

    

    playCard = (card,type) => () => {
      let {handCards,permanentsCards,permanentsTapped,creaturesCards,landsTapped,creaturesTapped,landsCards, graveCards} = this.state
      type = type.toUpperCase()
      if (type.includes('CREATURE')){
        creaturesCards.push(card.name_en)
        creaturesTapped.push('default')
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({creaturesCards,handCards,creaturesTapped})
        return
      }

      if (type.includes('INSTANT') || type.includes('SORCERY') || type.includes('INTERRUPT')){
        graveCards.push(card.name_en)
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({graveCards,handCards})
        return
      }

      if (type.includes('LAND')){
        landsCards.push(card.name_en)
        landsTapped.push('default')
        handCards.splice(handCards.indexOf(card.name_en), 1);
        this.setState({landsCards,landsTapped,handCards})
        return
      }
      permanentsCards.push(card.name_en)
      permanentsTapped.push('default')
      handCards.splice(handCards.indexOf(card.name_en), 1);
      this.setState({permanentsCards,handCards,permanentsTapped})
    }

    getTopDeckImage = () => {
      return (
        <div className="playtestPageContainer">
          <img  alt={"mtgCardBack"} className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/backofcard/back.jpg'} onClick={this.drawCard}/>
          <div className="playtestPageMiddle2">
              <div className="playtestPageImageCenterText" onClick={this.drawCard}>{localStorage.getItem('countryCode')==='+55'?'Comprar':'Draw'}</div>
          </div>
          <button  style={{width:this.state.imageWidth-2,display:'block',fontSize:this.state.fontSize}} onClick={() => {this.setState({shuffledDeck:this.shuffleArray(this.state.shuffledDeck)})}}>{localStorage.getItem('countryCode')==='+55'?'Embaralhar':'Shuffle'}</button>
        </div>
      )
    }

    getHandCards = () => {
      let {cardHash,handCards} = this.state
      let cardImages = []
      if (handCards.length===0){
        return (<div key="handEmpty" style={{margin:'2px'}}>Hand Cards</div>)
      }
      for (let cardStr of handCards){
        let card = cardHash[cardStr]
        let type = card['type_en']
        cardImages.push(
        <div key={cardStr+Math.random(1000)}>
          <div key={cardStr.name_en+Math.random(10000)} className="playtestPageContainer">
            <img onMouseEnter = {this.changeSelectedImage(card)} key={cardStr.name_en+Math.random(10000)} alt={card.name_en} className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'} onClick={this.playCard(card,type)}/>
            {window.innerWidth>600?<div className="playtestPageMiddle2">
                <div key={cardStr.name_en+Math.random(10000)} className="playtestPageImageCenterText" onClick={this.playCard(card,type)}>{localStorage.getItem('countryCode')==='+55'?'Jogar':'Play'}</div>
            </div>:<nav></nav>}
          </div>
          <button  style={{width:this.state.imageWidth-2,display:'block',fontSize:this.state.fontSize}} onClick={this.sendToGrave(card,'handZone')}>{localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'}</button>
          <button  style={{width:this.state.imageWidth/2-2,fontSize:this.state.fontSize}} onClick={this.sendToTop(card,'handZone')}>{localStorage.getItem('countryCode')==='+55'?'Topo':'Top'}</button>
          <button  style={{width:this.state.imageWidth/2-2,fontSize:this.state.fontSize}} onClick={this.sendToBottom(card,'handZone')}>{localStorage.getItem('countryCode')==='+55'?'Fundo':'Bottom'}</button>
        </div>)
      }
      return cardImages
    }

    getTopGraveyardImage = () => {
      let {graveCards,cardHash} = this.state
      if (graveCards.length===0){
          return <div style={{ borderRadius:"5px", border:'3px', backgroundColor:'snow', width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo", textAlign:"center"}}> {localStorage.getItem('countryCode')==='+55'?'Cemitério':'Grave'} </div>
      }else{
        let card = cardHash[graveCards[graveCards.length-1]]
        return (
          <div className="playtestPageContainer">
            <div className="playtestPageMiddle2">
                <div className="playtestPageImageCenterText" onClick={this.graveToHand(card)}> to Hand</div>
            </div>
            <img onMouseEnter = {this.changeSelectedImage(card)} className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} alt={card.name_en} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'} onClick={this.graveToHand(card)}/>
          </div>
        )
      }
    }

    toNextTurn = () =>{
      let {currentTurn,permanentsTapped,landsTapped,creaturesTapped} = this.state
      this.drawCard()
      currentTurn+=1
      this.setState({currentTurn, permanentsTapped:permanentsTapped.map(() => {return 'default'}),landsTapped:landsTapped.map(() => {return 'default'}),creaturesTapped:creaturesTapped.map(() => {return 'default'})})
    }

    loadDisplayCard = (token) => {
        if ((token.length)<3){
            return []
        }
        let cartas = []
        for (let card of this.state.shuffledDeck){
            if ( card.toUpperCase().includes(token.toUpperCase()) && cartas.length<=12 ){
                cartas.push({name:card});
            }
        }
        return cartas
    }


    onChangeEvent = (e) => {
      if (this.state.shuffledDeck.includes(e.target.value.trim())){
        this.tutorCard(e.target.value.trim())        
      }
      this.setState({cardArea:e.target.value})
      
      
    }



    getCommanderBox = () => {
      let {commandersPlay ,cardHash} = this.state

      if (commandersPlay.length===0){
          return
      }else{
        let card = cardHash[commandersPlay[commandersPlay.length-1]]
        let type = card['type_en']
        return (
          <div className="playtestPageContainer" key={'playtestPageContainerCommander'}>
            <img onMouseEnter = {this.changeSelectedImage(card)} className="deckfolderimage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} alt={card.name_en} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop/'+card.last_edition+'/en/'+card.name_en+'.jpg'} onClick={this.playCommander(card,type)}/>
            <div className="playtestPageMiddle2">
                <div className="playtestPageImageCenterText" onClick={this.playCommander(card,type)}>{localStorage.getItem('countryCode')==='+55'?'Jogar':'Play'}</div>
            </div>
          </div>)

      }

    }

    render(){
        try{
          if (window.innerWidth<=600){
            return <Redirect to={"/users/"+this.props.match.params.user+"/decks/"+this.props.match.params.deckName+'/playtestM'}/>
          }
          return (
            
            <div className="playtestPage" key={"mainPLaytestPage"+Math.floor(50000)}>
                
                <GlobalNavBar/>
                <div className='playtest' style={{display:"block",backgroundColor:"rgb(56, 56, 56)"}} key={"subPlaytestpage"+Math.floor(50000)} >
                    <div style={{color:'snow',display:'block',textAlign:'center'}}>
                      <nav style={{marginTop:window.innerHeight*0.02, width:window.innerWidth/3-10, display:'inline-block'}}> {localStorage.getItem('countryCode')==='+55'?'Turno':'Turn'} {this.state.currentTurn} </nav>
                      <button style={{width:window.innerWidth/3-10, display:'inline-block', height:'40px',marginTop:'0px'}} className="playtestPageButton" onClick={()=>{window.location.reload()}}> Mulligan </button>
                      <button style={{width:window.innerWidth/3-10, display:'inline-block', height:'40px',marginTop:'0px'}} className="playtestPageButton" onClick={this.toNextTurn}> {localStorage.getItem('countryCode')==='+55'?'Próximo turno':'Next Turn'} </button>
                      
                      
                    </div>
                    <div>
                      <nav style={{width:window.innerWidth/3,height:'25px', display:'inline-block'}}>
                          <ReactTextareaAutocomplete
                          className="CardInput"
                          placeholder= {localStorage.getItem("countryCode")==='+55'?"Busque cartas usando o @. Ex: @sol ring":'Fetch for cards using @. Ex: @sol ring'}
                          onChange={this.onChangeEvent}
                          loadingComponent={() => <span>Loading</span>}
                          renderToBody={false}                       
                          
                          dropdownStyle={{all:"initial"}}
                          containerStyle={{all:"initial"}}
                          trigger={{
                              "@": {
                                  dataProvider: token => {
                                      let res = this.loadDisplayCard(token)
                                      return res;
                                  },
                                  component: ItemTextArea,
                                  allowWhitespace:true,
                                  output: (item, trigger) => item.name
                              }
                          }}
                          />
                      </nav>
                    </div>
                    <div style={{display:'grid', gridTemplateColumns:50+this.state.imageWidth*2+'px auto'}}>
                        <div style={{ backgroundColor:'#222222',borderRadius:'2px',width:10+this.state.imageWidth*2+'px'}} >
                          <nav style={{color:'snow',textAlign:'center'}}> <FontAwesomeIcon icon="eye"/> {localStorage.getItem('countryCode')==='+55'?'Visão da carta':'Card view'}</nav>
                          <img key={Math.random()}  alt="cardImage" style={{width:this.state.imageWidth*2+'px',height:this.state.imageHeight*2+'px'}} className="deckPageFixedImage" src={this.state.selectedImage}></img>
                        </div>
                        <div style={{minHeight:'50vh'}}>
                          <div style={{display:'grid', gridTemplateColumns:'auto auto auto auto auto auto auto'}} > {this.getPlayedCreatures()} </div>
                          <div style={{ display:'grid', gridTemplateColumns:'auto auto auto auto auto'}} > {this.getPlayedPermanents()} </div>
                          <div style={{display:'grid', gridTemplateColumns:'auto auto auto auto auto auto auto'}} > {this.getPlayedLands()} </div>
                        </div>
                    </div>                    
                    <div style={{display:'grid',gridTemplateColumns:'auto auto auto auto'}}>
                      <div key={'cartasMaoCaramba'} style={{color:'snow',backgroundColor:"rgb(36, 36, 36)",width:window.innerWidth*0.7,display:'grid',gridTemplateColumns:'auto auto auto auto auto auto auto auto'}}> {this.getHandCards()} </div>
                      <div style={{display:'inline',width:window.innerWidth*0.1}}> {this.getTopDeckImage()} </div>
                      <div style={{display:'inline',width:window.innerWidth*0.1}}> {this.getTopGraveyardImage()} </div>
                      <div style={{display:'inline',width:window.innerWidth*0.1}}>
                        {this.getCommanderBox()}
                      </div>
                    </div>
                    
                    {this.footerPropaganda()}
                    <BottomBar/>
                </div>
                
            </div>
           
          )
        }catch(e){
          return <Redirect to={"/"}/>        
        }
        
    }
}
export default withRouter(PlaytestPage);