import React, { Component } from "react";
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../services/api';
import './style.css'
import FacebookButton from '../../components/facebookButton'
const axios = require('axios');

class LoginBox extends Component {

    
    state = {
        login: "",
        password: "",
        hiddenPassword: true,
        token: "",
        modalBox:false,
        modalBoxMessage:"",
        modalBoxTitle:"",
        responseGet:null,
        displayMessage:false,
        message:"",
        textsEn:{"error":"Oops...something went wrong, update the page and try again","authError":"Oops... there is no user with this login and password","show":"Show/Hide","email":"Email","password":"Password","login":"Sign in","register":"Register","member":"Forgot your password?"},
        textsPt:{"error":"Vish...algo de errado não está certo, atualize a página e tente denovo","authError":"Vish... não existe um usuário com esse login e senha","show":"Ver/Ocultar","email":"Email","password":"Senha","login":"Entrar","register":"Registrar","member":"Esqueceu a senha?"}
    }

    buttonSend = async () => {
        const { login, password } = this.state;
        const axiosConfig = api.axiosConfig;

        let data = {
            "email": login,
            "passwd": password,
            "nickname":login,
        }

        await axios({
            method: 'post',
            url: api.authUrl + '/authenticate',
            data: data, headers:axiosConfig.headers
        }).then(async res => {

            if (res.status === 200) {
                this.setState({ login, password, token: res.data.token })
                localStorage.setItem('mdtUser', res.data.results[0].nickname)
                localStorage.setItem('mdtToken', res.data.token)
                localStorage.setItem('mdtConf', res.data.results[0].ispremium)
                await this.props.history.push('/')
                document.location.reload()
            }else if(res.status===400 && res.error==="Player not found"){
                this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
            }
        }).catch(err => {
            this.setState({displayMessage:true,message:localStorage.getItem("countryCode")==="+55"?this.state.textsPt.authError:this.state.textsEn.authError})
        });
    }


    buttonShowPassword = () => {
        let { hiddenPassword } = this.state
        if (hiddenPassword === true) {
            this.setState({ hiddenPassword: false })
        } else {
            this.setState({ hiddenPassword: true })
        }
    }

    fieldStateSetEmail = (event) => {
        this.setState({ "login": event.target.value })

    }

    fieldStateSetPassword = (event) => {
        let { password } = this.state
        password = event.target.value
        this.setState({ password })
    }

    stringHide = (string) => {
        let out = "";
        let parts = string.split('')
        for (let p of parts) {
            out += '*';
        }
        return out;
    }


    divEmail = () => {
        return (
            <div>
                <input className="loginInput" type="email" value={this.state.email} onChange={this.fieldStateSetEmail} placeholder="Email"></input>
            </div>
        )
    }

    divPassword = () => {
        return (
            <div className="passwordGrid">
                <div>
                    <input className="passwordInput" type={this.state.hiddenPassword ? "password" : "text"} placeholder={localStorage.getItem("countryCode")==="+55"?this.state.textsPt.password:this.state.textsEn.password  } value={this.state.password} onChange={this.fieldStateSetPassword}/>
                </div>

                <div className="tooltip" style={{width:"16px"}}>
                    <FontAwesomeIcon id="iconEye" icon="eye" onClick={this.buttonShowPassword}/>
                    <span className="tooltiptext">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.show:this.state.textsEn.show}</span>
                </div>

            </div>
        )
    }

    formLogin = () => {
        return (
            <>
                {this.displayMessage()}
                {this.divEmail()}
                {this.divPassword()}
            </>
        )
    }

    displayMessage = () => {
        if (this.state.displayMessage){
            return (
                <div id="displayMessage">
                    {this.state.message}
                </div>
            )
        }
        return <div id="hiddenMessage"></div>
    }

    centralBox = () => {
        return(
            <div id="central">
                {this.formLogin()}
                <div className="buttons">
                    <button style={{display:"inline"}} id="sign" onClick={this.buttonSend}> <FontAwesomeIcon id="iconCrow" icon="crow" onClick={this.buttonShowPassword}/> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.login:this.state.textsEn.login  }</button>
                    {window.innerWidth>900?<FacebookButton parentObjectMdt={this}/>:<></>}
                </div>
                <Link to="/recoverPassword">{localStorage.getItem("countryCode")==="+55"?this.state.textsPt.member:this.state.textsEn.member}</Link>
            </div>
        )
    }

    render() {
        return (
            this.centralBox()
        )
    }
};

export default withRouter(LoginBox);
