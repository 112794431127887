import BottomBar from "../../components/bottomBar"
import ErrorPage from "../ErrorPage"
import React, {Component} from 'react'
import {withRouter} from "react-router"
import {Link} from 'react-router-dom';
import api from "../../services/api"
import './styleDeckPage.css'
import GlobalNavBar from "../../components/globalNavBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import AdSense from 'react-adsense';
import dragonShieldSingle from '../../assets/dragonShieldSingle.png';
import centralShieldSingle from '../../assets/centralShieldSingle.png';
import bucaneirosShieldSingle from '../../assets/bucaneirosSingle.png';
import precons from '../../assets/precons.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import YouTube from 'react-youtube';
import ReactPlayer from "react-player";

import {
    BarChart, Bar, Cell, XAxis, YAxis, Tooltip,
} from 'recharts';
const axios = require('axios')


function isLoggedUser(userNameString){
    if (userNameString===null||userNameString===undefined||localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined){
        return false;
      }
    if (userNameString.trim()===localStorage.getItem('mdtUser').trim()){
      return true;
    }
    return false
}

function userOnLocalStorage(){
    if (localStorage.getItem('mdtUser')===null||localStorage.getItem('mdtUser')===undefined||localStorage.getItem('mdtToken')===null||localStorage.getItem('mdtToken')===undefined){
        return false
    }
    return true
}
class DeckPageOp extends Component{

    constructor(props){
        super(props)

        this.state = {
            cardsToFetch:{},
            ligaStoresHash:{},
            copied:false,
            value:'',
            deckListOk:false,
            problemOnLoad:false,
            link:'',
            onevidence:false,
            iscreator:0,
            deckname:"",
            lastPrice:0,
            lastCategorie:"",
            deckInsertOk:false,
            imageWidth:-10+window.innerWidth/3.5,
            imageHeight:(-10+window.innerWidth/3.5)*1.34482,
            left:0,
            warningModalIsOpen:false,
            showGoogleAdd:false,
            salePrice:0,
            flipCardsDisplay:{},
            donationLinkPt:"https://apoia.se/commanderdecks",
            bottomMessage:"",
            top:0,
            selectedDisplay:"default",
            selectedPresentation:"list",
            loadingDisplay:'none',
            youtubeLink:"",
            twitchLink:"",
            totalOfCards:0,
            avgCmc:0,
            purchaseFromPlayerModalIsOpen:false,
            deckString:"",
            clickedButton1:false,
            deleteModalIsOpen:false,
            cardPrices:{},
            selectedCmc:-1,
            loadingBarColor:'#8884d8',
            activeIndex:-1,
            barChartData:[],
            typeChartData:[],
           
            abaDeckDisplay:"block",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"none",
            displayStrsTabs:"none",
            loadedDecklistTuples:[],
            mainboard:[],
            maybeboard:[],
            sideboard:[],
            lastValue:-1,
            bestValue:-1,
            clicledCopyDeck:false,
            bestStores:[],
            stores:[],
            selectedImage:"",
            selectedImageClass:"scaleAnimation",
            selectedImageObject:null,
            priceHash:{}, //key:cardname value:price
            typeCardHash:{'creature':[],'artifact':[],'enchantment':[],'land':[],'planeswalker':[],'instant':[],'sorcery':[],'interrupt':[]}, //key:tipo[creature, artifact, enchantment, instant, sorcery, planeswalker] value:cardObject[]
            customTypeHash:{}, //key:tipo value:cardObject[]
            displayHiddenCustomTypes:{},
            cmcHash:{},
            commanders:[],//cardObject[]
            companions:[],
            displayCardNamesHash:{},
            allVersions:{},
            priceRangesHash:{},
            versionsClasses:{},
            selectedVersion:"",
            cardHash:{},
            greaterVersion:"",
            bannedCards:[],
            promoteModal2IsOpen:false,
            copyLinkModalIsOpen:false,
            displayVerTabs:"none",
            buttonDeckClass:"tablinks active",
            ispremium:false,
            promoteModalIsOpen:false,
            promoteDeckModalIsOpen:false,
            saleModalIsOpen:false,
            cancelSaleModalIsOpen:false,
            modalsDisplay:{},
            qtViews:0,
            loadedPrices:false,
            saleLink:'',
            displayMessage:false,
            fontSize:'16px',
            paypalResponseLink:'',
            newDeckName:'',
            textsEn:{
                "apoiaseWarning":"Payments made on apoia.se will take between 1 to 3 days for us to process",
                "alternative":"OR... click on Discover+Video, that will display the best buy combination and an show you a nice cards video",
                "setPriceAndLink":"You must set a price and a sale link(with https://yourFavoriteSaleSite) to sell a deck",
                "saleLinkProblem":"There is a problem with the sale link, sorry for that =(",
                "buyerInfo":"To buy this deck from it's owner, type a message with your contact information (such as email). We will forward this message to the owner of the deck.",
                "saleLink":"Type the link of your sale, it is safe to sell products in web sites such as Amazon, Ebay, or Facebook marketplace. You can also put here a contact link such as a facebook or instagram page.",
                "paypalPay":"Help with paypal",
                "promoteTitle": "Sometimes purchasing from multiple stores is cheaper!",
                "sell":"Sell deck",
                "payApoia":"Help on apoia.se",
                "removeFromSale":"Cancel sale",
                "cancelMessage":"Are you sure about this? The deck will no longer be on sale.",
                "removeSaleMessage":"The deck is not on sale anymore",
                "promote":"Promote deck",
                "salePriceText":"This deck is ON SALE, the current owner announced it by:",
                "playerPurchase":"Buy from player",
                "sellMessage":"Type the price that you wish to publish for the deck, in dolars",
                "promoteMessage":"For just 5 dolars, your deck will appear on the homepage of every user for 7 days!",
                "loadingMessage":"Loading...",
                "message":"Are you sure about this? The deck will REALLY be deleted",
                "buttonText":"Delete deck",
                "editdeck":"Edit deck",
                "barchartTitle":"Quantity of cards by cost",
                "typeChartTitle":"Quantity of cards by type",
                "type":"Type of card",
                "deleteDeck":"Delete deck",
                "copy":"Copy deck",
                "playtest":"Playtest",
                "totalLabel":"Min cost",
                "totalValue":"Total Value (Shipping included)",
                "addedCards":"Cards in",
                "removedCards":"Cards out",
                "purchase":"Purchase deck",
                "export":"Export",
                "promoteMessage2":"",
                "promoteMessage3":"",
                "history":"Deck versions",
                "defaultDisplay":"Display: Default",
                "priceDisplay":"Display: Price",
                "cmcDisplay":"Display: Cmc",
                "typeDisplay":"Display: Type",
                "listPresentation":"View as list",
                "imagesPresentation":"View as images",
                "totalCards":"Cards in deck",

                "averageCmc":"Avg CMC"
            },
            textsPt:{
                "apoiaseWarning":"Contribuições feitas no apoia.se podem levar de 1 a 3 dias para serem processadas",
                "alternative":"OU... clique em Descobrir+Video para ver em quais lojas o seu deck sai mais em conta. (E ver um vídeo super bacana no Youtube)",
                "setPriceAndLink":"Você precisa definir um preço e um link de venda(ex https://seusitedevendafavorito/seudeck) para vender um deck",
                "saleLinkProblem":"Tem um problema no link de venda, desculpe por isso =(",
                "saleLink":"Digite o link da sua venda, é seguro vender produtos online no Mercado Livre ou no Market Place do Facebook. Alternativamente você pode deixar uma página sua de contato no instagram ou facebook.",
                "buyerInfo":"Para comprar esse deck de seu dono, digite uma mensagem com seu contato (email por exemplo). Nós iremos encaminhar essa mensagem ao dono do deck.",
                "paypalPay":"Ajude via Paypal",

                "promoteTitle": "As vezes sai mais em conta comprar em mais que uma loja amigo! =)",
                "sell":"Vender deck",
                "promote":"Promover deck",
                "payApoia":"Ajude pelo apoia.se",
                "removeFromSale":"Cancelar venda",
                "cancelMessage":"Tem certeza que quer cancelar? O deck não vai mais estar à venda.",
                "removeSaleMessage":"The deck não está mais a venda",
                "salePriceText":"Esse deck está marcado como À VENDA, o anunciante vende por apenas:",
                "playerPurchase":"Comprar do dono",
                "sellMessage":"Digite o preço pelo qual você deseja vender o deck, em reais (R$)",
                "promoteMessage":"Se torne um parceiro do commanderdecks.com por apenas 20 R$, descubra combinações de lojas em que seus decks saem mais em conta e ganhe os seguintes poderes:",
                "loadingMessage":"Carregando...",
                "message":"Tem certeza disso? O deck vai REALMENTE ser deletado",
                "buttonText":"Deletar deck",
                "barchartTitle":"Quantidade de cartas por custo",
                "typeChartTitle":"Quantidade de cartas por tipo",
                "editdeck":"Editar Deck",
                "type":"Tipo de carta",
                "copy":"Copiar deck",
                "playtest":"Playtest",
                "totalValue":"Valor total (Frete incluso)",
                "purchase":"Comprar de loja",
                "export":"Exportar",
                "totalLabel":"Menor preço",
                "deleteDeck":"Deletar deck",
                "promoteMessage2":"",
                "promoteMessage3":"",
                "history":"Versões do deck",
                "defaultDisplay":"Display: Padrão",
                "priceDisplay":"Display: Preço",
                "cmcDisplay":"Display: Cmc",
                "addedCards":"Cartas adicionadas",
                "removedCards":"Cards removidas",
                "typeDisplay":"Display: Tipo",
                "listPresentation":"Ver em Lista",
                "imagesPresentation":"Ver em Imagens",
                "totalCards":"Cartas no deck",
                "averageCmc":"Custo de mana médio"
            },

        }
    }    

    displayMessage = () => {
        if (this.state.displayMessage){
            return (
                <div id="displayMessage">
                    {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.setPriceAndLink:this.state.textsEn.setPriceAndLink}
                </div>
            )
        }
        return <div></div>
    }

    cancelSaleModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.cancelSaleModalIsOpen}
                onRequestClose={() => {this.setState({cancelSaleModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.cancelSaleWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    promoteModal2 = () => {
        let message=undefined
        if(localStorage.getItem('countryCode')==='+55'){
            message='Deck promovido por 3 dias!'
        }else{
            message='Deck promoted for 4 days!'
        }
        return (
            <div>
                <Modal
                isOpen={this.state.promoteModal2IsOpen}
                onRequestClose={() => {this.setState({promoteModal2IsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%",textAlign:'center',padding:'5%'}}>
                    <div><h3>{message}</h3></div>
                    <button style={{width:'100%'}} className="deckPageButton" onClick={() => {this.setState({promoteModal2IsOpen:false})}}>OK</button>
                </div>
                </Modal>
            </div>
        )
    }

    LinkCopyedModal = () => {
        let message=undefined
        if(localStorage.getItem('countryCode')==='+55'){
            message='Link do deck copiado para a área de transferência'
        }else{            
            message='Deck link saved in the transfer area.'
        }
        return (
            <div>
                <Modal
                isOpen={this.state.copyLinkModalIsOpen}
                onRequestClose={() => {this.setState({copyLinkModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%",textAlign:'center',padding:'5%'}}>
                    <div><h3>{message}</h3></div>
                    <button style={{width:'100%'}} className="deckPageButton" onClick={() => {this.setState({copyLinkModalIsOpen:false})}}>OK</button>
                </div>
                </Modal>
            </div>
        )
    }

    purchaseFromPlayerModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.purchaseFromPlayerModalIsOpen}
                onRequestClose={() => {this.setState({saleModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.purchaseFromPlayerWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    isValidSaleLink = (saleLink) => {
        if ((saleLink.toLowerCase().includes('http') || saleLink.toLowerCase().includes('www.') ) && saleLink.length>11){
            return true
        }
        return false
    }

    sellDeck = async () => {
        let {loadedDecklistTuples} = this.state
        const salePrice = this.state.salePrice
        let saleLink = this.state.saleLink

        if (isNaN(salePrice) ||  this.isValidSaleLink(saleLink)===false){
            this.setState({displayMessage:true})
            return
        }
        if (saleLink.toLowerCase().includes('https://')===false){
            saleLink='https://'+saleLink
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer ' + localStorage.getItem('mdtToken');
        let data = {
            "player": this.props.match.params.user,
            "deckname": this.changeDeckName(loadedDecklistTuples[0].deckname),
            "saleprice":salePrice,
            "currencyCode":localStorage.getItem('countryCode')==='+55'?'BRL':'USD',
            "saleLink":saleLink
        }
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/sell',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                document.location.reload()
            }
        })
    }

    purchaseFromPlayerWindow = () => {
        return(
            <div id="central">
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.sellMessage:this.state.textsEn.sellMessage } </div>
                <div className="buttons">
                    <input value={this.state.salePrice} style={{marginLeft:'30%',marginTop:'5px',marginBottom:'5px'}} type="number" step="any" onChange={this.fieldStateSetSalePrice} ></input>
                    <button style={{display:"inline",width:"100%"}} className="deckPageButton" onClick={this.sellDeck}>  {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.sell:this.state.textsEn.sell }</button>

                </div>
            </div>
        )
    }

    promoteDeckModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.promoteDeckModalIsOpen}
                onRequestClose={() => {this.setState({promoteDeckModalIsOpen:false})}}
                className="ModalSmart"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    <div id="central">
                        <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?"Torne-se nosso parceiro e promova seus decks!":"Become a commanderdecks.com pal and promote your decks!" } </div>
                        <div className="buttons">
                            <Link to="/becomePro" className="deckPageButton" style={{fontWeight:'800',display:'block',color:'snow',padding:'15px',width:'100%',marginBottom:'2px',marginTop:'5px',textDecoration:'none'}}> {localStorage.getItem("countryCode")==="+55"?"Virar parceiro!":"Become our pal!" } </Link>
                            <button style={{display:"inline",width:"100%",padding:'10px',color:'snow',backgroundColor:'salmon'}}  onClick={() => {this.setState({promoteDeckModalIsOpen:false})}}>  {localStorage.getItem("countryCode")==="+55"?"Nah, fechar":"Not today, close" }</button>
                        </div>
                    </div>
                </div>
                </Modal>
            </div>
        )
    }

    promoteModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.promoteModalIsOpen}
                onRequestClose={() => {this.setState({promoteModalIsOpen:false})}}
                className="ModalSmart"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.premiumWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    premiumWindow = () => {
        return(
            <div style={{margin:'25px'}}>
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> <h3>{localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteTitle:this.state.textsEn.promoteTitle }</h3> </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:800, color:'#00008b'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteMessage2:this.state.textsEn.promoteMessage2 } </div>
                    <div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:800, color:'#00008b'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.promoteMessage3:this.state.textsEn.promoteMessage3 } </div>
                    {userOnLocalStorage()===true?<div style={{textAlign:'center',marginBottom:'10px',marginLeft:'2px', fontWeight:800}}> {localStorage.getItem("countryCode")==="+55"?"Se torne nosso parceiro por 1 ano para sempre economizar! É só nos apoiar com 20 R$ no paypal ou apoia.se":"Become our partner for a year to always save money! All you have to do is to help is with 20 bucks on paypal or apoia.se" } </div>:<></>}
                    <div>
                        {this.paypalBox()}
                    </div>
            </div>
        )
    }


    changeDisplayName = (player) => {
        if(player.includes('fb') === false || player.includes('-') === false) return this.getPreArrobaPart(player)        
        let playerSplit = player.split('-')
        if(playerSplit[playerSplit.length-1].includes('fb')===false){
            return player
        }
        let displayName = ""
        for(let i = 0;i<playerSplit.length-1;i++){
            if (i!==playerSplit.length-2){
                displayName+=playerSplit[i]+'-'
            }else{
                displayName+=playerSplit[i]
            }
        }
        displayName = this.getPreArrobaPart(displayName)
        return displayName
    }

    getPreArrobaPart = (playerName) => {        
        if (playerName.includes('@')===false) return playerName
        let splitArroba = playerName.split('@')
        return splitArroba[0]
    }


    paypalBox = () => {
        let buttonWidth = window.innerWidth<=800?'100%':'40%'
        return (
            <div style={{textAlign:'center'}}>
                {userOnLocalStorage()===true?<a target="popup" className={'deckPageButton float'} style={{fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}} href={'/becomePro'}><FontAwesomeIcon icon={"laugh"} style={{marginRight:'3px'}}/>{localStorage.getItem('countryCode')==='+55'?'Virar parceiro':'Become our pal'}</a>:<></>}
                <div style={{color:'red',textAlign:'center',marginBottom:'10px',marginLeft:'2px',fontWeight:'800'}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.alternative:this.state.textsEn.alternative } </div>
                <div>
                    <a target="popup" onClick={this.showBestStores} className='deckPageButtonRed float' style={{width:buttonWidth,fontSize: "23px",textDecoration:"none",color:"white",fontWeight:'400',border:'2px solid',borderColor:"lightgray"}} href="https://youtu.be/0WFpiRWS4UI"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","youtube"]}></FontAwesomeIcon> {localStorage.getItem('countryCode')==='+55'?'Descobrir+Vídeo':'Discover+Video'} </a>
                    <button onClick={() =>this.setState({promoteModalIsOpen:false})} className={'deckPageButton float'} style={{width:buttonWidth,marginTop:'4px',color:'snow',backgroundColor:'salmon'}}><FontAwesomeIcon icon="meh" style={{marginRight:'2px'}}></FontAwesomeIcon>{localStorage.getItem('countryCode')==='+55'?'Ignorar e comprar numa loja só':'Ignore and buy from one store'}</button>
                </div>
            </div>
        )
    }


    fieldStateSetSalePrice = (event) => {
        this.setState({salePrice:event.target.value})
    }

    fieldStateSetSaleLink = (event) => {
        this.setState({saleLink:event.target.value})
    }

    cancelSaleWindow = () => {
        return(
            <div id="central">
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.cancelMessage:this.state.textsEn.cancelMessage } </div>
                <div className="buttons">

                    <button style={{display:"inline",width:"100%"}} className="deckPageButton" onClick={this.cancelSale}>  {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.removeFromSale:this.state.textsEn.removeFromSale }</button>

                </div>
            </div>
        )
    }

    cancelSale = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player": this.props.match.params.user,
            "deckname": this.changeDeckName(this.state.loadedDecklistTuples[0].deckname)
        }
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/cancelSale',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                document.location.reload()
            }
        })
    }

    saleWindow = () => {
        return(
            <div id="central">
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.sellMessage:this.state.textsEn.sellMessage } </div>
                <div className="buttons">
                    <input value={this.state.salePrice} style={{marginLeft:'30%',marginTop:'5px',marginBottom:'5px'}} type="number" step="any" onChange={this.fieldStateSetSalePrice} ></input>

                </div>
                <div style={{marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.saleLink:this.state.textsEn.saleLink } </div>
                <div className="buttons">
                    <input value={this.state.saleLink} style={{width:'98%',marginTop:'5px',marginBottom:'5px'}} type="text" step="any" onChange={this.fieldStateSetSaleLink} ></input>
                    <button style={{display:"inline",width:"100%"}} className="deckPageButton" onClick={this.sellDeck}>  {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.sell:this.state.textsEn.sell }</button>
                </div>
                {this.displayMessage()}
            </div>
        )
    }

    saleModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.saleModalIsOpen}
                onRequestClose={() => {this.setState({saleModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.saleWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    deleteModal = () => {
        return (
            <div>
                <Modal
                isOpen={this.state.deleteModalIsOpen}
                onRequestClose={() => {this.setState({deleteModalIsOpen:false})}}
                className="Modal"
                overlayClassName="Overlay"
                >
                <div style={{"width":"100%"}}>
                    {this.deleteDeckWindow()}
                </div>
                </Modal>
            </div>
        )
    }

    deleteDeckWindow = () => {
        return(
            <div id="central">
                <div style={{textAlign:'center',marginBottom:'2px',fontWeight:900}}> {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.message:this.state.textsEn.message } </div>
                <div className="buttons">

                    <button style={{display:"inline",width:"100%"}} className="deckPageButton" onClick={this.deleteDeck}>  {localStorage.getItem("countryCode")==="+55"?this.state.textsPt.buttonText:this.state.textsEn.buttonText }</button>

                </div>
            </div>
        )
    }

    deleteDeck = async () => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player": this.props.match.params.user,
            "deckname": this.changeDeckName(this.state.loadedDecklistTuples[0].deckname)
        }
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/drop',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                this.props.history.push("/users/"+this.props.match.params.user+"/decks/")
            }else{
              let {textsEn,textsPt} = this.state
              textsEn.message = "Oops...something went wrong on the delete deck action, refresh the page and try again"
              textsPt.message = "Oops...alguma coisa deu errado na hora de deletar o deck, atualize e tente novamente"
              this.setState({textsEn,textsPt})
            }
        })
    }

    getStoresWindows = () => {
        let {ligaStoresHash} = this.state;
        let keys = Object.keys(this.state.stores);
        let storesSections = [];
        
        for (let store of keys){
            if (ligaStoresHash.hasOwnProperty(store.trim())){
                storesSections.push(
                    <div style={{margin:"1%"}} key={store+Math.floor(5000)}>
                        <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}> <a onClick={() => this.updateClicks(ligaStoresHash[store.trim()].id,ligaStoresHash[store.trim()].clicks+1)}  className="ligaStoreLink" href={ligaStoresHash[store.trim()].storelink} target = "_blank" 
rel = "noopener noreferrer"> {ligaStoresHash[store.trim()].storename} {localStorage.getItem("countryCode")==="+55"?"(Clique para visitar a loja)":"(Go to site)"}</a></div>
                        <div className="grid-container-deckPage deckPage" style={{fontSize:this.state.fontSize}}>
                            {this.getAllCardsFromStore(store)}
                        </div>
                    </div>
                )
            }else{         
                
                storesSections.push(
                    <div style={{margin:"1%"}} key={store+Math.floor(5000)}>
                        <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}> {store}</div>
                        <div className="grid-container-deckPage deckPage" style={{fontSize:this.state.fontSize}}>
                            {this.getAllCardsFromStore(store)}
                        </div>
                    </div>
                )                               
            }            
        }
        return storesSections
    }

    getAllCardsFromStore = (store) => {
        let {stores,cardHash} = this.state
        let keys = Object.keys(stores[store])
        let out = []
        for (let card of keys){
            if(card==='frete shipping'){
                out.push(<div key={'frete'+Math.random(4000)}>{localStorage.getItem('countryCode')==='+55'?'Frete - 12 R$':'Shipping - 12 $'}

                </div>)
                continue
            }
            let cardArray = stores[store][card]
            out.push( <div key={card}> <nav className="generalLink" onMouseEnter = {this.changeSelectedImage(cardHash[card])}>  <nav > {cardArray[2]} {this.getPresentationName(card)}   </nav> </nav>    </div>)
        }
        return out
    }

    purchaseFromPlayerAction = () => {
        if (this.state.loadedDecklistTuples[0].salelink&&  (this.state.loadedDecklistTuples[0].salelink.toLowerCase().includes('https://')||this.state.loadedDecklistTuples[0].salelink.includes('www.')||this.state.loadedDecklistTuples[0].salelink.length>15 ) ){
            window.location.replace(this.state.loadedDecklistTuples[0].salelink)
        }else{
            console.log(localStorage.getItem('countryCode')==='+55'?this.state.textsPt.saleLinkProblem:this.state.textsEn.saleLinkProblem)
        }
    }

    deleteDeckAction = () => {
        this.setState({deleteModalIsOpen:true})
    }

    saleAction = () => {
          this.setState({saleModalIsOpen:true})
    }

    cancelSaleAction = () => {
          this.setState({cancelSaleModalIsOpen:true})
    }

    promoteAction = async () => {
        if (this.state.ispremium===true||this.state.iscreator===1){
            this.setState({promoteModal2IsOpen:true})
            await this.promoteDeck()
        }else{

            this.setState({promoteDeckModalIsOpen:true})
        }
    }

    promoteDeck = async() => {
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player": this.props.match.params.user,
            "deckname": this.changeDeckName(this.state.loadedDecklistTuples[0].deckname)
        }
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/promoteDeck',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                this.setState({warningModalIsOpen:true})
            }
        })
    }


    promoteButton = () => {
        if (isLoggedUser(this.props.match.params.user)&&(this.state.iscreator===1||this.state.ispremium===true)&&this.state.onevidence===false){
            return (<nav className="deckPageButtonOrange" onClick={this.promoteAction}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.promote:this.state.textsEn.promote}</nav>)
        }
        return <></>
    }

    totalPrice = () => {
        let total = 0
        let {priceHash,mainboard,commanders,companions} = this.state
        for (let card of mainboard){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        for (let card of commanders){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        for (let card of companions){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        return total
      }

      totalPriceLocal = (priceHash) => {
        let total = 0
        let {mainboard,commanders,companions} = this.state
        for (let card of mainboard){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        for (let card of commanders){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        for (let card of companions){
            if (priceHash.hasOwnProperty(card.cardname)){
                total+=priceHash[card.cardname]*card.card_qtde
            }
        }
        return total
      }

      changeDeckName = (deckName) => {
        if(deckName===undefined || deckName===null || isNaN(deckName)===false)
            return ""
        return deckName.trim().replace(/ /g,'--')
    }

    getSalePriceDisplay = () => {
        if (this.state.loadedDecklistTuples[0].onsale===true){
            return  <div style={{backgroundColor:"#FF851B",padding:"1.5%",textAlign:"center", margin:"1px"} }> <div style={{fontWeight:"bold"}}> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.salePriceText:this.state.textsEn.salePriceText}</div> {this.state.loadedDecklistTuples[0].saleprice} {this.state.loadedDecklistTuples[0].salecurrencycode==='BRL'?"R$":"$$"}</div>
        }
    }

    getMovingImageDisplay = () => {
        if (this.state.selectedPresentation==="list"){
            return "inline"
        }else{
            return "none"
        }
    }

    sumOfQuantitys = (cardArray) => {
        let sum=0
        for (let card of cardArray){
            sum+=card.card_qtde
        }
        return sum
    }

    registerToString = (decklisttuple) => {
        let displayNameArray = decklisttuple.cardname.split(';')
        if (decklisttuple.layout==='modal_dfc'||decklisttuple.layout==='transform'){
            return {"name":displayNameArray[0].trim(),"qtdeName":decklisttuple.card_qtde+" "+displayNameArray[0].trim()+"\r\n"}
        }else{
          let qtde=decklisttuple.card_qtde+' '
          let name=''
          for(let i = 0; i<displayNameArray.length;i++){
              if (i===displayNameArray.length-1){
                name+=displayNameArray[i].trim()
              }else{
                name+=displayNameArray[i].trim()+" / "
              }
          }
          return {"name":name,"qtdeName":qtde+name+"\r\n"}
        }
    }

    handleClick = (data, index) => {
        if (index!==this.state.activeIndex){
            this.setState({
            selectedCmc:data.name,
            activeIndex: index,
            });
        }else{
            this.setState({
                selectedCmc:-1,
                activeIndex: -1,
            });
        }
    }

    getNextDeckName = (deckName) => {
        return deckName+'*'
    }

    getNextDeckLink = (deckName) => {
        return deckName.trim()+'1'
    }

    insertDeckFolder = async (name, player, deckname, presentationorder) => {
        let data = {
            "name": name,
            "player": player,
            "deckname": deckname,
            "presentationorder": presentationorder
        }
        const axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method:'post',
            url:api.baseUrl+'/deckfolder/',
            data:data,
            headers:axiosConfig.headers,
        }).catch(async err => {
            console.log(err)
            await this.setState({'deckInsertOk':false})
        });
    }


    insertDeck = async (player, deckname, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews) => {
        let data = {
            "player": player,
            "colors": colors,
            "f0rmat": format,
            "description": "description",
            "upvotes": upvotes,
            "last_update": lastUpdate,
            "maincard2": this.getCardNameOut(maincard2),
            "qtviews": qtViews,
            "deckname": deckname,
            "maincard": this.getCardNameOut(maincard),
            "isprivate":false
        }
        let axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method: 'post',
            url: api.baseUrl + '/deck/',
            data: data, headers:axiosConfig.headers,
        }).then (async res => {
            if (res.status === 200) {
                await this.setState({'deckInsertOk':true, deckname:deckname})
            }
        }).catch(async err => {
            console.log(err)
            if (err.response){
                if (err.response.data && err.response.data.Message.includes('duplicate key')){
                    await this.insertDeck(player, this.getNextDeckName(deckname), maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews)
                }else{
                    await this.setState({'deckInsertOk':false})

                }
            }else{
                await this.setState({'deckInsertOk':false})
            }
        });
    }

    getCardNameOut = (cardName) => {
        return cardName.replace( "/",";" )
    }

    insertDeckList = async ( player, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck, deckName,currentDate,deckCards, sideboardCards, maybeboardCards,folder, link) => {
        let {commanders,companions} = this.state;
        let batchGroup = []
        let commander=""
        let partner=""
        if (this.isCommanderFormat()===true){
            if (commanders.length>0){
              commander=commanders[0].cardname
            }
            if (commanders.length>1){
              partner=commanders[1].cardname;
            }
            if (commander!==""){
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut(commander),
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":0,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":commanders[0].last_edition,
                    "deckname":deckName
                })
            }
            if (partner!==""){
                batchGroup.push({
                    "customcategorie":"Commander",
                    "cardname": this.getCardNameOut(partner),
                    "isfoil":0,
                    "listdate":currentDate,
                    "player":localStorage.getItem('mdtUser'),
                    "iscommander":1,
                    "iscompanion":0,
                    "version":"*",
                    "listversion":0,
                    "listtype":"commander",
                    "card_qtde":1,
                    "edition":commanders[1].last_edition,
                    "deckname":deckName
                })
            }
        }

        let companion="";
        if (companions.length>0){
          companion=companions[0].cardname
        }
        if (companion!==""){
            batchGroup.push({
                "customcategorie":"Companion",
                "cardname": this.getCardNameOut(companion),
                "isfoil":0,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":1,
                "version":"*",
                "listversion":0,
                "listtype":"companion",
                "card_qtde":1,
                "edition":companions[0].last_edition,
                "deckname":deckName
            })
        }
        companion = companion!==undefined&&companion!==null?companion:""
        commander = commander!==undefined&&commander!==null?commander:""
        partner = partner!==undefined&&partner!==null?partner:""
        for (let card of deckCards){
            if (card.cardname.trim()===this.getCardNameOut(companion.trim()) || card.cardname.trim()===this.getCardNameOut(commander.trim())||card.cardname.trim()===this.getCardNameOut(partner.trim())){
                continue;
            }
            batchGroup.push({
                "customcategorie":card.customcategorie,
                "cardname": this.getCardNameOut(card.cardname),
                "isfoil":card.isfoil,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"mainboard",
                "card_qtde":card.card_qtde,
                "edition":card.edition,
                "deckname":deckName
            })
        }
        for (let card of sideboardCards){
            if (card.cardname.trim()===this.getCardNameOut(companion.trim()) || card.cardname.trim()===this.getCardNameOut(commander.trim())||card.cardname.trim()===this.getCardNameOut(partner.trim())){
                continue;
            }
            batchGroup.push({
                "customcategorie":card.customcategorie,
                "cardname": this.getCardNameOut(card.cardname),
                "isfoil":card.isfoil,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"sideboard",
                "card_qtde":card.card_qtde,
                "edition":card.edition,
                "deckname":deckName
            })
        }
        for (let card of maybeboardCards){
            batchGroup.push({
                "customcategorie":card.customcategorie,
                "cardname": this.getCardNameOut(card.cardname),
                "isfoil":card.isfoil,
                "listdate":currentDate,
                "player":localStorage.getItem('mdtUser'),
                "iscommander":0,
                "iscompanion":0,
                "version":"*",
                "listversion":0,
                "listtype":"maybeboard",
                "card_qtde":card.card_qtde,
                "edition":card.edition,
                "deckname":deckName
            })
        }
        let data = {
            "player": player,
            "colors": colors,
            "f0rmat": format,
            "description": description,
            "upvotes": upvotes,
            "last_update": lastUpdate,
            "maincard2": this.getCardNameOut(maincard2),
            "qtviews": qtViews,
            "deckname": deckName,
            "maincard": this.getCardNameOut(maincard),
            "isprivate":privateDeck,
            "cardsArray":batchGroup,
            "name":folder,
            "presentationorder":0,
            "link":link,
            "youtubeLink":"",
            "twitchLink":""
        }
        
        let axiosConfig = api.axiosConfig;
        axiosConfig.headers['authorization'] = 'Bearer '+localStorage.getItem('mdtToken')
        await axios({
            method: 'post',
            url: api.baseUrl + '/decklist/multiple',
            data: data, headers:axiosConfig.headers,
        }).then (async res => {
            if (res.status === 200) {
                await this.setState({'deckInsertOk':true, newDeckName:deckName, link:link})
            }
        }).catch(async err => {
            if (err.response){
                if (err.response.data && err.response.data.Message.includes('duplicate key')){
                    await this.insertDeckList(player, maincard, maincard2, colors, format, description, upvotes, lastUpdate, qtViews, privateDeck, this.getNextDeckName(deckName),currentDate,deckCards, sideboardCards, maybeboardCards,folder,this.getNextDeckLink(link))
                }else{
                    console.log(err)
                    console.log(err.response)
                    await this.setState({'deckInsertOk':false})
                }
            }else{
                console.log(err)
                await this.setState({'deckInsertOk':false})

            }
        });
    }


    showDeckTab = (e) => {
        this.setState({
            abaDeckDisplay:"block",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks active",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks",
        })
    }

    showSideboardTab = (e) => {
        let {displayHiddenCustomTypes} = this.state
        displayHiddenCustomTypes['sideboardOficial']=this.state.sideboard
        this.setState({
            displayHiddenCustomTypes:displayHiddenCustomTypes,
            abaDeckDisplay:"none",
            abaSideboardDisplay:"block",
            abaMaybeboardDisplay:"none",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks active",
            buttonMaybeboardClass:"tablinks",
        })
    }

    showMaybeboardTab = (e) => {
        let {displayHiddenCustomTypes} = this.state
        displayHiddenCustomTypes['maybeboardOficial']=this.state.maybeboard
        this.setState({
            displayHiddenCustomTypes:displayHiddenCustomTypes,
            abaDeckDisplay:"none",
            abaSideboardDisplay:"none",
            abaMaybeboardDisplay:"block",
            buttonDeckClass:"tablinks",
            buttonSideboardClass:"tablinks",
            buttonMaybeboardClass:"tablinks active",
        })
    }

    changeSelectedDisplay = (event) => {
        this.setState({selectedDisplay:event.target.value})
    }

    changeSelectedPresentation = (event) => {
        this.setState({selectedPresentation:event.target.value})
    }

    orderBySize = (listMap) => {
        let tmp = null
        let keys = Object.keys(listMap)
        for(let i = 0;i<keys.length;i++){
            for(let j = i+1; j<keys.length;j++){
                if( listMap[keys[i]].length<listMap[keys[j]].length ){
                    tmp =keys[j]
                    keys[j] = keys[i]
                    keys[i] = tmp
                }
            }
        }
        return keys
    }

    presentationSelect = () => {
        return (
            <select className="deckPageSelect" value={this.state.selectedPresentation} onChange={this.changeSelectedPresentation}>
                <option value="list">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.listPresentation:this.state.textsEn.listPresentation}</option>
                <option value="images">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.imagesPresentation:this.state.textsEn.imagesPresentation}</option>
            </select>
        )
    }

    displaySelect = () => {
        if (this.state.abaDeckDisplay==="block"){
            return (
                <select className="deckPageSelect" value={this.state.selectedDisplay} onChange={this.changeSelectedDisplay}>
                    <option value="default">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.defaultDisplay:this.state.textsEn.defaultDisplay}</option>
                    <option value="price">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.priceDisplay:this.state.textsEn.priceDisplay}</option>
                    <option value="cmc">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.cmcDisplay:this.state.textsEn.cmcDisplay}</option>
                    <option value="type">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.typeDisplay:this.state.textsEn.typeDisplay}</option>
                    <option value="categorie">{localStorage.getItem('countryCode')==='+55'?'Nome da Categoria':'Category Name'}</option>
                </select>
            )
        }
    }

    getPagePaddingLeft = () => {
        if (this.state.selectedPresentation==="list"){
            return "1%"
        }else{
            return "0px"
        }
    }

    getCursorSectionClassname = () => {
        if (window.innerWidth<=500){
            return "grid-container-deckPage2 deckPage"
        }
        if (this.state.selectedPresentation==="list"){
            return "grid-container-deckPage deckPage"
        }else if(this.state.selectedPresentation==="images"){
            return "grid-container-deckPage2 deckPage"
        }
    }

    orderByCmc = (cards) => {
        for(let i = 0;i<cards.length;i++){
            for(let j = i+1;j<cards.length;j++){
                if (cards[j].cmc<cards[i].cmc){
                    let tmp = cards[i]
                    cards[i] = cards[j]
                    cards[j] = tmp
                }
            }
        }
        return cards
    }

    changeSelectedImage = (card) => ()  => {
        if(card===null || card===undefined){
            return
        }

        let {displayHiddenCustomTypes} = this.state
        if (this.state.customTypeHash.hasOwnProperty(card.customcategorie)){
            displayHiddenCustomTypes[card.customcategorie]=this.state.customTypeHash[card.customcategorie]
        }
        if (card && this.state.selectedImage!==process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg'){            
            let nextClass = "";
            if (this.state.selectedImageClass=='scaleAnimation'){
                nextClass = "littleRotationLeft";
            }else if(this.state.selectedImageClass=='littleRotationLeft'){
                nextClass = "littleRotationRight";
            }else{
                nextClass = "scaleAnimation";
            }
            
            this.setState({selectedImageClass:nextClass,selectedImage:process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg',lastPrice:card.last_price,lastCategorie:card.customcategorie,selectedImageObject:card,displayHiddenCustomTypes})
        }
    }

    getPresentationName = (cardName) => {
        let split = cardName.split(';')
        return split[0]
    }


    isCommanderFormat = () => {
        if (this.state.loadedDecklistTuples[0].f0rmat==="Commander"||this.state.loadedDecklistTuples[0]==="Brawl"){
            return true;
        }else{
            return false;
        }
    }

    commanders = () => {
        let cardGroups = []
        let {commanders} = this.state
        if (commanders.length>0){
            cardGroups.push(
                <div style={{marginTop:"1%",marginBottom:'1%'}} key={"commanders"}>
                    <div className="typeLine">Commander</div>
                    {this.displayCardlist(commanders)}
                </div>
            )
        }
        return cardGroups
    }

    companions = () => {
        let cardGroups = []
        let {companions} = this.state
        if (companions.length>0){
            cardGroups.push(
                <div style={{marginTop:"1%", marginBottom:'1%'}} key={"companions"} >
                    <div className="typeLine">Companion</div>
                    {this.displayCardlist(companions)}
                </div>
            )
        }
        return cardGroups
    }


    orderByCategorieName = (listMap) => {
        let tmp = null
        let keys = Object.keys(listMap)
        for(let i = 0;i<keys.length;i++){
            for(let j = i+1; j<keys.length;j++){
                
                if( keys[i]>keys[j] ){
                    tmp =keys[j]
                    keys[j] = keys[i]
                    keys[i] = tmp
                }
            }
        }        
        return keys
    }

    getAllCards = () => {
        let hash = undefined;
        let keys = undefined;
        if (this.state.selectedDisplay==="default"){
            hash = this.state.customTypeHash
            keys = this.orderBySize(hash)
        } else if (this.state.selectedDisplay==="cmc"){
            hash = this.state.cmcHash
            keys = this.orderByConvertedCostLabel(hash)
        } else if (this.state.selectedDisplay==="type"){
            hash = this.state.typeCardHash
            keys = this.orderBySize(hash)
        } else if (this.state.selectedDisplay==="price"){
            hash = this.state.priceRangesHash
            keys = this.orderByPriceRange(hash)
        } else if (this.state.selectedDisplay==="categorie"){
            hash = this.state.customTypeHash
            keys = this.orderByCategorieName(hash)
        }
        let cardGroups = []
        
        for (let type of keys){
            if(hash[type].length===0){
                continue;
            }
            cardGroups.push(
                <div style={{marginBottom:"3%"}} key={type+Math.random(5000)}>
                    <div className="typeLine">{type} ({this.sumOfQuantitys(hash[type])})</div>
                    {this.displayCardlist(hash[type])}
                </div>
            )
        }
        return cardGroups
    }

    displayCardlist = (cards) => {
        cards = this.orderByCmc(cards)
        if (this.state.selectedPresentation==='list'){
            return this.getCards(cards)
        }else if (this.state.selectedPresentation==='images'){
            return (
                <div className="grid-container-deckPage3" >{this.getCardsImageMode(cards)}</div>

            )
        }
    }

    getCards = (cardList) => {
        let cards = []
        let color="#111111"
        for (let card of cardList){
            let linkHtml = undefined
            if (localStorage.getItem("countryCode")==="+55"&&card.name_pt!==card.cardname && card.name_pt.trim()!==""){
                if (parseInt(card.cmc)===parseInt(this.state.selectedCmc)||(card.isbanned===true && card.f0rmat==='Commander')){
                    color="#dc143c"

                    linkHtml = <nav className="generalLink" style={{color:color, fontSize:this.state.fontSize}} onClick = {this.changeSelectedImage(card)}>  <nav style={{color:color}}> {card.card_qtde} {this.getPresentationName(card.name_pt)}   </nav> </nav>
                }else{
                    color="#111111"
                    linkHtml = <nav className="generalLink" style={{fontSize:this.state.fontSize}} onClick = {this.changeSelectedImage(card)}>  <nav > {card.card_qtde} {this.getPresentationName(card.name_pt)}   </nav> </nav>
                }
            }else{
                if (parseInt(card.cmc)===parseInt(this.state.selectedCmc) || (card.isbanned===true && card.f0rmat==='Commander')){
                    color="#dc143c"

                    linkHtml = <nav className="generalLink" style={{color:color,fontSize:this.state.fontSize}}  onClick = {this.changeSelectedImage(card)}>  <nav style={{color:color}}> {card.card_qtde} {this.getPresentationName(card.cardname)}   </nav> </nav>
                }else{
                    color="#111111"
                    linkHtml = <nav className="generalLink" style={{fontSize:this.state.fontSize}}  onClick = {this.changeSelectedImage(card)}>  <nav > {card.card_qtde} {this.getPresentationName(card.cardname)}  </nav> </nav>
                }
            }

            cards.push(
                <div className="cardTextPresentationLine" key={card.cardname+Math.random() * 1000}>
                   {linkHtml}
                </div>
           )
        }
        return cards
    }

    flipImage2 = (cardname) => {
        let {flipCardsDisplay} = this.state
        let values = flipCardsDisplay[cardname]
        if (values[0]==="block"){
            flipCardsDisplay[cardname]=["none","block"]
        }else{
            flipCardsDisplay[cardname]=["block","none"]
        }
        this.setState({flipCardsDisplay})

    }

    getCardsImageMode = (cardList) => {
        let cards = []
        for (let card of cardList){       

            if (card.layout==="modal_dfc"||card.layout==="transform"){
                cards.push(
                    <div key={card.cardname+"ImageModal"+Math.random(1000)}>

                        <div className="decksPageOPContainer" key={"inner"+card.cardname+Math.random(2000)}>
                            <img alt={"cardImage"} style={{ borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:this.state.flipCardsDisplay[card.cardname][0],fontFamily:"arvo"}} onClick={() => { this.flipImage2(card.cardname) }} key={card.cardname} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg'}></img>
                            <div>
                                <img alt={card.anotherside} style={{ borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:this.state.flipCardsDisplay[card.cardname][1],fontFamily:"arvo"}} onClick={() => { this.flipImage2(card.cardname) }}  key={card.cardname} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.anotherside+'.jpg'}></img>
                            </div>
                            <div className="decksPageOPMiddle">
                                    <div className="decksPageOPImageCenterText" onClick={() => { this.flipImage2(card.cardname) }}><FontAwesomeIcon style={{marginRight:'2px'}} icon="sync"></FontAwesomeIcon> </div>
                            </div>
                        </div>

                        <div style={{fontSize:this.state.fontSize,textAlign:"center",marginBottom:"4%",width:this.state.imageWidth,color:parseInt(card.cmc)===parseInt(this.state.selectedCmc)||(card.isbanned===true && card.f0rmat==='Commander')?"#dc143c":'#111111'}}> <div>{card.card_qtde} {this.getPresentationName(card.cardname)}</div> <div> {this.state.priceHash[card.cardname]} R$</div> </div>

                    </div>
                )
            }else{
                cards.push(
                    <div key={card.cardname+"ImageMode"+Math.random(1000)}>
                        <img alt={"cardImage"} style={{ borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo"}} key={card.cardname} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg'}></img>
                        <div style={{fontSize:this.state.fontSize,textAlign:"center",marginBottom:"4%",width:this.state.imageWidth,color:parseInt(card.cmc)===parseInt(this.state.selectedCmc)||(card.isbanned===true && card.f0rmat==='Commander')?"#dc143c":'#111111'}}> <div>{card.card_qtde} {this.getPresentationName(card.cardname)}</div> <div> {this.state.priceHash[card.cardname]} R$</div> </div>
                    </div>
                )
            }

        }
        return cards
    }

    updateTypeCardHash = (typeCardHash, decklisttuple) => {
        const types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt','land']
        let lowerCaseType=decklisttuple['type_en'].toLowerCase();
        for (let tp of types){
            if (lowerCaseType.includes(tp)){
                typeCardHash[tp].push(decklisttuple)
                break;
            }
        }
        return typeCardHash
    }

    updateCustomTypeHash = (customTypeHash,decklisttuple) => {
        if (decklisttuple.customcategorie!=="" && decklisttuple.customcategorie !== null && decklisttuple.customcategorie !== undefined){
            if (!(decklisttuple.customcategorie in customTypeHash)){
                customTypeHash[decklisttuple.customcategorie]=[]
            }
            customTypeHash[decklisttuple.customcategorie].push(decklisttuple)
        }else{
            let types=['creature','artifact','enchantment','planeswalker','instant','sorcery','interrupt']
            let lowerCaseType=decklisttuple['type_en'].toLowerCase();
            for (let tp of types){
                if (lowerCaseType.includes(tp)){
                    if (!(tp in customTypeHash)){
                        customTypeHash[tp]=[]
                    }
                    customTypeHash[tp].push(decklisttuple)
                    break;
                }
            }
            if (lowerCaseType.includes('land') && !lowerCaseType.includes('artifact') && !lowerCaseType.includes('enchantment') && !lowerCaseType.includes('creature') && !lowerCaseType.includes('planeswalker')){
                if (!('land' in customTypeHash)){
                    customTypeHash['land']=[]
                }
                customTypeHash['land'].push(decklisttuple)
            }
        }
        return customTypeHash
    }

    loadBarChartData = (decklisttuple,qtdeHash,cmcHash) => {
        if (!cmcHash.hasOwnProperty(decklisttuple.cmc)){
            cmcHash[decklisttuple.cmc]=[]
        }
        cmcHash[decklisttuple.cmc].push(decklisttuple)
        if (decklisttuple.cmc!==0){
            if (!qtdeHash.hasOwnProperty(decklisttuple.cmc)){
                qtdeHash[decklisttuple.cmc]=0
            }
            qtdeHash[decklisttuple.cmc] += decklisttuple.card_qtde
        }
        return {'qtdeHash':qtdeHash,'cmcHash':cmcHash}
    }

    listOlderVersions = async () => {
        const axiosConfig = api.axiosConfig
        let message = localStorage.getItem("countryCode")==='+55'?'Carregando versões antigas...':'Loading old versions...'
        this.setState({bottomMessage:message})
        let data = {
            "player":this.props.match.params.user,
            "link":this.changeDeckName(this.props.match.params.deckName)
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/listOlderVersions',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200 && res.data.length>0) {
                let {allVersions,selectedVersion,greaterVersion,versionsClasses} = this.state
                greaterVersion = -1
                for (let decklisttuple of res.data){
                    if (allVersions.hasOwnProperty(decklisttuple.listversion)===false){
                        allVersions[decklisttuple.listversion]={}
                    }
                    if (greaterVersion<decklisttuple.listversion){
                        greaterVersion=decklisttuple.listversion
                    }
                    allVersions[decklisttuple.listversion][decklisttuple.cardname]=decklisttuple
                    versionsClasses[decklisttuple.listversion]="tablinks"
                }
                selectedVersion=greaterVersion
                versionsClasses[selectedVersion]="tablinks active"
                greaterVersion = greaterVersion+1

                allVersions[greaterVersion]={}
                for (let card of this.state.mainboard){
                    allVersions[greaterVersion][card.cardname]=card
                }
                for (let card of this.state.commanders){
                    allVersions[greaterVersion][card.cardname]=card
                }
                for (let card of this.state.companions){
                    allVersions[greaterVersion][card.cardname]=card
                }
                let message = localStorage.getItem("countryCode")==='+55'?'Versões antigas carregadas':'Old versions loaded'

                this.setState({bottomMessage:message,allVersions, selectedVersion,greaterVersion,versionsClasses})
            }
        })
    }

    loadDeckHeader =  () => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player":this.props.match.params.user,
            "link":this.props.match.params.deckName
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/getByLink',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200 && res.data.length>0) {                
                this.updateQtViews(res.data[0].player,res.data[0].deckname,res.data[0].qtviews+1)
                this.setState({onevidence:res.data[0].onevidence})
            }
        }).catch(err => {
            this.setState({deckHeaderOk:false})
        })
    }

    updateQtViews = (player,deckname,qtviews) => {
        const axiosConfig = api.axiosConfig
        axiosConfig.headers['authorization'] =  'Bearer '+ localStorage.getItem('mdtToken');
        let data = {
            "player":player,
            "deckname":deckname,
            "qtViews":qtviews

        }
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/updateQtdeViews',
            data: data, headers:axiosConfig.headers
        }).then( () => {
            this.setState({qtViews:qtviews})
        })
    }


    makeAbsoluteList = async () => {
        const axiosConfig = api.axiosConfig
        let data = {
            "player":this.props.match.params.user,
            "link":this.changeDeckName(this.props.match.params.deckName)
        }
        await axios({
            method: 'post',
            url: api.freeUrl + '/decklist/absoluteListLink',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200 && res.data.length>0) {
                let {customTypeHash,typeCardHash,displayCardNamesHash,cmcHash, cardsToFetch,flipCardsDisplay} = this.state // dicts {}
                let {commanders, companions,barChartData, mainboard, sideboard, maybeboard} = this.state // arrays []
                let mainboardStr = ""
                let deckname=""
                let commander = ""
                let companion = ""
                let priceHash = {}
                let qtdeHash = {}
                let totalOfCards = 0
                let totalOfLands = 0
                let avgCmc = 0
                let cardHash = {}
                let bannedCards = []
                let youtubeLink = ""
                let twitchLink = ""
                for (let decklisttuple of res.data){
                    let barTmpData= this.loadBarChartData(decklisttuple,qtdeHash,cmcHash)
                    if (deckname==="")
                        deckname=decklisttuple.deckname;
                    
                    qtdeHash=barTmpData.qtdeHash
                    cmcHash=barTmpData.cmcHash
                    if (isNaN(decklisttuple.last_price)===false && decklisttuple.last_price)
                        priceHash[decklisttuple.cardname]=decklisttuple.last_price;                        
                    else
                        priceHash[decklisttuple.cardname]=0;
                    youtubeLink=decklisttuple.youtubelink;
                    twitchLink=decklisttuple.twitchlink;
                    const displayCardValues = this.registerToString(decklisttuple)
                    cardsToFetch[displayCardValues.name]=""
                    cardHash[decklisttuple.cardname]=decklisttuple
                    displayCardNamesHash[decklisttuple.cardname]=displayCardValues.name
                    if (decklisttuple.layout==='modal_dfc'||decklisttuple.layout==='transform'){
                        flipCardsDisplay[decklisttuple.cardname]=["block","none"]
                    }
                    if (decklisttuple.listtype==='mainboard'){
                        if (decklisttuple.f0rmat==='Commander' && decklisttuple.isbanned===true){
                            bannedCards.push(decklisttuple)
                        }
                        mainboard.push(decklisttuple)
                        totalOfCards+=decklisttuple.card_qtde
                        if (decklisttuple.type_en.toLowerCase().includes('land') && decklisttuple.cmc===0){
                            totalOfLands+=decklisttuple.card_qtde
                        }
                        avgCmc+=decklisttuple.cmc*decklisttuple.card_qtde
                        mainboardStr+=displayCardValues.qtdeName
                        customTypeHash = this.updateCustomTypeHash(customTypeHash,decklisttuple)
                        typeCardHash = this.updateTypeCardHash(typeCardHash,decklisttuple)
                    }else if(decklisttuple.listtype==='sideboard'){
                        sideboard.push(decklisttuple)
                    }else if(decklisttuple.listtype==='maybeboard'){
                        maybeboard.push(decklisttuple)
                    }else if(decklisttuple.listtype==='commander'){
                        totalOfCards+=decklisttuple.card_qtde
                        commanders.push(decklisttuple)
                        commander+=displayCardValues.qtdeName
                    }else if(decklisttuple.listtype==='companion'){
                        totalOfCards+=decklisttuple.card_qtde
                        companions.push(decklisttuple)
                        companion+=displayCardValues.qtdeName
                    }
                }
                let cmcs = Object.keys(qtdeHash)
                for (let cmc of cmcs){
                    barChartData.push( {name:cmc,qtde:qtdeHash[cmc]} )
                }
                const deckString = commander+companion+mainboardStr
                avgCmc = avgCmc/(totalOfCards-totalOfLands)                
                this.setState({deckname,priceHash,flipCardsDisplay,loadedDecklistTuples:res.data,deckListOk:true,deckString,customTypeHash,commanders,companions,barChartData,cmcHash,mainboard,sideboard,maybeboard,cardsToFetch,displayCardNamesHash,totalOfCards,avgCmc,typeCardHash,cardHash,bannedCards,youtubeLink,twitchLink})
            }
        }).catch(err => {
            console.log(err)
            console.log(err.response.data)
            this.setState({deckListOk:false})
        })
    }

    getIsPrivateBox = () => {
        return(
            <>
                <label>
                    {localStorage.getItem("countryCode")==="+55"?"É um deck privado?":"It's a private deck?"}*
                </label>
                <select value={this.state.selectedPrivacy} onChange={this.changeSelectedPrivacy}>
                    <option value="no">{localStorage.getItem('countryCode')==='+55'?'Não':'No'}</option>
                    <option value="yes">{localStorage.getItem('countryCode')==='+55'?'Sim':'Yes'}</option>

                </select>
            </>
        )
    }

    updateCardsPricesfromShopbotServer = async () => {
        const axiosConfig = api.axiosConfig
        let data = {
            "cardList":this.state.deckString
        }
        await axios({
            method: 'post',
            url: api.shopbotUrl + '/update',
            data: data, headers:axiosConfig.headers
        }).then(res => {            
            this.loadCardPrices()
        })
    }

    loadCardPrices = () => {
        const axiosConfig = api.axiosConfig
        let {cardsToFetch,displayCardNamesHash,mainboard,sideboard,maybeboard,commanders,companions} = this.state
        axios({
            method: 'post',
            url: api.shopbotUrl + '/read',
            data: {
                "cardHash":cardsToFetch
            }, headers:axiosConfig.headers
        }).then(res => {
            let {priceHash} = this.state
            let cards=[]
            if (res.status === 200 ) {
                let priceRangesHash = {'0 < 1':[],'1 < 2':[],'2 < 5':[],'5 < 10':[],'10 < 20':[],'> 20':[]}
                for(let decklisttuple of mainboard){                  
                  const displayName = displayCardNamesHash[decklisttuple.cardname]
                  if (res.data.hasOwnProperty(decklisttuple.cardname)){
                      priceHash[decklisttuple.cardname] = res.data[decklisttuple.cardname]
                      cards.push([decklisttuple.cardname, res.data[decklisttuple.cardname]])
                      if (res.data[decklisttuple.cardname] <= 1){
                        priceRangesHash['0 < 1'].push(decklisttuple)
                      } else if (res.data[decklisttuple.cardname] <= 2){
                        priceRangesHash['1 < 2'].push(decklisttuple)
                      } else if (res.data[decklisttuple.cardname] <= 5){
                        priceRangesHash['2 < 5'].push(decklisttuple)
                      } else if (res.data[decklisttuple.cardname] <= 10){
                        priceRangesHash['5 < 10'].push(decklisttuple)
                      } else if (res.data[decklisttuple.cardname] <= 20){
                        priceRangesHash['10 < 20'].push(decklisttuple)
                      } else {
                        priceRangesHash['> 20'].push(decklisttuple)
                      }
                  } else if (res.data.hasOwnProperty(displayName)){
                      priceHash[decklisttuple.cardname] = res.data[displayName]
                      cards.push([decklisttuple.cardname, res.data[displayName]])
                      if (res.data[displayName] <= 1){
                        priceRangesHash['0 < 1'].push(decklisttuple)
                      } else if (res.data[displayName] <= 2){
                        priceRangesHash['1 < 2'].push(decklisttuple)
                      } else if (res.data[displayName] <= 5){
                        priceRangesHash['2 < 5'].push(decklisttuple)
                      } else if (res.data[displayName] <= 10){
                        priceRangesHash['5 < 10'].push(decklisttuple)
                      } else if (res.data[displayName] <= 20){
                        priceRangesHash['10 < 20'].push(decklisttuple)
                      } else {
                        priceRangesHash['> 20'].push(decklisttuple)
                      }
                  }
                }
                for(let decklisttuple of sideboard){
                    const displayName = displayCardNamesHash[decklisttuple.cardname]
                    if (res.data.hasOwnProperty(displayName)){
                        cards.push([decklisttuple.cardname, res.data[displayName]])
                        priceHash[decklisttuple.cardname] = res.data[displayName]
                    }
                }
                for(let decklisttuple of maybeboard){
                    const displayName = displayCardNamesHash[decklisttuple.cardname]
                    if (res.data.hasOwnProperty(displayName)){
                        cards.push([decklisttuple.cardname, res.data[displayName]])
                        priceHash[decklisttuple.cardname] = res.data[displayName]
                    }
                }
                for(let decklisttuple of commanders){
                    const displayName = displayCardNamesHash[decklisttuple.cardname]
                    if (res.data.hasOwnProperty(displayName)){
                        cards.push([decklisttuple.cardname, res.data[displayName]])
                        priceHash[decklisttuple.cardname] = res.data[displayName]
                    }
                }
                for(let decklisttuple of companions){
                    const displayName = displayCardNamesHash[decklisttuple.cardname]
                    if (res.data.hasOwnProperty(displayName)){
                        cards.push([decklisttuple.cardname, res.data[displayName]])
                        priceHash[decklisttuple.cardname] = res.data[displayName]
                    }
                }
                this.updateCardPrices(cards)
                this.updateDeckPrice(priceHash)
                this.setState({priceHash,priceRangesHash,loadedPrices:true})
            }
        }).catch(err => {
            console.log(err)
            console.log(err.response)
        })
    }

    updateStoresAppearances  = (id,appearances) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "id":id,
            "appearances":appearances
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/ligastore/appearances',
            data: data, headers:axiosConfig.headers
        })
    }

    updateCardPrices  = (cardsArray) => {
        const axiosConfig = api.axiosConfig
        axios({
            method: 'put',
            url: api.freeUrl + '/card/updatePrices',
            data: {"cardList":cardsArray}, headers:axiosConfig.headers
        })
    }

    updateDeckPrice = (priceHash) => {
        const axiosConfig = api.axiosConfig
        let last_price=this.totalPriceLocal(priceHash);        
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/updateLastPrice',
            data: {"player":this.props.match.params.user,"deckname":this.state.deckname,"last_price":last_price}, headers:axiosConfig.headers
        })
    }

    updateClicks  = (id,clicks) => {
        const axiosConfig = api.axiosConfig
        let data = {
            "id":id,
            "clicks":clicks
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/ligastore/clicks',
            data: data, headers:axiosConfig.headers
        })
    }

  
    findBestCombination = async () => {
        let {textsEn,textsPt} = this.state
        textsPt.loadingMessage = "Buscando preços nas lojas... Isto pode demorar um pouco"
        textsEn.loadingMessage = "Fetching card prices on stores, this may take a while"

        this.setState({loadingDisplay:'block',clickedButton1:true,textsEn,textsPt})
        const axiosConfig = api.axiosConfig
        let data = {
            "cardList":this.state.deckString
        }

        
        await axios({
            method: 'post',
            url:Math.floor(Math.random()*2)!=0?api.shopbotUrlB+'/update':api.shopbotUrl+'/update',
            data: data, headers:axiosConfig.headers
        }).then( res => {
            textsPt.loadingMessage = "Buscando a melhor combinação de lojas, pode demorar de 1 a 5 minutinhos."
            textsEn.loadingMessage = "Fetching the best combination of stores to buy, this can take about 5 minutes."
            this.setState({textsEn,textsPt,loadingBarColor:'#FF851B'})
        })
        this.loadCardPrices()
        await axios({
            method: 'post',
            url: Math.floor(Math.random()*2)!=0?api.shopbotUrlB+'/solver':api.shopbotUrl+'/solver',
            data: data, headers:axiosConfig.headers, timeout:900000
        }).then(res => {
            if (res.status === 200 ) {
                textsPt.loadingMessage = "Carregando..."
                textsEn.loadingMessage = "Loading..."
                if (res.data.status===1){
                    for (let storeKey in res.data.stores){
                        if (this.state.ligaStoresHash.hasOwnProperty(storeKey)===false)
                            continue;                        
                        this.updateStoresAppearances(this.state.ligaStoresHash[storeKey].id,this.state.ligaStoresHash[storeKey].appearances+1)
                    }
                    this.setState({textsEn,textsPt, loadingBarColor:'#8884d8',lastValue:res.data.finalValue,stores:res.data.stores,displayStrsTabs:"block",loadingDisplay:'none'})
                }else{
                    this.setState({textsEn,textsPt, loadingBarColor:'#8884d8',lastValue:-1,stores:[],loadingDisplay:'none'})
                }
            }
        }).catch(err => {
            console.log(err)
            console.log(err.response)
            this.setState({loadingDisplay:'none'})
        })
    }

    showBestStores = () => {
        this.setState({promoteModalIsOpen:false,lastValue:this.state.bestValue,stores:this.state.bestStores})
    }

    loadUser = () =>{
        const axiosConfig = api.axiosConfig;
        let data = {
            "nickname": localStorage.getItem('mdtUser'),
        }
        axios({
            method: 'post',
            url: api.freeUrl + '/player/get',
            data: data, headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                if (res.data.length>0){
                    localStorage.setItem('mdtConf',res.data[0].ispremium)
                    this.setState({ispremium:res.data[0].ispremium,iscreator:res.data[0].iscreator})
                }else{
                    this.setState({ispremium:false})
                }
            }
        }).catch( (err) => {
            console.log(err)
            this.setState({ispremium:false})
        })
    }

    loadLigaStores = () =>{
        const axiosConfig = api.axiosConfig;
         axios({
            method: 'get',
            url: api.freeUrl + '/ligastore',
            headers:axiosConfig.headers
        }).then(res => {
            if (res.status === 200) {
                this.setState({ligaStoresHash:res.data});
            }
        })
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidMount = async () => {

        try{
            this.loadUser()
            this.loadLigaStores()
            this.loadDeckHeader()
            await this.makeAbsoluteList()
            
            if (this.state.deckListOk===true){
                try{
                    this.updateCardsPricesfromShopbotServer()
                }catch(e){
                    console.log('failed on update cards prices from api')
                    console.log(e)
                }

                if (window.innerWidth<500){
                    this.setState({selectedPresentation:"images",fontSize:'8px'})
                }

            }else{
                this.setState({problemOnLoad:true})
            }

        }catch(e){
            console.log(e)
            this.setState({problemOnLoad:true})
        }
    }

    loadingPricesText = () => {
        if (this.state.loadedPrices===false){
            return <div style={{fontWeight:'800',padding:'5px'}}>{localStorage.getItem('countryCode')==='+55'?'Carregando novos preços das cartas...':'Loading new card prices...'}</div>
        }
    }



    displayTab = () => {
        if (this.state.maybeboard.length>0 || this.state.sideboard.length>0){
            return (
                <div className="tab">
                        <button className={this.state.buttonDeckClass} onClick={this.showDeckTab}>Deck</button>
                        {(this.isCommanderFormat())?<></>:<button className={this.state.buttonSideboardClass} onClick={this.showSideboardTab}>Sideboard</button>}
                        { this.state.maybeboard.length>0?<button className={this.state.buttonMaybeboardClass} onClick={this.showMaybeboardTab}>Maybeboard</button>:<></>}
                </div>
            )
        }
    }

    getMaybeboardWindow = () => {
        return (
            <div style={{display:this.state.abaMaybeboardDisplay,width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-(window.innerWidth*0.03):window.innerWidth}}>
                    <div className={this.getCursorSectionClassname()}>
                        {this.displayCardlist(this.state.maybeboard)}
                    </div>
                    {this.getDeckInfo()}
            </div>
        )
    }

    getSideboardWindow = () => {
        if (this.isCommanderFormat() || this.state.sideboard.length===0){
            return
        }
        return (
            <div style={{display:this.state.abaSideboardDisplay,width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-window.innerWidth*0.03:window.innerWidth}}>
                    <div className={this.getCursorSectionClassname()}>
                        {this.displayCardlist(this.state.sideboard)}
                    </div>
                    {this.getDeckInfo()}
            </div>
        )
    }

    getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy+'-'+mm+'-'+dd
    }

    removeTrashDecks = () => {
        let axiosConfig = api.axiosConfig;
        axios({
            method: 'post',
            url: api.freeUrl + '/deck/dropIrrelevantOnes',
            data: {}, headers:axiosConfig.headers
        }).catch((e)=>{
            console.log(e)
        })
    }

    generateLink = (maincard) => {        
        let split = maincard.split(' ')
        let decklink = split[0].replace(/,/g,'')
        decklink = decklink.replace(/-/g,'')
        return decklink
    }

    copyDeck = async () => {
        let {loadedDecklistTuples} = this.state
        this.setState({clicledCopyDeck:true})
        await this.insertDeckList(localStorage.getItem('mdtUser'),loadedDecklistTuples[0].maincard,loadedDecklistTuples[0].maincard2,loadedDecklistTuples[0].colors,loadedDecklistTuples[0].f0rmat,loadedDecklistTuples[0].description,0,this.getCurrentDate(),0, false, loadedDecklistTuples[0].deckname.trim(),this.getCurrentDate(),this.state.mainboard, this.state.sideboard, this.state.maybeboard, loadedDecklistTuples[0].f0rmat, this.generateLink(loadedDecklistTuples[0].maincard))
        if (this.state.deckInsertOk===false){
            this.removeTrashDecks()
            return;
        }
        this.removeTrashDecks()
        let {link} = this.state
        this.props.history.push('/users/'+localStorage.getItem('mdtUser')+'/decks/'+this.changeDeckName(link))
        document.location.reload()
      }

    saleButton = () => {
        if (isLoggedUser(this.props.match.params.user)){
           if (this.state.loadedDecklistTuples[0].onsale===true){
                return <nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.cancelSaleAction}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.removeFromSale:this.state.textsEn.removeFromSale}</nav>
           }else{
                return <nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.saleAction}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.sell:this.state.textsEn.sell}</nav>
           }
        }
        return <></>
    }

    displayVersionsTab = () => {
        let buttons = []
        let {allVersions} = this.state
        let keys = Object.keys(allVersions)
        for (let i=0;i<keys.length-1;i++){
            let version = keys[i]
            if(i===keys.length-2){
                buttons.push(<button key={version}  className={this.state.versionsClasses[version]} onClick={this.setActiveVersion(version)}> Version {version} </button> )
            }else{
                buttons.push(<button key={version}  className={this.state.versionsClasses[version]} onClick={this.setActiveVersion(version)}> Version {version} </button> )
            }
        }
        return (
            <div className="tab">
                {buttons}
            </div>
        )
    }

    setActiveVersion = (version) => () => {
        let {versionsClasses} = this.state
        let keys = Object.keys(versionsClasses)
        let selectedVersion = ""
        for (let ver of keys){
            versionsClasses[ver] = "tablinks"
        }
        if (version){
            versionsClasses[version] = "tablinks active"
            selectedVersion = version
            this.setState({versionsClasses:versionsClasses,selectedVersion})
        }
    }

    getDateOfVersion = (version) => {
        if (this.state.allVersions[version]){
            let keys = Object.keys(this.state.allVersions[version])
            if (keys.length<=0){
                return ;
            }
            return (
                <div style={{backgroundColor:"#f1f1f1",padding:"1.5%",textAlign:"center", margin:"1px"} }> <div style={{fontWeight:"bold"}}> {this.state.allVersions[version][keys[0]].listdate} </div>   </div>
            )
        }
    }

    getAllCardsFromSelectedVersion = () => {
        let {allVersions,selectedVersion} = this.state
        let deckList = allVersions[selectedVersion]
        let out = []
        let customTypeHash  = {}
        for (let card in deckList){
            if (!customTypeHash.hasOwnProperty(deckList[card].customcategorie)){
                customTypeHash[deckList[card].customcategorie]=[]
            }
            customTypeHash[deckList[card].customcategorie].push(deckList[card])
        }
        let keys = this.orderBySize(customTypeHash)
        if (this.state.selectedPresentation==='images'){
            for (let categorie of keys){

                out.push(
                    <div key={categorie+Math.random(10000)}>
                        <div className="typeLine" >{categorie} ({this.sumOfQuantitys(customTypeHash[categorie])})</div>
                        <div className="grid-container-deckPage3">
                            {this.listImages(customTypeHash[categorie])}
                        </div>
                    </div>
                )
            }
        }else if (this.state.selectedPresentation==='list'){
            for (let categorie of keys){
                out.push(
                <div key={categorie} style={{marginBottom:"1%", marginLeft:"1%"}}>
                    <div className="typeLine">{categorie} ({this.sumOfQuantitys(customTypeHash[categorie])})</div>
                    {this.getCardsFromVersionCustomCat(customTypeHash[categorie])}
                </div>)
            }
        }
        return out
    }

    getCardsFromVersionCustomCat = (customCat) => {
        let out = []
        for (let card of customCat){
            out.push( <div key={card.cardname}> <nav className="generalLink" onMouseEnter = {this.changeSelectedImage(card)}>  <nav > {card.card_qtde} {this.getPresentationName(card.cardname)}  </nav> </nav>    </div>)
        }
        return out
    }

    getVersionWindows = () => {
        let diff = this.compareSelectedVersionToLastVersion()
        return (
            <div style={{margin:"1%"}}>
                {this.getDateOfVersion(this.state.selectedVersion)}
                <div className=  {this.state.selectedPresentation==='images'?"block":"twoColumnGrid"}>
                    <div style={ {display: diff.addedCards.length>0?"block":"none" } }>
                        <div className="typeLine">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.addedCards:this.state.textsEn.addedCards}</div>
                        {this.state.selectedPresentation==='images'?<div className="grid-container-deckPage3">{this.listImagesCrdsInOut(diff.addedCards,diff.addedHash)}</div>:this.getAddRmCardsFromList(diff.addedCards,diff.addedHash)}
                    </div>
                    <div style={{display: diff.removedCards.length>0?"block":"none" }}>
                        <div className="typeLine">{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.removedCards:this.state.textsEn.removedCards}</div>
                        {this.state.selectedPresentation==='images'?<div className="grid-container-deckPage3">{this.listImagesCrdsInOut(diff.removedCards,diff.removedHash)}</div>:this.getAddRmCardsFromList(diff.removedCards,diff.removedHash)}
                    </div>
                </div>

                <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}> Deck </div>
                <div className={this.getCursorSectionClassname()}>
                    {this.getAllCardsFromSelectedVersion()}
                </div>
            </div>
        )
    }

    listImagesCrdsInOut = (cardlist,qtHash) =>{
        let images = []
        for(let card of cardlist){
            images.push(
                <div key={card+Math.floor(5000)}>
                    <img alt="cardImage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo",padding:"2px"}} key={card.cardname} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg'}></img>
                    <div style={{textAlign:"center",marginBottom:"4%",width:this.state.imageWidth,fontSize:this.state.fontSize}}> {qtHash[card.cardname]} {this.getPresentationName(card.cardname)} </div>
                </div>
             )
        }
        return images
    }

    listImages= (cardlist) =>{
        let images = []
        for(let card of cardlist){
            console.log(process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg')
            images.push(
                <div key={card+Math.floor(5000)}>
                    <img alt="cardImage" style={{ border:"5px", borderRadius:"5px", width:this.state.imageWidth,height:this.state.imageHeight,display:"block",fontFamily:"arvo",padding:"2px"}} key={card.cardname} src={process.env.REACT_APP_BACKENDURL+'/images/border_crop_HD/'+card.edition+'/en/'+card.cardname+'.jpg'}></img>
                    <div style={{textAlign:"center",marginBottom:"4%",width:this.state.imageWidth,fontSize:this.state.fontSize}}> {card.card_qtde} {this.getPresentationName(card.cardname)} </div>
                </div>
             )
        }
        return images
    }

    getAddRmCardsFromList = (customCat,cardQtHash) => {
        let out = []
        for (let card of customCat){

            out.push( <div key={card.cardname}> <nav className="generalLink" onMouseEnter = {this.changeSelectedImage(card)}>  <nav > {cardQtHash[card.cardname]} {this.getPresentationName(card.cardname)}  </nav> </nav>    </div>)
        }
        return out
    }

    compareSelectedVersionToLastVersion = () => {
        let {allVersions,selectedVersion,greaterVersion} = this.state
        let selectedOne = allVersions[selectedVersion]
        let deckCardsHash = allVersions[greaterVersion]
        let addedObjects = {}
        let addedHash = {}
        let removedObjects = {}
        let removedHash = {}

        for (let updatedCard in deckCardsHash){
            if (selectedOne && (updatedCard in selectedOne===false)){
                if (deckCardsHash[updatedCard].iscommander===0 && deckCardsHash[updatedCard].iscompanion===0){

                    addedObjects[updatedCard]=deckCardsHash[updatedCard]
                    if(addedHash.hasOwnProperty(updatedCard)){
                        addedHash[updatedCard]+=deckCardsHash[updatedCard].card_qtde
                    }else{
                        addedHash[updatedCard]=deckCardsHash[updatedCard].card_qtde
                    }
                }
            }else{
                if(deckCardsHash[updatedCard].card_qtde>selectedOne[updatedCard].card_qtde){
                    addedObjects[updatedCard]=deckCardsHash[updatedCard]
                    addedHash[updatedCard]=deckCardsHash[updatedCard].card_qtde-selectedOne[updatedCard].card_qtde
                }
            }
        }
        for (let card in selectedOne){
            if ( deckCardsHash && (card in deckCardsHash===false)){

                removedObjects[card]=selectedOne[card]
                if(removedHash.hasOwnProperty(card)){
                    removedHash[card]+=selectedOne[card].card_qtde
                }else{
                    removedHash[card]=selectedOne[card].card_qtde
                }
            }else{
                if(deckCardsHash[card].card_qtde<selectedOne[card].card_qtde){
                    removedObjects[card]=deckCardsHash[card]
                    removedHash[card]=selectedOne[card].card_qtde-deckCardsHash[card].card_qtde
                }
            }
        }
        return {removedCards:Object.values(removedObjects),addedCards:Object.values(addedObjects), addedHash,removedHash}
    }

    showVersionsTabs = async () => {
        if(this.state.allVersions!=={}){
            await this.listOlderVersions()
        }
        if (this.state.displayVerTabs==="none"){
            this.setState({displayVerTabs:"block"})
        }else if(this.state.displayVerTabs==="block") {
            this.setState({displayVerTabs:"none"})
        }
    }

    downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([this.state.deckString],{type: 'text/plain;charSet=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = this.props.match.params.deckName+".txt";
        document.body.appendChild(element);
        element.click();
    }

    renderPlaytestButton = () => {
        
        return <Link  style={{color:'snow',textDecoration:'none'}} className="deckPageButton" to={"/users/"+this.props.match.params.user+"/decks/"+this.changeDeckName(this.props.match.params.deckName)+'/playtest'} >Playtest</Link>
    }

    renderShareButton = () => {
        return (
            <CopyToClipboard style={{margin:'0px'}} text={'https://commanderdecks.com/users/'+this.props.match.params.user+'/decks/'+this.props.match.params.deckName}
                onCopy={() => this.setState({copied: true,copyLinkModalIsOpen:true})}>
                <nav style={{color:'snow',textDecoration:'none',marginLeft:'1px'}} className="deckPageButton" >{localStorage.getItem('countryCode')==="+55"?"Compartilhar":"Share"}</nav>
            </CopyToClipboard>
        )
    }


    renderButtons = () => {
        return (
          <div>
             <div style={{marginLeft:"1%",marginBottom:"1%",width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-(window.innerWidth*0.03):window.innerWidth*0.98}}>
                     { isLoggedUser(this.props.match.params.user)? <Link style={{color:'snow',textDecoration:'none'}} to={"/editDeck/"+this.props.match.params.user+'/'+this.changeDeckName(this.props.match.params.deckName)} className="deckPageButton">{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.editdeck:this.state.textsEn.editdeck}</Link>:<></> }
                     { this.state.loadedDecklistTuples[0].onsale===true?<a target="popup" href={this.state.loadedDecklistTuples[0].salelink} style={{color:'snow',textDecoration:'none'}} className="deckPageButton" >{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.playerPurchase:this.state.textsEn.playerPurchase}</a>:<></> }
                     {this.state.clickedButton1?<nav style={{color:'snow',textDecoration:'none',backgroundColor:"rgba(28,87,45,0.9)"}} className="deckPageButton" disabled >{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.purchase:this.state.textsEn.purchase}</nav>:<nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.findBestCombination} >{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.purchase:this.state.textsEn.purchase}</nav>}
                     {this.state.clicledCopyDeck===false? userOnLocalStorage()?<nav style={{color:'snow',textDecoration:'none'}}className="deckPageButton" onClick={this.copyDeck}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.copy:this.state.textsEn.copy}</nav>:<></>: userOnLocalStorage()?<nav style={{color:'snow',textDecoration:'none',opacity:'0.5'}}className="deckPageButton">{localStorage.getItem('countryCode')==="+55"?"Copiando...":"Copying..."}</nav>:<></> }
                     <nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.downloadTxtFile}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.export:this.state.textsEn.export}</nav>
                     {this.state.loadedDecklistTuples[0].listversion>0?<nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.showVersionsTabs}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.history:this.state.textsEn.history}</nav>:<></>}
                     {this.renderPlaytestButton()}
                     {this.renderShareButton()}
                     {this.promoteButton()}
                     { isLoggedUser(this.props.match.params.user)? <nav style={{color:'snow',textDecoration:'none'}} className="deckPageButton" onClick={this.deleteDeckAction}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.deleteDeck:this.state.textsEn.deleteDeck}</nav> :<></> }
             </div>
             <div style={{ display:this.state.displayVerTabs,  width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-(window.innerWidth*0.03):window.innerWidth*0.98}}>
                 {this.displayVersionsTab()}
                 {this.getVersionWindows()}
             </div>

             <div style={{ display:this.state.displayStrsTabs,  width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-(window.innerWidth*0.03):window.innerWidth*0.98}}>
                 <div style={{textAlign:"center",fontWeight:"10px",fontSize:"20px",marginTop:"1%",backgroundColor:"#333",color:"snow",padding:'3px',marginBottom:"1%"}}>   {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.totalValue:this.state.textsEn.totalValue}:{this.state.lastValue.toFixed(2)} </div>
                 {this.getStoresWindows()}
             </div>
             {this.deleteModal()}
             {this.saleModal()}
             {this.cancelSaleModal()}
             {this.promoteModal()}
             {this.promoteModal2()}
             {this.promoteDeckModal()}
             {this.LinkCopyedModal()}
             {this.purchaseFromPlayerModal()}

         </div>)
    }

    getDeckInfo = () => {
        const { activeIndex } = this.state;
        return (
            <div style={{marginBottom:"2%"}}>
                <div style={{display:"inline-block",textAlign:"center", width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-100:window.innerWidth*0.99}}>{localStorage.getItem('countryCode')==="+55"?this.state.textsPt.barchartTitle:this.state.textsEn.barchartTitle}</div>
                    <BarChart width={ this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-100:window.innerWidth*0.97} height={100} data={this.state.barChartData}>
                        <XAxis dataKey="name" stroke="#8884d8" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="qtde" onClick={this.handleClick}>
                            {
                                this.state.barChartData.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} label/>
                                ))
                            }
                        </Bar>
                    </BarChart>
                    <div style={{display:"inline-block",textAlign:"center", width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-10+"px":window.innerWidth*0.99}}>CMC</div>

                    <div className="grid-container-deckPage">
                        <div style={{backgroundColor:"#f1f1f1",padding:"1.5%",textAlign:"center", margin:"1px"} }> <div style={{fontWeight:"bold"}}> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.totalCards:this.state.textsEn.totalCards}</div> {this.state.totalOfCards}</div>
                        <div style={{backgroundColor:"#f1f1f1",padding:"1.5%",textAlign:"center",margin:"1px"}}> <div style={{fontWeight:"bold"}}>{localStorage.getItem('countryCode')==='+55'?this.state.textsPt.averageCmc:this.state.textsEn.averageCmc}</div> {this.state.avgCmc.toFixed(2)}</div>
                        <div style={{backgroundColor:"#f1f1f1",padding:"1.5%",textAlign:"center",margin:"1px"}}> <div style={{fontWeight:"bold"}}> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.totalLabel:this.state.textsEn.totalLabel} </div> {this.totalPrice().toFixed(2)}</div>
                    </div>
                    {this.getSalePriceDisplay()}

            </div>
        )
    }


    getDeckWindow = () => {
        const { activeIndex } = this.state;
        return (
            <div style={{display:this.state.abaDeckDisplay,width: this.state.selectedPresentation==="list"?window.innerWidth-this.state.imageWidth-(window.innerWidth*0.03):window.innerWidth*0.99}} >
                
                <div className="grid-container-deckPage deckPage">
                    {this.commanders()}
                    {this.companions()}
                </div>
                <div className={this.getCursorSectionClassname()}>
                    {this.getAllCards()}
                </div>
                
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        )
    }

    getHiddenImgs = () => {
        let out = []
        for (let customTypeList in this.state.displayHiddenCustomTypes){
            out.push(
                <div key={customTypeList+Math.random(10000)} style={{visibility:'hidden',display:'none'}}>
                    {this.getCardsImageMode(this.state.displayHiddenCustomTypes[customTypeList])}
                </div>
            )
        }
        return out
    }

    showDefaultCardImage = () => {
        let {selectedImageObject} = this.state;
        if (selectedImageObject===null || selectedImageObject===undefined){
            return
        }
        if (selectedImageObject["layout"]==="split"){
            return <img key={this.state.selectedImage} alt="cardImage" style={{width:this.state.imageWidth,height:this.state.imageWidth,transform:"rotate(90deg)",animation:'fadein 3s'}}  src={this.state.selectedImage} className={this.state.selectedImageClass}></img>
        }
        return (
            <img key={this.state.selectedImage} alt="cardImage" style={{width:this.state.imageWidth,height:this.state.imageHeight}} className={this.state.selectedImageClass} src={this.state.selectedImage}></img>
        );
    }


    flipImageButton = () => {
        let {selectedImageObject} = this.state;
        if (selectedImageObject===null || selectedImageObject===undefined){
            return
        }
        if (selectedImageObject["layout"]==="modal_dfc"||selectedImageObject["layout"]==="transform"){
            return (
                <div>
                    <button style={{width:this.state.imageWidth+'px'}} onClick={this.flipImage}> {localStorage.getItem('countryCode')==='+55'?'Virar':'Flip'} </button>
                </div>
            )
        }
    }

    flipImage = () => {
        let {selectedImage,selectedImageObject} = this.state
        let parts = selectedImage.split('/')
        let name = parts[parts.length-1].replace('.jpg','')
        name = name.trim()
        let newSelected = ""
        let cardParts = selectedImage.split(';')
        for (let i = 0;i<parts.length-1;i++){
            newSelected+=parts[i]+'/'
        }

        if (name===selectedImageObject.cardname){
            newSelected+=cardParts[1].trim()
        }else{
            newSelected+=selectedImageObject.cardname+'.jpg'
        }

        this.setState({selectedImage:newSelected})
    }

    topPropaganda = () => {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
        if(localStorage.getItem('mdtConf')==="true"||this.state.showGoogleAdd===false||localStorage.getItem('mdtDate')===date){
            return <></>
        }        
        let width = window.innerWidth*0.6
        let margin = window.innerWidth*0.2
        if (this.state.selectedPresentation==='list'){
            margin="1px"
        }
        return (
            <div>
               <div onClick={()=>{localStorage.setItem('mdtDate',date)}} style={{marginTop:'3px'}} >
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 105, marginLeft:margin }}                      
                      format=''
                    />
                </div>
            </div>
        )  
    }

    insidePropaganda = () => {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
        if(localStorage.getItem('mdtConf')==="true"||this.state.showGoogleAdd===false||localStorage.getItem('mdtDate')===date){
            return <></>
        }        
        let width = window.innerWidth*0.5
        let margin = window.innerWidth*0.24
        if (this.state.selectedPresentation==='list'){
            margin=window.innerWidth*0.24
        }
        return (
            <div>
               <div onClick={()=>{localStorage.setItem('mdtDate',date)}} style={{marginTop:'3px'}} >
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:margin }}                      
                      format=''
                    />
                </div>
            </div>
        )  
    }

    footerPropaganda = () => {
        if(localStorage.getItem('mdtConf')==="true"){
            return <></>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.2
        return (
            <div>
               <div style={{marginTop:'3px',marginBottom:'3px'}}>
                    <AdSense.Google
                      client='ca-pub-9649956420997697'
                      slot='7996053677'
                      style={{ width: width, height: 100, marginLeft:window.innerWidth*0.2 }}
                      format=''
                    />
                </div>
            </div>
        )  
    }

    twitchComponent = () =>{
        if (this.state.twitchLink===""||this.state.twitchLink===null||this.state.twitchLink===undefined){
            return (<></>);
        }
        let link = this.state.twitchLink
        let defaultSize=0.74;
        if (window.innerWidth<=600){
            defaultSize=0.95;
        }
        return (
            <div className="opTitleArea2" style={{marginLeft:"2%", marginBottom:"2%"}}>                
                <label style={{width:window.innerWidth*defaultSize}}>
                    <FontAwesomeIcon icon={["fab","twitch"]}></FontAwesomeIcon> {localStorage.getItem("countryCode")==="+55"?"Vídeo do deck":"Deck video"}
                </label>
                <div>
                <ReactPlayer
                    url={link}
                    height={window.innerWidth*defaultSize*0.55}
                    width={ window.innerWidth*defaultSize}
                    controls
                />
                </div>      
            </div>
        );
    }

    YouTubeGetID = (urlIn) => {
        let url = urlIn.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    youtubeComponent = () =>{            
        if (this.state.youtubeLink===""||this.state.youtubeLink===null||this.state.youtubeLink===undefined){
            return (<></>);
        }
        let defaultSize=0.74;
        if (window.innerWidth<=600){
            defaultSize=0.95;
        }
        const opts = {
            height: window.innerWidth*defaultSize*0.55,
            width: window.innerWidth*defaultSize,
            playerVars: {              
              autoplay: 0,
            },
        };
        return (
            <div className="opTitleArea" style={{marginLeft:"2%"}}>
                
                <label style={{width:window.innerWidth*defaultSize}}>
                    <FontAwesomeIcon icon={["fab","youtube"]}></FontAwesomeIcon> {localStorage.getItem("countryCode")==="+55"?"Vídeo do deck":"Deck video"}
                </label>
                <YouTube videoId={this.YouTubeGetID(this.state.youtubeLink)} opts={opts} onReady={this._onReady} />
                
            </div>
        );
    }

    shieldComponent = () => {
        let defaultSize=0.74;
        let dragonShieldColor='#ece8e8';
        let centralColor='#ece8e8';
        let bucaneirosColor='#ece8e8';
        let deckPrice = this.totalPriceLocal(this.state.priceHash);
        if (deckPrice<=50){
            bucaneirosColor='#d6d2d2';
        }else if(deckPrice<=500){
            centralColor='#d6d2d2';
        }else{
            dragonShieldColor='#d6d2d2';
        }
        if (window.innerWidth<=600){
            defaultSize=0.95;
        }
        
        return (
            <div className="opTitleArea3" style={{marginLeft:"2%"}}>
                <label style={{width:window.innerWidth*defaultSize}}>{localStorage.getItem('countryCode')==='+55'?'Shield apropriado':'Ideal shield'}</label>
                <nav className="shieldArea" style={{backgroundColor:dragonShieldColor,display:'inline-block',width:window.innerWidth*defaultSize/3}}>
                    <a target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Dragon Shield"> <img alt="DragonShield" style={{width:window.innerWidth/5.5+"px",height:window.innerWidth/5.5/0.83+"px"}} src={dragonShieldSingle}/> </a>
                    <h3>Dragon Shield</h3>
                    <h5>{localStorage.getItem('countryCode')=='+55'?'Excelente, não é barato, mas vale cada centavo.':"Excelence, it's not cheap, but it's worth every peny"}</h5>
                    <a style={{color:'black'}} className="deckPageButtonOrange2" target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Dragon Shield"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","amazon"]}/> {localStorage.getItem('countryCode')==='+55'?'Comprar na Amazon':'Buy on Amazon'} </a>
                </nav>
                <nav className="shieldArea" style={{backgroundColor:centralColor,display:'inline-block',width:window.innerWidth*defaultSize/3}}>
                <a target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Central Shield"> <img alt="CentralShield" style={{width:window.innerWidth/5.5+"px",height:window.innerWidth/5.5/0.83+"px"}} src={centralShieldSingle}/> </a>
                    <h3>Central Shield</h3>
                    <h5>{localStorage.getItem('countryCode')==='+55'?'Serve bem, o melhor custo benefício.':"Ok shield, good cost"}</h5>
                    <a style={{color:'black'}} className="deckPageButtonOrange2" target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Central Shield"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","amazon"]}/> {localStorage.getItem('countryCode')==='+55'?'Comprar na Amazon':'Buy on Amazon'} </a>
                </nav>
                <nav className="shieldArea" style={{backgroundColor:bucaneirosColor,display:'inline-block',width:window.innerWidth*defaultSize/3}}>
                <a target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Sleeve bucaneiros"> <img alt="Bucaneiros" style={{width:window.innerWidth/5.5+"px",height:window.innerWidth/5.5/0.83+"px"}} src={bucaneirosShieldSingle}/> </a>
                    <h3>Bucaneiros Shield</h3>
                    <h5>{localStorage.getItem('countryCode')==='+55'?'Baratissímo, legal pra decks baratinhos.':"Cheap shield, nice for very cheap decks"}</h5>
                    <a style={{color:'black'}} className="deckPageButtonOrange2" target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=92950725-20&linkCode=ur2&linkId=d09adbaca1d83995160295d3780692d1&camp=1789&creative=9325&index=toys&keywords=Sleeve Bucaneiros"> <FontAwesomeIcon style={{marginRight:'2px'}} icon={["fab","amazon"]}/> {localStorage.getItem('countryCode')==='+55'?'Comprar na Amazon':'Buy on Amazon'} </a>
                </nav>  
                
            </div>

        );
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    

    render(){
        if (this.state.problemOnLoad===true){
            return <ErrorPage/>
        }
        let width = window.innerWidth-(-20+window.innerWidth/5)-(window.innerWidth*0.03)-window.innerWidth*0.25;
        let widthIneer = window.innerWidth*0.2;
        if (this.state.deckListOk===false){
            return <GlobalNavBar></GlobalNavBar>
        }

        
        return (
            <div className="deckPageGlobal" key="global" id="animate-area">

                <div style={{minHeight:"94vh", maxWidth:'100%'}} >
                    
                    {this.topPropaganda()}
                    <h4 style={{padding:'1px',marginLeft:'5px', marginTop:'3px'}}> {this.state.loadedDecklistTuples[0].deckname}{localStorage.getItem('countryCode')==='+55'?' por ':' by '} <Link to={"/users/"+this.state.loadedDecklistTuples[0].player+'/decks'} className="playerDisplayLine" >{this.changeDisplayName(this.state.loadedDecklistTuples[0].player)}</Link></h4>
                    <h5 style={{padding:'1px',marginLeft:'5px', marginTop:'3px'}}>{localStorage.getItem('countryCode')==='+55'?' Visualizações: ':' Views: '} 
                        {this.state.qtViews}. { this.state.loadedPrices?<></>:localStorage.getItem('countryCode')==='+55'?'Carregando  novos preços das cartas...':'Loading new card prices...'}
                    </h5>
                    
                    {this.state.bannedCards.length>0?<h4 style={{padding:'1px',marginLeft:'5px', marginTop:'3px'}}> {localStorage.getItem('countryCode')==='+55'?'Esse deck contém cartas banidas, estão em vermelho':' This deck has banned cards, they are in red'} </h4>:<></>}
                    
                    {this.displayTab()}
                    {this.displaySelect()}
                    {this.presentationSelect()}
                    
                    <div style={{paddingLeft:this.getPagePaddingLeft()}}>
                        
                        {this.getDeckWindow()}
                        {this.getSideboardWindow()}
                        {this.getMaybeboardWindow()}
                        <div style={{textAlign:'center',fontWeight:'800'}}>{this.state.bottomMessage}</div>
                        <div style={{display:this.state.loadingDisplay}}>
            
                            <div style={{marginLeft:'48%'}}>
                                <ReactLoading type={'spokes'} color={this.state.loadingBarColor} width={'15%'} height={'15%'} />
                            </div>
                            
                            <div style={{ marginLeft:'35%', marginBottom:'1%', width:'35%',display: "inline-block",textAlign:'center',backgroundColor:this.state.loadingBarColor,color:'snow',fontSize:'20px',padding:'5px'}}> {localStorage.getItem('countryCode')==='+55'?this.state.textsPt.loadingMessage:this.state.textsEn.loadingMessage}  </div>
                            
                        </div>
                        
                        {this.getHiddenImgs()}

                        <div style={{position:"fixed",display:this.getMovingImageDisplay(), top:"15%" ,right: "1.2%"}}>
                            <div className="specialFont" style={{marginBottom:'1.4%'}}>{this.state.lastCategorie}</div>
                            
                            {this.showDefaultCardImage()}
                            {this.flipImageButton()}
                            <div className="specialFont"> R$ {this.state.lastPrice} </div>
                            
                        </div>
                    </div>
                    
                    {this.renderButtons()}
                </div>
                
                
               
            </div>)
    }

}
export default withRouter(DeckPageOp);
